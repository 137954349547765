export function getRowNum(idx, page) {
	return (page - 1) * 10 + idx + 1;
}
export const getConditionPrice = (conditions, clientId) => {
	if (!conditions.length) {
		return null;
	}
	let price = conditions.find(condition => parseInt(condition.toString().split(':')[0]) === clientId)
	if (price) {
		price = price.split(':')[1]
	}
	return price || null;
}