import React, {useEffect} from 'react';
import './Home.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderHomePage from '../../content/HomeSlider/HomeSlider';
import About from '../../content/About/About';
import CountSlider from '../../content/CountSlider/CountSlider';
import HomeText from '../../content/HomeText/HomeText';
import Calculator from '../../containers/Calculator/Calculator';
import Feedback from '../../content/Feedback/Feedback';
import Footer from '../../layout/Footer/Footer';
import ScrollPage from '../../layout/ScrollPage/ScrollPage';
import ForCompanies from '../../content/ForCompanies/ForCompanies';
import StepsHowTo from '../../content/StepsHowTo/StepsHowTo';
import OneClickEstimate from '../../content/OneClickEstimate/OneClickEstimate';
import CategoryList from '../../content/CategoryList/CategoryList';
import Advantages from '../../content/Advantages/Advantages';
import HowItWork from '../../content/HowItWork/HowItWork';
import ModalContainer from '../../content/Modal/ModalContainer';
import WorkersSlider from '../../content/WorkersSlider/WorkersSlider';

const Home = () => {
	useEffect(()=> {
		
	}, []);
	return (
		<div className="Home">
			<ModalContainer/>
			<SliderHomePage/>
			<About/>
			<ForCompanies/>
			<CategoryList/>
			<Advantages/>
			<CountSlider/>
			<StepsHowTo/>
			{/* <HomeText/> */}
			<Calculator/>
			<OneClickEstimate/>
			<HowItWork/>
			<WorkersSlider/>
			<Feedback/>
			<Footer/>
			<ScrollPage/>
		</div>
	);
};

export default Home;