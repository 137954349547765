import React, { useEffect, useState} from 'react';
import './IndividualWorks.scss';
import {
	fetchOrderConditions,
	fetchServiceSelectCategories,
	fetchServiceSelectServices,
	setServiceSelectProperty
} from '../../../../store/actions/serviceSelectActions';
import {useDispatch, useSelector} from 'react-redux';
import {getOrderState, getServiceSelectState, getWorkerPersonalState} from '../../../../store/selectors';
import IndividualWorksCategories from './IndividualWorksCategories';
import {validateControl} from '../../../../form/formFramework';
import {
	setOrderFormControlValue,
	setOrderProperty
} from '../../../../store/actions/orderActions';
import IndividualWorksSteps from './IndividualWorksSteps';
import IndividualWorksButton from './IndividualWorksButton';
import IndividualWorksSubCategories from './IndividualWorksSubCategories';
import IndividualWorksConditions from './IndividualWorksConditions';
import IndividualWorksAdditions from './IndividualWorksAdditions';
import IndividualWorksConfirm from './IndividualWorksConfirm';
import {useHistory} from 'react-router-dom';
import IndividualWorksBottomTotal from './IndividualWorksBottomTotal';
import {useCalculatorContext} from '../CalculatorContext';
import {setActiveNavAnchor} from '../../../../store/actions/navActions';
import {CALCULATOR_ANCHOR} from '../../../../config';

const IndividualWorks = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {user} = useSelector(getWorkerPersonalState);
	const {
		categories, services, formTouched,
	} = useSelector(getServiceSelectState);
	const {
		service, subCategory, category, quantity,
		clear, formControls,
	} = useSelector(getOrderState);

	const {
		setServiceHandler, step, setStep, currentServiceId,
		setCurrentCategoryId, setCurrentSubCategoryId, setCurrentServiceId
	} = useCalculatorContext();
	const [formValid, setFormValid] = useState(true);

	const formValidHandler = () => {

		let formValid = true

		Object.keys(formControls).forEach(property => {
			const formControl = formControls[property];
			const error = validateControl(formControl.value, formControl.validation, property);
			const valid = !error;
			dispatch(setOrderFormControlValue(formControl.value, property, error));
			formValid = valid && formValid;
		});
		formValid = formValid && !!service;
		setFormValid(formValid);
		dispatch(setServiceSelectProperty(true, 'formTouched'));

		return formValid;
	};

	const submitHandler = () => {
		if (formValidHandler() && formTouched) {
			setStep(3)
			dispatch(setActiveNavAnchor(CALCULATOR_ANCHOR))
		} else {
			dispatch(setServiceSelectProperty(true, 'formTouched'))
		}
	};

	const confirmHandler = () => {
		history.push("/calculate");
	};


	const backToSubCategory = () => {
		setStep(1);
		setCurrentSubCategoryId('');
		dispatch(setOrderProperty('', 'subCategory'));
		localStorage.removeItem('subCategory')
		localStorage.setItem('serviceSelectStep', 1)
	};

	const backToCategory = () => {
		setStep(0);
		setCurrentServiceId('');
		setCurrentSubCategoryId('');
		setCurrentCategoryId('');
		localStorage.removeItem('service')
		localStorage.removeItem('subCategory')
		localStorage.removeItem('category')
		localStorage.setItem('serviceSelectStep', 0)
		dispatch(setOrderProperty('', 'service'));
		dispatch(setOrderProperty('', 'subCategory'));
		dispatch(setOrderProperty('', 'category'));
	};

	useEffect(() => {
		if (clear) {
			setServiceHandler('', 'category')
			setServiceHandler('', 'subCategory')
			setServiceHandler('', 'service')
		}
	}, [clear])

	useEffect(() => {
		if (!categories.length) {
			dispatch(fetchServiceSelectCategories());
		}
	}, [fetchServiceSelectCategories, categories, dispatch]);

	useEffect(() => {
		if (!services.length && !user.id) {
			dispatch(fetchServiceSelectServices());
		}
	}, [fetchServiceSelectServices, services, user, dispatch]);

	useEffect(() => {
		if (user.id) {
			dispatch(fetchServiceSelectServices());
		}
	}, [fetchServiceSelectServices, user, dispatch]);

	useEffect(() => {
		if (service || subCategory || category || quantity) {
			dispatch(setServiceSelectProperty(true, 'formTouched'));
		}
	}, [service, subCategory, category, quantity])

	useEffect(() => {
		dispatch(fetchOrderConditions())
	}, [dispatch])


	return (
		<div className="IndividualWorks">
			<div className="IndividualWorks__header">
				<IndividualWorksSteps currentStep={step}/>
			</div>
			<div className="IndividualWorks__body">
				{!step && <IndividualWorksCategories/>}
				{step === 1 && <IndividualWorksSubCategories back={backToCategory}/>}
				{step === 2 && <IndividualWorksConditions formValidHandler={formValidHandler}/>}
				{step === 3 && <IndividualWorksAdditions/>}
				{step === 4 && <IndividualWorksConfirm/>}

			</div>
			<div className={`IndividualWorks__footer ${!step || step === 1 ? "IndividualWorks__footer-first" : ""}`}>
				{!step && <IndividualWorksButton title="" active={false}/>}
				{step === 1 && <IndividualWorksButton title="Показать цену" active={currentServiceId}
													  onClick={() => {
														  setStep(2)
														  dispatch(setActiveNavAnchor(CALCULATOR_ANCHOR))
													  }}/>}
				{step === 2 && <>
					<IndividualWorksButton title="Назад"
										   back
										   className="d-none d-sm-flex"
										   onClick={backToSubCategory}/>
					<IndividualWorksBottomTotal/>
					<IndividualWorksButton title="Назад"
										   back
										   className="d-flex d-sm-none"
										   onClick={backToSubCategory}/>
					<IndividualWorksButton title="Укажите условия"
										   active={!!formValid && !!service}
										   forward
										   onClick={submitHandler}/>
				</>}
				{step === 3 && <>
					<IndividualWorksButton title="Назад"
										   back
										   className="d-none d-sm-flex"
										   onClick={() => setStep(2)}/>
					<IndividualWorksBottomTotal/>
					<IndividualWorksButton title="Назад"
										   back
										   className="d-flex d-sm-none"
										   onClick={() => setStep(2)}/>
					<IndividualWorksButton title="Проверьте заказ"
										   active
										   forward
										   onClick={() => {
											   setStep(4)
											   dispatch(setActiveNavAnchor(CALCULATOR_ANCHOR))
										   }}/>
				</>}

				{step === 4 && <>
					<IndividualWorksButton title="Назад"
										   back
										   onClick={() => setStep(3)}/>
					<IndividualWorksButton title="Получить график работ и детализацию оплат" active
										   onClick={confirmHandler}/>
				</>}

			</div>
		</div>
	);
};

export default IndividualWorks;
