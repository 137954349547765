import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@material-ui/core';

const AdminOrderListDetailScoreboardTable = ({order, count}) => {
	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<Paper component={'thead'}>
					<TableRow className="AdminOrderList__table-head">
						<TableCell className="AdminOrderList__cell px-3 py-2 border-right">
							<Typography align='center'>№</Typography>
						</TableCell>
						<TableCell align="left" className="AdminOrderList__cell p-3 large border-right">
							<Typography>Наименование работ</Typography>
						</TableCell>
						<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
							<Typography>Ед. изм.</Typography>
						</TableCell>
						<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
							<Typography>Кол-во</Typography>
						</TableCell>
						<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
							<Typography>Цена</Typography>
						</TableCell>
						<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
							<Typography>Сумма, руб.</Typography>
						</TableCell>
					</TableRow>
				</Paper>
				<TableBody>
					<TableRow>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"

						>
							<Typography>1</Typography>
						</TableCell>
						<TableCell align="justify"
								   className="AdminOrderList__row-title p-2 bg-light"
						>
							<Typography>{order.serviceName}</Typography>
						</TableCell>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"
						>
							<Typography>{order.unit}</Typography>
						</TableCell>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"
						>
							<Typography>{count}</Typography>
						</TableCell>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"
						>
							<Typography>{order.unitPrice}</Typography>
						</TableCell>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"
						>
							<Typography>{(order.unitPrice * count).toFixed(2)}</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"

						>
							<Typography>Итого</Typography>
						</TableCell>
						<TableCell className="bg-light"/>
						<TableCell className="bg-light"/>
						<TableCell className="bg-light"/>
						<TableCell className="bg-light"/>
						<TableCell align="center"
								   className="AdminOrderList__row-title p-2 bg-light"

						>
							<Typography>{(order.unitPrice * count).toFixed(2)}</Typography>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default AdminOrderListDetailScoreboardTable;