import React, { useEffect } from "react";
import { connect } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";
import { useDispatch } from "react-redux";
import {
	countSliderChangeCategory,
	countSliderSetCategories,
	countSliderSetServices,
} from "../../../store/actions/countSliderAxtions";
import cn from "./CategoryList.module.scss";
import CategoryListSlider from "./CategoryListSlider";
import CategoryListTextItem from "./CategoryListTextItem";
import { useHistory } from "react-router";
import { setActiveNavAnchor } from "../../../store/actions/navActions";
import { CALCULATOR_ANCHOR } from "../../../config";
import yandexGoal from "../../../utils/yandexGoal";
import GreenButton from "../../../components/UI/GreenButton/GreenButton";

const CategoryList = (props) => {
	const { categories } = props.categorySlider;
	const dispatch = useDispatch();
	const history = useHistory();

	const { fetchedServices, fetchedCategories } = props;

	useEffect(() => {
		//Фильтрация и диспатч категорий и услуг
		if (!fetchedCategories.length || !fetchedServices.length) return;
		const activeServices = fetchedServices.filter((service) => {
			let active = false;
			if (!!service.items.length) {
				service.items.forEach((i) => {
					active = active || !!i.norms.length;
				});
			}
			return active || !!service.norms.length;
		});
		const activeCategories = fetchedCategories.filter((c) => {
			return activeServices.some((s) => s.category.id === c.id);
		});

		activeCategories.sort((a, b) => a.sort - b.sort);
		activeServices.sort((a, b) => a.sort - b.sort);

		props.countSliderSetCategories(activeCategories);
		props.countSliderSetServices(activeServices);
		props.countSliderChangeCategory(activeCategories[0].id);
	}, [fetchedServices, fetchedCategories]);

	const goToEstimate = () => {
		history.replace("/");
		dispatch(setActiveNavAnchor(CALCULATOR_ANCHOR));
		yandexGoal("BANNER_BUTTON");
	};

	const textData = [
		{
			id: 0,
			title: "Отдельные виды работ",
			text: (
				<>
					Оформите заказ самостоятельно, выбрав вид работы из{" "}
					<span onClick={goToEstimate}>перечня</span> или{" "}
					<a href={`tel:+78007003877`}>позвоните нам</a>. Укажите объем и срок
					выполнения работ. Оформите заказ по заданной форме. Ваш заказ появится
					в «Ленте заказов» в приложении Finish Worker на{" "}
					<a
						href='https://play.google.com/store/apps/details?id=com.finishworker&hl=ru&gl=US'
						target='_blank'
						rel='noreffer'>
						Android
					</a>{" "}
					или{" "}
					<a
						href='https://apps.apple.com/ru/app/finish-worker/id1499489035'
						target='_blank'
						rel='noreffer'>
						IOS
					</a>
					.
				</>
			),
		},
		{
			id: 1,
			title: "Комплексное строительство",
			text: (
				<>
					Вы можете выбрать сразу все работы, в комплексе, составив с помощью
					нашего сервиса самостоятельно смету, или доверить ее составление нам,
					и оформить весь <span onClick={goToEstimate}>комплекс работ</span>.
				</>
			),
		},
	];

	return (
		<section className={cn.main}>
			<h3 className={cn.mainTitle}>
				Оформите заказ и управляйте им в мобильном приложении
				<br />
				Сервис Finish Worker позволяет самостоятельно вести все виды
				строительных работ и контролировать ход работ без необходимости
				постоянного присутствия на объекте. Достаточно выбрать услугу, объем и
				срок выполнения работ.
				<br />
			</h3>
			<div className={`${cn.mainWrapper} row`}>
				<div className={`${cn.mainTextBlock} col-12 col-lg-6`}>
					{textData.map((item) => (
						<CategoryListTextItem key={item.id} title={item.title}>
							{item.text}
						</CategoryListTextItem>
					))}
				</div>
				<div className={`${cn.mainSliderBlock} col-12 col-lg-6`}>
					<div className={cn.mainSliderWrapper}>
						<div className={cn.mainSliderHead}>
							<b>Виды работ</b>
						</div>
						<div className={cn.mainSliderContent}>
							{!!categories.length ? (
								<CategoryListSlider
									categories={categories}
									beforeChange={props.countSliderChangeCategory}
								/>
							) : (
								<SyncLoader className='SyncLoader' color='#7B53DA' />
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={cn.mainFooter}>
				<h3 className={cn.mainTitle}>
					В сервисе назначается Менеджер вашего заказа. Менеджер определяет
					актуальные задачи строителям, проверяет по ним отчет и принимает
					результаты работ. Депонирование средств происходит при оформлении
					заказа по удобному вам графику на номинальный счет платежного сервиса
					банка-партнера сервиса. Неотработанные средства возвращаются вам
					обратно. Исполнителям перечисляется оплата только за объем работ,
					принятый менеджером.
				</h3>
				<div className='SliderHomePage__button-wrapper'>
					<GreenButton
						className='SliderHomePage__button'
						onClick={goToEstimate}>
						Заказать
					</GreenButton>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	categorySlider: state.categorySlider,
	fetchedServices: state.serviceSelect.services,
	fetchedCategories: state.serviceSelect.categories,
});
const mapDispatchToprops = {
	countSliderChangeCategory,
	countSliderSetServices,
	countSliderSetCategories,
};
export default connect(mapStateToProps, mapDispatchToprops)(CategoryList);
