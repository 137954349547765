import React from 'react';
import './ActKSTopLine.scss'

const ActKsTopLine = ({name, underLine, text, groupName, cell}) => {
    return (
        <div className="ActKsTopLine">
            <div>
                {name}
            </div>
            <div>
                <span>{text}</span>
                <span>{underLine}</span>
            </div>
            <div>
                {groupName}
            </div>
            <div>
                {cell}
            </div>
        </div>
    );
}

export default ActKsTopLine;