import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {WorkerImageUploader} from "../../WorkerComponents";
import {renderFormControl} from "../../../../form/formFramework";


const WorkerMainInfo = ({controls, formControlHandler, picture = null, title = 'Личные данные'}) => {

    return (
        <Paper className='p-3 mt-2'>
            {
                title &&
                <Typography variant="button" display="block" className='py-3'>
                    {title}
                </Typography>
            }
            <div className='row'>
                <div className="col-12 col-sm-2 d-flex align-items-center flex-column">
                    <WorkerImageUploader
                        picture={picture}
                        maxSize={5000}
                        handler={formControlHandler}
                    />
                </div>
                <div className="col-12 col-sm-10">
                    <div className="row">
                        {
                            Object.keys(controls).map(property => {
                                const control = controls[property];
                                return control.id !== 'photo' && control.id !== 'description'
                                    ?
                                    (
                                        <div key={control.id} className="col-12 col-sm-6 mt-3">
                                            {renderFormControl(control, formControlHandler, {
                                                // disabled: control.id === 'email',
                                                addLabel: control.validation.required ? ' *' : ''})}
                                        </div>
                                    )
                                    : null
                            })
                        }
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default WorkerMainInfo;