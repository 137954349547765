import axios from "../../rest/axios";

import {
  ESTIMATE_CHANGE_CHECKBOX_SELECT,
  ESTIMATE_ADD_SERVICES_TO_ORDER,
  ESTIMATE_NEXT_STEP,
  ESTIMATE_PREV_STEP,
  ESTIMATE_SET_FORM_ITEM_VALUE,
  ESTIMATE_SET_VALUE,
  ESTIMATE_FILTER_SERVICES,
  ESTIMATE_FILTER_CATEGORIES,
  ESTIMATE_FILTER_SUBCATEGORIES,
  ESTIMATE_FETCH_CONDITIONS_SUCCESS,
  ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS,
  ESTIMATE_RESET_ORDER,
  ESTIMATE_RESET_VALUE
} from "./actionTypes";

export function setFormItemValue(value, id) {
  return {
    type: ESTIMATE_SET_FORM_ITEM_VALUE,
    payload: {
      id,
      value,
    },
  };
}

export function changeCheckboxSelect(id, value) {
  return {
    type: ESTIMATE_CHANGE_CHECKBOX_SELECT,
    payload: {
      id,
      value,
    },
  };
}

export function setValue(id, value) {
  return {
    type: ESTIMATE_SET_VALUE,
    payload: {
      id,
      value,
    },
  };
}

export function nextStep() {
  return {
    type: ESTIMATE_NEXT_STEP,
  };
}

export function prevStep() {
  return {
    type: ESTIMATE_PREV_STEP,
  };
}

export const addServices = (payload) => ({
  type: ESTIMATE_ADD_SERVICES_TO_ORDER,
  payload,
});

export const setFilterCategories = (activeCategories) => ({
  type: ESTIMATE_FILTER_CATEGORIES,
  payload: {
    activeCategories,
  },
});

export const setFilterSubCategories = (activeSubCategories) => ({
  type: ESTIMATE_FILTER_SUBCATEGORIES,
  payload: {
    activeSubCategories,
  },
});

export const setFilterServices = (activeServices) => ({
  type: ESTIMATE_FILTER_SERVICES,
  payload: {
    activeServices,
  },
});

export const estimateFetchConditions = () => {
	return async dispatch => {
		try {
			const {data} = await axios.get('/admin/orders/conditions/');
			dispatch({type: ESTIMATE_FETCH_CONDITIONS_SUCCESS, payload: {...data.data}})
		} catch (e) {
      console.error(e)
		}
	}
}

export const estimateChangeServiceAnimateItems = (item) => ({
  type: ESTIMATE_CHANGE_SERVICE_ANIMATE_ITEMS,
  payload: {
    item
  }
})

export const estimateResetorder = () => ({
  type: ESTIMATE_RESET_ORDER,
})