import {
	REMOVE_SELECTED_MASTER,
	SET_SELECTED_MASTER, WORKER_SELECT_CLEAR_WORKERS,
	WORKER_SELECT_DETAIL_SUCCESS,
	WORKER_SELECT_SEARCH_SUCCESS,
	WORKER_SELECT_SET_MASTERS
} from "../actions/actionTypes";

const initialState = {
	masters: [],
	selectedMasters: [],
	activeMaster: {},
	loading: false,
	error: null
};

export default function (state = initialState, {type, payload}) {
	let selectedMasters = [...state.selectedMasters]

	switch (type) {
		case WORKER_SELECT_SEARCH_SUCCESS:
			return {
				...state,
				masters: payload.masters
			}
		case WORKER_SELECT_SET_MASTERS:
			return {
				...state,
				masters: [...state.masters, payload.master]
			};
		// case WORKER_SELECT_DETAIL_SUCCESS:
		//     return {
		//         ...state,
		//         activeMaster: payload.master
		//     }
		case WORKER_SELECT_DETAIL_SUCCESS:
			return {
				...state,
				masters: [],
				selectedMasters: [payload.master, ...state.selectedMasters]
			}
		case SET_SELECTED_MASTER:
			let master = state.selectedMasters.find(master => master.id === state.activeMaster.id);
			if (!master) {
				selectedMasters = [state.activeMaster, ...state.selectedMasters]
			}
			return {
				...state,
				activeMaster: {},
				selectedMasters,
			}
		case REMOVE_SELECTED_MASTER:
			return {
				...state,
				selectedMasters: selectedMasters.filter(master => master.id !== payload.masterId)
			};
		case WORKER_SELECT_CLEAR_WORKERS:
			return {
				...state,
				selectedMasters: []
			};
		default:
			return state
	}
}