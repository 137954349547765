import React, {useEffect, useState} from 'react'
import './Personal.scss'
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter, Switch, Route, useHistory} from "react-router-dom";

import {workerPersonalLogin} from "../../store/actions/workerPersonalActions";
import WorkerAuthForm from "../Worker/WorkerAuthForm/WorkerAuthForm";
import Page from "../../hoc/Page/Page";
import {WorkerFooter, WorkerTopNav} from "../Worker/WorkerComponents";
import PersonalOrderList from "./components/PersonalOrderList/PersonalOrderList";
import PersonalTabs from "../../components/PersonalTabs/PersonalTabs";
import PersonalOrderItem from "./components/PersonalOrderItem/PersonalOrderItem";
import {getIsLogin, getPersonalLoading, getUserRole} from "../../store/selectors";
import {ROLE_ADMINISTRATORS, ROLE_ADMINS, ROLE_CLIENT} from "../../config";


const Personal = ({personalTabs}) => {

	const [currentTab, setCurrentTab] = useState(1);
	const history = useHistory();
	const role = useSelector(getUserRole);
	const isLogin = useSelector(getIsLogin);
	const loading = useSelector(getPersonalLoading);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isLogin) {
			dispatch(workerPersonalLogin())
		}
	}, [isLogin, dispatch]);

	useEffect(() => {
		if (role && isLogin) {
			if (role === ROLE_CLIENT) {
				history.push('/personal/orders')
			} else if (role === ROLE_ADMINISTRATORS || role === ROLE_ADMINS) {
				history.push('/admin')
			} else {
				history.push('/worker')
			}
		}

	}, [role, history, isLogin]);

	// if (loading) {
	//     return <Loader/>
	// }

	const title = 'Личный кабинет';
	return (
		<>
			{
				!isLogin && !loading ?
					<WorkerAuthForm/>
					:
					<Page title={title}>
						{
							role === ROLE_CLIENT &&
							<div className='Personal'>
								<WorkerTopNav title={title}/>
								<div className="Personal__content container">
									<div className="py-4 px-3">
										<PersonalTabs
											currentTab={currentTab}
											tabs={personalTabs}
											switchTab={setCurrentTab}
										/>
									</div>
									<Switch>
										<Route
											path={"/personal/orders/:id"}
											component={PersonalOrderItem}
										/>
										<Route
											path={"/personal/orders"}
											component={PersonalOrderList}
											exact
										/>
										{/*<Redirect to={"/personal/orders"}/>*/}
									</Switch>

								</div>
								<WorkerFooter/>
							</div>
						}
					</Page>
			}
		</>
	)

}

function mapStateToProps(state)
{
	return {
		isLogin: !!state.workerPersonal.isLogin,
		loading: state.workerPersonal.loading,
		user: state.workerPersonal.user,
		isClient: state.workerPersonal.isClient,
		currentTab: state.personal.currentTab,
		personalTabs: state.personal.personalTabs,
	}
}

const actions =
{
	workerPersonalLogin,
}


export default withRouter(connect(mapStateToProps, actions)(Personal))