import React, {useEffect} from 'react';
import LoaderAdmin from "../../../components/UI/LoaderAdmin/Loader";
import List from "@material-ui/core/List";
import {ListItem, TextField} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AdminActive from "./AdminUI/AdminActive";
import AdminBtn from "./AdminUI/AdminBtn";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import SaveIcon from "@material-ui/icons/Save";

import {useDispatch, useSelector} from "react-redux";
import {
    addAddress,
    deleteAddress,
    fetchDates,
    saveAdminAddresses,
    setAddressProperty
} from "../../../store/actions/adminDatesActions";
import cuid from 'cuid'

const AdminAddresses = () => {
    const {loading, addresses} = useSelector(state => state.adminDates);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!addresses.length) {
            dispatch(fetchDates())
        }
    }, [addresses, dispatch]);

    const editHandler = (id) => {
        dispatch(setAddressProperty(id, true, 'edit'));
        dispatch(setAddressProperty(id, true, 'edited'));
    };

    const deleteHandler = (id, isNew) => {
        dispatch(setAddressProperty(id, true, 'edited'));
        isNew ? dispatch(deleteAddress(id)) : dispatch(setAddressProperty(id, true, 'deleted'));
    }

    const addAddressHandler = () => {
        const address = {
            id: cuid(),
            value: '',
            edit: true,
            edited: true,
            isNew: true
        };
        dispatch(addAddress(address));
    };

    return (
        <>
            {
                loading ?
                    <LoaderAdmin/>
                    :
                    <>
                        <div>
                            <h3 className='text-center'>Адреса инструктажей</h3>
                        </div>
                        <List>
                            {
                                addresses.map((address, address_idx) => {
                                    return (
                                        <ListItem key={address.id} className='Admin_material-list-item px-0'>
                                            <Paper className='w-100 h-100 py-1'>
                                                <div
                                                    className='Admin_material-list-content border-top border-bottom border-gray px-5 py-3'>
                                                    <div
                                                        className='d-flex justify-content-between py-3'>
                                                        <div className='d-flex align-items-center w-100'>
                                                               <span className='mr-3'>
                                                                   {address_idx + 1}.
                                                               </span>
                                                            {
                                                                address.edit
                                                                    ?
                                                                    <TextField
                                                                        value={address.value}
                                                                        className='w-100 px-3'
                                                                        onChange={(e) => dispatch(setAddressProperty(address.id, e.target.value, 'value'))}
                                                                    />
                                                                    : <>
                                                                        {address.value}
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className='d-flex'>

                                                            <AdminActive active={true} edited={address.edited}/>
                                                            {
                                                                address.edit
                                                                    ?
                                                                    <AdminBtn
                                                                        tip="Сохранить адрес"
                                                                        type="save"
                                                                        onClick={() => dispatch(setAddressProperty(address.id, false, 'edit'))}
                                                                    />
                                                                    :
                                                                    <AdminBtn
                                                                        tip="Редактировать адрес"
                                                                        type="edit"
                                                                        onClick={() => editHandler(address.id)}
                                                                    />
                                                            }
                                                            {
                                                                address.deleted
                                                                    ?
                                                                    <AdminBtn tip="Восстановить адрес"
                                                                              type="restore"
                                                                              onClick={() => dispatch(setAddressProperty(address.id, false, 'deleted'))}
                                                                    />
                                                                    :
                                                                    <AdminBtn tip="Удалить адрес" type="delete"
                                                                              onClick={() => deleteHandler(address.id, address.isNew)}
                                                                    />
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </Paper>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>


                        <Paper className="mt-2">
                            <div className="d-flex p-3 justify-content-end">
                                <Button
                                    className="Admin_btn text mr-2"
                                    variant="contained"
                                    color="primary"
                                    onClick={addAddressHandler}
                                >
                                    <AddIcon
                                        className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                    <Typography variant="overline" display="block">
                                        Добавить адреса
                                    </Typography>
                                </Button>
                                <Button
                                    className="Admin_btn text "
                                    variant="contained"
                                    color="primary"
                                       onClick={()=>dispatch(saveAdminAddresses())}
                                >
                                    <SaveIcon
                                        className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                    <Typography variant="overline" display="block">
                                        Сохранить изменения
                                    </Typography>
                                </Button>
                            </div>

                        </Paper>

                        {/*{*/}
                        {/*    saveResult.title &&*/}
                        {/*    <AdminPopup saveResult={saveResult} clearResultHandler={this.clearResultHandler}/>*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    saveInProcess &&*/}
                        {/*    <AdminSendLoader/>*/}
                        {/*}*/}

                    </>

            }
        </>
    );
};

export default AdminAddresses;