import React from 'react';
import {useSelector} from 'react-redux';
import {getOrderState, getServiceSelectState} from '../../../../store/selectors';
import parse from 'html-react-parser';
import NumberFormat from 'react-number-format';
import {useConditionsContext} from '../ConditionsContext';
import {declOfNum} from '../../../../utils/declOfNum';

const IndividualWorksConfirm = () => {
	const {
		currentCategory, currentService, workType,
		quantity, startDate, dueDate, needMaterials, classifiers, workerCount,
		locationObject, floor, materialPlace, withInstrument, withHousing, withEquipment, docs,
	} = useSelector(getOrderState);
	const {
		docsTabs, conditions: {
			locationObjectConditions,
			floorConditions,
			instrumentConditions,
			equipmentConditions,
			housingConditions,
			materialPlaceConditions,
		}
	} = useSelector(getServiceSelectState);

	const {servicePrice, totalServicePrice, materialPrice, sum} = useConditionsContext();

	return (
		<div className="IndividualWorks__confirm">

			<div className="IndividualWorks__conditions-desc">
				Проверьте выбранный заказ
			</div>

			{
				!!currentCategory && !!currentService
				&& <div className="IndividualWorks__confirm-top">

					<div className="d-flex align-items-baseline justify-content-between pb-3 flex-column flex-sm-row">

						<div className="d-flex flex-column IndividualWorks__confirm-top-main">
							<div className="d-flex align-items-baseline">
								<img className="IndividualWorks__confirm-img" src={currentCategory.picture} alt=""/>
								<div className="d-flex align-items-baseline mt-2">
									<div className="IndividualWorks__top-sub-title">
										Цена:&nbsp;
									</div>
									<div>
										<NumberFormat
											value={servicePrice}
											displayType={'text'}
											thousandSeparator={" "}/>&nbsp;₽/{parse(currentService.unit)}
									</div>
								</div>
							</div>
							<div className="IndividualWorks__top-name">
								{currentService.name}
							</div>
						</div>
						<div className="d-flex align-items-center flex-column flex-sm-row w-100">
							<div className="d-flex flex-column ml-0 ml-sm-2 mt-3 mt-sm-0">
								<div className="d-flex align-items-baseline justify-content-center justify-content-sm-start">
									<svg width="25" height="25" viewBox="0 0 25 25" fill="none">
										<path
											d="M19.9696 14.9972C19.4717 14.9972 19.0983 15.122 18.7248 15.122L15.1149 11.5011L18.1024 8.50453L21.9613 7.13108L24.8244 2.51132C24.9489 2.2616 24.9489 1.88703 24.8244 1.76217L23.3306 0.263865C23.0817 0.0141482 22.7082 0.0141481 22.4593 0.139007L17.729 2.88589L16.2352 6.88137L13.2477 9.87797L9.88669 6.25707C9.88669 5.8825 10.0112 5.50792 10.0112 5.00849C10.0112 1.76217 6.89915 -0.610144 3.78713 0.139007L5.65434 2.01188C6.65019 3.01075 6.65019 4.50906 5.65434 5.50792C4.65849 6.50679 3.16473 6.50679 2.16888 5.50792L0.177184 3.7599C-0.818663 7.3808 2.54232 10.8768 6.27675 9.75311L9.88669 13.374L8.39292 14.8723C7.52156 14.6226 6.65019 14.8723 6.02778 15.4966L0.924069 20.6158C-0.0717775 21.6147 -0.196258 23.113 0.675108 24.1118C1.67095 25.2356 3.28921 25.2356 4.28505 24.2367L9.51325 18.9926C10.1357 18.3684 10.3846 17.3695 10.1357 16.6203L11.6294 15.122L15.2394 18.7429C15.1149 19.1175 15.1149 19.6169 15.1149 19.9915C15.1149 23.2378 18.2269 25.6101 21.3389 24.861L19.4717 22.9881C18.4759 21.9892 18.4759 20.4909 19.4717 19.4921C20.4676 18.4932 21.9613 18.4932 22.9572 19.4921L24.8244 21.365C25.6958 18.1186 23.2061 14.9972 19.9696 14.9972Z"
											fill="#7B53DA"/>
									</svg>
								</div>
								<div className="d-flex align-items-baseline mt-2">
									<div className="IndividualWorks__top-sub-title">
										Объем работ:&nbsp;
									</div>
									<div className="IndividualWorks__top-sub-num">
										{quantity}&nbsp;{parse(currentService.unit)}
									</div>
								</div>
								<div className="d-flex align-items-baseline">
									<div className="IndividualWorks__top-sub-title">
										Уровень работ:&nbsp;
									</div>
									<div className="IndividualWorks__top-sub-num">
										{workType === 1 ? 'Стандарт' : 'Премиум'}
									</div>
								</div>
							</div>
							<div className="d-flex flex-column ml-0 ml-sm-2 mt-3 mt-sm-0">
								<div className="d-flex align-items-baseline justify-content-center justify-content-sm-start">
									<svg width="25" height="27" viewBox="0 0 25 27" fill="none">
										<path
											d="M6.85768 6.81883C6.37997 6.81883 5.99275 6.44035 5.99275 5.97342V0.845413C5.99275 0.378481 6.37997 0 6.85768 0C7.33539 0 7.72261 0.378481 7.72261 0.845413V5.97342C7.72261 6.44035 7.33539 6.81883 6.85768 6.81883Z"
											fill="#7B53DA"/>
										<path
											d="M18.1423 6.81883C17.6646 6.81883 17.2774 6.44035 17.2774 5.97342V0.845413C17.2774 0.378481 17.6646 0 18.1423 0C18.62 0 19.0072 0.378481 19.0072 0.845413V5.97342C19.0072 6.44035 18.62 6.81883 18.1423 6.81883Z"
											fill="#7B53DA"/>
										<path
											d="M0 11.7288V23.3937C0 25.3822 1.65509 27 3.68951 27H21.3105C23.3449 27 25 25.3822 25 23.3937V11.7288H0Z"
											fill="#7B53DA"/>
										<path
											d="M24.9999 10.038V6.1703C24.9999 4.18178 23.3449 2.56403 21.3104 2.56403H20.7371V5.97342C20.7371 7.37195 19.5731 8.50966 18.1423 8.50966C16.7115 8.50966 15.5475 7.37195 15.5475 5.97342V2.56398H9.45246V5.97337C9.45246 7.37189 8.28848 8.50961 6.85768 8.50961C5.42687 8.50961 4.2629 7.37189 4.2629 5.97337V2.56398H3.68951C1.65509 2.56398 0 4.18173 0 6.17024V10.0379H24.9999V10.038Z"
											fill="#7B53DA"/>
									</svg>
								</div>
								<div className="d-flex align-items-baseline mt-2">
									<div className="IndividualWorks__top-sub-title">
										Дата начала:&nbsp;
									</div>
									<div className="IndividualWorks__top-sub-num">
										{startDate}
									</div>
								</div>
								<div className="d-flex align-items-baseline">
									<div className="IndividualWorks__top-sub-title">
										Срок выполнения:&nbsp;
									</div>
									<div className="IndividualWorks__top-sub-num">
										{dueDate} {declOfNum(dueDate, 'days')}
									</div>
								</div>
							</div>
							<div className="d-flex flex-column ml-0 ml-sm-2 mt-3 mt-sm-0">
								<div className="d-flex align-items-baseline justify-content-center justify-content-sm-start">
									<svg width="25" height="23" viewBox="0 0 25 23" fill="none">
										<path
											d="M17.9896 4.19103L15.7781 0.388671C15.6729 0.207806 15.5 0.0760769 15.2979 0.0238036C15.0969 -0.0263787 14.8812 0.00289426 14.7021 0.110577L7.94167 4.18162L17.9896 4.19103Z"
											fill="#7B53DA"/>
										<path
											d="M3.125 5.22709C2.55208 5.22709 2.08333 4.75663 2.08333 4.18162C2.08333 3.60661 2.55208 3.13615 3.125 3.13615H6.63542L10.1146 1.04522H3.125C1.58333 1.04522 0.302083 2.17433 0.0520833 3.65889C0.03125 3.74252 0 3.82616 0 3.92025V19.8636C0 21.5886 1.40625 23 3.125 23H20.8333C21.9792 23 22.9167 22.0591 22.9167 20.9091V18.8181H20.3125C17.7292 18.8181 15.625 16.7063 15.625 14.1135C15.625 11.5208 17.7292 9.40895 20.3125 9.40895H22.9167V7.31802C22.9167 6.168 21.9792 5.22709 20.8333 5.22709H3.125ZM19.7917 3.13615C19.7917 2.08023 19.0104 1.20204 17.9896 1.06613L19.7917 4.18162V3.13615Z"
											fill="#7B53DA"/>
										<path
											d="M24.2188 10.9771H20.3125C18.5896 10.9771 17.1875 12.3843 17.1875 14.1135C17.1875 15.8427 18.5896 17.2499 20.3125 17.2499H24.2188C24.65 17.2499 25 16.8987 25 16.4658V11.7612C25 11.3284 24.65 10.9771 24.2188 10.9771ZM20.3125 15.159C19.7375 15.159 19.2708 14.6906 19.2708 14.1135C19.2708 13.5364 19.7375 13.0681 20.3125 13.0681C20.8875 13.0681 21.3542 13.5364 21.3542 14.1135C21.3542 14.6906 20.8875 15.159 20.3125 15.159Z"
											fill="#7B53DA"/>
									</svg>
								</div>
								<div className="d-flex align-items-baseline mt-2">
									<div className="IndividualWorks__top-sub-title">
										Работы:&nbsp;
									</div>
									<div className="IndividualWorks__top-sub-num">
										<NumberFormat
											value={totalServicePrice}
											displayType={'text'}
											thousandSeparator={" "}/>&nbsp;₽
									</div>
								</div>
								{
									needMaterials === 2
									&& (
										<div className="d-flex align-items-baseline">
											<div className="IndividualWorks__top-sub-title">
												Материалы:&nbsp;
											</div>
											<div className="IndividualWorks__top-sub-num">
												<NumberFormat
													value={materialPrice}
													displayType={'text'}
													thousandSeparator={" "}/>&nbsp;₽
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>
					<div className="IndividualWorks__conditions-list">
						<div className="d-flex flex-column">
							<div className="IndividualWorks__conditions-list-item">
								Расположение объекта {locationObjectConditions.find(p => p.id === locationObject).title}
							</div>
							<div className="IndividualWorks__conditions-list-item">
								Зоны работ {floorConditions.find(f => f.id === floor).title}
							</div>
							<div className="IndividualWorks__conditions-list-item">
								Материал {materialPlaceConditions.find(m => m.id === materialPlace).title}
							</div>
						</div>
						<div className="d-flex flex-column">
							<div className="IndividualWorks__conditions-list-item">
								Оборудование {equipmentConditions.find(p => p.id === withEquipment).title}
							</div>
							<div className="IndividualWorks__conditions-list-item">
								Жилье {housingConditions.find(f => f.id === withHousing).title}
							</div>
							<div className="IndividualWorks__conditions-list-item">
								Инструменты {instrumentConditions.find(m => m.id === withInstrument).title}
							</div>
						</div>
						<div className="d-flex flex-column">
							<div className="IndividualWorks__conditions-list-item">
								Исполнительная документация от {docsTabs.find(p => p.id === docs).title}
							</div>

							<div className="d-flex flex-column">
								{
									!!classifiers.length && classifiers.map((doc) => {
										return <div key={doc.id}>— {doc.title}</div>
									})
								}
							</div>

						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center pt-4 flex-column flex-sm-row">
						<div className="IndividualWorks__confirm-top-total">
							<div>Сумма заказа:</div>
							<div>
								<NumberFormat
									value={sum}
									displayType={'text'}
									thousandSeparator={" "}/>&nbsp;₽
							</div>
						</div>
						<div className="d-flex align-items-center">
							<svg width="17" height="22" viewBox="0 0 17 22" fill="none">
								<path
									d="M15.6578 15.3535C15.1642 14.8132 14.5694 14.5493 13.8099 14.5493H12.2098L11.1593 13.5443C10.3872 14.1725 9.45066 14.5368 8.43808 14.5368C7.48883 14.5368 6.59021 14.2102 5.85613 13.6448L4.91949 14.5368H3.25419C2.4062 14.5368 1.74805 14.8258 1.22913 15.4164C-0.277013 17.1502 -0.0238798 20.8567 0.09003 22H16.9487C17.0374 20.844 17.1892 17.0496 15.6578 15.3535Z"
									fill="#7B53DA"/>
								<path
									d="M4.05119 7.48827C3.74743 7.73955 3.54492 8.1416 3.54492 8.60648C3.54492 9.29751 4.00056 9.8629 4.58277 9.96341C4.70934 11.0062 5.13966 11.9234 5.78515 12.6019C5.92433 12.7401 6.06356 12.8783 6.21544 12.9914C6.40533 13.1422 6.60779 13.2678 6.82295 13.3809C7.30391 13.6321 7.83544 13.7703 8.40504 13.7703C8.97458 13.7703 9.5062 13.6321 9.98712 13.3809C10.2023 13.2678 10.4048 13.1422 10.5946 12.9914C10.7466 12.8783 10.8857 12.7401 11.025 12.6019C11.6578 11.9235 12.1008 11.0188 12.2147 9.97598C12.2274 9.97598 12.24 9.97598 12.2527 9.97598C12.9235 9.97598 13.455 9.36033 13.455 8.60648C13.455 8.1416 13.2525 7.73955 12.9488 7.48827H4.05119Z"
									fill="#7B53DA"/>
								<path
									d="M13.0294 4.84978C12.637 3.55567 11.5992 2.23643 10.2829 1.55796V4.9126C10.2829 4.93773 10.2829 4.9503 10.2829 4.96286C10.2702 5.10107 10.2449 5.22671 10.1817 5.35235C10.0171 5.66646 9.68803 5.89261 9.30837 5.89261H7.71363C7.35925 5.89261 7.04279 5.70415 6.8783 5.42774C6.80236 5.31466 6.76439 5.18906 6.73907 5.05081C6.72642 5.00055 6.72642 4.96286 6.72642 4.9126V1.5454C5.41012 2.23643 4.37228 3.55567 3.97992 4.84978L3.89133 5.12619H3.1699V6.69672H13.8303V5.12619H13.1181L13.0294 4.84978Z"
									fill="#7B53DA"/>
								<path
									d="M7.70896 5.50312H9.29104C9.51886 5.50312 9.70871 5.37748 9.80997 5.18902C9.86059 5.10107 9.8859 5.01312 9.8859 4.9126V0.778981C9.8859 0.678467 9.86059 0.590518 9.83528 0.502568C9.72141 0.213591 9.43031 0 9.10123 0H7.70896C7.4052 0 7.15207 0.226156 7.12676 0.515132C7.12676 0.540261 7.1141 0.565389 7.1141 0.590518V4.9126C7.1141 5.03825 7.15207 5.15132 7.21535 5.25184C7.3293 5.40261 7.50645 5.50312 7.70896 5.50312Z"
									fill="#7B53DA"/>
							</svg>
							<div
								className="IndividualWorks__confirm-top-total-count">{workerCount} {declOfNum(workerCount, 'masters')}</div>
						</div>
					</div>
				</div>
			}


		</div>
	);
};

export default IndividualWorksConfirm;