import React, { useEffect } from "react";
import "./PersonalOrderList.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import PersonalOrderListItem from "./components/PersonalOrderListItem/PersonalOrderListItem";
import { fetchOrderList } from "../../../../store/actions/orderListActions";
import { setConfirmOrderProperty } from "../../../../store/actions/confirmOrderActions";
import GreenButton from "../../../../components/UI/GreenButton/GreenButton";
import { Redirect, useHistory } from "react-router-dom";
import { getUserRole } from "../../../../store/selectors";
import { setActiveNavAnchor } from "../../../../store/actions/navActions";
import { SERVICE_SELECT_ANCHOR } from "../../../../config";
import AdminSendLoader from "../../../Admin/AdminComponents/AdminUI/AdminSendLoader";
import { clearOrder } from "../../../../store/actions/orderActions";

const PersonalOrderList = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		isLogin,
		fetchOrderList,
		loading,
		orders,
		fetchOrders,
		orderId,
		setConfirmOrderProperty,
	} = props;

	const role = useSelector(getUserRole);

	const addLink = (anchor, url) => {
		history.push(url);
		dispatch(setActiveNavAnchor(anchor));
	};

	useEffect(() => {
		if (isLogin && !fetchOrders) {
			fetchOrderList();
		}
	}, [isLogin, fetchOrderList, fetchOrders, role]);

	useEffect(() => {
		if (orderId) {
			fetchOrderList();
			setConfirmOrderProperty(null, "orderId");
		}
	}, [orderId, fetchOrderList, setConfirmOrderProperty]);

	if (role !== 9) {
		return <Redirect to={"/"} />;
	}

	// if (loading) {
	//     return <Loader/>
	// }

	const createOrderHandler = () => {
		localStorage.removeItem("service");
		localStorage.removeItem("subCategory");
		localStorage.removeItem("category");
		localStorage.removeItem("serviceSelectStep");
		localStorage.removeItem("startDate");
		localStorage.removeItem("dueDate");
		localStorage.removeItem("quantity");
		dispatch(clearOrder());
		addLink(SERVICE_SELECT_ANCHOR, "/");
	};

	return (
		<div className='PersonalOrderList'>
			<GreenButton
				className='PersonalOrderList__create-btn'
				onClick={createOrderHandler}>
				Сделать заказ
			</GreenButton>
			{!!orders.length ? (
				<ul className='PersonalOrderList'>
					{orders.map((order) => {
						return <PersonalOrderListItem key={order.id} {...order} />;
					})}
				</ul>
			) : (
				<div className='container d-flex align-items-center flex-column'>
					{!loading && <span className='m-5'>Список ваших заказов пуст</span>}
				</div>
			)}
			{loading && <AdminSendLoader />}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		isLogin: !!state.workerPersonal.isLogin,
		loading: state.orderList.loading,
		orders: state.orderList.orders,
		fetchOrders: state.orderList.fetchOrders,
		auth: state.auth,
		orderId: state.confirmOrder.orderId,
	};
}

const actions = {
	setConfirmOrderProperty,
	fetchOrderList,
};

export default connect(mapStateToProps, actions)(PersonalOrderList);
