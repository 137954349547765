import {
	ADD_ADMIN_SERVICES_LIST_CATEGORY,
	ADD_ADMIN_SERVICES_LIST_SERVICE,
	ADD_ADMIN_SERVICES_LIST_SUB_CATEGORY,
	ADD_ADMIN_SERVICES_LIST_SUPPORT_SERVICE,
	DELETE_ADMIN_SERVICES_LIST_CATEGORY,
	DELETE_ADMIN_SERVICES_LIST_SERVICE,
	DELETE_ADMIN_SERVICES_LIST_SUB_CATEGORY,
	FETCH_ADMIN_CATEGORIES_LIST_SUCCESS,
	FETCH_ADMIN_SERVICES_LIST_ERROR,
	FETCH_ADMIN_SERVICES_LIST_START,
	FETCH_ADMIN_SERVICES_LIST_SUCCESS,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_ERROR,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_NOTHING,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_START,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_SUCCESS,
	SET_ADMIN_SERVICES_LIST_CATEGORY_PROPERTY,
	SET_ADMIN_SERVICES_LIST_SERVICE_PROPERTY,
	SET_ADMIN_SERVICES_LIST_SUB_CATEGORY_PROPERTY,
	SET_ADMIN_SERVICES_LIST_SUPPORT_SERVICE_PROPERTY
} from "../actions/actionTypes";


const initialState = {
	services: [],
	categories: [],
	saveResult: {
		title: null,
		description: null,
		list: []
	},
	saveInProcess: false,
	loading: false,
	error: null
}

const adminServiceListReducer = (state = initialState, {type, payload}) => {
	let services = [...state.services]
	let categories = [...state.categories]
	let findServiceId = null
	let findCategoryId = null

	if (payload && payload.serviceId) {
		findServiceId = services.findIndex(service => {
			return service.id === payload.serviceId
		})
	}
	if (payload && payload.categoryId) {
		findCategoryId = categories.findIndex(category => {
			return category.id === payload.categoryId
		})
	}

	switch (type) {
		case FETCH_ADMIN_SERVICES_LIST_START:
			return {
				...state,
				loading: true
			}
		case FETCH_ADMIN_SERVICES_LIST_SUCCESS:
			return {
				...state,
				services: payload.services,
				loading: false
			}
		case FETCH_ADMIN_SERVICES_LIST_ERROR:
			return {
				...state,
				error: payload.error,
			}
		case FETCH_ADMIN_CATEGORIES_LIST_SUCCESS:
			return {
				...state,
				categories: payload.categories,
				loading: false
			}

		case SAVE_ADMIN_SERVICE_LIST_CHANGES_START:
			return {
				...state,
				saveInProcess: true,
			}

		case SAVE_ADMIN_SERVICE_LIST_CHANGES_SUCCESS:

			services = [...services.map(profession => {
				profession.edited = false
				// profession.isNew = false
				return profession
			})]

			return {
				...state,
				saveResult: {
					title: "Отправлено!",
					description: "Результат сохранения:",
					list: payload.saveResult
				},
				services,
				saveInProcess: false
			}

		case SAVE_ADMIN_SERVICE_LIST_CHANGES_NOTHING:
			return {
				...state,
				saveResult: {title: "!", description: "Нечего сохранять!", list: []},
				saveInProcess: false
			}

		case SAVE_ADMIN_SERVICE_LIST_CHANGES_ERROR:
			return {
				...state,
				error: payload.error
			}

		case ADD_ADMIN_SERVICES_LIST_SERVICE:
			services = [...services, payload.service]
			return {
				...state,
				services
			}

		case SET_ADMIN_SERVICES_LIST_SERVICE_PROPERTY:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service[payload.property] = payload.value
				service.edited = true
			}
			return {
				...state,
				services
			}
		case DELETE_ADMIN_SERVICES_LIST_SERVICE:
			if (findServiceId >= 0) {
				services = services.filter(service => service.id !== payload.serviceId)
			}
			return {
				...state,
				services
			}
		case ADD_ADMIN_SERVICES_LIST_SUPPORT_SERVICE:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.items = [...service.items, payload.supportService]
			}
			return {
				...state,
				services
			}
		case SET_ADMIN_SERVICES_LIST_SUPPORT_SERVICE_PROPERTY:

			if (findServiceId >= 0) {
				let service = services[findServiceId]

				let items = service.items
				let itemIdx = items.findIndex(item => {
					return item.id === payload.itemId
				})
				let item = items[itemIdx]

				item[payload.property] = payload.value
				service.edited = true
				service.items = [
					...items.slice(0, itemIdx),
					item,
					...items.slice(itemIdx + 1)
				]

			}
			return {
				...state,
				services
			}

		case ADD_ADMIN_SERVICES_LIST_CATEGORY:
			categories = [...categories, payload.category]
			return {
				...state,
				categories
			}
		case SET_ADMIN_SERVICES_LIST_CATEGORY_PROPERTY:
			if (findCategoryId >= 0) {
				let category = categories[findCategoryId]
				category[payload.property] = payload.value
				category.edited = true
			}
			return {
				...state,
				categories
			}

		case DELETE_ADMIN_SERVICES_LIST_CATEGORY:
			if (findCategoryId >= 0) {
				if (categories[findCategoryId].deleted) {
					categories[findCategoryId]['deleted'] = false;
				} else {
					if (categories[findCategoryId].isNew) {
						categories = categories.filter(category => category.id !== payload.categoryId)
					} else {
						categories[findCategoryId]['edited'] = true;
						categories[findCategoryId]['deleted'] = true;
					}
				}
			}
			return {
				...state,
				categories,
			};
		case ADD_ADMIN_SERVICES_LIST_SUB_CATEGORY:
			if (findCategoryId >= 0) {
				let category = categories[findCategoryId]
				category.subCategory = [...category.subCategory, payload.subCategory]
				category.edited = true
			}
			return {
				...state,
				categories
			}
		case SET_ADMIN_SERVICES_LIST_SUB_CATEGORY_PROPERTY:

			if (findCategoryId >= 0) {
				let category = categories[findCategoryId]
				category.edited = true
				let subCategories = category.subCategory
				let subCategoryIdx = subCategories.findIndex(subCategory => {
					return subCategory.id === payload.subCategoryId
				})
				let subCategory = subCategories[subCategoryIdx]
				subCategory.edited = true

				subCategory[payload.property] = payload.value
				category.subCategory = [
					...subCategories.slice(0, subCategoryIdx),
					subCategory,
					...subCategories.slice(subCategoryIdx + 1)
				]

			}
			return {
				...state,
				categories
			}
		case DELETE_ADMIN_SERVICES_LIST_SUB_CATEGORY:
			if (findCategoryId >= 0) {
				let category = categories[findCategoryId]
				let subCategory = category.subCategory.find(subCategory => subCategory.id === payload.subCategoryId)
				if (subCategory.deleted) {
					subCategory['deleted'] = false;
				} else {
					if (subCategory.isNew) {
						category.subCategory = category.subCategory.filter(subCategory => subCategory.id !== payload.subCategoryId)
					} else {
						subCategory['deleted'] = true;
						subCategory['edited'] = true;
					}
				}
			category['edited'] = true;
			}
			return {
				...state,
				categories
			}
		default:
			return {
				...state
			};

	}


}

export default adminServiceListReducer

