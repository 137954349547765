import React from 'react';
import {Paper} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {renderFormControl} from '../../../../../form/formFramework';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import {useDispatch, useSelector} from 'react-redux';
import {setAdminOrderListFormControlValue, setOrderPay} from '../../../../../store/actions/adminOrderListActions';

const AdminOrderListFinanceModal = ({master, orderId, onClose}) => {
	const dispatch = useDispatch();
	const {payDate, quantity} = useSelector(state => state.adminOrderList.formControls)

	const formControlHandler = (value, property) => {
		dispatch(setAdminOrderListFormControlValue(value, property))
	}

	const setPayHandler = () => {
		if (+quantity.value > 0) {
			dispatch(setOrderPay(orderId, master.id, quantity.value, payDate.value));
			onClose();
		}
	}

	return (
		<div className={`AdminOrderList__modal js-close`}
			 onClick={onClose}>
			<Paper className="p-3">
				<form>
					<Typography variant="h5">
						Заказ №{orderId}
					</Typography>
					<Typography variant="h5">
						{master.name}
					</Typography>
					<CloseIcon className="AdminOrderList__modal-close js-close"/>
					<hr/>
					{renderFormControl(payDate, formControlHandler)}
					{renderFormControl(quantity, formControlHandler)}
					<Button variant="contained"
							color="primary"
							onClick={setPayHandler}
							className="mt-3">
						Подтвердить
					</Button>
				</form>
			</Paper>
		</div>
	);
};

export default AdminOrderListFinanceModal;