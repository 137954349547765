import axios from "../../rest/axios";
import {
    ADD_ADMIN_MATERIALS_LIST_MATERIAL,
    DELETE_ADMIN_MATERIALS_LIST_MATERIAL,
    FETCH_ADMIN_MATERIALS_LIST_CATEGORIES_SUCCESS,
    FETCH_ADMIN_MATERIALS_LIST_ERROR,
    FETCH_ADMIN_MATERIALS_LIST_START,
    FETCH_ADMIN_MATERIALS_LIST_SUCCESS,
    FETCH_ADMIN_MATERIALS_LIST_UNITS_SUCCESS,
    SAVE_ADMIN_CHANGES_MATERIAL_ERROR,
    SAVE_ADMIN_CHANGES_MATERIAL_NOTHING, SAVE_ADMIN_CHANGES_MATERIAL_START,
    SAVE_ADMIN_CHANGES_MATERIAL_SUCCESS,
    SAVE_ADMIN_MATERIALS_LIST_MATERIAL,
    SET_ADMIN_MATERIALS_LIST_MATERIAL_PROPERTY, SET_ADMIN_MATERIALS_LIST_PROPERTY,
} from "./actionTypes";
import {store} from "../../index";
import qs from 'qs'

export function fetchAdminMaterialListMaterials() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            dispatch(fetchStart())
            try {
                let materials = await axios.post('/admin/materials/', qs.stringify({token}))

                dispatch(fetchSuccess(materials.data.data))
            } catch (e) {
                dispatch(fetchError(e))
            }
        }
    }
}

export function fetchAdminMaterialListCategories() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let categories = await axios.post('/admin/categories/', qs.stringify({token}))
            dispatch(fetchCategoriesSuccess(categories.data.data))
        }
    }
}

export function fetchAdminMaterialListUnits() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let units = await axios.post('/mobile/support/units/', qs.stringify({token}))
            dispatch(fetchUnitsSuccess(units.data)) // заменить апи на бэк
        }
    }
}


function fetchStart() {
    return {
        type: FETCH_ADMIN_MATERIALS_LIST_START
    }
}

function fetchSuccess(materials) {
    return {
        type: FETCH_ADMIN_MATERIALS_LIST_SUCCESS,
        payload: {
            materials
        }
    }
}

function fetchError(error) {
    return {
        type: FETCH_ADMIN_MATERIALS_LIST_ERROR,
        payload: {
            error
        }
    }
}

function fetchCategoriesSuccess(categories) {
    return {
        type: FETCH_ADMIN_MATERIALS_LIST_CATEGORIES_SUCCESS,
        payload: {
            categories
        }
    }
}

function fetchUnitsSuccess(units) {
    return {
        type: FETCH_ADMIN_MATERIALS_LIST_UNITS_SUCCESS,
        payload: {
            units
        }
    }
}

export function setAdminMaterialListMaterialProperty(materialId, value, property) {
    return {
        type: SET_ADMIN_MATERIALS_LIST_MATERIAL_PROPERTY,
        payload: {
            materialId, value, property
        }
    }

}

export function saveAdminMaterialListMaterial(material) {
    return {
        type: SAVE_ADMIN_MATERIALS_LIST_MATERIAL,
        payload: {
            material
        }
    }
}

export function addAdminMaterialListMaterial(material) {
    return {
        type: ADD_ADMIN_MATERIALS_LIST_MATERIAL,
        payload: {
            material
        }
    }
}

export function deleteAdminMaterialListMaterial(material) {
    return {
        type: DELETE_ADMIN_MATERIALS_LIST_MATERIAL,
        payload: {
            material
        }
    }
}

export function setAdminMaterialListProperty(value, property) {
    return {
        type: SET_ADMIN_MATERIALS_LIST_PROPERTY,
        payload: {
            value,
            property
        }
    }
}

export function saveMaterialList() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            dispatch(saveStart())
            try {
                const adminMaterialList = store.getState().adminMaterialList

                const materials = adminMaterialList.materials.filter(material => material.edited)

                if (!!materials.length) {
                    let response = await axios.post('/admin/saveMaterials/', qs.stringify({materials, token}));

                    await dispatch(saveSuccess(response.data))

                    let errors = response.data.filter(data => {
                        return !data.result
                    })

                    if (!errors.length) {
                        dispatch(fetchAdminMaterialListMaterials())
                    }

                } else {
                    dispatch(saveNothing())
                }


            } catch (e) {
                dispatch(saveError(e))
            }
        }
    }
}


function saveNothing() {
    return {
        type: SAVE_ADMIN_CHANGES_MATERIAL_NOTHING
    }
}

function saveStart() {
    return {
        type: SAVE_ADMIN_CHANGES_MATERIAL_START
    }
}

function saveSuccess(saveResult) {
    return {
        type: SAVE_ADMIN_CHANGES_MATERIAL_SUCCESS,
        payload: {
            saveResult
        }
    }
}

function saveError(error) {
    return {
        type: SAVE_ADMIN_CHANGES_MATERIAL_ERROR,
        payload: {
            error
        }
    }
}