import {
	ADD_SPECIALIST,
	AUTH_SPECIALIST_ERROR,
	AUTH_SPECIALIST_START,
	AUTH_SPECIALIST_SUCCESS,
	FETCH_CATEGORIES_SUCCESS,
	FETCH_SPECIALIST_ERROR,
	FETCH_SPECIALIST_START,
	// FETCH_SPECIALIST_SUCCESS,
	GET_PROFESSIONS_ERROR,
	GET_PROFESSIONS_START,
	GET_PROFESSIONS_SUCCESS,
	GET_SPECIALIST_SPECIALIZATIONS,
	REMOVE_SPECIALIST,
	SEND_DATE_INSTRUCTION,
	SET_CATEGORY,
	SET_CURRENT_TAB_SPECIALIST,
	SET_SPECIALIST,
	SET_SPECIALIST_CARD_TYPE,
	SET_SPECIALIST_FORM_CONTROL,
	SET_SPECIALIST_METHOD_WORK,
	SET_SPECIALIST_PROPERTY,
	SET_SPECIALIST_TYPE_WORK,
} from "./actionTypes";
import { store } from "../../index";
// import * as moment from "./confirmOrderActions";
import axios from "../../rest/axios";
// import qs from "qs";
import latinize from "latinize";
import { validateControl } from "../../form/formFramework";

// import {setAuthFormProperty} from "./confirmOrderActions";

export function setCurrentTab(id) {
	return {
		type: SET_CURRENT_TAB_SPECIALIST,
		id,
	};
}

export function setSpecialistProperty(value, property) {
	return {
		type: SET_SPECIALIST_PROPERTY,
		value,
		property,
	};
}

export function setCardType(value) {
	return {
		type: SET_SPECIALIST_CARD_TYPE,
		value,
	};
}

export function setSpecialistMethodWork(value) {
	return {
		type: SET_SPECIALIST_METHOD_WORK,
		value,
	};
}

export function setSpecialistTypeWork(value) {
	return {
		type: SET_SPECIALIST_TYPE_WORK,
		value,
	};
}

export function setSpecialistFormControl(value, property) {
	let formControls = store.getState().specialist.formControls;
	let keys = property.split(".");
	let formControl;
	if (keys.length === 2) {
		formControl = formControls[keys[0]][keys[1]];
	} else {
		formControl = formControls[keys[0]];
	}
	let error = "";

	if (property !== "photo" && keys[0] !== "specialist") {
		error = validateControl(value, formControl.validation);
	}

	if (property === "card.owner") {
		value = latinize(value).toUpperCase();
	}

	return (dispatch) => {
		if (keys[0] === "card") {
			if (error && error !== "creditCard") {
				dispatch(setCardType(error));
				error = "";
			}
		}
		dispatch(setFormControl(value, property, error));
	};
}

export function setFormControl(value, property, error) {
	return {
		type: SET_SPECIALIST_FORM_CONTROL,
		value,
		property,
		error,
	};
}

export function addSpecialist() {
	return {
		type: ADD_SPECIALIST,
	};
}

export function setSpecialist(specialist = {}) {
	return {
		type: SET_SPECIALIST,
		id: specialist.id,
		value: specialist.value,
		property: specialist.property,
		error: specialist.error,
	};
}

export function removeSpecialist(id) {
	return {
		type: REMOVE_SPECIALIST,
		id,
	};
}

export function fetchRegisterSpecialist() {
	const specialist = store.getState().specialist;

	let formControlKeys = Object.keys(specialist.formControls);
	let data = {};
	formControlKeys.forEach((key) => {
		let properties = Object.keys(specialist.formControls[key]);
		data[key] = {};
		properties.forEach((property) => {
			let item = specialist.formControls[key][property];
			data[key][property] = item.value;
		});

		if (key === "card") {
			data[key]["typeCard"] = specialist.card.typeCard;
		}
	});

	if (!!specialist.specialists.length) {
		data.specialists = specialist.specialists.map((specialist) => {
			let item = {};

			Object.values(specialist.formControls).forEach((control) => {
				item[control.id] = control.value;
			});
			return item;
		});
	}
	data.methodOfWork = specialist.methodOfWork;
	data.typeOfWork = specialist.typeOfWork;
	data.specializationType = specialist.specializationType;

	return async (dispatch) => {
		dispatch(fetchStart());
		try {
			dispatch(setSpecialistProperty(true, "loading"));
			let response = await axios.post("/web/specialist/create/", data);

			if (response.data.data.status) {
				dispatch(setSpecialistProperty(response.data.data.status, "status"));
				dispatch(setSpecialistProperty(false, "loading"));
			}
		} catch (e) {
			dispatch(fetchError(e));
		}
	};
}

export function authSpecialist() {
	return async (dispatch) => {
		dispatch(authStart());
		try {
			let response = await axios.get("/web/specialist/auth/");

			dispatch(authSuccess(response.data));
		} catch (e) {
			dispatch(authError(e));
		}
	};
}

export function getProfessions() {
	return async (dispatch) => {
		dispatch(getProfessionsStart());
		try {
			let response = await axios.get("/web/professions/list/");

			dispatch(getProfessionsSuccess(response.data));
		} catch (e) {
			dispatch(getProfessionsError(e));
		}
	};
}

export function getCategories() {
	return async (dispatch) => {
		dispatch(getProfessionsStart());
		try {
			let response = await axios.get("/web/services/categories/");

			dispatch(getCategoriesSuccess(response.data.data));
		} catch (e) {
			dispatch(getProfessionsError(e));
		}
	};
}

export function sendDateSpecialist() {
	let token = localStorage.getItem("token");
	let dateInstruction = store.getState().specialist.dateInstruction;
	return async (dispatch) => {
		let response = await axios.get("/web/specialist/dateInstruction/", {
			headers: { Authorization: `Bearer ${token}` },
			params: { dateInstruction },
		});
		dispatch(sendDate(response.data.instructionInfo));
	};
}

export function getSpecializations() {
	return async (dispatch) => {
		let { data } = await axios.get("/web/specialist/specializations/");
		dispatch({ type: GET_SPECIALIST_SPECIALIZATIONS, payload: data });
	};
}

function sendDate(payload) {
	return {
		type: SEND_DATE_INSTRUCTION,
		payload,
	};
}

function fetchStart() {
	return {
		type: FETCH_SPECIALIST_START,
	};
}

function fetchError(error) {
	return {
		type: FETCH_SPECIALIST_ERROR,
		error,
	};
}

function authStart() {
	return {
		type: AUTH_SPECIALIST_START,
	};
}

function authSuccess(data) {
	return {
		type: AUTH_SPECIALIST_SUCCESS,
		data,
	};
}

function authError(error) {
	return {
		type: AUTH_SPECIALIST_ERROR,
		error,
	};
}

function getProfessionsStart() {
	return {
		type: GET_PROFESSIONS_START,
	};
}

function getCategoriesSuccess(categories) {
	return {
		type: FETCH_CATEGORIES_SUCCESS,
		payload: {
			categories,
		},
	};
}

function getProfessionsSuccess(professions) {
	return {
		type: GET_PROFESSIONS_SUCCESS,
		professions,
	};
}

function getProfessionsError(error) {
	return {
		type: GET_PROFESSIONS_ERROR,
		error,
	};
}

export function setCategory(id, actionType) {
	return {
		type: SET_CATEGORY,
		payload: {
			id,
			actionType,
		},
	};
}
