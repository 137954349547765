import {
	ADMIN_MASTERS_CHECK_MODERATION,
	ADMIN_MASTERS_FETCH_ERROR,
	ADMIN_MASTERS_FETCH_START,
	ADMIN_MASTERS_FETCH_SUCCESS, ADMIN_WORKERS_FETCH_SUCCESS
} from "./actionTypes";
import axios from "../../rest/axios";
import qs from "qs";
import {ROLE_INDEPENDENT_MASTER, ROLE_MANAGER, ROLE_MASTER, ROLE_SPECIALIST} from "../../config";
import {getUserRoleCode} from '../../utils/roleFunctions';

export function adminMastersFetch(role = ROLE_MASTER, page = 'all', size = 1000, moderation = 'all', profession = 'all') {
	let rolePost = getUserRoleCode(role);

	return async dispatch => {
		let token = localStorage.getItem('token');
		if (token) {
			try {
				dispatch(adminMastersFetchStart());
				const masters = await axios.get('/admin/users/', {
					params: {
						token,
						roles: rolePost,
						nav: `page-${page}-size-${size}`,
						'filter[moderation]': moderation,
						'filter[profession]': profession,
					}
				});
				// debugger
				dispatch(adminMastersFetchSuccess(masters.data.data, role, moderation))
			} catch (e) {
				adminMastersFetchError(e.message)
			}
		}
	}
}


function adminMastersFetchStart() {
	return {
		type: ADMIN_MASTERS_FETCH_START
	}
}

function adminMastersFetchError(error) {
	return {
		type: ADMIN_MASTERS_FETCH_ERROR,
		payload: {
			error
		}
	}
}

function adminMastersFetchSuccess(data, role, moderation) {
	return {
		type: ADMIN_MASTERS_FETCH_SUCCESS,
		payload: {
			data,
			role,
			moderation
		}
	}
}

export function checkModeration(masterId, workerId = false) {
	return {
		type: ADMIN_MASTERS_CHECK_MODERATION,
		payload: {
			masterId,
			workerId
		}
	}
}

/////-------------------------------------
export function setMasterListModeration(userId, status = false, role) {
	return async dispatch => {
		let token = localStorage.getItem('token');
		if (token) {
			try {
				await axios.post('/admin/masterModeration/', qs.stringify({userId, status, token}));
				dispatch(adminMastersFetch(role))
			} catch (e) {
				adminMastersFetchError(e.message)
			}
		}
	}
}

export function setIndividualOrders(id, UF_ONLY_INDIVIDUAL_ORDERS) {
	return async dispatch => {
		let token = localStorage.getItem('token');
		if (token) {
			try {
				await axios.post('/admin/users/update/', qs.stringify({
					id,
					token,
					fields: {UF_ONLY_INDIVIDUAL_ORDERS}
				}));
				dispatch(adminMastersFetch(ROLE_MANAGER))
			} catch
				(e) {
				adminMastersFetchError(e.message)
			}
		}
	}
}