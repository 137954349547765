import axios from "../../rest/axios";
import {
	ADD_ADMIN_SERVICES_LIST_CATEGORY,
	ADD_ADMIN_SERVICES_LIST_SERVICE,
	ADD_ADMIN_SERVICES_LIST_SUB_CATEGORY,
	ADD_ADMIN_SERVICES_LIST_SUPPORT_SERVICE,
	DELETE_ADMIN_SERVICES_LIST_CATEGORY,
	DELETE_ADMIN_SERVICES_LIST_SERVICE,
	DELETE_ADMIN_SERVICES_LIST_SUB_CATEGORY,
	FETCH_ADMIN_CATEGORIES_LIST_SUCCESS,
	FETCH_ADMIN_SERVICES_LIST_ERROR,
	FETCH_ADMIN_SERVICES_LIST_START,
	FETCH_ADMIN_SERVICES_LIST_SUCCESS,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_ERROR,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_NOTHING,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_START,
	SAVE_ADMIN_SERVICE_LIST_CHANGES_SUCCESS,
	SET_ADMIN_SERVICES_LIST_CATEGORY_PROPERTY,
	SET_ADMIN_SERVICES_LIST_SERVICE_PROPERTY,
	SET_ADMIN_SERVICES_LIST_SUB_CATEGORY_PROPERTY,
	SET_ADMIN_SERVICES_LIST_SUPPORT_SERVICE_PROPERTY,
} from "./actionTypes";
import { store } from "../../index";
import qs from "qs";
import { fetchServiceSelectServices } from "./serviceSelectActions";

export function fetchServicesList() {
	return async (dispatch) => {
		let token = localStorage.getItem("token");
		if (token) {
			dispatch(fetchStart());
			try {
				let services = await axios.get("/admin/services/", {
					params: { short: true, token },
				});
				if (services.data) {
					dispatch(fetchSuccess(services.data.data));
				}
			} catch (e) {
				dispatch(fetchError(e));
			}
		}
	};
}

export function fetchCategoriesList() {
	return async (dispatch) => {
		let token = localStorage.getItem("token");
		if (token) {
			try {
				dispatch(fetchStart());
				let categories = await axios.get("/admin/categories/", {
					params: { token },
				});
				dispatch(fetchCategoriesSuccess(categories.data.data));
			} catch (e) {
				dispatch(fetchError(e));
			}
		}
	};
}

function fetchStart() {
	return {
		type: FETCH_ADMIN_SERVICES_LIST_START,
	};
}

function fetchSuccess(services) {
	return {
		type: FETCH_ADMIN_SERVICES_LIST_SUCCESS,
		payload: {
			services,
		},
	};
}

function fetchError(error) {
	return {
		type: FETCH_ADMIN_SERVICES_LIST_ERROR,
		payload: {
			error,
		},
	};
}

function fetchCategoriesSuccess(categories) {
	return {
		type: FETCH_ADMIN_CATEGORIES_LIST_SUCCESS,
		payload: {
			categories,
		},
	};
}

// EDIT SERVICES

export function setServiceListServiceProperty(serviceId, value, property) {
	return {
		type: SET_ADMIN_SERVICES_LIST_SERVICE_PROPERTY,
		payload: {
			serviceId,
			value,
			property,
		},
	};
}

export function addServiceListService($categoryId, $subCategoryId, service) {
	return {
		type: ADD_ADMIN_SERVICES_LIST_SERVICE,
		payload: {
			$categoryId,
			$subCategoryId,
			service,
		},
	};
}

export function deleteServiceListService(serviceId) {
	return {
		type: DELETE_ADMIN_SERVICES_LIST_SERVICE,
		payload: {
			serviceId,
		},
	};
}

export function addServiceListSupportService(serviceId, supportService) {
	return {
		type: ADD_ADMIN_SERVICES_LIST_SUPPORT_SERVICE,
		payload: {
			serviceId,
			supportService,
		},
	};
}

export function setServiceListSupportServiceProperty(
	serviceId,
	itemId,
	value,
	property,
) {
	return {
		type: SET_ADMIN_SERVICES_LIST_SUPPORT_SERVICE_PROPERTY,
		payload: {
			serviceId,
			itemId,
			value,
			property,
		},
	};
}

export function addServiceListCategory(category) {
	return {
		type: ADD_ADMIN_SERVICES_LIST_CATEGORY,
		payload: {
			category,
		},
	};
}

export function setServiceListCategoryProperty(categoryId, value, property) {
	return {
		type: SET_ADMIN_SERVICES_LIST_CATEGORY_PROPERTY,
		payload: {
			categoryId,
			value,
			property,
		},
	};
}

export function deleteServiceListCategory(categoryId) {
	return {
		type: DELETE_ADMIN_SERVICES_LIST_CATEGORY,
		payload: {
			categoryId,
		},
	};
}

export function addServiceListSubCategory(categoryId, subCategory) {
	return {
		type: ADD_ADMIN_SERVICES_LIST_SUB_CATEGORY,
		payload: {
			categoryId,
			subCategory,
		},
	};
}

export function setServiceListSubCategoryProperty(
	categoryId,
	subCategoryId,
	value,
	property,
) {
	return {
		type: SET_ADMIN_SERVICES_LIST_SUB_CATEGORY_PROPERTY,
		payload: {
			categoryId,
			subCategoryId,
			value,
			property,
		},
	};
}

export function deleteServiceListSubCategory(categoryId, subCategoryId) {
	return {
		type: DELETE_ADMIN_SERVICES_LIST_SUB_CATEGORY,
		payload: {
			categoryId,
			subCategoryId,
		},
	};
}

export function saveAdminServiceListChanges() {
	return async (dispatch) => {
		let token = localStorage.getItem("token");
		if (token) {
			dispatch(saveStart());
			try {
				const adminServiceList = store.getState().adminServiceList;
				// выбираем услуги, которые были изменены
				const services = adminServiceList.services.filter(
					(service) => service.edited,
				);
				const categories = adminServiceList.categories.filter(
					(category) => category.edited,
				);

				if (!!services.length || !!categories.length) {
					// let response = await axios.post('/admin/save/', qs.stringify({services}))
					let response = await axios.post(
						"/admin/saveServices/",
						qs.stringify({ services, categories, token }),
					);
					await dispatch(saveSuccess(response.data));

					let errors = response.data.data.filter((data) => {
						return data.error;
					});
					if (!errors.length) {
						dispatch(fetchServicesList());
						dispatch(fetchCategoriesList());
					}
				} else {
					dispatch(saveNothing());
				}
			} catch (e) {
				dispatch(saveError(e));
			}
		}
	};
}

export function saveAdminIndividualService(service) {
	return async (dispatch) => {
		let token = localStorage.getItem("token");
		if (token) {
			dispatch(saveStart());
			try {
				if (!!service) {
					let response = await axios.post(
						"/admin/saveServices/",
						qs.stringify({ services: [service], token }),
					);
					await dispatch(saveSuccess(response.data));
					dispatch(fetchServiceSelectServices());
				} else {
					dispatch(saveNothing());
				}
			} catch (e) {
				dispatch(saveError(e));
			}
		}
	};
}

function saveNothing() {
	return {
		type: SAVE_ADMIN_SERVICE_LIST_CHANGES_NOTHING,
	};
}

function saveStart() {
	return {
		type: SAVE_ADMIN_SERVICE_LIST_CHANGES_START,
	};
}

function saveSuccess(data) {
	return {
		type: SAVE_ADMIN_SERVICE_LIST_CHANGES_SUCCESS,
		payload: {
			data,
		},
	};
}

function saveError(error) {
	return {
		type: SAVE_ADMIN_SERVICE_LIST_CHANGES_ERROR,
		payload: {
			error,
		},
	};
}
