import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


const AdminOrderListDetail = ({children, closeHandler, title}) => {
	return (
		<div className="AdminOrderList__detail-modal">
			<div className="d-flex align-items-center pl-5">
				<Typography variant="h5">{title}</Typography>
				<div className="AdminOrderList__detail-modal-close" onClick={closeHandler}>
					<CloseIcon/>
				</div>
			</div>
			<div className="AdminOrderList__detail-modal-scroll">
				{children}
			</div>
		</div>
	);
};

export default AdminOrderListDetail;