import React from 'react';
import {useDispatch} from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AdminActive from '../AdminUI/AdminActive';
import AdminBtn from '../AdminUI/AdminBtn';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AdminServiceListServices from './AdminServiceListServices';
import {
	addServiceListService, deleteServiceListSubCategory,
	setServiceListCategoryProperty,
	setServiceListSubCategoryProperty
} from '../../../../store/actions/adminServiceListActions';
import cuid from 'cuid';

const AdminServiceListSubCategory = ({category, services, idx, setExpandedHandler, expanded2, expanded3, handleChange}) => {
	const dispatch = useDispatch();
	const setPropertyHandler = (categoryId, subCategoryId, property, value = undefined) => {
		return (evt) => {
			dispatch(setServiceListSubCategoryProperty(categoryId, subCategoryId, typeof value !== 'undefined' ? value : evt.target.value, property))
		}
	};

	const saveInputHandler = (category, subCategory) => {
		if (subCategory.name) {
			dispatch(setServiceListSubCategoryProperty(category.id, subCategory.id, false, 'edit'));
		}
	};

	const deleteSubCategory = (categoryId, subCategoryId) => {
		dispatch(deleteServiceListSubCategory(categoryId, subCategoryId))
	}

	const addServiceHandler = (categoryId, subCategoryId) => {

		const subCategoryServices = services.filter(service => {
			if (!!service.category.id) {
				return service.category.subCategory.id === subCategoryId;
			}
			return false;
		}).filter(service => service.edit);
		if (!subCategoryServices.length) {
			const service = {
				id: cuid(),
				active: false,
				name: "",
				unit: "м&sup2;",
				items: [],
				edit: true,
				edited: true,
				isNew: true,
				category: {
					id: categoryId,
					subCategory: {
						id: subCategoryId
					}
				}
			};
			setExpandedHandler(subCategoryId, 2);
			dispatch(addServiceListService(categoryId, subCategoryId, service))
		}
	};


	return (
		<>
			{
				category.subCategory && !!category.subCategory.length &&
				<List className='w-100 py-0'>
					{
						category.subCategory.map((subCategory, subCategory_idx) => {
							const subCategoryServices = services.filter(service => {

								if (service.category.subCategory) {
									return service.category.subCategory.id === subCategory.id
								}
							});

							return (
								<ListItem key={subCategory.id}
										  className='w-100 p-0 my-2'>
									{
										!subCategoryServices.length ?
											<Paper
												className={`p-3 pr-5 w-100 d-flex justify-content-between align-items-center Admin_subcategory`}>

												{
													subCategory.edit ?
														<div className='d-flex align-items-center w-75'>
															<TextField
																variant="outlined"
																className='Admin_subCategory-list col-8 edit_btn__js'
																onChange={setPropertyHandler(category.id, subCategory.id, 'name')}
																value={subCategory.name || ''}
															/>
															<TextField
																variant="outlined"
																className='Admin_subCategory-list col-4 edit_btn__js'
																onChange={setPropertyHandler(category.id, subCategory.id, 'sort')}
																value={subCategory.sort}
															/>
														</div>
														:
														<div
															className='d-flex align-items-center justify-content-between a__service-list__item'>
															<Typography variant="button"
																		className="m-0 w-75">{idx}.{subCategory_idx + 1}. {subCategory.name}</Typography>
															<Typography variant="button"
																		className="d-flex align-items-center justify-content-end w-25">Сортировка: {subCategory.sort}</Typography>
														</div>
												}
												<div
													className="pr-2 d-flex align-items-center justify-content-end ">
													<AdminActive
														edited={subCategory.edited}
														active={subCategory.active}/>
													{
														!subCategory.isNew &&
														<AdminBtn
															tip="Добавить работу"
															type="add"
															onClick={() => addServiceHandler(category.id, subCategory.id)}/>
													}
													{
														subCategory.edit
															?
															<AdminBtn
																tip="Сохранить раздел"
																type="save"
																onClick={() => saveInputHandler(category, subCategory)}/>
															:
															<AdminBtn
																tip="Редактировать раздел"
																type="edit"
																onClick={setPropertyHandler(category.id, subCategory.id, 'edit', true)}/>
													}
													{
														subCategory.active ?
															<AdminBtn
																tip="Деактивировать раздел"
																type="clear"
																onClick={setPropertyHandler(category.id, subCategory.id, 'active', false)}/>
															:
															<AdminBtn
																tip="Активировать раздел"
																type="check"
																onClick={setPropertyHandler(category.id, subCategory.id, 'active', true)}/>
													}
													<AdminBtn tip="Удалить раздел"
															  type={`${subCategory.deleted ? 'restore' : 'delete'}`}
															  onClick={() => deleteSubCategory(category.id, subCategory.id)}/>


												</div>
											</Paper>
											:
											<Accordion
												className={`w-100 px-0 Admin_subcategory editedItem`}
												TransitionProps={{unmountOnExit: true}}
												key={subCategory.id}
												expanded={expanded2 === subCategory.id}
												onChange={handleChange(subCategory.id, 2)}>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon
														className="Admin_toggle-btn"/>}
													aria-controls="panel1bh-content"
													id="panel1bh-header"
												>

													<div
														className="d-flex align-items-center justify-content-between w-100">

														{
															subCategory.edit ?
																<div className='d-flex align-items-center w-75'>
																	<TextField
																		variant="outlined"
																		className='Admin_subCategory-list col-8 edit_btn__js'
																		onChange={setPropertyHandler(category.id, subCategory.id, 'name')}
																		value={subCategory.name || ''}
																	/>
																	<TextField
																		variant="outlined"
																		className='Admin_subCategory-list col-4 edit_btn__js'
																		onChange={setPropertyHandler(category.id, subCategory.id, 'sort')}
																		value={subCategory.sort}
																	/>
																</div>
																:
																<div className='d-flex align-items-center justify-content-between a__service-list__item'>
																	<div
																		className="d-flex align-items-center w-75">
																		<Typography
																			variant="button"
																			className="m-0">
																			{idx}.{subCategory_idx + 1}. {subCategory.name}&nbsp;
																		</Typography>
																		<Typography
																			variant="overline"
																			display="block">
																			{`[ Работ: ${subCategoryServices.length} ]`}
																		</Typography>
																	</div>
																	<Typography variant="button"
																				className="d-flex align-items-center justify-content-end w-25">Сортировка: {subCategory.sort }</Typography>
																</div>
														}


														<div
															className="d-flex align-items-center justify-content-end ">
															<AdminActive
																edited={subCategory.edited}
																active={subCategory.active}/>
															<AdminBtn
																tip="Добавить работу"
																type="add"
																onClick={() => addServiceHandler(category.id, subCategory.id)}/>

															{
																subCategory.edit
																	?
																	<AdminBtn
																		tip="Сохранить раздел"
																		type="save"
																		onClick={() => saveInputHandler(category, subCategory)}/>
																	:
																	<AdminBtn
																		tip="Редактировать раздел"
																		type="edit"
																		onClick={setPropertyHandler(category.id, subCategory.id, 'edit', true)}/>
															}

															{
																subCategory.active ?
																	<AdminBtn
																		tip="Деактивировать раздел"
																		type="clear"
																		onClick={setPropertyHandler(category.id, subCategory.id, 'active', false)}/>
																	:
																	<AdminBtn
																		tip="Активировать раздел"
																		type="check"
																		onClick={setPropertyHandler(category.id, subCategory.id, 'active', true)}/>
															}

															<AdminBtn
																tip="Удалить раздел"
																type={`${subCategory.deleted ? 'restore' : 'delete'}`}
																onClick={setPropertyHandler(category.id, subCategory.id)}/>

														</div>
													</div>
												</AccordionSummary>
												<AccordionDetails
													className="pl-2 pr-0 py-0 ">
													{
														!!subCategoryServices.length &&
														<AdminServiceListServices
															services={subCategoryServices}
															expanded={expanded3}
															setExpandedHandler={setExpandedHandler}
															handleChange={handleChange}
															catIdx={idx}
															subCatIdx={subCategory_idx}
														/>
													}
												</AccordionDetails>
											</Accordion>
									}

								</ListItem>
							)
						})
					}
				</List>
			}
		</>
	);
};

export default AdminServiceListSubCategory;