import React, { useCallback } from "react";
import "./Calculator.scss";
import Tab from "../../ui/Tab/Tab";
import IndividualWorks from "./IndividualWorks/IndividualWorks";
import { useSelector } from "react-redux";
import { getOrderState } from "../../../store/selectors";
import Complex from "./Complex/Complex";
import CalculatorContextProvider from "./CalculatorContext";
import ConditionsContextProvider from "./ConditionsContext";
import MasterHourly from "./MasterHourly/MasterHourly";
import { useHistory } from "react-router";

const Calculator = () => {
	const { category } = useSelector(getOrderState);

	const history = useHistory();
	const {
		location: { pathname },
	} = history;

	const changeTab = useCallback((path) => {
		history.replace(path);
	});

	const tabs = [
		{ path: "/", title: "Отдельные виды работ" },
		{ path: "/complex-works", title: "Комплекс работ «Под ключ»" },
		{ path: "/master-hourly", title: "Почасовой мастер" },
	];

	return (
		<CalculatorContextProvider>
			<ConditionsContextProvider>
				<div id='calculator' className='Calculator'>
					<div className='Calculator__layout'>
						<div className='Calculator__layout-title'>ОФОРМИТЕ ЗАКАЗ</div>
						<div className='Calculator__layout-desc'>
							Цене указаны для заказов строителям из базы сервиса. Для
							индивидуального определения цен позвоните на{" "}
							<a href={`tel:+78007003877`}>Горячую линию</a>, мы можем
							индивидуально установить цены работ для вас и ваших строителей.
						</div>
						<div className='Calculator__layout-heading'>
							<div className='Calculator__layout-heading-line mr-3' />
							<div>Выберите вид работ</div>
							<div className='Calculator__layout-heading-line ml-3' />
						</div>
					</div>
					<div className='Calculator__content'>
						{!category && (
							<div className='Calculator__content-header'>
								{tabs.map((tab) => (
									<Tab
										key={tab.path}
										title={tab.title}
										active={pathname === tab.path}
										onClick={() => changeTab(tab.path)}
									/>
								))}
							</div>
						)}
						<div
							className={`Calculator__content-body ${
								category ? "Calculator__content-body_round" : ""
							}`}>
							{pathname === "/" && <IndividualWorks />}
							{pathname === "/complex-works" && <Complex />}
							{pathname === "/master-hourly" && <MasterHourly />}
						</div>
					</div>
				</div>
			</ConditionsContextProvider>
		</CalculatorContextProvider>
	);
};

export default Calculator;
