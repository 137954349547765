import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import GroupIcon from "@material-ui/icons/Group";
import ListItemText from "@material-ui/core/ListItemText";
import WorkIcon from "@material-ui/icons/Work";
import { ExpandLess, ExpandMore, StarBorder } from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { ROLE_MASTER, ROLE_SPECIALIST } from "../../../../config";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { Badge } from "@material-ui/core";

const AdminWorkerPersonalNav = ({ tabHandler, selected, user }) => {
	const [openOrders, setOpenOrders] = useState(false);
	const [role, setRole] = useState(ROLE_MASTER);
	const openOrdersHandler = () => {
		setOpenOrders(!openOrders);
	};
	useEffect(() => {
		setRole(user.role);
	}, [user]);
	return (
		<div className='w-20'>
			{!!user && user.id && (
				<List component='nav'>
					<ListItem
						button
						selected={selected === "data"}
						onClick={tabHandler("data")}>
						<ListItemIcon>
							<HowToRegIcon />
						</ListItemIcon>
						<ListItemText primary='Личные данные' />
					</ListItem>
					{(role === ROLE_MASTER || role === ROLE_SPECIALIST) && (
						<>
							<ListItem
								button
								selected={selected === "team"}
								// disabled={!user.workers.length}
								onClick={tabHandler("team")}>
								<ListItemIcon>
									<GroupIcon />
								</ListItemIcon>
								<ListItemText primary='Состав бригады' />
							</ListItem>
						</>
					)}
					{role === ROLE_MASTER && (
						<>
							<ListItem
								button
								selected={selected === "request"}
								onClick={tabHandler("request")}>
								<ListItemIcon>
									<GroupAddIcon />
								</ListItemIcon>
								<ListItemText primary='Запросы в бригаду' />
								<Badge
									color='secondary'
									className='mr-4 pb-3'
									badgeContent={user.requestWorkers.length}
								/>
							</ListItem>
						</>
					)}
					{role !== ROLE_SPECIALIST && (
						<>
							<ListItem button selected={false} onClick={openOrdersHandler}>
								<ListItemIcon>
									<WorkIcon />
								</ListItemIcon>
								<ListItemText primary='История заказов' />
								{openOrders ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={openOrders} timeout='auto' unmountOnExit>
								<List component='div' disablePadding>
									<ListItem
										button
										className='pl-5'
										selected={selected === "orders-current"}
										onClick={tabHandler("orders-current")}>
										<ListItemIcon>
											<StarBorder />
										</ListItemIcon>
										<ListItemText primary='Текущие' />
									</ListItem>
									<ListItem
										button
										className='pl-5'
										selected={selected === "orders-done"}
										disabled
										onClick={tabHandler("orders-done")}>
										<ListItemIcon>
											<StarBorder />
										</ListItemIcon>
										<ListItemText primary='Завершенные' />
									</ListItem>
								</List>
							</Collapse>
							<ListItem
								button
								selected={selected === "reports"}
								onClick={tabHandler("reports")}>
								<ListItemIcon>
									<AssignmentIcon />
								</ListItemIcon>
								<ListItemText primary='Отчеты' />
							</ListItem>

							<ListItem
								button
								selected={selected === "payment"}
								disabled
								onClick={tabHandler("payment")}>
								<ListItemIcon>
									<AccountBalanceWalletIcon />
								</ListItemIcon>
								<ListItemText primary='Оплаты' />
							</ListItem>
						</>
					)}
				</List>
			)}
		</div>
	);
};
export default AdminWorkerPersonalNav;
