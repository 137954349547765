import React from "react";
import "./EstimateUI.scss";

export default function EstimateRadioInput2({
  element,
  clickHandler,
  titleClassNames,
}) {
  return (
    <div className="estimate-radio-input-wrapper second-type">
      {element.label && (
        <span
          className={`radio-input-title ${element.id}-title ${titleClassNames}`}
        >
          {element.label}
        </span>
      )}
      <div className="estimate-radio-inputs-wrapper">
        {element.options.map((option) => (
          <label
            className="label-pointer"
            onClick={() => clickHandler(option.id, element.id)}
          >
            <input
              checked={element.value === option.id}
              type="radio"
              name={element.id}
              value={option.id}
              className="estimate-hidden-input"
            />
            <span className="radio-input-content">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
}
