import React from 'react';
import {Paper} from '@material-ui/core';
import {Link} from 'react-router-dom';

const WorkerPersonalPayments = () => {
	return (
		<>
			<h3 className="py-3">Оплаты</h3>
			{/*<ul className="wp__list p-0">*/}
			{/*	<li className="mt-2">*/}
			{/*		<Link to={`/worker/personal/payment/${312}`}>*/}
			{/*			<Paper className="d-flex align-items-center p-3">*/}
			{/*				<div className="col-2">Заказ № 312</div>*/}
			{/*				<div className="col-3">Кладка стен из пазогребневых блоков</div>*/}
			{/*				<div className="col-3 font-weight-bold">Выполнено (Объем/сумма): 112 м2/15000 р.</div>*/}
			{/*				<div className="col-2 font-weight-bold">Оплачено: 13000 р.</div>*/}
			{/*				<div className="col-2 font-weight-bold">Сумма к оплате: 10000 р.</div>*/}
			{/*			</Paper>*/}
			{/*		</Link>*/}
			{/*	</li>*/}
			{/*	<li className="mt-2">*/}
			{/*		<Paper className="d-flex align-items-center p-3">*/}
			{/*			<div className="col-2">Заказ № 325</div>*/}
			{/*			<div className="col-3">Кладка стен из пазогребневых блоков</div>*/}
			{/*			<div className="col-3 font-weight-bold">Выполнено (Объем/сумма): 112 м2/15000 р.</div>*/}
			{/*			<div className="col-2 font-weight-bold">Оплачено: 13000 р.</div>*/}
			{/*			<div className="col-2 font-weight-bold">Сумма к оплате: 10000 р.</div>*/}
			{/*		</Paper>*/}
			{/*	</li>*/}

			{/*	<li className="mt-2">*/}
			{/*		<Paper className="d-flex align-items-center p-3 bg-main-2">*/}
			{/*			<div className="col-5 font-weight-bold">ВСЕГО:</div>*/}
			{/*			<div className="col-3 font-weight-bold">Выполнено (Сумма): 30000 р.</div>*/}
			{/*			<div className="col-2 font-weight-bold">Оплачено: 26000 р.</div>*/}
			{/*			<div className="col-2 font-weight-bold">Сумма к оплате: 20000 р.</div>*/}
			{/*		</Paper>*/}
			{/*	</li>*/}
			{/*</ul>*/}
		</>
	);
};

export default WorkerPersonalPayments;