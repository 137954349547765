import React from 'react';
import './PersonalOrderItemAccordionWrapper.scss'
import {Accordion, Card, useAccordionToggle} from "react-bootstrap";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default ({toggleName, eventKey, children, setActiveKey, activeKey,}) => {


	const CustomToggle = () => {
		const decoratedOnClick = useAccordionToggle(eventKey, () => {
			setActiveKey(eventKey)
		})

		return (

			<div onClick={decoratedOnClick}
				 className="PersonalOrderItemAccordionWrapper_toggle">

				<strong>{toggleName}</strong>

				<div className="PersonalOrderItemAccordionWrapper_toggle-arrow">
					{
						eventKey === activeKey
							? <ExpandLessIcon/>
							: <ExpandMoreIcon/>
					}
				</div>

			</div>

		);
	}

	return (
		<div
			className={`PersonalOrderItemAccordionWrapper_container`}>
			<CustomToggle/>
			<Accordion.Collapse eventKey={eventKey}>
				<Card.Body>
					{children}
				</Card.Body>
			</Accordion.Collapse>
		</div>
	);

}

