import axios from "../../rest/axios";

const PROJECT_CALC_CHANGE_FIELD_VALUE = "PROJECT_CALC_CHANGE_FIELD_VALUE";
const PROJECT_CALC_CHANGE_FIELD_ERROR = "PROJECT_CALC_CHANGE_FIELD_ERROR";
const PROJECT_CALC_CHANGE_FILE = "PROJECT_CALC_CHANGE_FILE";
const PROJECT_CALC_CLEAR_FILE = "PROJECT_CALC_CLEAR_FILE";
const PROJECT_CALC_VALIDATE_FILE = "PROJECT_CALC_VALIDATE_FILE";

const initialState = {
	formControls: {
		name: {
			id: "name",
			label: "Контактное лицо",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
			},
			errorMessage: {
				required: "Обязательное поле",
			},
		},
		contact: {
			id: "contact",
			label: "Телефон / e-mail",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
			},
			errorMessage: {
				required: "Обязательное поле",
			},
		},
		file: {
			id: "file",
			label: "Загрузите исходные материалы для рассчета",
			type: "file",
			valid: true,
			value: "Файлы не выбраны",
			error: "",
			files: [],
			hasFile: false,
			filesSize: 0,
			error: "",
			validation: {
				maxSize: 52428800,
				allowedExt: [
					"image/gif",
					"image/jpeg",
					"image/pjpeg",
					"image/png",
					"image/svg+xml",
					"image/webp",
				],
			},
			errorMessage: {
				maxSize: (max) => `Максимальный общий ращмер файлов - ${max / 1024}kb`,
				allowedExt: (ext) => `Файлы с расширением .${ext} не допускаются`,
			},
		},
		project_text: {
			id: "project_text",
			label: "Описание проекта",
			type: "textarea",
			valid: true,
			value: "",
		},
		task_text: {
			id: "task_text",
			label: "Описание задачи",
			type: "textarea",
			valid: true,
			value: "",
		},
	},
};

const projectCalcReducer = (state = initialState, { type, payload }) => {
	let tempFormControls = { ...state.formControls };
	switch (type) {
		case PROJECT_CALC_CHANGE_FIELD_VALUE:
			tempFormControls[payload.field].value = payload.value;
			return {
				...state,
				formControls: tempFormControls,
			};
		case PROJECT_CALC_CHANGE_FIELD_ERROR:
			tempFormControls[payload.field].error = payload.error;
			return {
				...state,
				formControls: tempFormControls,
			};
		case PROJECT_CALC_CHANGE_FILE:
			tempFormControls[payload.field].filesSize = 0;
			tempFormControls[payload.field].error = "";
			tempFormControls[payload.field].files = payload.files;
			for (let index = 0; index < payload.files.length; index++) {
				let file = payload.files[index];
				if (index === 0) {
					tempFormControls[payload.field].value = `${file.name} `;
				} else {
					tempFormControls[payload.field].value += `, ${file.name}`;
				}
				tempFormControls[payload.field].filesSize += file.size;
			}
			tempFormControls[payload.field].hasFile = true;
			return {
				...state,
				formControls: tempFormControls,
			};
		case PROJECT_CALC_CLEAR_FILE:
			tempFormControls[payload.field].value = "Файлы не выбраны";
			tempFormControls[payload.field].hasFile = false;
			tempFormControls[payload.field].files = [];
			tempFormControls[payload.field].filesSize = 0;
			tempFormControls[payload.field].error = "";
			return {
				...state,
				formControls: tempFormControls,
			};
		case PROJECT_CALC_VALIDATE_FILE:
			let field = tempFormControls[payload.field];
			let error = "";
			let errorValue = "";
			Object.keys(field.validation).forEach((errorName) => {
				switch (errorName) {
					case "maxSize":
						if (field.filesSize > field.validation[errorName]) {
							error = errorName;
							errorValue = field.validation[errorName];
						}
						break;
					case "allowedExt":
						for (let i = 0; i < payload.files.length; i++) {
							const fileType = payload.files[i].type;
							if (!field.validation[errorName].includes(fileType)) {
								error = errorName;
								errorValue = fileType;
							}
						}
						break;
				}
			});
			if (error) field.error = field.errorMessage[error](errorValue);
		default:
			return state;
	}
};

export const projectCalcChangeFieldValue = (value, field) => ({
	type: PROJECT_CALC_CHANGE_FIELD_VALUE,
	payload: {
		value,
		field,
	},
});

export const projectCalcChangeFieldError = (error, field) => ({
	type: PROJECT_CALC_CHANGE_FIELD_ERROR,
	payload: {
		error,
		field,
	},
});

const _projectCalcUploadFile = (files, field) => ({
	type: PROJECT_CALC_CHANGE_FILE,
	payload: {
		files,
		field,
	},
});

const _projectCalcValidateFile = (field, files) => ({
	type: PROJECT_CALC_VALIDATE_FILE,
	payload: {
		field,
		files,
	},
});

export const projectCalcChangeFile = (files, field) => (dispatch) => {
	dispatch(_projectCalcUploadFile(files, field));
	dispatch(_projectCalcValidateFile(field, files));
};

export const projectCalcClearFile = (field) => ({
	type: PROJECT_CALC_CLEAR_FILE,
	payload: {
		field,
	},
});

export const projectCalcSendForm = (formData) => async (dispatch) => {
	try {
		const { data } = await axios.post("feedback/order/", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	} catch (error) {}
};

export default projectCalcReducer;
