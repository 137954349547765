import React from 'react';
import { connect } from 'react-redux';
import GreenButton from '../../../components/UI/GreenButton/GreenButton';
import './Feedback.scss';
import { modalOpenModal } from '../../../store/reducers/modalReducer';
import yandexGoal from '../../../utils/yandexGoal';

const Feedback = ({modalOpenModal}) => {
	const openModal = () => {
		yandexGoal('QUESTION_BUTTON')
		modalOpenModal({
			title: 'Задать вопрос',
			buttonText: 'Отправить', 
			activeFields: ['name', 'phone', 'email', 'question'],
			goal: 'QUESTION_FORM',
		})
	}
	return (
		<div className="Feedback">
			<div className="Feedback__title">
				Остались вопросы? Мы ответим!
			</div>
			<div className="d-flex justify-content-center mt-5">
				<GreenButton className='Feedback__button' onClick={openModal}>Задать вопрос</GreenButton>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	
})
const mapDispatchToprops = {
	modalOpenModal
}
export default connect(mapStateToProps, mapDispatchToprops)(Feedback)