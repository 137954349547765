import React, { useEffect, useState } from "react";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import parse from "html-react-parser";

const AdminPopup = ({ saveResult, clearResultHandler }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(!!saveResult.title);
	}, [saveResult]);

	// const handleOpen = () => {
	//     setOpen(true);
	// };

	const handleClose = () => {
		clearResultHandler();
		setOpen(false);
	};

	return (
		<div>
			{/*<button type="button" onClick={handleOpen}>*/}
			{/*react-transition-group*/}
			{/*</button>*/}
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className='d-flex justify-content-center align-items-center'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={open}>
					<Paper className='AdminUI_modal'>
						<h2>{saveResult.title}</h2>
						<p>{saveResult.description}</p>
						{saveResult.list ? (
							<List>
								{saveResult.list.map((result, result_idx) => {
									return (
										<ListItem key={result.id}>
											{result_idx + 1}. {result.name} &nbsp; -
											{result.result ? (
												<span className='text-success'>Ok!</span>
											) : (
												<span className='text-danger'>
													{parse(result.error)}
												</span>
											)}
										</ListItem>
									);
								})}
							</List>
						) : null}
					</Paper>
				</Fade>
			</Modal>
		</div>
	);
};

export default AdminPopup;
