import React from 'react';
import './AdminUI.scss'

const AdminActive = ({edited, active}) => {
    return (
        <div className="d-flex align-items-center" style={{height: '26px'}}>
            {
                edited && <div className="AdminUI_chip AdminUI_chip-edited"/>
            }
            {
                active ?
                    <div className="AdminUI_chip AdminUI_chip-active"/>
                    :
                    <div className="AdminUI_chip AdminUI_chip-disable"/>

            }
        </div>
    );
};

export default AdminActive;