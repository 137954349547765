import React from "react";
import parse from "html-react-parser";
import { getMaterialPrice } from "../calculateFunctions";
import NumberFormat from "react-number-format";

export default function EstimateOrderMaterialRow({ material, inParent }) {

  let pricePerOne = material.price;
  if (material.goods.price) {
    pricePerOne = material.goods.price / material.goods.pack * material.goods.package_coefficient
  }

  return (
    <tr>
      <td className="text-right"></td>
      <td className={`${inParent ? "pl-4" : "pl-5"} text-left`}>
        - {material.name}
      </td>
      <td>{material.unit && parse(material.unit)}</td>
      <td>
        <NumberFormat
          value={material.quanity.toFixed(3)}
          displayType={"text"}
          thousandSeparator={" "}
        />
      </td>
      <td>
        {
          <NumberFormat
            value={pricePerOne.toFixed(2)}
            displayType={"text"}
            thousandSeparator={" "}
          />
        }
      </td>
      <td>-</td>
      <td>
        {
          <NumberFormat
            value={(
              pricePerOne * material.quanity
            ).toFixed(2)}
            displayType={"text"}
            thousandSeparator={" "}
          />
        }
      </td>
    </tr>
  );
}
