import * as firebase from 'firebase/app';

var firebaseConfig = {
    apiKey: "AIzaSyAZaKN2amwSrAMTGbB5nWH_pYtU58VsOuU",
    authDomain: "finishworker-13afa.firebaseapp.com",
    databaseURL: "https://finishworker-13afa.firebaseio.com",
    projectId: "finishworker-13afa",
    storageBucket: "finishworker-13afa.appspot.com",
    messagingSenderId: "536007210966",
    appId: "1:536007210966:web:a597fb2516ab2730be1677",
    measurementId: "G-755LZ7GFZK"
};
firebase.initializeApp(firebaseConfig);


export default firebase;