import React, {Component, Fragment} from 'react';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import {connect} from "react-redux";
import {
    addServiceNorm,
    deleteServiceNorm, editServiceNorm,
    setServiceNorm,
    // setServiceProperty
} from "../../../../store/actions/adminServiceActions";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// import ScrollAnimation from 'react-animate-on-scroll';


class AdminNormWorks extends Component {

    state = {
        currentTab: 0,
        normTotal: 0,
        ccTotal: 1,
    }

    setTotalValues = () => {

        let normTotal = 0
        let ccTotal = 0
        let count = 0

        const service = this.props.services.find(service => service.id === this.props.serviceId)

        if (!!service.items.length) {
            service.items.forEach((item) => {

                item.norms.forEach(norm => {
                    normTotal += parseFloat(norm.norm) || 0
                    ccTotal += parseFloat(norm.cc) || 0
                    count++
                })


            })
            if (count > 0) {
                ccTotal /= count
            } else {
                ccTotal = 0
            }
        } else {
            service.norms.forEach(norm => {
                normTotal += parseFloat(norm.norm) || 0
                ccTotal += parseFloat(norm.cc) || 0
            })

            if (service.norms.length > 0) {
                ccTotal /= service.norms.length
            } else {
                ccTotal = 0;
            }
        }


        this.setState({
            normTotal: normTotal.toFixed(2),
            ccTotal: ccTotal.toFixed(2),

        })
    }

    setNormHandler = async (normId, value, property, itemId = null) => {
        await this.props.setServiceNorm(this.props.serviceId, normId, value, property, itemId)
        this.setTotalValues()
    }

    addNormHandler = (itemId = null) => {
        const {professions, addServiceNorm} = this.props
        const service = this.props.services.find(service => service.id === this.props.serviceId)
        if (itemId) {
            // выбираем профессии, которые не учавствуют
            const subService = service.items.find(item => item.id === itemId)

            const activeProfessions = subService.norms.map(norm => norm.id)
            const freeProfessions = professions.filter(profession => {
                return activeProfessions.indexOf(profession.id) < 0
            })

            if (!!freeProfessions.length) {
                const norm = {...freeProfessions[0], norm: 0, cc: 1, edit: true}
                addServiceNorm(service.id, norm, itemId)
            }
        } else {
            // выбираем профессии, которые не учавствуют
            const activeProfessions = service.norms.map(norm => norm.id)
            const freeProfessions = professions.filter(profession => {

                return activeProfessions.indexOf(profession.id) < 0
            })

            if (!!freeProfessions.length) {
                const norm = {...freeProfessions[0], norm: 0, cc: 1, edit: true}
                addServiceNorm(service.id, norm, itemId)
            }
        }
        this.setTotalValues()
    }

    deleteNormHandler = async (normId, itemId = null) => {
        await this.props.deleteServiceNorm(this.props.serviceId, normId, itemId)
        this.setTotalValues()
    }

    editNormHandler = async (normId, id, itemId = null) => {

        if (!!this.props.professions.length) {
            const selectProfession = this.props.professions.find(profession => {
                return profession.id === id
            })
            const norm = {...selectProfession, norm: 0, cc: 1, edit: true}
            await this.props.editServiceNorm(this.props.serviceId, normId, norm, itemId)
            this.setTotalValues()
        }

    }

    componentDidMount() {
        this.setTotalValues()
    }

    render() {
        const service = this.props.services.find(service => service.id === this.props.serviceId)

        const {
            normTotal,
            ccTotal,
        } = this.state

        const height = '50px'


        return (

            <Fragment>
                {
                    !!service &&
                    <List className="w-100 px-0">
                        {
                            !!service.items.length ?
                                <Fragment>
                                    <ListItem
                                        className="Admin_row d-flex p-0  align-items-center justify-content-end border-top border-gray"
                                        style={{height}}>
                                        <Typography variant="h6"
                                                    className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                            {normTotal}
                                        </Typography>
                                        <Typography variant="h6"
                                                    className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                            {ccTotal}
                                        </Typography>

                                        <div className="col-2"/>
                                    </ListItem>
                                    {
                                        service.items.map(item => {

                                            return (
                                                <Fragment key={item.id}>
                                                    {
                                                        item.norms.map((normItem, normItem_idx) => {
                                                            let freeProfessions = []
                                                            if (normItem.edit) {
                                                                const activeProfessions = item.norms.map(norm => norm.id)
                                                                freeProfessions = this.props.professions.filter(profession => {
                                                                    return activeProfessions.indexOf(profession.id) < 0 || profession.id === normItem.id
                                                                })
                                                            }
                                                            return (
                                                                <ListItem
                                                                    key={normItem.id}
                                                                    className={`Admin_row d-flex p-0 align-items-center border-top border-gray`}
                                                                    style={{height}}>
                                                                    {normItem.edit ?
                                                                        <FormControl variant="outlined"
                                                                                     className='Admin_select col-4'>
                                                                            <Select
                                                                                className="Admin_select-list"
                                                                                size={5}
                                                                                value={normItem.id}
                                                                                onChange={evt => this.editNormHandler(normItem.id, evt.target.value, item.id)}
                                                                            >
                                                                                {freeProfessions.map(profession => {
                                                                                    return (

                                                                                        <MenuItem
                                                                                            key={profession.id}
                                                                                            value={profession.id}>
                                                                                            {profession.name}
                                                                                        </MenuItem>
                                                                                    )
                                                                                })}

                                                                            </Select>
                                                                        </FormControl>
                                                                        :
                                                                        <Typography variant="subtitle1"
                                                                                    className="col-4 pl-4 m-0 align-self-center">
                                                                            {normItem_idx + 1}. &nbsp;{normItem.name}
                                                                        </Typography>
                                                                    }
                                                                    <TextField
                                                                        className='Admin_cell col-3 align-self-center'
                                                                        variant="outlined"
                                                                        type="number"
                                                                        value={normItem.norm || ''}
                                                                        onChange={(e) => this.setNormHandler(normItem.id, e.target.value, 'norm', item.id)}
                                                                    />
                                                                    <TextField
                                                                        className='Admin_cell col-3 align-self-center'
                                                                        variant="outlined"
                                                                        type="number"
                                                                        value={normItem.cc || ''}
                                                                        onChange={(e) => this.setNormHandler(normItem.id, e.target.value, 'cc', item.id)}
                                                                    />
                                                                    <div
                                                                        className="d-flex justify-content-end align-items-center align-self-center col-2">
                                                                        {
                                                                            normItem.edit ?
                                                                                <Button
                                                                                    className="Admin_btn ml-2"
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={() => this.setNormHandler(normItem.id, false, 'edit', item.id)}
                                                                                >
                                                                                    <SaveIcon
                                                                                        className='Admin_btn-icon Admin_btn-icon-create'/>
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    className="Admin_btn ml-2"
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={() => this.setNormHandler(normItem.id, true, 'edit', item.id)}
                                                                                >
                                                                                    <EditIcon
                                                                                        className='Admin_btn-icon Admin_btn-icon-create'/>
                                                                                </Button>
                                                                        }
                                                                        <Button
                                                                            className="Admin_btn ml-2"
                                                                            variant="contained" color="secondary"
                                                                            onClick={() => this.deleteNormHandler(normItem.id, item.id)}
                                                                        >
                                                                            <DeleteIcon
                                                                                className='Admin_btn-icon Admin_btn-icon-create'/>
                                                                        </Button>
                                                                    </div>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                    <ListItem
                                                        className='w-100 d-flex align-items-center justify-content-end align-self-center border-top  border-gray'
                                                        style={{height}}>
                                                        <Button
                                                            className="Admin_btn align-self-center"
                                                            variant="contained" color="primary"
                                                            onClick={() => this.addNormHandler(item.id)}
                                                        >
                                                            <AddCircleOutlineIcon
                                                                className='Admin_btn-icon Admin_btn-icon-create'/>
                                                        </Button>
                                                    </ListItem>
                                                </Fragment>
                                            )
                                        })
                                    }

                                </Fragment>
                                :
                                <Fragment>
                                    <ListItem
                                        className="Admin_row d-flex p-0  align-items-center justify-content-end border-top border-gray"
                                        style={{height}}>

                                        <Typography variant="h6"
                                                    className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                            {normTotal}
                                        </Typography>
                                        <Typography variant="h6"
                                                    className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                            {ccTotal}
                                        </Typography>
                                        <div className='col-2'/>
                                    </ListItem>
                                    {
                                        service.norms.map((normItem, normItem_idx) => {
                                            let freeProfessions = []
                                            if (normItem.edit) {
                                                const activeProfessions = service.norms.map(norm => norm.id)
                                                freeProfessions = this.props.professions.filter(profession => {
                                                    return activeProfessions.indexOf(profession.id) < 0 || profession.id === normItem.id
                                                })
                                            }

                                            return (


                                                <ListItem
                                                    key={normItem.id}
                                                    className={`Admin_row d-flex p-0 align-items-center border-top border-gray`}
                                                    style={{height}}>
                                                    {
                                                        normItem.edit ?
                                                            <FormControl variant="outlined"
                                                                         className='Admin_select col-4'>
                                                                <Select
                                                                    className="Admin_select-list"
                                                                    size={5}
                                                                    value={normItem.id}
                                                                    onChange={evt => this.editNormHandler(normItem.id, evt.target.value)}
                                                                >
                                                                    {freeProfessions.map(profession => {
                                                                        return (

                                                                            <MenuItem
                                                                                key={profession.id}
                                                                                value={profession.id}>
                                                                                {profession.name}
                                                                            </MenuItem>
                                                                        )
                                                                    })}

                                                                </Select>
                                                            </FormControl>
                                                            :
                                                            <Typography variant="subtitle1"
                                                                        className="col-4 pl-4 m-0  align-self-center ">
                                                                {normItem_idx + 1}. &nbsp;{normItem.name}
                                                            </Typography>
                                                    }


                                                    <TextField
                                                        className='Admin_cell col-3 align-self-center'
                                                        variant="outlined"
                                                        type="number"
                                                        value={normItem.norm || ''}
                                                        onChange={(e) => this.setNormHandler(normItem.id, e.target.value, 'norm')}
                                                    />
                                                    <TextField
                                                        className='Admin_cell col-3 align-self-center'
                                                        variant="outlined"
                                                        type="number"
                                                        value={normItem.cc || ''}
                                                        onChange={(e) => this.setNormHandler(normItem.id, e.target.value, 'cc')}
                                                    />
                                                    <div
                                                        className="d-flex justify-content-end align-items-center align-self-center col-2">
                                                        {
                                                            normItem.edit ?
                                                                <Button
                                                                    className="Admin_btn ml-2"
                                                                    variant="contained" color="primary"
                                                                    onClick={() => this.setNormHandler(normItem.id, false, 'edit')}
                                                                >
                                                                    <SaveIcon
                                                                        className='Admin_btn-icon Admin_btn-icon-create'/>
                                                                </Button>
                                                                :
                                                                <Button
                                                                    className="Admin_btn ml-2"
                                                                    variant="contained" color="primary"
                                                                    onClick={() => this.setNormHandler(normItem.id, true, 'edit')}
                                                                >
                                                                    <EditIcon
                                                                        className='Admin_btn-icon Admin_btn-icon-create'/>
                                                                </Button>
                                                        }
                                                        <Button
                                                            className="Admin_btn ml-2"
                                                            variant="contained" color="secondary"
                                                            onClick={() => this.deleteNormHandler(normItem.id)}
                                                        >
                                                            <DeleteIcon
                                                                className='Admin_btn-icon Admin_btn-icon-create'/>
                                                        </Button>
                                                    </div>

                                                </ListItem>


                                            )

                                        })
                                    }
                                    <ListItem
                                        className='w-100 d-flex align-items-center justify-content-end align-self-center border-top border-bottom border-gray'
                                        style={{height}}>
                                        <Button
                                            className="Admin_btn align-self-center"
                                            variant="contained" color="primary"
                                            onClick={() => this.addNormHandler()}
                                        >
                                            <AddCircleOutlineIcon
                                                className='Admin_btn-icon Admin_btn-icon-create'/>
                                        </Button>
                                    </ListItem>
                                </Fragment>
                        }
                    </List>
                }
            </Fragment>


        );
    }
}


const mapState = (state) => {
    return {
        services: state.adminService.services,
        professions: state.adminService.professions
    }
}
const actions = {
    setServiceNorm,
    // setServiceProperty,
    addServiceNorm,
    deleteServiceNorm,
    editServiceNorm
}

export default connect(mapState, actions)(AdminNormWorks);

