import React from 'react';
import './LittleLoader.scss'

const LittleLoader = () => {
    return (
        <div className='LittleLoader'>
            <svg  viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path d="M5 50A45 45 0 0 0 95 50A45 48.3 0 0 1 5 50" fill="#4248f7" stroke="none">
                    <animateTransform attributeName="transform" type="rotate" dur="1.5384615384615383s"
                                      repeatCount="indefinite" keyTimes="0;1"
                                      values="0 50 51.65;360 50 51.65">
                    </animateTransform>
                </path>
            </svg>
        </div>
    );
};

export default LittleLoader;