import React from 'react';
import BackBtn from '../../../../components/UI/BackBtn/BackBtn';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const WorkerPersonalPaymentDetail = (props) => {
	return (
		<div className="mt-3">
			<BackBtn to="/worker/personal/payment/" variant="dark"/>
			<h3 className="px-3">Заказ №{props.match.params.id}</h3>
			<div className="d-flex align-items-center mt-3">
				<strong className="col-3">Итого выполнено (Объем/сумма):</strong>
				<div className="ml-3">112 м2 / 15 000 р.</div>
			</div>
			<div className="d-flex align-items-center">
				<strong className="col-3">Итого оплачено:</strong>
				<div className="ml-3">15 000 р.</div>
			</div>
			<div className="d-flex align-items-center">
				<strong className="col-3">Итого сумма к оплате:</strong>
				<div className="ml-3">15 000 р.</div>
			</div>
			<Paper className='wp__line mt-5 px-3 py-2 '>
				<Typography variant="overline"
							className="col-1 p-0 m-0 font-weight-bold">#</Typography>
				<Typography variant="overline"
							className="col-2 p-0 m-0 font-weight-bold">Транши</Typography>
				<Typography variant="overline"
							className="col-3 p-0 m-0 font-weight-bold">
					Сумма остатка оплаты (руб.)
				</Typography>
				<Typography variant="overline"
							className="col-2 p-0 m-0 font-weight-bold">
					Дата оплаты
				</Typography>
				<Typography variant="overline"
							className="col-2 p-0 m-0 font-weight-bold">
					Статус оплаты
				</Typography>
			</Paper>
			<ul className="p-3">
				<li className="d-flex align-items-center border-bottom py-2">
					<Typography variant="body2"
								className="col-1 p-0 m-0 font-weight-bold">1</Typography>
					<Typography variant="body2"
								className="col-2 p-0 m-0 font-weight-bold">Транш # 1</Typography>
					<Typography variant="body2"
								className="col-3 p-0 m-0 font-weight-bold">
						3000
					</Typography>
					<Typography variant="body2"
								className="col-2 p-0 m-0 font-weight-bold">
						08.11.2020
					</Typography>
					<Typography variant="body2"
								className="col-2 p-0 m-0 font-weight-bold text-success">
						Оплачено
					</Typography>
				</li>
				<li className="d-flex align-items-center border-bottom py-2">
					<Typography variant="body2"
								className="col-1 p-0 m-0 font-weight-bold">2</Typography>
					<Typography variant="body2"
								className="col-2 p-0 m-0 font-weight-bold">Транш # 2</Typography>
					<Typography variant="body2"
								className="col-3 p-0 m-0 font-weight-bold">
						3000
					</Typography>
					<Typography variant="body2"
								className="col-2 p-0 m-0 font-weight-bold">
						08.11.2020
					</Typography>
					<Typography variant="body2"
								className="col-2 p-0 m-0 font-weight-bold text-warning">
						Оплачено
					</Typography>
				</li>
			</ul>

		</div>
	);
};

export default WorkerPersonalPaymentDetail;