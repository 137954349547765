import React from 'react'
import classes from './GreenButton.module.scss'
import {Link} from 'react-router-dom'

export default ({disabled = false, className, onClick, to, styles, children}) => {

    const style = {...styles};
    const classNames = [classes.GreenButton, className];
    const onClickHandler = () => {
        if (disabled) {
            return null
        }
        onClick()
    };


    return (
        <>
            {
                to
                    ?
                    <Link
                        to={to}
                        className={classNames.join(" ")}
                        style={style}
                    >
                        {children}
                    </Link>
                    : <div
                        role={"button"}
                        className={classNames.join(" ")}
                        style={style}
                        onClick={onClickHandler}
                    >
                        {children}
                    </div>
            }
        </>
    )
}

