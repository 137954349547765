import React from 'react'
import './ActKsBottomItem.scss'

export default ({text, underLineDesc}) => {
    return (
        <div className='ActKsBottomItem'>
            <div className="border-bottom border-dark">
                <div>{text}</div>
                <div>{underLineDesc}</div>
            </div>
        </div>
    )
}
