import React from 'react';
import {useDispatch} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import * as moment from 'moment';
import {setIndividualOrders, setMasterListModeration} from '../../../../store/actions/adminMastersListActions';
import {ROLE_MANAGER} from '../../../../config';
import {useHistory} from 'react-router-dom';

const AdminUserLine = ({role, user, idx}) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const {
		id,
		name,
		phone,
		email,
		moderation,
		dateInstructionFormated,
		picture,
		bOnlyIndividualOrders,
		masterName
	} = user;

	const switchModerationHandler = (userId, statusId) => {
		dispatch(setMasterListModeration(userId, statusId === 2 ? '' : 'Y', role))
	};

	const switchIndividualOrderHandler = () => {
		dispatch(setIndividualOrders(id, bOnlyIndividualOrders ? 'N' : 'Y'))
	};

	const setUserPersonal = (id) => () => {
		history.push(history.location.pathname + '/' + id + '/data')
	}

	return (
		<Paper className='w-100 p-3 my-1 d-flex align-items-center'>
			<div className='col-1 pr-2 d-flex justify-content-between align-items-center'>
				<span className="pr-2">{idx}. / {id}</span>
			</div>
			<div className='col-2 pr-2 d-flex justify-content-start align-items-center'
				 onClick={setUserPersonal(id)}
				 style={{cursor: 'pointer'}}>
				<Avatar alt="Cindy Baker" src={picture}/>
				<span className="pl-2">{name}</span>
			</div>
			<div className='col-3 p-0'>{email}</div>
			<div className='col-1 p-2'>{phone}</div>
			<div className='col pr-2'>{masterName}</div>
			{
				role === ROLE_MANAGER &&
				<div className='col d-flex flex-column position-relative'>
					<FormControlLabel
						control={
							<Switch
								checked={!bOnlyIndividualOrders}
								onChange={switchIndividualOrderHandler}
								name="checkedB"
								color="primary"
								size="small"
								classes={{root: `Admin__switch-btn ${bOnlyIndividualOrders ? '' : 'Admin__switch-btn_checked'}`}}
							/>
						}
						label={bOnlyIndividualOrders ? 'Индивид.заказы' : 'Все заказы'}
						classes={{
							label: `Admin__switch-label ${bOnlyIndividualOrders ? '' : 'Admin__switch-label_checked'}`,
							root: 'Admin__switch'
						}}
					/>
				</div>
			}
			<div className='col p-0 d-flex flex-column position-relative'>
				<FormControlLabel
					control={
						<Switch
							checked={moderation.id === 2}
							onChange={() => switchModerationHandler(id, moderation.id)}
							name="checkedB"
							color="primary"
							size="small"
							classes={{root: `Admin__switch-btn ${moderation.id === 1 ? '' : 'Admin__switch-btn_checked'}`}}
						/>
					}
					label={moderation.id === 1 ? 'На модерации' : 'Прошел модерацию'}
					classes={{
						label: `Admin__switch-label ${moderation.id === 1 ? '' : 'Admin__switch-label_checked'}`,
						root: 'Admin__switch'
					}}
				/>
				{
					!!dateInstructionFormated &&
					<time className='Admin__switch-time text-secondary'>
						{moment(dateInstructionFormated, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')}
					</time>
				}
			</div>
		</Paper>
	);
};

export default AdminUserLine;