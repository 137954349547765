import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import logo from '../../../img/logo_transparent.png'
import {adminLogOut} from "../../../store/actions/adminActions";


const WorkerTopNav = ({adminLogOut, title}) => {


    return (
        <div className='a__top-nav'>
            <div className='a__top-nav-left'>
                <Link to={'/'}>
                    <div className='a__top-nav-logo' style={{backgroundImage: `url(${logo})`}}/>
                </Link>
                <div className='a__top-nav-title'>
                    {title}
                </div>
            </div>
            <div className='d-flex'>
                {/*<div className="a__top-nav-link">*/}
                {/*    <Link to={'/'}>*/}
                {/*        <HomeIcon/>*/}
                {/*        <span className='text-white ml-3'>На главную</span>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                <div className="a__top-nav-link" onClick={adminLogOut}>
                    <span className='text-white mr-3'>Выход</span>
                    <ExitToAppIcon/>
                </div>
            </div>
        </div>
    );
};

const actions = {
    adminLogOut
}
export default connect(false, actions)(WorkerTopNav);