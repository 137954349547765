import React, { Component } from "react";

import "./PersonalOrderItemService.scss";
import { connect } from "react-redux";
import parse from "html-react-parser";
import DraggableScroll from "../../../../../components/UI/DraggableScroll/DraggableScroll";
import OrderStatus from "../../../../../components/UI/OrderStatus/OrderStatus";

class PersonalOrderItemService extends Component {
	render() {
		return (
			<DraggableScroll>
				<div className='PersonalOrderItemService pt-4'>
					<table>
						<thead>
							<tr>
								{this.props.servicesTableHead.map((head) => {
									return (
										<th colSpan={head.col} key={head.id}>
											<div className='p-2 font-weight-normal'>{head.name}</div>
										</th>
									);
								})}
							</tr>
						</thead>

						{this.props.order ? (
							<tbody>
								<tr>
									<td colSpan={3}>
										<div className='p-2'>{this.props.order.serviceName}</div>
									</td>
									<td>
										<div className='p-2'>
											<OrderStatus status={this.props.order.status} />
										</div>
									</td>
									<td>
										<div className='p-2'>{parse(this.props.order.unit)}</div>
									</td>
									<td>
										<div className='p-2'>{this.props.order.unitPrice}</div>
									</td>
									<td>
										<div className='p-2'>{this.props.order.serviceValue}</div>
									</td>
									<td>
										<div className='p-2'>
											{this.props.order.servicePriceFormat}
										</div>
									</td>
									<td>
										<div className='p-2'>{this.props.order.startDate}</div>
									</td>
									<td>
										<div className='p-2'>{this.props.order.finishDate}</div>
									</td>
									<td>
										<div className='p-2'>{this.props.order.workersCount}</div>
									</td>
									<td>
										<div className='p-2'>{this.props.order.orderType}</div>
									</td>
								</tr>
							</tbody>
						) : null}
					</table>
				</div>
			</DraggableScroll>
		);
	}
}

function mapStateToProps(state) {
	return {
		servicesTableHead: state.orderList.servicesTableHead,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PersonalOrderItemService);
