import React from "react";
import { getConfirmedValue, withMaterials } from "../functions";

const PersonalOrderItemTranchesDay = ({ order, day }) => {
	let dayReports = order.reports.filter((report) => report.date === day);
	let serviceValues = [];
	if (!!dayReports.length) {
		dayReports.forEach((report) => {
			report.services.forEach((service) => {
				if (!!serviceValues[service.id]) {
					serviceValues[service.id] += service.value;
				} else {
					serviceValues[service.id] = service.value;
				}
			});
		});
	}

	let servicesRows = [];

	let trancheDates = Object.keys(order.tranches).map((key) => {
		let tranche = order.tranches[key];
		return tranche;
	});
	trancheDates = trancheDates.filter((tranche) => !!tranche.date);
	let tranche = trancheDates.filter((tranche) => {
		return tranche.date === day;
	})[0];

	let isTranche = !!tranche;

	order.services.forEach((service, idx) => {
		let value = "";

		serviceValues.forEach((serviceValue, idx) => {
			if (idx === service.id) {
				value = serviceValue;
			}
		});

		let confirmedValue = serviceValues.length
			? getConfirmedValue(serviceValues).toFixed(2)
			: 0;

		// количество проделаной работы
		servicesRows.push(
			<div
				key={day + Math.random()}
				className={`px-2 ${
					isTranche ? "border-tranche-1" : "border-main-1-3"
				} border-bottom d-flex align-items-center justify-content-center h40`}>
				<div
					className={`${
						isTranche ? "bg-tranche-1" : "bg-white"
					} d-flex align-items-center justify-content-center h-100 w-100`}>
					<strong>{confirmedValue}</strong>
				</div>
			</div>,
		);

		// фин выполнение по работе
		servicesRows.push(
			<div
				key={day + Math.random()}
				className={`px-2 ${
					isTranche ? "border-tranche-1" : "border-main-1-3"
				} border-bottom d-flex align-items-center justify-content-center h40`}>
				<div
					className={`${
						isTranche ? "bg-tranche-1" : "bg-white"
					} d-flex align-items-center justify-content-center h-100 w-100`}>
					{(confirmedValue * order.unitPrice).toFixed()}
				</div>
			</div>,
		);
		// фин выполнение по материалам
		if (withMaterials(order)) {
			const totalMaterialPrice = order.totalPrice - order.servicePrice;
			const materialPrice = totalMaterialPrice / order.serviceValue;
			servicesRows.push(
				<div
					key={day + Math.random()}
					className={`px-2 ${
						isTranche ? "border-tranche-1" : "border-main-1-3"
					} border-bottom d-flex align-items-center justify-content-center h40`}>
					<div
						className={`${
							isTranche ? "bg-tranche-1" : "bg-white"
						} d-flex align-items-center justify-content-center h-100 w-100`}>
						{(confirmedValue * materialPrice).toFixed()}
					</div>
				</div>,
			);

			servicesRows.push(
				<div
					key={day + Math.random()}
					className={`px-2 ${
						isTranche ? "border-tranche-1" : "border-main-1-3"
					} border-bottom d-flex align-items-center justify-content-center h40`}>
					<div
						className={`${
							isTranche ? "bg-tranche-1" : "bg-white"
						} d-flex align-items-center justify-content-center h-100 w-100`}>
						<strong>
							{(confirmedValue * (materialPrice + order.unitPrice)).toFixed()}
						</strong>
					</div>
				</div>,
			);
		}
	});

	return servicesRows;
};

export default PersonalOrderItemTranchesDay;
