import React, {useEffect, useMemo, useState} from 'react';
import is from 'is_js';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import {Button, Typography} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';
import {LockOpen} from '@material-ui/icons';
import {useDispatch} from 'react-redux';
import {saveAdminIndividualService} from '../../../../../store/actions/adminServiceListActions';
import {getConditionPrice} from '../functions';

const AdminClientListIndividual = ({service, client, setDiscount, isNew = false}) => {
	const dispatch = useDispatch();
	const [lock, setLock] = useState(false);
	const [prices, setPrices] = useState(null);
	const [individualMasterPrice, setIndividualMasterPrice] = useState('');
	const [individualServicePrice, setIndividualServicePrice] = useState('');
	const [startServicePrice, setStartServicePrice] = useState(null);
	const [isEdited, setEdited] = useState(false);

	const getLockMasterPrice = () => {
		return (individualServicePrice / service.customerCoefficient.standard).toFixed()
	}

	const updateIndividualService = (save = true) => {
		let conditions = [...service.individual_conditions.filter(condition => parseInt(condition.toString()
			.split(':')[0]) !== client.id)];
		let masterPrices = [...service.individual_master_price.filter(price => parseInt(price.toString()
			.split(':')[0]) !== client.id)];
		if (individualServicePrice && is.number(+individualServicePrice) && save) {
			conditions = [...conditions, `${client.id}:${individualServicePrice}`];
		}
		if (lock && save) {
			masterPrices = [...masterPrices, `${client.id}:${getLockMasterPrice()}`]
		} else {
			masterPrices = [...masterPrices, `${client.id}:${individualMasterPrice}`]
		}
		const editedService = _.cloneDeep(service);
		editedService.individual_conditions = conditions;
		editedService.individual_master_price = masterPrices;
		editedService.edited = true;
		dispatch(saveAdminIndividualService(editedService))
		// для закрытия добавления
		if (setDiscount) {
			setDiscount(false)
		}
	}

	const setLockHandler = () => {
		if (lock) {
			setLock(false)
		} else {
			setLock(true)
			setIndividualMasterPrice(getLockMasterPrice())
		}
	}

	const getMasterPrices = useMemo(() => {
		let standardPrice = 0
		let premiumPrice = 0

		if (!!service.items.length) {
			service.items.forEach((item) => {

				item.norms.forEach(norm => {

					standardPrice += norm.norm * norm.cc * norm.costHourStandard
					premiumPrice += norm.norm * norm.cc * norm.costHourPremium

				})

			})
		} else {
			service.norms.forEach(norm => {
				standardPrice += norm.norm * norm.cc * norm.costHourStandard
				premiumPrice += norm.norm * norm.cc * norm.costHourPremium
			})
		}
		return {standardPrice, premiumPrice}
	}, [service]);

	const getIndividualServicePrice = useMemo(() => {
		return getConditionPrice(service.individual_conditions, client.id)
	}, [service]);

	const getIndividualMasterPrice = useMemo(() => {
		return getConditionPrice(service.individual_master_price, client.id)
	}, [service]);

	const changeServicePriceHandler = (value) => {
		setEdited(true)
		setIndividualServicePrice(Math.abs(value))
	}

	const changeMasterPriceHandler = (value) => {
		setEdited(true)
		setIndividualMasterPrice(value ? Math.abs(value) : 0)
	}

	useEffect(() => {
		setPrices(getMasterPrices)
		setIndividualServicePrice(Math.abs(getIndividualServicePrice))
		setStartServicePrice(Math.abs(getIndividualServicePrice))
	}, [service]);

	useEffect(() => {
		if (individualServicePrice) {
			if (!individualMasterPrice && individualMasterPrice !== 0) {
				const lockPrice = getLockMasterPrice();
				const realPrice = getIndividualMasterPrice;
				if (lockPrice === realPrice || !realPrice) {
					setLock(true)
					setIndividualMasterPrice(lockPrice)
				} else if (!!realPrice) {
					setLock(false)
					setIndividualMasterPrice(realPrice)
				}
			}
		} else {
			setIndividualMasterPrice('')
		}
	}, [individualServicePrice, individualMasterPrice, service])

	useEffect(() => {
		if (individualServicePrice) {
			if (lock) {
				setIndividualMasterPrice(getLockMasterPrice())
			}
		}
	}, [individualServicePrice, lock])

	useEffect(() => {
		let edited = startServicePrice !== individualServicePrice || individualMasterPrice !== getLockMasterPrice();
		if (isNew && (!individualServicePrice && !individualMasterPrice)) {
			edited = false
		}
		setEdited(edited)

	}, [individualServicePrice, individualMasterPrice, startServicePrice])

	return (
		<div id={service.id} className="d-flex flex-column w-100">
			{
				!!prices
				&& (<div className="d-flex flex-column bg-white p-3 rounded">
					<div
						className={`border-bottom border-blue my-3 pb-3 d-flex justify-content-${isNew ? 'between' : 'end'}`}>
						{
							isNew
							&& <div className="d-flex align-items-center">{service.name} [id:{service.id}]</div>
						}
						<div className="d-flex">
							<div className="AdminClientList__price AdminClientList__price_title">Цена стандарт, руб.
							</div>
							<div className="AdminClientList__price AdminClientList__price_title">Цена премиум, руб.
							</div>
							<div className="AdminClientList__price AdminClientList__price_title">Новая цена, руб.</div>
						</div>
					</div>
					<div
						className='pl-3 py-2 pb-1 d-flex justify-content-between align-items-center'>
						<div className="d-flex align-items-center">Цена клиента</div>
						<div className="d-flex align-items-center">
							<div className="AdminClientList__price">
								<div
									className="AdminClientList__price-num">{(prices.standardPrice * service.customerCoefficient.standard).toFixed()}</div>
							</div>
							<div className="AdminClientList__price">
								<div
									className="AdminClientList__price-num">{(prices.premiumPrice * service.customerCoefficient.premium).toFixed()}</div>
							</div>
							<TextField
								type="number"
								value={individualServicePrice || ''}
								className="Admin_cell AdminClientList__price m-0"
								variant="outlined"
								onChange={(e) => changeServicePriceHandler(e.target.value)}
							/>
						</div>
					</div>
					<div className="d-flex justify-content-end pr-4">
						{
							lock
								? <LockIcon className="AdminClientList__lock" onClick={setLockHandler}/>
								: <LockOpen className="AdminClientList__lock" onClick={setLockHandler}/>
						}
					</div>
					<div
						className='pl-3 py-2 pb-1 d-flex justify-content-between align-items-center'>
						<div className="d-flex align-items-center">Цена для бригадира</div>
						<div className="d-flex align-items-center">
							<div className="AdminClientList__price">
								<div className="AdminClientList__price-num">{prices.standardPrice.toFixed()}</div>
							</div>
							<div className="AdminClientList__price">
								<div className="AdminClientList__price-num">{prices.premiumPrice.toFixed()}</div>
							</div>
							{
								lock
									? <div className="AdminClientList__price">
										<div className="AdminClientList__price-num ">{individualMasterPrice || ''}</div>
									</div>
									:
									<TextField
										type="number"
										value={individualMasterPrice || ''}
										className="Admin_cell AdminClientList__price"
										variant="outlined"
										onChange={(e) => changeMasterPriceHandler(e.target.value)}
									/>

							}
						</div>
					</div>
				</div>)
			}
			<div className="d-flex align-items-center justify-content-end mt-4">
				{
					isNew
					&& (
						<Button
							className={`Admin_btn text ${isEdited ? 'mr-2' : 'AdminClientList__save'}`}
							variant="contained"
							color="primary"
							onClick={() => setDiscount(false)}
						>
							<ClearIcon
								className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
							<Typography variant="overline" display="block">
								Отменить
							</Typography>
						</Button>
					)

				}

				{
					isEdited
					&& (
						<Button
							className="Admin_btn text AdminClientList__btn AdminClientList__save"
							variant="contained"
							color="primary"
							onClick={() => updateIndividualService(true)}
						>
							<SaveIcon
								className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
							<Typography variant="overline" display="block">
								Сохранить
							</Typography>
						</Button>
					)
				}

			</div>
		</div>
	);
};

export default AdminClientListIndividual;