import React, { useEffect, useState } from "react";
import "./HomeSlider.scss";
import { useDispatch } from "react-redux";
import "swiper/swiper.scss";
import violetPattern from "../../../img/pattern-1.png";
import photo from "../../../img/homePageBanner.jpg";
import GreenButton from "../../../components/UI/GreenButton/GreenButton";
import { useHistory } from "react-router";
import { setActiveNavAnchor } from "../../../store/actions/navActions";
import { CALCULATOR_ANCHOR } from "../../../config";
import yandexGoal from "../../../utils/yandexGoal";

const SliderHomePage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [firstSpan, setFirstSpan] = useState("");
	const [secondSpan, setSecondSpan] = useState("");

	const goToEstimate = () => {
		history.replace("/");
		dispatch(setActiveNavAnchor(CALCULATOR_ANCHOR));
		yandexGoal("BANNER_BUTTON");
	};
	useEffect(() => {
		setTimeout(() => {
			setFirstSpan("active");
		}, 200);
		setTimeout(() => {
			setSecondSpan("active");
		}, 900);
	}, []);

	const renderAsideSlider = () => {
		return (
			<div
				className='SliderHomePage__info'
				style={{
					backgroundImage: `url(${violetPattern})`,
					backgroundColor: "#6d4cbe",
				}}>
				<div className='SliderHomePage__heading'>
					БЕЗОПАСНАЯ СДЕЛКА В СТРОИТЕЛЬСТВЕ! <br />
					<span className={`hiddenSpan ${firstSpan}`}>
						ВСЕ ВИДЫ СТРОИТЕЛЬНЫХ РАБОТ
					</span>
					<span className={`hiddenSpan ${secondSpan}`}>
						Управление заказами через мобильное приложение
					</span>
				</div>
				<div className='SliderHomePage__address'>
					<div className='SliderHomePage__button-wrapper'>
						<GreenButton
							className='SliderHomePage__button'
							onClick={goToEstimate}>
							Заказать
						</GreenButton>
					</div>
					<div className='SliderHomePage__address-left'>
						<div className='SliderHomePage__address-icon'>
							<svg viewBox='0 0 490 490'>
								<path
									d='M245.001,0C156.458,0,84.424,71.987,84.424,160.472c0,85.04,145.93,315.146,152.142,324.9
		c1.836,2.882,5.017,4.628,8.435,4.628c3.418,0,6.599-1.746,8.434-4.628c6.212-9.754,152.141-239.861,152.141-324.9
		C405.576,71.987,333.542,0,245.001,0z M245.001,461.127c-13.207-21.363-40.009-65.587-66.549-114.588
		c-48.429-89.418-74.027-153.758-74.027-186.067C104.424,83.015,167.487,20,245.001,20c77.514,0,140.575,63.015,140.575,140.472
		c0,32.309-25.599,96.649-74.027,186.067C285.009,395.539,258.207,439.764,245.001,461.127z'
								/>
								<path
									d='M244.999,76.071c-41.884,0-75.959,33.149-75.959,73.894c0,40.744,34.075,73.891,75.959,73.891s75.959-33.147,75.959-73.891
		C320.958,109.22,286.883,76.071,244.999,76.071z M244.999,203.855c-30.855,0-55.959-24.175-55.959-53.891
		c0-29.717,25.104-53.894,55.959-53.894s55.959,24.177,55.959,53.894C300.958,179.68,275.855,203.855,244.999,203.855z'
								/>
							</svg>
						</div>
						<div className='SliderHomePage__address-text'>
							Работаем по всей России
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='SliderHomePage'>
			<div className='SliderHomePage__sliders'>
				<div className='SliderHomePage__slide'>
					<img src={photo} className='img-fluid' />
				</div>
			</div>
			{renderAsideSlider()}
		</div>
	);
};

export default SliderHomePage;
