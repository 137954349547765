import React from 'react';

const IndividualWorksChoice = ({choices, active, onClick, property}) => {
	const onClickHandler = (id) => {
		onClick(id, property);
	}
	return (
		<div className="IndividualWorks__choice">
			{
				choices.map((choice) => {
					const isActive = choice.id === active;
					const {name, tooltip} = choice;
					return (
						<div
							key={choice.id}
							onClick={() => onClickHandler(choice.id)}
							className={`IndividualWorks__choice-item ${isActive ? 'IndividualWorks__choice-item_active' : ''}`}
						>
							<div
								className={`IndividualWorks__choice-item-success ${isActive ? 'IndividualWorks__choice-item-success_active' : ''}`}/>
							<div className="d-flex flex-column align-items-baseline ml-3">
								<div
									className={`IndividualWorks__choice-item-title ${isActive ? 'IndividualWorks__choice-item-title_active' : ''}`}>
									{name}
								</div>
								<div className="IndividualWorks__choice-item-desc">{tooltip}</div>
							</div>
						</div>
					)
				})
			}
		</div>
	);
};

export default IndividualWorksChoice;