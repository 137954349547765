import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";

const WorkerCategories = ({ categories, checkBoxHandler, formData }) => {
	return (
		<Paper className='p-3 mt-2'>
			<Typography variant='button' className='py-3'>
				Категории работ
			</Typography>

			<div id='wc-categories' className='row'>
				{categories.map((category) => {
					if (!category.id || !category.subCategory.length) {
						return null;
					}

					return (
						<div key={category.id} className='col-12'>
							<Typography
								variant='overline'
								className='my-3 mr-3 d-flex border-bottom border-gray'>
								{category.name}
							</Typography>
							<List className=''>
								{category.subCategory.map((section) => {
									return (
										<ListItem key={section.id} className='py-0'>
											<FormControlLabel
												control={
													<Checkbox
														value={section.id}
														checked={
															formData.categories.indexOf(section.id) !== -1
														}
														onChange={(e) =>
															checkBoxHandler(e.target.value, "categories")
														}
														name='checked'
														color='primary'
													/>
												}
												label={section.name}
											/>
										</ListItem>
									);
								})}
							</List>
						</div>
					);
				})}
			</div>
		</Paper>
	);
};

export default WorkerCategories;
