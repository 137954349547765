import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {workerPersonalLogOut} from "../../../store/actions/workerPersonalActions";
import logo from '../../../img/logo_180_120.png'


const WorkerTopNav = ({workerPersonalLogOut, title}) => {

    return (
        <div className='w__top-nav'>
            <div className='w__top-nav-left'>
                <Link to={'/'}>
                    <div className='w__top-nav-logo' style={{backgroundImage: `url(${logo})`}}/>
                </Link>
                <div className='w__top-nav-title d-none d-sm-block'>
                    {title}
                </div>
            </div>
            <div className='d-flex'>
                {/*<div className="w__top-nav-link">*/}
                {/*    <Link to={'/'}>*/}
                {/*        <HomeIcon/>*/}
                {/*        <span className='text-white ml-3 d-none d-sm-block'>На главную</span>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                <div className="w__top-nav-link" onClick={workerPersonalLogOut}>
                    <span className='text-white mr-3 d-none d-sm-block'>Выход</span>
                    <ExitToAppIcon/>
                </div>
            </div>
        </div>
    );
};

const actions = {
    workerPersonalLogOut
}
export default connect(false, actions)(WorkerTopNav);