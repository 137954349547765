import React from 'react';
import IndividualWorksCategoriesItem from './IndividualWorksCategoriesItem';
import {useCalculatorContext} from '../CalculatorContext';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {useSelector} from 'react-redux';
import {getServiceSelectState} from '../../../../store/selectors';


const IndividualWorksCategories = () => {
	const {activeCategories, setServiceHandler, filterSubCategories} = useCalculatorContext();
	const {services} = useSelector(getServiceSelectState);
	if (!activeCategories.length) {
		return <div className="d-flex align-items-center justify-content-center m-5 p-5">
			<ScaleLoader color="#7B53DA" height={50} width={12} radius={3} margin={3}/>
		</div>
	}

	return (
		<div className="IndividualWorks__categories">
			{
				activeCategories.map((category) => {
					const subCategories = filterSubCategories(category.subCategory, services);
					return <IndividualWorksCategoriesItem key={category.id} onClick={setServiceHandler}
														  subCategories={subCategories}
														  category={category}/>
				})
			}
		</div>
	);
};

export default IndividualWorksCategories;