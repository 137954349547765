import React, {useEffect} from 'react';
import {ListItem, Paper} from '@material-ui/core';
import BackBtn from '../../../../components/UI/BackBtn/BackBtn';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import OrderStatus from '../../../../components/UI/OrderStatus/OrderStatus';
import DraggableScroll from '../../../../components/UI/DraggableScroll/DraggableScroll';
import WorkerPersonalOrdersCurrentLeftSide from './WorkerPersonalOrdersCurrentLeftSide';
import WorkerPersonalOrdersCurrentDays from './WorkerPersonalOrdersCurrentDays';
import {useDispatch, useSelector} from 'react-redux';
import {getWorkerPersonalOrder} from '../../../../store/selectors';
import {fetchWorkerPersonalOrderDetail} from '../../../../store/actions/workerPersonalActions';
import AdminBtn from '../../../Admin/AdminComponents/AdminUI/AdminBtn';

const WorkerPersonalOrdersCurrentDetail = (props) => {
	const dispatch = useDispatch()
	const order = useSelector(getWorkerPersonalOrder)

	useEffect(() => {
		const orderId = props.match.params.id
		if (orderId) {
			dispatch(fetchWorkerPersonalOrderDetail(orderId));
		}
	}, [])

	return (
		<div className="mt-3">
			<BackBtn to="/worker/personal/orders-current" variant="dark"/>
			<h3 className="py-3">Заказ №{props.match.params.id} от 10.12.2020</h3>
			{
				order.id
				&&
				<Paper>
					<List>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Статус:
							</Typography>
							<Typography variant="overline">
								<OrderStatus status={order.status}/>
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Объект:
							</Typography>
							<Typography variant="overline">
								{order.objectName}
							</Typography>
						</ListItem>
						{
							order.location &&
							<ListItem className='d-flex align-items-center border-bottom border-gray'>
								<Typography variant="button" className='mr-3'>
									Адрес объекта:
								</Typography>
								<Typography variant="overline">
									{order.location.address}
								</Typography>
							</ListItem>
						}
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Место работы:
							</Typography>
							<Typography variant="overline">
								{order.objectPlace}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Работы:
							</Typography>
							<Typography variant="overline">
								{order.serviceName}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Ед. изм:
							</Typography>
							<Typography variant="overline">
								{order.serviceUnit}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Объем выполнения:
							</Typography>
							<Typography variant="overline">
								{order.serviceValue}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Начало работ:
							</Typography>
							<Typography variant="overline">
								{order.startDate}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Дата завершения:
							</Typography>
							<Typography variant="overline">
								{order.finishDate}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Кол-во мастеров:
							</Typography>
							<Typography variant="overline">
								2
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant="button" className='mr-3'>
								Уровень мастеров:
							</Typography>
							<Typography variant="overline">
								{order.orderType}
							</Typography>
						</ListItem>
					</List>
				</Paper>
			}
			<div className="d-flex align-items-center justify-content-end py-3">
				<AdminBtn type="add" text="Добавить отчет" onClick={() => {
					props.history.push('/worker/personal/reports/' + props.match.params.id)
				}}
				/>
			</div>

		</div>
	);
};

export default WorkerPersonalOrdersCurrentDetail;