import React from 'react';
import Main from "../../hoc/Main/Main";

const PrivacyPolicy = () => {
    return (
      <Main title={'Политика конфидициальности | Finish Worker'}>
          <div>
              <h2 className="text-center">Политика конфиденциальности</h2>
              <br/>

              <h4 className="text-center"> Политика конфиденциальности персональных данных Посетителей сайта</h4>
              <br/>

              <p> Настоящая Политика конфиденциальности персональных данных Посетителей сайта (далее – «Политика») принята
                  ООО "ИСТ ЛАЙН", (ИНН 9723078333, ОГРН 1197746093500, адрес места нахождения: 109451, г. Москва, ул.
                  Бульвар Мячковский, д.3А, пом.4, оф.9) (далее — «Компания») и действует в отношении всей информации,
                  которую Компания может получить о Посетителе сайта www.finish-worker.ru с любого устройства и при
                  коммуникации с Компанией в любой форме.
                  Используя сайт www.finish-worker.ru (просмотр, чтение текста, отправка или загрузка информации; далее -
                  Сайт) и предоставляя свои персональные данные, Посетитель сайта дает согласие на обработку персональных
                  данных в соответствии с данной Политикой.
                  <p> Понятия и состав персональных данных</p>
                  персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому
                  физическому лицу (субъекту персональных данных);
                  обработка персональных данных – любое действие (операция) или совокупность действий (операций) с
                  персональными данными, совершаемое с использованием средств автоматизации или без их использования.
                  Компания производит обработку персональных данных Посетителя сайта, включая фамилию, имя, отчество,
                  сведения об основном документе, удостоверяющем личность, адрес электронной почты, номер телефона, адрес
                  регистрации, адрес доставки и др.
                  Компания осуществляет обработку персональных данных способом сбора, систематизации, накопления,
                  хранения, уточнения (обновления, изменения), использования, передачи (предоставления, доступа),
                  обезличивания, блокирования, удаления, уничтожения.
                  Технологии «cookies»
              </p>
              <p> Cookies – небольшие по размеру текстовые файлы, хранящиеся в браузере Посетителя сайта.</p>
              <p> При просмотре Сайта происходит автоматический сбор (из Cookies) следующих обезличенных статистических
                  данных о Посетителе сайта, в том числе:
                  тип выполненного на сайте действия (клик, наведение курсора и т.п.); ⎯ дата и время выполнения действия;
                  URL страницы; ⎯ Referer; ⎯ IP (без возможности работы с IP-адресами в статистике); ⎯ User-Agent; ⎯
                  ClientID (идентификатор браузера по файлу Cookie); ⎯ экранное разрешение; ⎯ класс HTML-элемента, на
                  который происходит клик;
                  данные о количестве просмотров карточек товаров в различных товарных списках, кликах по выбранным
                  карточкам товаров, добавлениях в корзину, удалениях из корзины в связке с данными о стоимости таких
                  товаров; ⎯ данные о содержании, стоимости, статусе выполнения заказов товаров, оформленных Посетителем
                  сайта; ⎯ данные о фактах заполнения веб-форм на сайте, включая ошибки при их заполнении.
                  Пользуясь Сайтом, Посетитель соглашается на то, что Компания может использовать статистические данные и
                  файлы Cookies для их последующей обработки системами Google Analytics, Яндекс.Метрика, Google Firebase,
                  Appmetrica, myTracker и может передавать третьему лицу для проведения исследований, выполнения работ или
                  оказания услуг по поручению Компании.
                  Посетитель сайта может самостоятельно управлять файлами Cookies путем изменения настроек браузера.
                  Изменения пользовательских настроек, в результате которых файлы Cookies будут заблокированы, могут
                  привести к недоступности отдельных компонентов Сайта.
                  Пользуясь Сайтом, в том числе при заполнении веб-форм на Сайте, Посетитель сайта соглашается на
                  получение рекламы, а также использование сведений о нем в целях продвижения товаров, работ, услуг
                  Компании и третьих лиц.
                  Цели сбора и обработки персональных данных Посетителя</p>
              <p>Компания обрабатывает персональные данные Посетителя исключительно в целях, для которых они
                  предоставлялись, в том числе:</p>
              <ul>
                  <li> идентификации и регистрации Посетителя сайта;</li>
                  <li>оформления договора купли-продажи товара;</li>
                  <li>предоставления Посетителю сайта информации о Компании, реализуемых товарах, оказываемых услугах;
                  </li>
                  <li>ознакомления Посетителя сайта с правовыми документами Компании, а также реализации полномочий и
                      обязанностей, возложенных на Компанию законодательством Российской Федерации;
                      установления обратной связи, включая направление Посетителю сайта уведомлений, запросов, обработка
                      заказов и обращений от Посетителя сайта;
                      подтверждения достоверности и полноты персональных данных, предоставленных Посетителем сайта;
                  </li>
                  <li> для других целей с согласия Посетителя сайта.</li>
              </ul>

              <p>По достижению целей обработки, а также наступления иных оснований, предусмотренных законодательством
                  Российской Федерации в области обработки и защиты персональных данных, персональные данные Посетителей
                  сайта
                  уничтожаются.</p>
              <br/>
              <h4 className="text-center"> Права Посетителя сайта</h4>
              <br/>

              <p> Посетитель сайта вправе реализовать свои права, предусмотренные законодательством Российской Федерации о
                  персональных данных, в том числе, но не ограничиваясь:</p>
              <ul>
                  <li>уточнять, обновлять свои персональные данные, требовать их блокирования или уничтожения;</li>
                  <li> запрашивать у Компании перечень обрабатываемых персональных данных, правовых оснований обработки,
                      источники
                      их получения, информацию о сроках обработки и хранения, а также иные сведения, связанные с
                      обработкой своих
                      персональных данных.
                  </li>
              </ul>

              <br/>
              <h4 className="text-center"> Срок обработки персональных данных</h4>
              <br/>
              <p> Посетитель сайта дает согласие на обработку его персональных данных на весь срок, необходимый Компании
                  для
                  достижения целей обработки.</p>
              <p> Посетитель сайта вправе в любое время отозвать согласие на обработку его персональных данных путем
                  направления письменного заявления по адресу: 109451, г. Москва, ул. Бульвар Мячковский, д. 3А, пом.4,
                  оф.9.
                  Обеспечение безопасности персональных данных Компания гарантирует Посетителю сайта надлежащую защиту
                  персональных данных при их обработке. Компания применяет правовые, организационные и технические меры
                  для
                  защиты персональных данных от несанкционированного или случайного доступа к ним, а также от иных
                  неправомерных действий в отношении персональных данных, в том числе:</p>
              <ul>
                  <li>разграничение доступа к информационным системам, обрабатывающим персональные данные;</li>
                  <li>предотвращение внедрения в информационные системы, обрабатывающие персональные данные, вредоносных
                      программ
                      и программных закладок;
                  </li>
                  <li>межсетевое экранирование с целью управления доступом, фильтрации сетевых пакетов и трансляции
                      сетевых
                      адресов для скрытия структуры информационных систем, обрабатывающих персональные данные;
                  </li>
                  <li>обнаружение вторжений в информационные системы, обрабатывающие персональные данные, нарушающие или
                      создающие
                      предпосылки к нарушению установленных требований по обеспечению безопасности персональных данных;
                  </li>
                  <li> анализ защищенности информационных систем персональных данных, предполагающий применение
                      специализированных
                      программных средств.
                  </li>
              </ul>
              <p> В Компании приняты локальные акты по вопросам безопасности персональных данных. Доступ к персональным
                  данным
                  Посетителей сайта предоставляется только уполномоченным сотрудникам Компании.</p>
              <br/>
              <h4 className="text-center">Заключительные положения</h4>
              <br/>
              <p> Компания вправе вносить изменения или дополнения в настоящую Политику, в случае необходимости, а также в
                  случае внесения соответствующих изменений в действующее законодательство Российской Федерации о
                  персональных
                  данных.</p>
              <p> Посетитель сайта всегда может ознакомиться с актуальной версией Политики на Сайте. Продолжая
                  пользоваться
                  сайтом, Посетитель подтверждает согласие с внесенными изменениями в Политику.</p>
              <br/>
              <p> Дата обновления Политики «25» июля 2020 года</p>
          </div>
      </Main>
    );
};

export default PrivacyPolicy;