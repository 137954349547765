import React from 'react'
import classes from './Td.module.scss'

const Td = ({children, width, bg, onClickHandler, className, colSpan = 1, center = true}) => {
	let minWidth = width || null
	let background = bg || null
	let onClick = onClickHandler || null

	const cls = [classes.Td]
	if (className) {
		cls.push(className)
	}

	return (

		<td className={cls.join(' ')} colSpan={colSpan} style={{minWidth, background}}
			onClick={onClick}>
			<div className={`${classes.TdItem} ${center ? 'justify-content-center' : 'justify-content-start'}`}>
				<div>
					{
						children
					}
				</div>
			</div>
		</td>
	)
}
export default Td;