import React, {Fragment} from 'react';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {setCustomerCoefficient} from "../../../store/actions/adminServiceActions";

class AdminPrices extends React.Component {

    state = {
        currentTab: 0,
        standardPriceTotal: 0,
        premiumPriceTotal: 0,

    }

    setTotalValues = () => {

        let standardPriceTotal = 0
        let premiumPriceTotal = 0

        const service = this.props.services.find(service => service.id === this.props.serviceId)

        if (!!service.items.length) {
            service.items.forEach((item) => {

                item.norms.forEach(norm => {

                    standardPriceTotal += norm.norm * norm.cc * norm.costHourStandard
                    premiumPriceTotal += norm.norm * norm.cc * norm.costHourPremium

                })

            })


        } else {
            service.norms.forEach(norm => {
                standardPriceTotal += norm.norm * norm.cc * norm.costHourStandard
                premiumPriceTotal += norm.norm * norm.cc * norm.costHourPremium

            })
        }


        this.setState({
            standardPriceTotal,
            premiumPriceTotal,
        })
    }

    minusHandler = (value, type) => {
        this.props.setCustomerCoefficient(this.props.serviceId, (+value - 0.01).toFixed(2), type)
    }
    plusHandler = (value, type) => {
        this.props.setCustomerCoefficient(this.props.serviceId, (+value + 0.01).toFixed(2), type)
    }
    inputHandler = (value, type) => {
        this.props.setCustomerCoefficient(this.props.serviceId, (Math.abs(+value) || ''), type)
    }


    componentDidMount() {
        this.setTotalValues(this.state.currentTab)
    }


    render() {

        const service = this.props.services.find(service => service.id === this.props.serviceId)
        const {
            standardPriceTotal,
            premiumPriceTotal,
        } = this.state


        return (

            <List className="w-100 px-0">
                {
                    service.items.length > 0 ?
                        <Fragment>
                            <ListItem
                                className="Admin_row d-flex p-0  align-items-center justify-content-end border-top border-gray"
								style={{height: `${this.props.height || 50}`}}>

                                <div
                                    className='Admin_counter col-3 px-3 d-flex align-items-center justify-content-center align-self-center'>
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.minusHandler(service.customerCoefficient.standard, 'standard')}
                                    >
                                        <RemoveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>
                                    <TextField
                                        type="number"
                                        value={service.customerCoefficient.standard}
                                        className="Admin_cell"
                                        variant="outlined"
                                        onChange={(e) => this.inputHandler(e.target.value, 'standard')}
                                    />
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.plusHandler(service.customerCoefficient.standard, 'standard')}
                                    >
                                        <AddIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>

                                </div>
                                <Typography variant="h6"
                                            className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {(standardPriceTotal * service.customerCoefficient.standard).toFixed()}
                                </Typography>
                                <div
                                    className='Admin_counter col-3 px-3 d-flex align-items-center justify-content-center align-self-center'>
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.minusHandler(service.customerCoefficient.premium, 'premium')}
                                    >
                                        <RemoveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>
                                    <TextField
                                        type="number"
                                        value={service.customerCoefficient.premium}
                                        className="Admin_cell"
                                        variant="outlined"
                                        onChange={(e) => this.inputHandler(e.target.value, 'premium')}
                                    />
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.plusHandler(service.customerCoefficient.premium, 'premium')}
                                    >
                                        <AddIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>

                                </div>
                                <Typography variant="h6"
                                            className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {(premiumPriceTotal * service.customerCoefficient.premium).toFixed()}
                                </Typography>
                            </ListItem>
                            {
                                service.items.map((item, item_idx) => {

                                    let itemPriceStandard = 0;
                                    let itemPricePremium = 0;
                                    item.norms.forEach(norm => {
                                        itemPriceStandard += norm.norm * norm.cc * norm.costHourStandard
                                        itemPricePremium += norm.norm * norm.cc * norm.costHourPremium
                                    })


                                    return (
                                        <ListItem
                                            key={item.id}
                                            className={`Admin_row d-flex p-0 align-items-center border-top border-gray ${!service.items[item_idx + 1] ? 'border-bottom' : ''}`}
											style={{height: 50}}
										>

                                            <Typography variant="subtitle1"
                                                        className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {/*{service.customerCoefficient.standard}*/}
                                            </Typography>

                                            <Typography variant="subtitle1"
                                                        className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {(itemPriceStandard * service.customerCoefficient.standard).toFixed()}
                                            </Typography>
                                            <Typography variant="subtitle1"
                                                        className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {/*{service.customerCoefficient.premium}*/}
                                            </Typography>

                                            <Typography variant="subtitle1"
                                                        className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {(itemPricePremium * service.customerCoefficient.premium).toFixed()}
                                            </Typography>
                                        </ListItem>
                                    )
                                })
                            }
                        </Fragment>
                        :
                        <Fragment>
                            <ListItem
                                className="Admin_row d-flex p-0  align-items-center justify-content-end border-bottom border-top border-gray"
								style={{height: `${this.props.height || 50}`}}>


                                <div
                                    className='Admin_counter col-3 px-3 d-flex align-items-center justify-content-center align-self-center'>
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.minusHandler(service.customerCoefficient.standard, 'standard')}
                                    >
                                        <RemoveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>
                                    <TextField
                                        type="number"
                                        value={service.customerCoefficient.standard}
                                        className="Admin_cell"
                                        variant="outlined"
                                        onChange={(e) => this.inputHandler(e.target.value, 'standard')}
                                    />
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.plusHandler(service.customerCoefficient.standard, 'standard')}
                                    >
                                        <AddIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>

                                </div>
                                <Typography variant="h6"
                                            className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {(standardPriceTotal * service.customerCoefficient.standard).toFixed()}
                                </Typography>
                                <div
                                    className='Admin_counter col-3 px-3 d-flex align-items-center justify-content-center align-self-center'>
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.minusHandler(service.customerCoefficient.premium, 'premium')}
                                    >
                                        <RemoveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>
                                    <TextField
                                        type="number"
                                        value={service.customerCoefficient.premium}
                                        className="Admin_cell"
                                        variant="outlined"
                                        onChange={(e) => this.inputHandler(e.target.value, 'premium')}
                                    />
                                    <Button
                                        className="Admin_btn col"
                                        variant="contained" color="primary"
                                        onClick={() => this.plusHandler(service.customerCoefficient.premium, 'premium')}
                                    >
                                        <AddIcon
                                            className='Admin_btn-icon Admin_btn-icon-create'/>
                                    </Button>

                                </div>
                                <Typography variant="h6"
                                            className="col-3 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {(premiumPriceTotal * service.customerCoefficient.premium).toFixed()}
                                </Typography>

                            </ListItem>
                        </Fragment>
                }
            </List>

        );
    }
}


const mapState = (state) => {
    return {
        services: state.adminService.services,
    }
}
const actions = {
    setCustomerCoefficient
}
export default connect(mapState, actions)(AdminPrices);
