import React from 'react';

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {renderFormControl} from "../../../../form/formFramework";


const WorkerPersonalCard = ({controls, formControlHandler}) => {

    return (
        <Paper className='p-3 mt-2'>
            <Typography variant="button" className='p-3'>
                Банковская карта
            </Typography>
            <div className='row'>
                {
                    Object.keys(controls).map(property => {
                        const control = controls[property]

                        let options = {}
                        // if (control.id === 'card.numberCard') {
                        //     options.typeCard = typeCard
                        // }

                        return (
                            <div key={control.id} className="col-12 col-sm-4 mt-3">
                                {renderFormControl(control, formControlHandler, options)}
                            </div>
                        )
                    })

                }
            </div>
        </Paper>
    );
};

export default WorkerPersonalCard;

