import {
    API_DOCS_FETCH_API_STORY_SUCCESS,
    API_DOCS_FETCH_ERROR, API_DOCS_FETCH_ERROR_LIST_SUCCESS,
    API_DOCS_FETCH_START,
    API_DOCS_FETCH_SUCCESS,
    API_DOCS_RESPONSE_FETCH_SUCCESS,
    API_DOCS_SET_PROP,
    API_USER_STORY_FETCH_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    groups: [],
    response: {},
    userStory: [],
    apiStory: {
        items: [],
        navigation: {},
    },
    errorList: {
        items: [],
        navigation: {},
    },
    nav: [
        {
            id: 1,
            name: 'Api',
        },
        {
            id: 2,
            name: 'Api Story',
        },
        {
            id: 3,
            name: 'Error List',
        },
        {
            id: 4,
            name: 'User Story',
        },
    ],
    loading: false,
    error: null,
};

export default function apiDocsReducer(state = initialState, {type, payload}) {
    switch (type) {
        case API_DOCS_FETCH_START:
            return {
                ...state,
                loading: true,
            };
        case API_DOCS_FETCH_SUCCESS:
            return {
                ...state,
                groups: payload.groups,
                loading: false,
            };
        case API_DOCS_RESPONSE_FETCH_SUCCESS:
            return {
                ...state,
                response: payload.response,
                loading: false,
            };
        case API_USER_STORY_FETCH_SUCCESS:
            return {
                ...state,
                userStory: payload.userStory,
                loading: false,
            };
        case API_DOCS_FETCH_ERROR:
            return {
                ...state,
                error: payload.error,
            };
        case API_DOCS_SET_PROP:
            return {
                ...state,
                [payload.property]: payload.value,
            };
        case API_DOCS_FETCH_API_STORY_SUCCESS:
            return {
                ...state,
                apiStory: payload.apiStory,
                loading: false,
            };
        case API_DOCS_FETCH_ERROR_LIST_SUCCESS:
            return {
                ...state,
                errorList: payload.errorList,
                loading: false,
            };
        default:
            return state;
    }
}
