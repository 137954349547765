import axios from "../../rest/axios";
import { modalSuccess } from "./modalReducer";

const INSPECTION_FORM_SET_FIELD_VALUE = "INSPECTION_FORM_SET_FIELD_VALUE";
const INSPECTION_FORM_SET_FIELD_ERROR = "INSPECTION_FORM_SET_FIELD_ERROR";

const initialState = {
	formControls: {
		name: {
			id: "name",
			label: "Контактное лицо",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
			},
			errorMessage: {
				required: "Обязательное поле",
			},
		},
		phone: {
			id: "phone",
			label: "Введите Ваш телефон",
			type: "tel",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: false,
				phone: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				phone: `Введите корректный номер телефона`,
			},
		},
		address: {
			id: "address",
			label: "Адрес объекта",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
			},
		},
		date: {
			id: "date",
			label: "Дата и время",
			type: "date",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				dateTime: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
			},
		},
	},
};

const inspectionFormReducer = (state = initialState, { type, payload }) => {
	const formControls = { ...state.formControls };
	switch (type) {
		case INSPECTION_FORM_SET_FIELD_VALUE:
			formControls[payload.id].value = payload.value;
			return {
				...state,
				formControls,
			};
		case INSPECTION_FORM_SET_FIELD_ERROR:
			formControls[payload.id].error = payload.error;
			return {
				...state,
				formControls,
			};
		default:
			return state;
	}
};

export const inspectionFormSetFieldValue = (value, id) => ({
	type: INSPECTION_FORM_SET_FIELD_VALUE,
	payload: {
		value,
		id,
	},
});

export const inspectionFormSetFieldError = (error, id) => ({
	type: INSPECTION_FORM_SET_FIELD_ERROR,
	payload: {
		error,
		id,
	},
});

export const inspectionFormSendMessage = (formData) => async (dispatch) => {
	try {
		const { status } = await axios.post("feedback/inspection/", formData);
		if (status === 200) dispatch(modalSuccess());
	} catch (error) {}
};

export default inspectionFormReducer;
