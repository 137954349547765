import React from 'react'
import classes from './Th.module.scss'

export default props => {

    let style = props.style

    return (

        <th className={classes.Th} colSpan={props.colSpan} style={{style}}>
            <div className={classes.ThItem}>
                <div>
                    {
                        props.children
                    }
                </div>
            </div>
        </th>
    )
}