import axios from "../../rest/axios";
import qs from 'qs'

const WORKERS_SLIDER_SET_USERS = "WORKERS_SLIDER_SET_USERS";

const initialState = {
	users: [],
};

const workersSliderReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case WORKERS_SLIDER_SET_USERS:
			return {
				...state,
				users: payload.users,
			};
		default:
			return state;
	}
};

const _setUsers = (users) => ({
	type: WORKERS_SLIDER_SET_USERS,
	paylaod: {
		users,
	},
});

export const workersSliderStartFetch = () => async (dispatch) => {
	let token = localStorage.getItem("token");
	if (token) {
		try {
			const masters = await axios.get('/admin/users/', {
        params: {
          token,
          roles: 'MASTER',
          nav: `page-all-size-1000`,
          'filter[moderation]': 'all',
          'filter[profession]': 'all',
        }
      });
			dispatch(_setUsers(masters));
		} catch (e) {
			alert(e);
		}
	}
};

export default workersSliderReducer;
