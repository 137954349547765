import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {useAdmin} from "../../context/adminContext";
import AdminProfessionList from "../AdminProfessionList/AdminProfessionList";
import AdminMaterialList from "../AdminMaterialList/AdminMaterialList";
import AdminServiceList from "../AdminServiceList/AdminServiceList";
import AdminGoods from "../AdminGoods/AdminGoods";
import AdminDates from "../AdminDates";
import AdminWrapper from "../AdminWrapper";
import AdminGoodsDetail from "../AdminGoods/AdminGoodsDetail/AdminGoodsDetail";
import AdminOrderList from "../AdminOrderList/AdminOrderList";
import AdminAddresses from "../AdminAddresses";

import AdminUserList from '../AdminUserList/AdminUserList';
import {
	ROLE_ADMINISTRATORS,
	ROLE_ADMINS,
	ROLE_ANCILLARY, ROLE_ENGINEER_PTO, ROLE_GEODESIST,
	ROLE_INDEPENDENT_MASTER,
	ROLE_MANAGER,
	ROLE_MASTER,
	ROLE_PROJECT_DIRECTOR, ROLE_SUPPLIER
} from '../../../../config';
import AdminOrderListDetail from '../AdminOrderList/AdminOrderListDetail/AdminOrderListDetail';
import AdminSpecialistList from '../AdminUserList/AdminSpecialistList/AdminSpecialistList';
import AdminClientList from '../AdminUserList/AdminClientList/AdminClientList';
import AdminClassifiers from '../AdminClassifiers/AdminClassifiers';
import {useSelector} from 'react-redux';
import {getAdminState} from '../AdminLeftNav/selectors';
import AdminWorkerPersonal from '../AdminWorkerPersonal/AdminWorkerPersonal';
import AdminOrderSettings from '../AdminOrderSettings/AdminOrderSettings';


const AdminContent = () => {

	const {compact} = useAdmin();
	const {user} = useSelector(getAdminState);
	const {worker: {id}} = useSelector(state => state.adminWorkerPersonal);

	const isAdmin = [ROLE_ADMINS, ROLE_ADMINISTRATORS].includes(user.role);
	const isClassifierAdmin = [ROLE_GEODESIST, ROLE_SUPPLIER, ROLE_ENGINEER_PTO].includes(user.role);

	return (
		<div className={`Admin a__content ${compact ? 'compact' : 'full'} ${id ? 'm-0 w-100' : ''}`}>
			<Switch>

				{
					(isAdmin || isClassifierAdmin) && <Route path={'/admin/orders'} exact component={AdminOrderList}/>
				}

				{
					isAdmin
					&& (<>
						<Route path={'/admin/orderSettings'} exact component={AdminOrderSettings}/>
						<Route path={'/admin/norms'} exact component={AdminWrapper}/>
						<Route path={'/admin/professions'} exact component={AdminProfessionList}/>
						<Route path={'/admin/materials'} exact component={AdminMaterialList}/>
						<Route path={'/admin/services'} exact component={AdminServiceList}/>
						<Route path={'/admin/goods/:id'} exact component={AdminGoodsDetail}/>
						<Route path={'/admin/goods'} exact component={AdminGoods}/>
						<Route path={'/admin/dates'} exact component={AdminDates}/>
						<Route path={'/admin/classifiers'} exact component={AdminClassifiers}/>
						<Route path={'/admin/addresses'} exact component={AdminAddresses}/>
						<Route path={'/admin/orders/online/current/:id'} exact component={AdminOrderListDetail}/>
						<Route path={['/admin/masters/:id', '/admin/managers/:id', '/admin/independentMaster/:id', '/admin/specialists/:id']} component={AdminWorkerPersonal}/>

						<Route path={'/admin/masters'} exact
							   component={() => <AdminUserList role={ROLE_MASTER}/>}/>

						<Route path={'/admin/independentMaster'} exact
							   component={() => <AdminUserList role={ROLE_INDEPENDENT_MASTER}/>}/>

						<Route path={'/admin/ancillary'} exact
							   component={() => <AdminUserList role={ROLE_ANCILLARY}/>}/>

						<Route path={'/admin/specialists'} exact
							   component={() => <AdminSpecialistList/>}/>

						<Route path={'/admin/managers'} exact
							   component={() => <AdminUserList role={ROLE_MANAGER}/>}/>

						<Route path={'/admin/projectDirector'} exact
							   component={() => <AdminUserList role={ROLE_PROJECT_DIRECTOR}/>}/>

						<Route path={'/admin/client'} exact
							   component={() => <AdminClientList/>}/>

						{/*<Redirect to={'/admin/norms'}/>*/}

					</>)
				}

			</Switch>
		</div>
	);
};

export default AdminContent;