import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getNavAnchor} from '../../../store/selectors';
import {setActiveNavAnchor} from '../../../store/actions/navActions';


const ScrollPage = () => {

    const dispatch = useDispatch(getNavAnchor);
    const anchor = useSelector(getNavAnchor);

    useEffect(() => {
        const $anchorBlock = document.getElementById(anchor);
        if ($anchorBlock) {
            window.scrollTo({
                top: $anchorBlock.offsetTop - 120,
                behavior: 'smooth'
            });
            dispatch(setActiveNavAnchor(''))
        }
    }, [anchor]);

    return null

};

export default ScrollPage;