import React from 'react';
import Page from "../Page/Page";
import Header from '../../app/layout/Header/Header';

const Main = ({children, title}) => {
	return (
		<Page title={title}>
			<div className="container">
				<Header/>
				{children}
			</div>
		</Page>
	);
};

export default Main;