import React from 'react';
import '../ui.scss'

const Tab = ({onClick, active = false, title}) => {
	return (
		<div className={`UI__Tab ${active ? 'active' : ''}`} onClick={onClick}>
			<div className="UI__Tab-circle"/>
			<div className="UI__Tab-title">{title}</div>
		</div>
	);
};

export default Tab;