import React from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import GreenButton from '../../../../../../components/UI/GreenButton/GreenButton';
import SyncLoader from 'react-spinners/SyncLoader';
import classes from './EstimateOrder.module.scss';
import EstimateOrderToPrint from './EstimateOrderToPrint';
import { estimateResetorder } from '../../../../../../store/actions/estimateActions';

class EstimateOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);
  }

  activate() {
    this.setState({ isActive: true });
  }

  deactivate() {
    this.setState({ isActive: false });
  }

  
  render() {
    return (
      this.props.estimateOrder.services.length > 0 && (
        <div
          className={`col-12 ${classes.estimateOrder} ${
            this.state.isActive ? classes.active : ''
          }`}
        >
          <div onClick={this.activate} className={classes.orderWrapper}>
            <GreenButton onClick={() => {}}>РАЗВЕРНУТЬ</GreenButton>
          </div>
          <div onClick={this.deactivate} className={classes.arrow}>
            <svg
              width="53"
              height="47"
              viewBox="0 0 53 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.832 45.3625L51.707 23.4875L29.832 1.6125"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M51.707 23.4875H1.70697"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {this.props.isLoaded ? (
            <>
            <ReactToPrint
              trigger={() => (
                <GreenButton onClick={() => {}} className="mb-3">Распечатать</GreenButton>
              )}
              pageStyle='<style>color: red</style>'
              content={() => this.componentRef}
            />
            <EstimateOrderToPrint
              estimateOrder={this.props.estimateOrder}
              categories={this.props.categories}
              options={this.props.options}
              ref={(el) => (this.componentRef = el)}
            />
            <button className={classes.clearBtn} onClick={this.props.estimateResetorder}>Очистить смету</button>
            <GreenButton onClick={() => {}} className="mt-3">Оформить заказ</GreenButton>
          </>
          ) : (
            <div className='d-flex align-items-center justify-content-center'>
              <SyncLoader className='SyncLoader' color="#7B53DA"/>
            </div>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  estimateOrder: state.estimateOrder,
  categories: state.serviceSelect.categories,
  options: state.estimateOrder.formControls,
});

const mapDispatchToProps = {
  estimateResetorder
};

export default connect(mapStateToProps, mapDispatchToProps)(EstimateOrder);
