import React, {Fragment, useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {getAdminOrderListTasksState} from '../selectors';
import * as moment from 'moment';
import AdminOrderListDetailScoreboardTable from './AdminOrderListDetailScoreboardTable';
import AdminOrderListDetailScoreboardTwoRow from './AdminOrderListDetailScoreboardTwoRow';

const AdminOrderListDetailScoreboardTwo = ({order, currentDay}) => {
	const {workers} = useSelector(getAdminOrderListTasksState)
	const [serviceCount, setServiceCount] = useState(0);
	const [showTasks, setShowTasks] = useState(false);
	const [workerOpen, setWorkerOpen] = useState({})
	useEffect(() => {
		let count = 0;
		workers.forEach((worker) => {
			worker.tasks.forEach((task) => {
				count += task.items.filter((item) => {
					const day = moment(item.lastInspectDate, 'DD.MM.YYYY HH.mm.ss');
					return moment(currentDay, "DD.MM.YYYY").diff(moment(day, "DD.MM.YYYY"), 'days') >= 0
				}).map((item) => item.acceptedValue)
					.reduce((acc, value) => acc + value, 0);
			})
		})
		setServiceCount(count);
	}, [workers, currentDay])
	return (
		<div className="mt-4">
			{
				!!workers.length && serviceCount
					? <>
						<AdminOrderListDetailScoreboardTable order={order} count={serviceCount}/>
						<div className="mt-3">
							<TableContainer component={Paper}>
								<Table aria-label="collapsible table">
									{
										showTasks && <Paper component={'thead'}>
											<TableRow className="AdminOrderList__table-head">
												<TableCell className="AdminOrderList__cell px-3 py-2 border-right">
													<Typography align='center'>№ п/п</Typography>
												</TableCell>
												<TableCell className="AdminOrderList__cell px-3 py-2 border-right">
													<Typography align='center'>№ ТЗ</Typography>
												</TableCell>
												<TableCell align="left"
														   className="AdminOrderList__cell p-3 large border-right">
													<Typography>Наименование работ</Typography>
												</TableCell>
												<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
													<Typography>Ед. изм.</Typography>
												</TableCell>
												<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
													<Typography>Кол-во</Typography>
												</TableCell>
												<TableCell align="left" className="AdminOrderList__cell p-3 border-right">
													<Typography>Цена</Typography>
												</TableCell>
												<TableCell align="left" className="AdminOrderList__cell p-3">
													<Typography>Сумма, руб.</Typography>
												</TableCell>
												<TableCell className="AdminOrderList__cell p-3"/>
											</TableRow>
										</Paper>
									}

									<TableBody>
										{workers.map((worker) => {
											return (<Fragment key={worker.id}>
												{
													!!workerOpen[worker.id] && (
														<TableRow>
															<TableCell align="justify"
																	   className="AdminOrderList__row-title p-2 bg-light"
																	   colSpan={12}
															>
																<Typography>{worker.name}</Typography>
															</TableCell>
														</TableRow>
													)
												}

												{
													worker.tasks.map((task, idx) => (
														<AdminOrderListDetailScoreboardTwoRow key={task.id || idx}
																							  idx={idx}
																							  task={task}
																							  order={order}
																							  worker={worker}
																							  currentDay={currentDay}
																							  setShowTasks={setShowTasks}
																							  showTasks={showTasks}
																							  setWorkerOpen={setWorkerOpen}
																							  workerOpen={workerOpen}
														/>

													))
												}
											</Fragment>)
										})}
									</TableBody>
								</Table>
							</TableContainer>

						</div>
					</>
					: <Typography className="mt-3">
						На <strong>{currentDay}</strong> в этом заказе нет текущих заданий, принятых менеджером.
					</Typography>
			}
		</div>
	);
};

export default AdminOrderListDetailScoreboardTwo;