import './Grid.scss'

import React from "react";
import getCellWidth from "../../../../helpers/getCellWidth";

function Grid({ rows, columns }) {
    const cellStyle = {
        width: getCellWidth(columns)
    }

    return(
        <div className="grid-wrapper">
            <div className="grid">
                {rows.map((row, rowIndex) => {
                    return <div className="grid-row" key={rowIndex}>
                        {columns.map((column, columnIndex) => {
                            return <div className="grid-cell" data-index={columnIndex + 1} key={columnIndex} style={cellStyle}>&nbsp;</div>
                        })}
                    </div>
                })}
            </div>
        </div>
    )
}

export default Grid;
