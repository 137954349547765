import React from 'react';
import {getConfirmedValue, withMaterials} from '../functions';

const PersonalOrderItemTranchesTotal = ({order}) => {
	let services = []
	if (order.reports.length > 0) {
		order.services.forEach(service => {
			services[service.id] = 0
			order.reports.forEach(report => {
				report.services.forEach(item => {
					if (service.id === item.id) {
						services[service.id] += item.value
					}
				})
			})
		})
	} else {
		order.services.forEach(service => {
			services[service.id] = 0
		})
	}

	let totalConfirmedValue = getConfirmedValue(services)
	const totalMaterialPrice = order.totalPrice - order.servicePrice
	const materialPrice = totalMaterialPrice / order.serviceValue;

	const table = [];


	table.push(
		<div
			key={'total-head'}
			className="PersonalOrderItemTranches_RightBlock-total">
			<div className={`PersonalOrderItemTranches_RightBlock__item active`}>

				<div
					className={`PersonalOrderItemTranches_RightBlock__item_total-head`}>
					<div
						className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100 pt-3`}>
						<strong>Итого</strong>
					</div>
				</div>

				<div
					className={`border-main-1-3 border-bottom d-flex align-items-center justify-content-center h60`}
				>
					<div
						className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100 pt-3`}>
						{(order.serviceValue).toFixed(2)}
					</div>
				</div>

				<div
					className={`${withMaterials(order) ? 'border-main-1-3': 'border-main-2-1'} border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
				>
					<div
						className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
						{order.servicePrice.toFixed(2)}
					</div>
				</div>

				{
					withMaterials(order) &&
					<div>
						<div
							className={`border-main-1-3 border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
						>
							<div
								className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
								{(order.totalPrice - order.servicePrice).toFixed(2)}
							</div>
						</div>
						<div
							className={`border-main-2-1 border-bottom border-main-1-3 d-flex align-items-center justify-content-center h40`}
						>
							<div
								className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
								<strong>{(order.totalPrice - order.servicePrice + order.servicePrice).toFixed(2)}</strong>
							</div>
						</div>
					</div>
				}

				{/*{*/}
				{/*	services.map((value, idx) => {*/}
				{/*		return (*/}
				{/*			<div key={idx}*/}
				{/*				 className={`border-main-1-3 border-bottom d-flex align-items-center justify-content-center h40`}*/}
				{/*			>*/}
				{/*				<div*/}
				{/*					className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>*/}
				{/*					{value}*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		)*/}
				{/*	})*/}
				{/*}*/}

				<div

					className={`border-main-1-3  border-bottom d-flex align-items-center justify-content-center h40`}
				>
					<div
						className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
						<strong>{totalConfirmedValue.toFixed()}</strong>
					</div>
				</div>

				<div
					className={`border-main-1-3 border-bottom d-flex align-items-center justify-content-center h40`}
				>
					<div
						className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
						<strong>{(totalConfirmedValue * order.unitPrice).toFixed()}</strong>
					</div>
				</div>

				{
					withMaterials(order) &&
					<div>
						<div
							className={`border-main-1-3 border-bottom d-flex align-items-center justify-content-center h40`}
						>
							<div
								className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
								<strong>{(Number(totalConfirmedValue) * materialPrice).toFixed()}</strong>
							</div>
						</div>
						<div
							className={`border-main-1-3 border-bottom d-flex align-items-center justify-content-center h40`}
						>
							<div
								className={`bg-main-3 d-flex align-items-center justify-content-center h-100 w-100`}>
								<strong>{(Number(totalConfirmedValue) * materialPrice + totalConfirmedValue * order.unitPrice).toFixed()}</strong>
							</div>
						</div>

					</div>

				}

			</div>
		</div>
	)
	return table
};

export default PersonalOrderItemTranchesTotal;