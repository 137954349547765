import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAdminOrderListTasksState} from '../selectors';
import {fetchAdminOrderTasks} from '../../../../../store/actions/adminOrderListTasksActions';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import AdminOrderListDetailWorkSubmitRow from './AdminOrderListDetailWorkSubmitRow';
import AdminBtn from '../../AdminUI/AdminBtn';
import AdminOrderListDetailModal from './AdminOrderListDetailModal';
import {fetchClassifiers} from '../../../../../store/actions/adminClassifiersActions';
import {getAdminState} from '../../AdminLeftNav/selectors';
import {ROLE_ADMINISTRATORS, ROLE_ADMINS} from '../../../../../config';
import {deleteOrderClassifier} from '../../../../../store/actions/adminOrderListActions';
import DeleteIcon from '@material-ui/icons/Delete';

const AdminOrderListDetailWorkSubmit = ({order}) => {
	const dispatch = useDispatch()
	const {classifiers} = useSelector(state => state.adminClassifiers)
	const {user} = useSelector(getAdminState);
	const {workers} = useSelector(getAdminOrderListTasksState)
	const [openModal, setOpenModal] = useState(false);
	const isAdmin = [ROLE_ADMINISTRATORS, ROLE_ADMINS].includes(user.role)

	// const [classifiers, setClassifiers] = useState([]);

	const modalCloseHandler = (evt) => {
		if (!evt || evt.target.classList.contains('js-close')) {
			document.querySelector('body').style.overflowY = 'auto';
			setOpenModal(false)
		}
	}

	const modalShowHandler = () => {
		setOpenModal(true)
		document.querySelector('body').style.overflowY = 'hidden';
	}

	const deleteClassifierHandler = (type) => {
		dispatch(deleteOrderClassifier(order.id, type))
	}

	useEffect(() => {
		dispatch(fetchAdminOrderTasks(order.id))
	}, [])

	// useEffect(() => {
	// 	const cId = order.classifiers.map(c => c.type.id)
	// 	setClassifiers(order.classifierTypes.filter(c => cId.indexOf(c.id) === -1))
	// }, [order])

	useEffect(() => {
		dispatch(fetchClassifiers())
	}, [dispatch])

	return (
		<>

			<div className="AdminOrderList__table-scroll mt-5 px-3">
				{/*<TableContainer component={Paper}>*/}
				<Table aria-label="collapsible table">
					<Paper component={'thead'}>
						<TableRow className="AdminOrderList__table-head">
							<TableCell align="center" className="w-40 p-0" colSpan={2}>
								<div className="h30 d-flex justify-content-center align-items-center">
									<Typography>Текущее задание</Typography>
								</div>
							</TableCell>
							<TableCell align="center" className="w-60 p-0" colSpan={3}>
								<div className="h30 d-flex justify-content-center align-items-center border-left">
									<Typography>Чек лист</Typography>
								</div>
							</TableCell>
						</TableRow>
						<TableRow className="AdminOrderList__table-head">
							<TableCell align="center" className="w-40 p-0" colSpan={2}>
								<div
									className={`${!!order.classifiers && !!order.classifiers.length ? 'h80' : 'h50'} p-0 d-flex justify-content-between align-items-center`}
									style={{minWidth: 400}}
								>
									<div
										className="h-100 d-flex align-items-center justify-content-center w-20 border-right">
										<Typography align='center'>№ ТЗ</Typography>
									</div>
									<div className="h-100 d-flex align-items-center justify-content-center w-80">
										<Typography>Наименование ТЗ</Typography>

									</div>
								</div>
							</TableCell>
							<TableCell align="justify" className="w-60 p-0" colSpan={3}>
								<div
									className={`${!!order.classifiers && !!order.classifiers.length ? 'h80' : 'h50'} p-0 d-flex align-items-center border-left`}>
									<div
										className="h-100 d-flex align-items-center justify-content-center border-right px-3 AdminOrderList__table-head_50">
										<Typography align='center'>№</Typography></div>
									<div
										className="h-100 d-flex align-items-center justify-content-center border-right px-3 AdminOrderList__table-head_200">
										<Typography>Зона</Typography></div>
									<div
										className="h-100 d-flex align-items-center justify-content-center border-right px-3 AdminOrderList__table-head_75">
										<Typography>Кол-во</Typography></div>
									<div
										className="h-100 d-flex align-items-center justify-content-center border-right px-3  AdminOrderList__table-head_75">
										<Typography>Ед.изм.</Typography></div>
									<div
										className="h-100 d-flex flex-column flex-grow-1 p-0">
										<div
											className={`h50 d-flex align-items-center px-3 ${isAdmin ? 'justify-content-between' : 'justify-content-center'}`}>
											<Typography>Классификаторы</Typography>
											{
												!!classifiers && !!classifiers.length && isAdmin
												&&
												<AdminBtn
													tip="Добавить классификатор"
													type="add"
													color="default"
													onClick={modalShowHandler}
												/>
											}

										</div>
										{
											!!order.classifierTypes.length
											&&
											<div
												className="h50 d-flex align-items-center justify-content-between border-top">
												{
													order.classifierTypes.map((type, idx) => {
														return (
															<div
																key={type.id}
																className={`h-100 d-flex align-items-center justify-content-center flex-grow-1 px-2 ${!!order.classifierTypes[idx + 1] ? 'border-right' : ''}`}>
																<Typography
																	className="AdminOrderList__table-head-ws">{type.name}</Typography>
																<DeleteIcon
																	className="AdminOrderList__table-head-delete"
																	onClick={() => deleteClassifierHandler(type.id)}
																/>
															</div>
														)
													})
												}
											</div>
										}

									</div>
								</div>
							</TableCell>
						</TableRow>
					</Paper>
					<TableBody>
						{workers.map((worker) => {
							return (<Fragment key={worker.id}>
								<TableRow>
									<TableCell align="justify"
											   className="AdminOrderList__row-title p-3 bg-light"
											   colSpan={12}
									>
										<Typography>{worker.name}</Typography>
									</TableCell>
								</TableRow>
								{
									worker.tasks.map((task, idx) => (
										<AdminOrderListDetailWorkSubmitRow key={task.id || idx}
																		   task={task}
																		   idx={idx}
																		   order={order}
																		   role={user.role}
										/>
									))
								}
							</Fragment>)
						})}
					</TableBody>
				</Table>
				{/*</TableContainer>*/}

			</div>
			{
				openModal && !!classifiers.length &&
				<AdminOrderListDetailModal onClose={modalCloseHandler} order={order} classifiers={classifiers}/>
			}
		</>
	);
}
export default AdminOrderListDetailWorkSubmit;