import {
	ADD_MATERIAL_NORMS,
	ADD_SERVICE_NORMS,
	DELETE_MATERIAL_NORMS,
	DELETE_SERVICE_NORMS,
	EDIT_MATERIAL_NORMS,
	EDIT_SERVICE_NORMS,
	FETCH_ADMIN_CATEGORIES_SUCCESS,
	FETCH_ADMIN_GOODS_SUCCESS,
	FETCH_ADMIN_MATERIALS_SUCCESS,
	FETCH_ADMIN_PROFESSIONS_SUCCESS,
	FETCH_ADMIN_SERVICES_ERROR,
	FETCH_ADMIN_SERVICES_START,
	FETCH_ADMIN_SERVICES_SUCCESS,
	SAVE_ADMIN_CHANGES_SERVICE_NORM_ERROR,
	SAVE_ADMIN_CHANGES_SERVICE_NORM_NOTHING,
	SAVE_ADMIN_CHANGES_SERVICE_NORM_START,
	SAVE_ADMIN_CHANGES_SERVICE_NORM_SUCCESS, SET_ADMIN_SERVICE_NORM_PROPERTY,
	SET_CUSTOMER_COEFFICIENT,
	SET_MATERIAL_NORMS,
	SET_SERVICE_NORMS,
	SET_SERVICE_PROPERTY
} from "../actions/actionTypes";


const initialState = {
	services: [],
	materials: [],
	professions: [],
	categories: [],
	goods: [],
	dayNorm: 8,
	saveResult: {
		title: null,
		description: null,
		list: []
	},
	saveInProcess: false,
	loading: false
}

const adminServiceReducer = (state = initialState, {type, payload}) => {

	let services = [...state.services]
	let findServiceId = null

	if (payload && payload.serviceId) {
		findServiceId = services.findIndex(service => {
			return service.id === payload.serviceId
		})
	}

	switch (type) {
		case FETCH_ADMIN_SERVICES_START:
			return {
				...state,
				loading: true
			}
		case FETCH_ADMIN_SERVICES_SUCCESS:
			return {
				...state,
				services: payload.services,
				loading: false
			}
		case FETCH_ADMIN_SERVICES_ERROR:
			return {
				...state,
				error: payload.error,
				loading: false
			}

		case FETCH_ADMIN_MATERIALS_SUCCESS:
			return {
				...state,
				materials: payload.materials,
				loading: false
			}

		case FETCH_ADMIN_PROFESSIONS_SUCCESS:
			return {
				...state,
				professions: payload.professions,
				loading: false
			}

		case FETCH_ADMIN_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: payload.categories,
				loading: false
			}

		case FETCH_ADMIN_GOODS_SUCCESS:
			return {
				...state,
				goods: payload.goods,
				loading: false
			}

		case SAVE_ADMIN_CHANGES_SERVICE_NORM_START:
			return {
				...state,
				saveInProcess: true,
			}

		case SAVE_ADMIN_CHANGES_SERVICE_NORM_SUCCESS:

			services = [...services.map(profession => {
				profession.edited = false
				// profession.isNew = false
				return profession
			})]

			return {
				...state,
				saveResult: {
					title: "Отправлено!",
					description: "Результат сохранения:",
					list: payload.saveResult
				},
				services,
				saveInProcess: false
			}

		case SAVE_ADMIN_CHANGES_SERVICE_NORM_NOTHING:
			return {
				...state,
				saveResult: {title: "!", description: "Нечего сохранять!", list: []},
				saveInProcess: false
			}

		case SAVE_ADMIN_CHANGES_SERVICE_NORM_ERROR:
			return {
				...state,
				error: payload.error
			}

		case SET_ADMIN_SERVICE_NORM_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value
			}

		case SET_SERVICE_PROPERTY:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true

				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]

					item[payload.property] = payload.value
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]
					/////////////////// Запись изменений
					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.properties = getUnique([...item.properties || [], payload.property])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {properties: [payload.property]}
						}
					}

				} else {
					service[payload.property] = payload.value
					if (service.editedProperties) {
						service.editedProperties.properties = getUnique([...service.editedProperties.properties || [], payload.property])
					} else {
						service.editedProperties = {properties: [payload.property]}
					}
				}

			}

			return {
				...state,
				services
			}

		case SET_SERVICE_NORMS:

			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let norms = item.norms
					let changedNormIdx = norms.findIndex(norm => {
						return norm.id === payload.normId
					})
					let norm = {...norms[changedNormIdx]}
					norm[payload.property] = payload.value
					item.norms = [
						...norms.slice(0, changedNormIdx),
						norm,
						...norms.slice(changedNormIdx + 1)
					]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.norms = getUnique([...item.norms || [], payload.normId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {norms: [payload.normId]}
						}
					}


				} else {
					let norms = service.norms
					let changedNormIdx = norms.findIndex(norm => {
						return norm.id === payload.normId
					})
					let norm = {...norms[changedNormIdx]}
					norm[payload.property] = payload.value
					service.norms = [
						...norms.slice(0, changedNormIdx),
						norm,
						...norms.slice(changedNormIdx + 1)
					]

					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							norms: getUnique([...service.editedProperties.norms || [], payload.normId])
						}
					} else {
						service.editedProperties = {
							norms: [payload.normId]
						}
					}
				}

			}

			return {
				...state,
				services
			}

		case
		ADD_SERVICE_NORMS:

			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let norms = item.norms
					item.norms = [
						...norms,
						payload.norm
					]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]


					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.norms = getUnique([...item.norms || [], payload.normId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {norms: [payload.norm.id]}
						}
					}

				} else {
					service.norms = [
						...service.norms,
						payload.norm
					]
					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							norms: getUnique([...service.editedProperties.norms || [], payload.norm.id])
						}
					} else {
						service.editedProperties = {
							norms: [payload.norm.id]
						}
					}
				}

			}

			return {
				...state,
				services
			}

		case
		DELETE_SERVICE_NORMS:

			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let norms = item.norms.filter(norm => norm.id !== payload.normId)
					item.norms = [...norms]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.norms = getUnique([...item.norms || [], payload.normId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {norms: [payload.normId]}
						}
					}

				} else {
					service.norms = [...service.norms.filter(norm => norm.id !== payload.normId)]
					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							norms: getUnique([...service.editedProperties.norms || [], payload.normId])
						}
					} else {
						service.editedProperties = {
							norms: [payload.normId]
						}
					}
				}
			}

			return {
				...state,
				services
			}

		case
		EDIT_SERVICE_NORMS:

			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let norms = item.norms
					let changedNormIdx = norms.findIndex(norm => {
						return norm.id === payload.normId
					})

					item.norms = [
						...norms.slice(0, changedNormIdx),
						payload.norm,
						...norms.slice(changedNormIdx + 1)
					]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.norms = getUnique([...item.norms || [], payload.normId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {norms: [payload.normId]}
						}
					}

				} else {
					let norms = service.norms
					let changedNormIdx = norms.findIndex(norm => {
						return norm.id === payload.normId
					})

					service.norms = [
						...norms.slice(0, changedNormIdx),
						payload.norm,
						...norms.slice(changedNormIdx + 1)
					]

					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							norms: getUnique([...service.editedProperties.norms || [], payload.normId])
						}
					} else {
						service.editedProperties = {
							norms: [payload.normId]
						}
					}
				}
			}

			return {
				...state,
				services
			}

		case
		SET_CUSTOMER_COEFFICIENT:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				service.customerCoefficient[payload.type] = payload.value

				if (service.editedProperties) {
					const properties = [...service.editedProperties.properties || []]
					service.editedProperties.properties = getUnique([...properties, 'customerCoefficient'])
				} else {
					service.editedProperties = {properties: ['customerCoefficient']}
				}
			}
			return {
				...state,
				services
			}

		case
		SET_MATERIAL_NORMS:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let materials = item.materials
					let changedMaterialIdx = materials.findIndex(material => {
						return material.id === payload.materialId
					})
					let material = {...materials[changedMaterialIdx]}
					material[payload.property] = payload.value
					item.materials = [
						...materials.slice(0, changedMaterialIdx),
						material,
						...materials.slice(changedMaterialIdx + 1)
					]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.materials = getUnique([...item.materials || [], payload.materialId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {materials: [payload.materialId]}
						}
					}

				} else {
					let materials = service.materials

					let changedMaterialIdx = materials.findIndex(material => {
						return material.id === payload.materialId

					})

					let material = {...materials[changedMaterialIdx]}

					material[payload.property] = payload.value

					service.materials = [
						...materials.slice(0, changedMaterialIdx),
						material,
						...materials.slice(changedMaterialIdx + 1)
					]
					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							materials: getUnique([...service.editedProperties.materials || [], payload.materialId])
						}
					} else {
						service.editedProperties = {
							materials: [payload.materialId]
						}
					}
				}

			}

			return {
				...state,
				services
			}

		case
		ADD_MATERIAL_NORMS:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let materials = item.materials
					item.materials = [
						...materials,
						payload.material
					]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.materials = getUnique([...item.materials || [], payload.material.id])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {materials: [payload.material.id]}
						}
					}

				} else {
					service.materials = [...service.materials, payload.material]
					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							materials: getUnique([...service.editedProperties.materials || [], payload.material.id])
						}
					} else {
						service.editedProperties = {
							materials: [payload.material.id]
						}
					}
				}

			}

			return {
				...state,
				services
			}

		case
		EDIT_MATERIAL_NORMS:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let materials = item.materials
					let changedMaterialIdx = materials.findIndex(material => {
						return material.id === payload.materialId
					})

					item.materials = [
						...materials.slice(0, changedMaterialIdx),
						payload.material,
						...materials.slice(changedMaterialIdx + 1)
					]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.materials = getUnique([...item.materials || [], payload.materialId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {materials: [payload.materialId]}
						}
					}

				} else {
					let materials = service.materials

					let changedMaterialIdx = materials.findIndex(material => {
						return material.id === payload.materialId
					})

					service.materials = [
						...materials.slice(0, changedMaterialIdx),
						payload.material,
						...materials.slice(changedMaterialIdx + 1)
					]
					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							materials: getUnique([...service.editedProperties.materials || [], payload.materialId])
						}
					} else {
						service.editedProperties = {
							materials: [payload.materialId]
						}
					}
				}
			}

			return {
				...state,
				services
			}

		case
		DELETE_MATERIAL_NORMS:
			if (findServiceId >= 0) {
				let service = services[findServiceId]
				service.edited = true
				if (payload.itemId) {
					let items = service.items
					let itemIdx = items.findIndex(item => {
						return item.id === payload.itemId
					})
					let item = items[itemIdx]
					let materials = item.materials.filter(material => material.id !== payload.materialId)
					item.materials = [...materials]
					service.items = [
						...items.slice(0, itemIdx),
						item,
						...items.slice(itemIdx + 1)
					]

					if (service.editedItemProperties) {
						const item = {...service.editedItemProperties[payload.itemId]} || {};
						item.materials = getUnique([...item.materials || [], payload.materialId])
						service.editedItemProperties[payload.itemId] = {...item}
					} else {
						service.editedItemProperties = {
							[payload.itemId]: {materials: [payload.materialId]}
						}
					}

				} else {
					service.materials = [...service.materials.filter(material => material.id !== payload.materialId)]
					if (service.editedProperties) {
						service.editedProperties = {
							...service.editedProperties,
							materials: getUnique([...service.editedProperties.materials || [], payload.materialId])
						}
					} else {
						service.editedProperties = {
							materials: [payload.materialId]
						}
					}
				}
			}

			return {
				...state,
				services
			}

		default:
			return state
	}

}

export default adminServiceReducer

function getUnique(array) {
	return array.filter((a, idx, arr) => {
		return arr.indexOf(a) === idx
	})
}