import React from 'react';
import Typography from '@material-ui/core/Typography';

const WorkerPersonalReportsLeftSide = ({order}) => {
	return (
		<>
			<div className='w-100'>
				<Typography
					className='wp_LeftBlock__head d-flex align-items-center justify-content-between border-right border-main-4'>
					<Typography variant="button" className='d-flex align-content-center pl-3'>
						Дата отчета:
					</Typography>
				</Typography>
			</div>
			<div
				className='wp_LeftBlock__block d-flex align-items-center bg-white w-100 position-relative border-right border-main-4'>
				<div
					className='wp_LeftBlock-leftHeadingRotate top'>
					<div>
						<div>План</div>
					</div>
				</div>
				<div className='w-100'>
					<Typography
						className='border-bottom border-main-4 d-flex align-items-end justify-content-between wp__row pb-3'>
						<Typography variant="button" className='d-flex align-content-center w-75 pl-3'>
							Объемы
						</Typography>
						<Typography variant="button"
									className='d-flex align-content-center justify-content-end w-25 pr-3'>
							{order.serviceUnit}
						</Typography>
					</Typography>
				</div>
			</div>
			<div
				className='wp_LeftBlock__block d-flex align-items-center bg-white w-100 position-relative border-right border-main-4'>

				<div
					className='wp_LeftBlock-leftHeadingRotate bottom'>
					<div>
						<div>Факт</div>
					</div>
				</div>
				<div
					className='w-100 border-bottom border-main-2-1 d-flex align-items-center flex-column wp__row-bottom-left'>
					<div className="w-100 border-bottom border-main-1-3">
						<Typography variant="button"
									className='d-flex align-content-center pl-3 py-3'>
							Подано
						</Typography>
					</div>
					<Typography variant="button" className='w-100 d-flex align-content-center pl-3 py-3'>
						Принято
					</Typography>
				</div>
			</div>
		</>
	);
};

export default WorkerPersonalReportsLeftSide;