import React from 'react'
import classes from './TooltipBlueWhite.module.scss'

import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'
import 'tippy.js/themes/light.css'

export default props => {
    let background = props.bg ? props.bg : '#3D6AAE'
    let fill = props.fill ? props.fill : '#fff'
    let top = props.top ? props.top : 0
    let right = props.right ? props.right : 5
    return (
        <Tippy
            content={props.content}
            animation="shift-away"
            theme="light"
            placement="top"
        >
            <span className={classes.TooltipBlueWhite} style={{background, top, right}}>
                   <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.90679 12.4892L6.71117 13.2888C6.12433 13.5204 5.65556 13.6967 5.30649 13.8179C4.95705 13.9395 4.55102 14 4.08839 14C3.37796 14 2.82537 13.826 2.43126 13.4803C2.03715 13.1332 1.84003 12.6934 1.84003 12.1599C1.84003 11.9534 1.85433 11.741 1.88407 11.5245C1.91406 11.3078 1.96174 11.0638 2.02698 10.791L2.76038 8.1952C2.82562 7.94664 2.88108 7.71112 2.9255 7.48841C2.97055 7.26707 2.99225 7.06343 2.99225 6.88011C2.99225 6.54861 2.92362 6.31673 2.78698 6.18636C2.65034 6.05637 2.3896 5.99037 2.00252 5.99037C1.81292 5.99037 1.61806 6.02074 1.41944 6.07946C1.21993 6.13843 1.04942 6.1954 0.90625 6.24835L1.10237 5.44808C1.58281 5.25246 2.04204 5.08495 2.48132 4.94593C2.92061 4.80653 3.33568 4.73676 3.72828 4.73676C4.43382 4.73676 4.97825 4.90728 5.36057 5.24832C5.74289 5.58961 5.93399 6.03203 5.93399 6.57722C5.93399 6.69002 5.92144 6.88877 5.89446 7.17284C5.86811 7.45754 5.81906 7.7184 5.74753 7.95567L5.01728 10.5411C4.95743 10.7487 4.9036 10.9861 4.8568 11.2533C4.80849 11.5186 4.7854 11.7214 4.7854 11.8574C4.7854 12.2007 4.86194 12.4351 5.01539 12.5597C5.16998 12.6843 5.43623 12.7463 5.81441 12.7463C5.99196 12.7463 6.19397 12.7148 6.41844 12.6529C6.64229 12.5911 6.80553 12.5367 6.90679 12.4892ZM7.09199 1.63492C7.09199 2.08538 6.92222 2.47008 6.58106 2.7864C6.24077 3.10385 5.83072 3.2627 5.35104 3.2627C4.86985 3.2627 4.45879 3.10385 4.11462 2.7864C3.77107 2.46995 3.59892 2.08538 3.59892 1.63492C3.59892 1.18535 3.77107 0.800021 4.11462 0.479687C4.45817 0.159854 4.86997 0 5.35104 0C5.8306 0 6.24077 0.16023 6.58106 0.479687C6.92247 0.800021 7.09199 1.18548 7.09199 1.63492Z"
                            fill={fill}/>
                  </svg>
            </span>
        </Tippy>
    )
}