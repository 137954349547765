import React, {Fragment, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {getAdminOrderListTasksState} from '../selectors';
import {fetchAdminOrderTasks} from '../../../../../store/actions/adminOrderListTasksActions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AdminOrderListDetail from './AdminOrderListDetail';
import AdminOrderListDetailRoom from './AdminOrderListDetailRoom';
import AdminOrderListDetailReceptionWorksRow from './AdminOrderListDetailReceptionWorksRow';
import Typography from '@material-ui/core/Typography';

const AdminOrderListDetailReceptionWorks = ({order}) => {
	const [openRoom, setOpenRoom] = useState(false);
	const [room, setRoom] = useState(false);
	const dispatch = useDispatch()
	const {workers} = useSelector(getAdminOrderListTasksState)
	useEffect(() => {
		dispatch(fetchAdminOrderTasks(order.id))
	}, [dispatch, order])
	const closeHandler = () => {
		setOpenRoom(false)
	}
	const openRoomHandler = (room) => {
		setOpenRoom(true)
		setRoom(room)
	}
	return (
		<>
			<div className="mt-5 px-3">
				{/*<TableContainer component={Paper}>*/}
					<Table aria-label="collapsible table">
						<Paper component={'thead'}>
							<TableRow className="AdminOrderList__table-head">
								<TableCell align="center" className="w-40 p-0" colSpan={2}>
									<div className="h40 d-flex justify-content-center align-items-center">
										<Typography>Текущее задание</Typography>
									</div>
								</TableCell>
								<TableCell align="center" className="w-60 p-0" colSpan={3}>
									<div className="h40 d-flex justify-content-center align-items-center border-left">
										<Typography>Чек лист</Typography>
									</div>
								</TableCell>
							</TableRow>
							<TableRow className="AdminOrderList__table-head">
								<TableCell align="center" className="w-40 p-0" colSpan={2}>
									<div className="h50 p-0 d-flex justify-content-between align-items-center">
										<div
											className="h-100 d-flex align-items-center justify-content-center w-20 border-right">
											<Typography>№ ТЗ</Typography></div>
										<div className="h-100 d-flex align-items-center justify-content-center w-80">
											<Typography>Наименование ТЗ</Typography></div>
									</div>
								</TableCell>
								<TableCell align="justify" className="w-60 p-0" colSpan={3}>
									<div className="h50 p-0 d-flex align-items-center border-left">
										<div
											className="h-100 d-flex align-items-center justify-content-center w-10 border-right">
											<Typography align='center'>№</Typography></div>
										<div
											className="h-100 d-flex align-items-center justify-content-center w-30 border-right">
											<Typography>Зона</Typography></div>
										<div
											className="h-100 d-flex align-items-center justify-content-center w-10 border-right">
											<Typography>Кол-во</Typography></div>
										<div
											className="h-100 d-flex align-items-center justify-content-center w-10 border-right">
											<Typography>Ед. изм.</Typography></div>
										<div
											className="h-100 d-flex align-items-center justify-content-center w-20 border-right">
											<Typography>Дата последнего<br/> приглашения на прием</Typography>
										</div>
										<div
											className="h-100 d-flex align-items-center justify-content-center flex-grow-1">
											<Typography align='center'>Статус приема</Typography>
										</div>
									</div>
								</TableCell>
							</TableRow>
						</Paper>
						<TableBody>
							{workers.map((worker) => {
								return (<Fragment key={worker.id}>
									<TableRow>
										<TableCell align="justify"
												   className="AdminOrderList__row-title p-3 bg-light"
												   colSpan={5}
										>
											<Typography>{worker.name}</Typography>
										</TableCell>
									</TableRow>
									{
										worker.tasks.map((task, idx) => (
											<AdminOrderListDetailReceptionWorksRow key={task.id || idx}
																				   task={task}
																				   idx={idx}
																				   worker={worker}
																				   order={order}
																				   openRoomHandler={openRoomHandler}
											/>
										))
									}
								</Fragment>)
							})}
						</TableBody>
					</Table>
				{/*</TableContainer>*/}

			</div>
			{
				openRoom &&
				<AdminOrderListDetail closeHandler={closeHandler} title={`Зона #${room.id}`}>
					<AdminOrderListDetailRoom roomData={room}/>
				</AdminOrderListDetail>
			}
		</>
	);
};

export default AdminOrderListDetailReceptionWorks;