export const ROLE_ADMINISTRATORS: number     = 1;
export const ROLE_MASTER: number             = 5;
export const ROLE_MANAGER: number            = 6;
export const ROLE_SPECIALIST: number         = 7;
export const ROLE_ADMINS: number             = 8;
export const ROLE_CLIENT: number             = 9;
export const ROLE_ANCILLARY: number          = 10;
export const ROLE_INDEPENDENT_MASTER: number = 11;
export const ROLE_PROJECT_DIRECTOR: number   = 12;
export const ROLE_GEODESIST: number          = 13;
export const ROLE_SUPPLIER: number           = 14;
export const ROLE_ENGINEER_PTO: number       = 15;

export const HOME_ANCHOR: string           = 'header';
export const ABOUT_ANCHOR: string          = 'about';
export const COTNACTS_ANCHOR: string         = 'contacts';
export const SERVICE_SELECT_ANCHOR: string = 'service_select';
export const CALCULATOR_ANCHOR: string     = 'calculator';
export const CONFIRM_ORDER: string         = 'confirm_order';
export const ONE_CLICK_ESTIMATE: string         = 'one-click-estimate';

export const TITLE_CATEGORY:string     = 'Вид работ';
export const TITLE_SUB_CATEGORY:string = 'Раздел';
export const TITLE_SERVICE:string      = 'Наименование работ';

