import {
	FETCH_ORDER_CONDITIONS_SUCCESS,
	FETCH_SERVICE_SELECT_CATEGORIES_SUCCESS,
	FETCH_SERVICES_ERROR,
	FETCH_SERVICES_START,
	FETCH_SERVICES_SUCCESS,
	SET_SERVICE_SELECT_PROPERTY,
	WORKER_SELECT_SET_MASTERS,
} from "./actionTypes";
import axios from "../../rest/axios";

export function fetchServiceSelectServices() {
	return async (dispatch) => {
		dispatch(fetchStart());
		try {
			let services = await axios.get("/admin/services/", {
				params: {
					token: localStorage.getItem("token"),
				},
			});

			dispatch(fetchServicesSuccess(services.data.data));
		} catch (e) {
			dispatch(fetchError(e));
		}
	};
}

export function fetchServiceSelectCategories() {
	return async (dispatch) => {
		try {
			let categories = await axios.get("/admin/categories/");
			dispatch(fetchCategoriesSuccess(categories.data.data));
		} catch (e) {
			dispatch(fetchError(e));
		}
	};
}

function fetchStart() {
	return {
		type: FETCH_SERVICES_START,
	};
}

function fetchServicesSuccess(services) {
	return {
		type: FETCH_SERVICES_SUCCESS,
		payload: {
			services,
		},
	};
}

function fetchCategoriesSuccess(categories) {
	return {
		type: FETCH_SERVICE_SELECT_CATEGORIES_SUCCESS,
		payload: {
			categories,
		},
	};
}

function fetchError(error) {
	return {
		type: FETCH_SERVICES_ERROR,
		payload: {
			error,
		},
	};
}

export function setServiceSelectProperty(value, property) {
	return {
		type: SET_SERVICE_SELECT_PROPERTY,
		payload: {
			value,
			property,
		},
	};
}

export function setOrderMasters(master) {
	return {
		type: WORKER_SELECT_SET_MASTERS,
		payload: {
			master,
		},
	};
}

export function fetchOrderConditions() {
	return async (dispatch) => {
		try {
			const { data } = await axios.get("/admin/orders/conditions/");
			dispatch({ type: FETCH_ORDER_CONDITIONS_SUCCESS, payload: { data } });
		} catch (e) {}
	};
}
