import React, {useEffect, useState} from 'react';
import './AdminGoodsCategoryDetail.scss';
import {useDispatch, useSelector} from "react-redux";
import {
	deleteAdminGoodsCategory, saveAdminGoodsCategory,
	setAdminGoodsCategoryProperty,
	setAdminGoodsGoodActivity, setAdminGoodsProperty,
} from "../../../../../store/actions/adminGoodsActions";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import AdminBtn from "../../AdminUI/AdminBtn";
import {Button, TextField} from "@material-ui/core";


const AdminGoodsCategoryDetail = () => {
	const dispatch = useDispatch();

	const [category, setCategory] = useState({});
	const {categories} = useSelector(state => state.adminGoods);
	useEffect(() => {
		setCategory(categories.find(category => category.edit));
	}, [categories]);

	const deleteCategory = () => {
		dispatch(deleteAdminGoodsCategory(category.id))
	}
	const saveCategory = () => {
		if (category.name) {
			dispatch(saveAdminGoodsCategory(category.id))
			dispatch(setAdminGoodsCategoryProperty(category.id, false, 'edit'));
			dispatch(setAdminGoodsProperty(category.id, 'currentCategory'));
		}
	};
	const backHandler = () => {
		dispatch(setAdminGoodsCategoryProperty(category.id, false, 'edit'));
	}


	return (
		<div className='row'>
			<Button className='my-3' variant='contained' color='primary'
					 onClick={backHandler}
			>
				Назад</Button>

			{category &&
			<div className="col-12 px-0">

				{
					!category.isNew &&
					<Paper className='px-3 p-2 d-flex align-items-center'>
						<Typography variant='subtitle1' className='mr-3'>ID:</Typography>
						<Typography variant='h6'>{category.id}</Typography>
					</Paper>
				}
				<Paper className='px-3 p-2 mt-2 d-flex align-items-center justify-content-between'>
					<div className='d-flex align-items-center'>
						<Typography variant='subtitle1' className='mr-3'>Активность:</Typography>
						<Typography variant='h6'
									className={category.active ? 'text-success' : 'text-danger'}>{category.active ? 'Активен' : 'Неактивен'}</Typography>
					</div>
					<div className='d-flex'>
						{
							category.active
								? <AdminBtn tip='Деактивировать' type='clear'
											onClick={() => dispatch(setAdminGoodsCategoryProperty(category.id, false, 'active'))}/>
								: <AdminBtn tip='Активировать' type='check'
											onClick={() => dispatch(setAdminGoodsCategoryProperty(category.id, true, 'active'))}/>
						}
					</div>
				</Paper>

				<Paper className='p-3 mt-2 w-100'>
					<Typography variant='subtitle1' className='mr-3'>Название:</Typography>
					<TextField value={category.name || ''}
							   variant='outlined'
							   className='w-100'
							   onChange={(evt) => dispatch(setAdminGoodsCategoryProperty(category.id, evt.target.value, 'name'))}
					/>
					<Typography variant='subtitle1' className='mr-3'>Описание:</Typography>
					<TextField value={category.description || ''}
							   variant='outlined'
							   className='w-100 mt-2'
							   multiline
							   onChange={(evt) => dispatch(setAdminGoodsCategoryProperty(category.id, evt.target.value, 'description'))}
					/>
				</Paper>


				<Paper className='px-3 p-2 mt-4 d-flex align-items-center'>
					{
						category.edit
							? <AdminBtn type='save' text='Сохранить'
										onClick={saveCategory}
							/>
							: <AdminBtn type='edit' text='Редактировать'
										onClick={() => dispatch(setAdminGoodsCategoryProperty(category.id, true, 'edit'))}
							/>
					}
					{
						category.deleted
							? <AdminBtn type='restore' text='Восстановить'
										onClick={() => dispatch(setAdminGoodsCategoryProperty(category.id, false, 'deleted'))}
							/>
							: <AdminBtn type='delete' text='Удалить'
										onClick={deleteCategory}
							/>
					}
				</Paper>

			</div>
			}
		</div>
	)
};


export default AdminGoodsCategoryDetail;