import firebase from "../../rest/firebase";
// import database from 'firebase/database'
import {
	FETCH_DIALOG_START,
	FETCH_DIALOG_SUCCESS,
	FETCH_DIALOG_ERROR,
	FETCH_PROFILE_START,
	FETCH_PROFILE_SUCCESS,
	FETCH_PROFILE_ERROR,
	SET_DIALOG_PROPERTY,
	READ_MSGS_SUCCESS,
} from "./actionTypes";
import axios from "../../rest/axios";
import { store } from "../../index";
import * as moment from "moment";
import qs from "qs";

export function getProfile() {
	return async (dispatch) => {
		dispatch(fetchProfileStart());
		try {
			const token = localStorage.getItem("token");
			let response = await axios.post(
				"/mobile/user/about/",
				qs.stringify({ token }),
			);
			dispatch(fetchProfileSuccess(response.data.data));
		} catch (e) {
			dispatch(fetchProfileError(e));
		}
	};
}

export function fetchDialogs(orderId, dialogId, opponent) {
	return async (dispatch) => {
		dispatch(fetchStart());
		try {
			const chat = {};

			chat.opponent = opponent;
			chat.id = dialogId;
			chat.messages = {};
			if (opponent.role === 8 || opponent.role === 1) {
				orderId = "admin";
			}

			await firebase
				.database()
				.ref(`dialogs/${orderId}/${dialogId}`)
				.orderByChild("timestamp")
				.once("value", (snapShot) => {
					const value = snapShot.val();

					if (value) {
						chat.messages = sortDialogByDates(value);
						chat.unreads = getUnReadMessages(value);
					}
				});

			dispatch(fetchSuccess(chat));
		} catch (e) {
			dispatch(fetchError(e));
		}
	};
}

export function sendMessage(orderId, dialogId, text, photo) {
	return async (dispatch) => {
		dispatch(fetchStart());
		const token = localStorage.getItem("token");

		let date = new Date();

		let data = {
			orderId,
			localId: date.getTime(), // timestamp
			dialogId,
			text,
			photo,
			web: true,
			token,
		};

		try {
			await axios.post("/mobile/message/send/", qs.stringify(data));
		} catch (e) {
			dispatch(fetchError(e));
		}
	};
}

export function setDialogProperty(value, property) {
	return {
		type: SET_DIALOG_PROPERTY,
		value,
		property,
	};
}

export function readMsgs(orderId, dialogId, read) {
	return async (dispatch) => {
		// dispatch(fetchStart())

		const token = localStorage.getItem("token");
		let data = {
			orderId,
			dialogId,
			read,
			token,
		};

		try {
			let response = await axios.post(
				"/mobile/message/read/",
				qs.stringify(data),
			);

			dispatch(readMsgsSuccess(response, dialogId));
		} catch (e) {
			// dispatch(fetchError(e))
		}
	};
}

function readMsgsSuccess(data, dialogId) {
	return {
		type: READ_MSGS_SUCCESS,
		data,
		dialogId,
	};
}

function getUnReadMessages(messages) {
	const userId = store.getState().dialog.profile.user.id;

	return Object.keys(messages).filter((key) => {
		const message = messages[key];
		return message.unread === true && userId !== message.uid;
	});
}

function sortDialogByDates(messages) {
	const messagesSorted = Object.values(messages).sort((a, b) => {
		return a.timestamp - b.timestamp;
	});

	const dates = messagesSorted
		.map((message) => message.timestamp)
		.map((date) => moment.unix(date).format("YYYY-MM-DD"))
		.filter((date, idx, self) => {
			return self.indexOf(date) === idx;
		});

	const dateGroups = {};

	dates.forEach((date) => {
		dateGroups[date] = [];
		let key = 0;
		messagesSorted.forEach((message) => {
			if (moment.unix(message.timestamp).format("YYYY-MM-DD") === date) {
				dateGroups[date][key] = message;
				key++;
			}
		});
	});

	return dateGroups;
}

function fetchStart() {
	return {
		type: FETCH_DIALOG_START,
	};
}

function fetchSuccess(chat) {
	return {
		type: FETCH_DIALOG_SUCCESS,
		chat,
	};
}

function fetchError(error) {
	return {
		type: FETCH_DIALOG_ERROR,
		error,
	};
}

function fetchProfileStart() {
	return {
		type: FETCH_PROFILE_START,
	};
}

function fetchProfileSuccess(data) {
	return {
		type: FETCH_PROFILE_SUCCESS,
		data,
	};
}

function fetchProfileError(error) {
	return {
		type: FETCH_PROFILE_ERROR,
		error,
	};
}
