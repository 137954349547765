import React, {useEffect, useState} from 'react';
import {Paper} from '@material-ui/core';
import NumberFormat from 'react-number-format';

const AdminOrderListFinanceMastersDebt = ({masterId, order}) => {
	const [sum, setSum] = useState(0);
	useEffect(() => {
		let reported = 0;
		let paid = 0;
		if (!!order.reports) {
			let masterReports = order.reports.filter(report => report.status > 1 && report.master === masterId);
			if (!!masterReports.length) {
				masterReports.forEach((report) => {
					reported += report.services[0].value * order.unitPrice;
					// временно, пока нет данных об оплатах
					paid += report.services[0].value * order.unitPrice;
				})
			}
			setSum((reported - paid).toFixed())
		}

	}, [order])

	return (
		<>
			{
				sum > 0
				&& <ul className="px-0 m-0">
					<li className="AdminOrderList__finance-li mt-2">
						<Paper
							className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
							<div>Осталось для выплаты по выполнению</div>
							<div><NumberFormat
								value={sum}
								displayType={'text'}
								thousandSeparator={" "}/> р.
							</div>
						</Paper>
					</li>
				</ul>
			}
		</>
	);
};

export default AdminOrderListFinanceMastersDebt;