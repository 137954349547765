import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import parse from "html-react-parser";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import AdminSideComponent from "./AdminSideComponent";
import { getSectionsMap } from "../admin.functions";
import AdminPricesSectionCounter from "../AdminPricesSectionCounter";

class AdminServiceSelect extends Component {
	handlerSelect = (serviceId, supportServiceId, evt, uid) => {
		this.props.setSupportService(
			serviceId,
			supportServiceId,
			evt.target.value,
			uid,
		);
	};

	render() {
		const { currentTab, tabId, currentCategory } = this.props;

		const { services } = this.props.adminService;

		const rowHeight = 50;

		const sections = getSectionsMap(currentCategory, services);

		return (
			<List aria-labelledby='nested-list-subheader'>
				{sections.map((section) => {
					return (
						<Fragment key={section.id}>
							<Typography variant='subtitle1' className='my-2'>
								<div className='first-letter font-weight-bold d-flex'>
									<div className='col-4'>{section.name}</div>
									{currentTab === 5 && (
										<AdminPricesSectionCounter section={section} />
									)}
								</div>
							</Typography>
							{section.services.map((service, service_idx) => {
								let rows = 1;
								let height = rowHeight;

								if (currentTab === 2) {
									// нормы материалов
									if (!service.items.length) {
										rows =
											!!service.materials && service.materials.length > 0
												? service.materials.length + 1
												: rows;
									}
									height = 70;
								}
								if (currentTab === 6) {
									if (!service.items.length) {
										rows =
											!!service.materials && service.materials.length > 0
												? service.materials.length
												: rows;
										height = 70;
									} else {
										// rows = !!service.materials && service.materials.length > 0 ? service.materials.length + 1 : rows
									}
								}

								if (currentTab === 3) {
									// нормы работ

									rows = !service.items.length
										? service.norms.length + 2
										: rows;
								}
								if (currentTab === 4) {
									// цена бригадира

									rows = !service.items.length
										? service.norms.length + 1
										: rows;
								}

								if (currentTab === 4) {
									// цена бригадира

									rows = !service.items.length
										? service.norms.length + 1
										: rows;
								}

								height *= rows;
								if (currentTab === 5) {
									height += 50;
								}

								if (currentTab === 1) {
									height += 10;
								}

								height += "px";

								return (
									<ListItem
										key={service.id}
										className='p-0 m-0 border-top border-bottom border-gray d-flex align-items-start'>
										{/*список слева*/}
										{currentTab !== 6 && (
											<div className='col-4 pl-0 pr-1 py-2'>
												<Paper>
													<List className='px-0'>
														<ListItem
															className={`p-0 pr-3 m-0 align-items-center border-top border-bottom border-gray listItem ${
																service.active ? "enableItem" : "disableItem"
															} ${service.edited ? "editedItem" : ""}`}
															style={{ height }}>
															<Typography
																variant='subtitle1'
																className='col-1 p-0 m-0 d-flex justify-content-center'>
																{service_idx + 1}.
															</Typography>
															<Typography
																variant='subtitle1'
																className='col-10 p-0 m-0'>
																{service.name}
																<span>[ {service.id} ]</span>
															</Typography>

															<Typography
																variant='subtitle1'
																className='col-1 p-0 m-0 d-flex justify-content-center'>
																{parse(service.unit || "")}
															</Typography>
														</ListItem>

														{!!service.items.length && (
															<ListItem className='p-0'>
																<List className='col-12 p-0'>
																	{service.items.map((item, item_idx) => {
																		let uid = item.uid || item.id;
																		let rows = 1;
																		let height = rowHeight;

																		if (currentTab === 2) {
																			height = 70;
																			rows =
																				!!item.materials &&
																				item.materials.length > 0
																					? item.materials.length + 1
																					: rows;
																		}

																		if (currentTab === 6) {
																			height = 70;
																			rows =
																				!!item.materials &&
																				item.materials.length > 0
																					? item.materials.length
																					: rows;
																		}

																		if (currentTab === 3) {
																			rows = !!item.norms.length
																				? item.norms.length + 1
																				: rows;
																		}
																		if (currentTab === 4) {
																			rows = !!item.norms.length
																				? item.norms.length
																				: rows;
																		}
																		height *= rows;
																		if (currentTab === 6) {
																			height += 50;
																		}
																		height += "px";

																		return (
																			<ListItem
																				key={uid}
																				className={`px-3 align-items-center border-bottom border-gray listItem ${
																					item.active
																						? "enableItem"
																						: "disableItem"
																				}`}
																				style={{ height }}>
																				<Typography
																					variant='body1'
																					className='col-1 p-0 m-0 d-flex justify-content-center'>
																					{" "}
																					{service_idx + 1}.{item_idx + 1}.{" "}
																				</Typography>
																				<Typography
																					variant='body1'
																					className='col-10 p-0 m-0'>
																					{item.name}
																					{/*[ {item.id} ]*/}
																				</Typography>
																				<Typography
																					variant='body1'
																					className='col-1 p-0 m-0 d-flex justify-content-center'>
																					{parse(item.unit)}
																				</Typography>
																			</ListItem>
																		);
																	})}
																</List>
															</ListItem>
														)}
													</List>
												</Paper>
											</div>
										)}

										{/*компонент справа*/}
										<div
											className={`${
												currentTab === 6 ? "col-12" : "col-8"
											} pr-0 pl-1 py-2 border-left border-gray`}>
											<Paper>
												<AdminSideComponent
													tabId={tabId}
													serviceId={service.id}
													height={height}
													serviceTabHandler={this.props.serviceTabHandler}
												/>
											</Paper>
										</div>
									</ListItem>
								);
							})}
						</Fragment>
					);
				})}
			</List>
		);
	}
}

const mapState = (state) => {
	return {
		adminService: state.adminService,
	};
};

const actions = {};

export default connect(mapState, actions)(AdminServiceSelect);
