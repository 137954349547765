import React, { Component, createRef } from "react";
import "./WorkerCreate.scss";
import { connect } from "react-redux";

import latinize from "latinize";
import {
	createWorker,
	fetchCategories,
	fetchProfessions,
} from "../../../store/actions/workerCreateActions";
import WorkerRecommendations from "./WorkerRecommendations/WorkerRecommendations";
import WorkerCategories from "./WorkerCategories/WorkerCategories";
import WorkerExperience from "./WorkerExperience/WorkerExperience";
import WorkerPassport from "./WorkerPassport/WorkerPassport";
import WorkerMainInfo from "./WorkerMainInfo/WorkerMainInfo";
import WorkerSpecialization from "./WorkerSpecialization/WorkerSpecialization";
import { validateControl } from "../../../form/formFramework";
import Page from "../../../hoc/Page/Page";
import { WorkerTopNav } from "../WorkerComponents";
import Button from "@material-ui/core/Button";
import WorkerFooter from "../WorkerComponents/WorkerFooter";
import { Redirect } from "react-router-dom";
import WorkerTypes from "./WorkerTypes/WorkerTypes";
import AdminSendLoader from "../../Admin/AdminComponents/AdminUI/AdminSendLoader";
import { validatePhone } from "../../../form/validators";
import {
	ROLE_INDEPENDENT_MASTER,
	ROLE_MANAGER,
	ROLE_MASTER,
	ROLE_PROJECT_DIRECTOR,
} from "../../../config";
import { getSpecializations } from "../../../store/actions/specialistActions";

class WorkerCreate extends Component {
	state = {
		currentSpecialization: ROLE_MASTER,
		formControls: this.props.formControls,
		formValid: true,
		formTouched: false,
		formData: {
			methodOfWork: [1],
			typeOfWork: [1],
			categories: [],
		},
	};
	page = createRef();

	submitHandler = (evt) => {
		evt.preventDefault();
		const formControls = { ...this.state.formControls };
		const formData = { ...this.state.formData };
		let formValid = true;
		let categoryError = !!formData.categories.length;
		let errorRef = "";

		Object.values(formControls).forEach((formControlGroup) => {
			Object.keys(formControlGroup).forEach((property) => {
				if (
					(property === "dateStartMainExperience" ||
						property === "mainExperience") &&
					(this.state.currentSpecialization === ROLE_MANAGER ||
						this.state.currentSpecialization === ROLE_PROJECT_DIRECTOR)
				) {
					return;
				}
				const control = formControlGroup[property];
				const value = control.value;
				const error = validateControl(value, control.validation);
				formValid = formValid && !error;
				control.error = error;
				control.value = value;
				formData[property] = value;
				if (error && !errorRef) {
					errorRef = control.id;
				}
			});
		});
		if (
			this.state.currentSpecialization === ROLE_MASTER ||
			this.state.currentSpecialization === ROLE_INDEPENDENT_MASTER
		) {
			formValid = categoryError && formValid;
			if (!categoryError && !errorRef) {
				errorRef = "wc-categories";
			}
		}

		if (formValid) {
			formData["specializationType"] = this.state.currentSpecialization;
			formData["userId"] = localStorage.getItem("userId") || "";
			this.props.createWorker(formData);
		} else {
			if (errorRef) {
				const $errorElement = document.getElementById(errorRef);
				let block = $errorElement.closest(".wc__block");
				if (block) {
					const top = block.offsetTop - 50;
					window.scrollTo({
						top,
						behavior: "smooth",
					});
				}
				errorRef = "";
			}
		}

		this.setState({
			formValid,
			formControls,
			formData,
			formTouched: true,
		});
	};

	formControlHandler = (value, propertyId) => {
		const formControls = { ...this.state.formControls };
		// const formTouched = this.state.formTouched

		if (propertyId === "owner") {
			value = latinize(value).toUpperCase();
		}

		if (propertyId === "phone") {
			value = validatePhone(value);
		}

		Object.values(formControls).forEach((formControlGroup) => {
			if (
				(propertyId === "dateStartMainExperience" ||
					propertyId === "mainExperience") &&
				(this.state.currentSpecialization === ROLE_MANAGER ||
					this.state.currentSpecialization === ROLE_PROJECT_DIRECTOR)
			) {
				return;
			}
			const control = Object.keys(formControlGroup).find(
				(key) => key === propertyId,
			);
			if (control) {
				// if (formTouched) {
				formControlGroup[control].error = validateControl(
					value,
					formControlGroup[control].validation,
				);
				// }
				formControlGroup[control].value = value;
			}
		});

		this.setState({
			formControls,
		});
	};

	checkBoxHandler = (value, property) => {
		const formData = { ...this.state.formData };
		let changedItemId = parseInt(value);
		let changedItemIdx = -1;

		if (formData[property]) {
			changedItemIdx = formData[property].findIndex(
				(id) => id === changedItemId,
			);
		} else {
			formData[property] = [];
		}

		if (changedItemIdx === -1) {
			formData[property] = [...formData[property], changedItemId];
		} else {
			formData[property] = [
				...formData[property].slice(0, changedItemIdx),
				...formData[property].slice(changedItemIdx + 1),
			];
		}
		this.setState({ formData });
	};

	componentDidMount() {
		if (this.props.isLogin) {
			this.props.fetchProfessions();
			this.props.fetchCategories();
			this.props.getSpecializations();
			let email = localStorage.getItem("email");
			if (email) {
				const formControls = { ...this.state.formControls };
				formControls.user.email.value = email;
			}
		}
	}

	currentSpecializationHandler = (_, currentSpecialization) => {
		this.setState({ currentSpecialization });
	};

	render() {
		if (!this.props.isLogin) {
			return <Redirect to={"/worker/"} />;
		}

		const { professions, categories, loading, createResult } = this.props;

		const { formControls, currentSpecialization, formData } = this.state;
		const title = "Регистрация";

		return (
			<Page title={title}>
				{loading ? (
					<AdminSendLoader />
				) : (
					<>
						<WorkerTopNav title={title} />
						<div id='wc' className='container wc' ref={this.page}>
							<form
								ref={this.registerSpecialistForm}
								// className={1 ? 'disabled' : ''}
								onSubmit={this.submitHandler}>
								<WorkerSpecialization
									currentSpecializationHandler={
										this.currentSpecializationHandler
									}
									currentSpecialization={this.state.currentSpecialization}
								/>
								<div className='wc__block'>
									<WorkerMainInfo
										controls={formControls.user}
										formControlHandler={this.formControlHandler}
									/>
								</div>

								<WorkerPassport
									controls={formControls.passport}
									formControlHandler={this.formControlHandler}
								/>

								{/*<WorkerCard controls={formControls.card} formControlHandler={this.formControlHandler}/>*/}

								{currentSpecialization !== ROLE_MANAGER &&
								currentSpecialization !== ROLE_PROJECT_DIRECTOR ? (
									<div className='wc__block'>
										<WorkerExperience
											controls={formControls.experience}
											formControlHandler={this.formControlHandler}
											professions={professions}
										/>
									</div>
								) : null}

								{currentSpecialization === ROLE_MASTER ||
								currentSpecialization === ROLE_INDEPENDENT_MASTER ? (
									<div
										className={`wc__block ${
											!this.state.formData.categories.length &&
											this.state.formTouched
												? "wc__error"
												: ""
										}`}>
										<WorkerCategories
											categories={categories}
											checkBoxHandler={this.checkBoxHandler}
											formData={formData}
										/>
									</div>
								) : null}

								<WorkerTypes
									controls={formControls.prices}
									formControlHandler={this.formControlHandler}
									checkBoxHandler={this.checkBoxHandler}
									formData={formData}
								/>
								<WorkerRecommendations
									controls={formControls.recommendations}
									formControlHandler={this.formControlHandler}
								/>

								{/*{*/}
								{/*    currentSpecialization === 3 || currentSpecialization === 4*/}
								{/*        ?*/}
								{/*        <WorkerMasters masters={masters}*/}
								{/*                       checkBoxHandler={this.checkBoxHandler}/>*/}
								{/*        :*/}
								{/*        null*/}
								{/*}*/}

								<div className='w-100 mt-2 d-flex p-3 justify-content-end'>
									<Button
										variant='contained'
										color='primary'
										component='button'
										type='submit'
										className='col-12 col-sm-3'>
										<div>Сохранить</div>
									</Button>
								</div>
							</form>
						</div>
						<WorkerFooter />
					</>
				)}
				{createResult.id && <Redirect to={"/worker/personal/data"} />}
			</Page>
		);
	}
}

function mapStateToProps(state) {
	return {
		email: state.auth.email,
		categories: state.workerCreate.categories,
		professions: state.workerCreate.professions,
		masters: state.workerCreate.masters,
		formControls: state.workerCreate.formControls,
		loading: state.workerCreate.loading,
		createResult: state.workerCreate.createResult,
		isLogin: state.workerPersonal.isLogin,
	};
}

const actions = {
	fetchProfessions,
	fetchCategories,
	getSpecializations,
	createWorker,
};

export default connect(mapStateToProps, actions)(WorkerCreate);
