import React, {useEffect, useState} from 'react'
import "./ServiceConfirmTranches.scss";
import * as moment from "moment";
import 'moment/locale/ru';
import {connect, useSelector} from "react-redux";
import {setOrderListProperty} from "../../../../../../store/actions/orderListActions";
import {setConfirmOrderProperty, setTrancheMax} from "../../../../../../store/actions/confirmOrderActions";
import DraggableScroll from "../../../../../../components/UI/DraggableScroll/DraggableScroll";
import {getOrderState} from '../../../../../../store/selectors';
import {useConditionsContext} from '../../../../../../app/containers/Calculator/ConditionsContext';


const ServiceConfirmTranches = (props) => {

    const {
         startDate, dueDate, quantity,
    } = useSelector(getOrderState);
    const {servicePrice, totalServicePrice } = useConditionsContext();


    const {tranchesCount, maxTranchesCount, order, setTrancheMax,  setConfirmOrderProperty} = props;
    const [tranches, setTranches] = useState(1);
    const [quantityDay, setQuantityDay] = useState(null);
    const [costDay, setCostDay] = useState(null);
    const [trancheIndexes, setTrancheIndexes] = useState([]);

    // Задаем максимальное количество траншей
    useEffect(() => {
        let maxTranchesCount = 1;
        for (let i = 1; i * 3 < order.dueDate; i++) {
            maxTranchesCount++
        }
        setTrancheMax(maxTranchesCount)
    }, [order, setTrancheMax]);

    useEffect(() => {
        let tranches = tranchesCount > maxTranchesCount ? 1 : tranchesCount || 1;
        setTranches(+tranches);
    }, [tranchesCount, maxTranchesCount]);

    useEffect(() => {
        // периоды между траншами
        let periods = Math.ceil(order.dueDate / tranches);
        let trancheIndex = 0;
        // индекс транша
        let trancheIndexes = [trancheIndex];
        while (trancheIndex < order.dueDate - 1) {
            trancheIndex += periods;
            if (trancheIndexes.length === +tranches) {
                break
            } else {
                trancheIndexes.push(trancheIndex)
            }
        }
        setTrancheIndexes(trancheIndexes);
    }, [order, tranches]);

    useEffect(() => {
        let quantityDay = (+quantity / +dueDate).toFixed(2);
        setQuantityDay(quantityDay)
    }, [quantity, dueDate]);

    useEffect(() => {
        let costDay = (servicePrice * quantity / +dueDate).toFixed(2)
        setCostDay(costDay)
    }, [quantity, servicePrice, dueDate]);

    useEffect(() => {
        let trancheData = [];
        for (let i = 0; i < order.dueDate; i++) {
            let day = moment(order.formControls.startDate.value, "DD.MM.YYYY").add(i, 'd').format("DD.MM.YYYY");
            if (trancheIndexes.indexOf(i) !== -1) {
                trancheData.push(day);
            }
        }
        if (!!trancheData.length) {
            setConfirmOrderProperty(trancheData, 'trancheData')
        }
    }, [setConfirmOrderProperty, order, trancheIndexes]);


    const Tranches = () => {
        const table = [];
        // индекс для визуальной части
        let trancheIdx = 1;

        // суммы для транша
        for (let i = 0; i < dueDate; i++) {
            let quantityDaySum = 0;
            let costDaySum = 0;

            if (+tranches === 1) {
                quantityDaySum = quantity;
                costDaySum = totalServicePrice;
            } else {
                if (trancheIndexes.indexOf(i) !== -1) {
                    // индекс текущего транша
                    const currentIndex = trancheIndexes.indexOf(i);
                    // значение следующего транша
                    const nextTrancheValue = trancheIndexes[currentIndex + 1];

                    if (nextTrancheValue) {
                        for (let j = i; j < nextTrancheValue; j++) {
                            quantityDaySum += +quantityDay;
                            costDaySum += +costDay;
                        }
                    } else {
                        for (let j = i; j < order.dueDate; j++) {
                            quantityDaySum += +quantityDay;
                            costDaySum += +costDay;
                        }
                    }
                } else {
                    quantityDaySum += +quantityDay;
                    costDaySum += +costDay;
                }
            }

            let day = moment(order.formControls.startDate.value, "DD.MM.YYYY").add(i, 'd').format("DD.MM.YYYY");
            let isTrancheInfo = trancheIndexes.indexOf(i) !== -1;

            table.push(
                <React.Fragment key={i}>
                    <div className={`ConfirmOrderTranches_RightBlock__item ${isTrancheInfo ? 'active' : ''}`}>
                        {/*шапка*/}
                        <div
                            className={`ConfirmOrderTranches_RightBlock__item_head`}>
                            <div>
                                <strong>{day}</strong>
                                <span>{moment(startDate, "DD.MM.YYYY").add(i, 'd').format("dddd")}</span>
                                <span>{i + 1} день</span>
                            </div>
                        </div>
                        <div
                            className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                            {quantityDay}
                        </div>
                        <div
                            className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-2' : 'bg-white border-main-2-1'} border-bottom border-main-1-3 d-flex align-items-center justify-content-center h50`}>
                            {costDay}
                        </div>
                        <div
                            className={`px-3 ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                            {isTrancheInfo
                                ?
                                <strong>{parseFloat(quantityDaySum).toFixed(2)}</strong>
                                : null}
                        </div>
                        <div
                            className={`px-3  ${isTrancheInfo ? 'bg-tranche-1 border-tranche-1' : 'bg-white border-main-1-3'} border-bottom d-flex align-items-center justify-content-center h50`}>
                            {isTrancheInfo
                                ? <strong>{parseFloat(costDaySum).toFixed(2)}</strong>
                                : null
                            }
                        </div>


                        {isTrancheInfo
                            ? <div
                                className='ConfirmOrderTranches_RightBlock__item_head-label bg-tranche-1 d-flex align-items-center justify-content-center'>Транш&nbsp;
                                {trancheIdx}
                            </div>
                            : null}
                    </div>

                </React.Fragment>
            )

            if (isTrancheInfo) {
                quantityDaySum = 0;
                costDaySum = 0;
                ++trancheIdx;
            }
        }

        return table
    };


    return (
        <div className="ConfirmOrderTranches">
            <div className="ConfirmOrderTranches_LeftBlock border-right border-main-1-3">
                <div className='ConfirmOrderTranches_LeftBlock__title'>Плановый график выполнения</div>
                <div className='d-flex align-content-center justify-content-end h70'>
                    {/*<div className="w-10"/>*/}
                    <div className='w-70 pl-2 d-flex align-items-center'>
                        Работы
                    </div>
                    <div
                        className='w-30 pl-3 d-flex  align-items-center'>Ед.<br/>изм
                    </div>
                </div>
                <div className='position-relative d-flex align-items-center w-100'>

                    <div
                        className='w-101 ConfirmOrderTranches_LeftBlock-leftHeadingRotate top'>

                        <div>
                            <span>План</span>
                        </div>

                    </div>

                    <div className='w-70'>
                        <div
                            className='border-bottom border-main-1-3 d-flex align-items-center h50 pl-2'>Объемы
                        </div>
                        <div className='border-bottom border-main-2-1 d-flex align-items-center h50 pl-2'>Фин.
                            выполнение
                        </div>
                    </div>

                    <div className='w-30'>
                        <div
                            className='pl-3 border-bottom border-main-1-3 d-flex align-items-center h50'>м2
                        </div>
                        <div
                            className='pl-3 border-bottom border-main-2-1 d-flex align-items-center h50'>руб.
                        </div>
                    </div>
                </div>
                <div className='position-relative d-flex align-items-center bg-white w-100'>
                    <div
                        className='ConfirmOrderTranches_LeftBlock-leftHeadingRotate w-101 bottom'>

                        <div>
                            <span>План по траншу</span>
                        </div>

                    </div>

                    <div className='w-70'>
                        <div
                            className='border-bottom border-main-1-3 d-flex align-items-center h50 pl-2'>Плановые
                            объемы
                        </div>
                        <div
                            className='border-bottom border-main-1-3 d-flex align-items-center h50 pl-2'>Плановое
                            Фин. выполнение
                        </div>
                    </div>

                    <div className='w-30'>
                        <div
                            className='pl-3 border-bottom border-main-1-3 d-flex align-items-center h50'>м2
                        </div>
                        <div
                            className='pl-3 border-bottom border-main-1-3 d-flex align-items-center h50'>руб.
                        </div>
                    </div>
                </div>
            </div>

            <DraggableScroll>
                <div className="ConfirmOrderTranches_RightBlock pb-3">
                    <Tranches/>
                </div>
            </DraggableScroll>
        </div>
    )

}


const mapState = (state) => {
    return {
        order: state.order,
        maxTranchesCount: state.confirmOrder.formControls.tranches.validation.max,
        tranchesCount: state.confirmOrder.formControls.tranches.value
    }
};


const actions = {
    setConfirmOrderProperty,
    setOrderListProperty,
    setTrancheMax
};

export default connect(mapState, actions)(ServiceConfirmTranches)