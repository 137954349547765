import {
	FETCH_ADMIN_ORDER_TASK_ROOM_START, FETCH_ADMIN_ORDER_TASK_ROOM_SUCCESS, FETCH_ADMIN_ORDER_TASKS_ERROR,
	FETCH_ADMIN_ORDER_TASKS_START, FETCH_ADMIN_ORDER_TASKS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
	loading: false,
	workers: [],
	roomData: {},
	error: null,
	loadingClass: false
}

const adminOrderListTasksReducer = (state = initialState, {type, payload}) => {
	switch (type) {

		case FETCH_ADMIN_ORDER_TASKS_START:
		case FETCH_ADMIN_ORDER_TASK_ROOM_START:
			return {
				...state,
				loading: true
			}
		case FETCH_ADMIN_ORDER_TASKS_SUCCESS:
			return {
				...state,
				loading: false,
				workers: payload.data
			}
		case FETCH_ADMIN_ORDER_TASK_ROOM_SUCCESS:
			return {
				...state,
				loading: false,
				roomData: payload.data
			}
		case FETCH_ADMIN_ORDER_TASKS_ERROR:
			return {
				...state,
				error: payload.error,
				loading: false,
				workers: [],
				roomData: {},
			}
		default:
			return state
	}
}

export default adminOrderListTasksReducer;