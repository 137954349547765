import React, {Component} from 'react';
import {connect} from "react-redux";
import AdminMaterialListHeader from "./AdminMaterialListHeader";
import List from "@material-ui/core/List";
import {
    addAdminMaterialListMaterial, deleteAdminMaterialListMaterial,
    fetchAdminMaterialListCategories,
    fetchAdminMaterialListMaterials,
    fetchAdminMaterialListUnits,
    saveAdminMaterialListMaterial, saveMaterialList, setAdminMaterialListProperty,
} from "../../../../store/actions/adminMaterialListActions";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import AdminMaterialListItem from "./AdminMaterialListItem";
import cuid from 'cuid'
import LoaderAdmin from "../../../../components/UI/LoaderAdmin/Loader";
import AdminPopup from "../AdminUI/AdminPopup";
import AdminSendLoader from "../AdminUI/AdminSendLoader";
import {saveProfessionList} from "../../../../store/actions/adminProfessionalListActions";


class AdminMaterialList extends Component {
    componentDidMount = async () => {
        if (!this.props.materials.length || !this.props.categories.length) {
            await this.props.fetchAdminMaterialListMaterials()
            await this.props.fetchAdminMaterialListCategories()
        }
        if (!this.props.units.length) {
            this.props.fetchAdminMaterialListUnits()
        }
    }

    addMaterialHandler = () => {
        const material = {
            id: cuid(),
            name: "",
            active: false,
            unit: "",
            price: 0,
            pack: 1,
            categories: [],
            edit: true,
            edited: true,
            isNew: true
        }
        this.props.addAdminMaterialListMaterial(material)
    }

    clearResultHandler = () => {
        this.props.setAdminMaterialListProperty({}, 'saveResult')
    }


    render() {
        const {materials, header, saveAdminMaterialListMaterial, deleteAdminMaterialListMaterial, categories, units, loading, saveResult, saveInProcess} = this.props

        return (
            <>
                {
                    loading ?
                        <LoaderAdmin/>
                        :
                        <>
                            <AdminMaterialListHeader header={header}/>
                            <List className="Admin_material-list">
                                {
                                    materials.map((material, material_idx) => {
                                        // if (!material.deleted) {
                                        return (
                                            <AdminMaterialListItem
                                                key={material.id}
                                                num={material_idx + 1}
                                                saveHandler={saveAdminMaterialListMaterial}
                                                deleteHandler={deleteAdminMaterialListMaterial}
                                                units={units}
                                                categories={categories}
                                                material={material}/>
                                        )
                                        // }
                                    })
                                }
                            </List>
                            <Paper className="mt-2">
                                <div className="d-flex p-3 justify-content-end">
                                    <Button
                                        className="Admin_btn text mr-2"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.addMaterialHandler}
                                    >
                                        <AddIcon
                                            className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                        <Typography variant="overline" display="block">
                                            Добавить материал
                                        </Typography>
                                    </Button>
                                    <Button
                                        className="Admin_btn text "
                                        variant="contained"
                                        color="primary"
                                        onClick={this.props.saveMaterialList}
                                    >
                                        <SaveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                        <Typography variant="overline" display="block">
                                            Сохранить изменения
                                        </Typography>
                                    </Button>
                                </div>

                            </Paper>
                            {
                                saveResult.title &&
                                <AdminPopup saveResult={saveResult} clearResultHandler={this.clearResultHandler}/>
                            }
                            {
                                saveInProcess &&
                                <AdminSendLoader/>
                            }
                        </>
                }

            </>
        );
    }
}

const mapState = (state) => {
    return {
        header: state.admin.materialTabs,
        materials: state.adminMaterialList.materials,
        categories: state.adminMaterialList.categories,
        units: state.adminMaterialList.units,
        loading: state.adminMaterialList.loading,
        saveResult: state.adminMaterialList.saveResult,
        saveInProcess: state.adminMaterialList.saveInProcess,
    }
}
const actions = {
    fetchAdminMaterialListMaterials,
    fetchAdminMaterialListCategories,
    fetchAdminMaterialListUnits,
    saveAdminMaterialListMaterial,
    addAdminMaterialListMaterial,
    deleteAdminMaterialListMaterial,
    saveProfessionList,
    saveMaterialList,
    setAdminMaterialListProperty
}

export default connect(mapState, actions)(AdminMaterialList);