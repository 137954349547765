import React from 'react';
import IndividualWorksTop from './IndividualWorksTop';
import parse from 'html-react-parser';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {getOrderState, getServiceSelectState} from '../../../../store/selectors';
import RadioTabs from '../../../ui/RadioTabs/RadioTabs';
import {setOrderProperty} from '../../../../store/actions/orderActions';
import SwitchCondition from '../../../ui/SwitchCondition/SwitchCondition';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import {useConditionsContext} from '../ConditionsContext';

const IndividualWorksAdditions = () => {
	const {
		currentCategory, currentService, workType,
		quantity, startDate, dueDate,
		locationObject, floor, withInstrument, withEquipment, withHousing,
		materialPlace, docs, classifiers,
	} = useSelector(getOrderState);

	const {
		docsTabs,
		conditions: {
			locationObjectConditions,
			floorConditions,
			instrumentConditions,
			equipmentConditions,
			housingConditions,
			materialPlaceConditions,
			classifiersConditions,
		}
	} = useSelector(getServiceSelectState);

	const {servicePrice} = useConditionsContext();

	const dispatch = useDispatch();

	const tabHandler = (property) => (value) => {
		dispatch(setOrderProperty(value, property))
	}

	const addDocsHandler = (evt, id) => {

		const classifier = classifiersConditions.find(c => c.id === id);
		if (classifier) {
			if (evt.target.checked) {
				dispatch(setOrderProperty([classifier, ...classifiers], 'classifiers'));
			} else {
				dispatch(setOrderProperty(classifiers.filter(c => c.id !== classifier.id), 'classifiers'));
			}
		}
	}

	return (
		<div className="d-flex align-items-center justify-content-between flex-column">
			{
				!!currentCategory && !!currentService
				&& <IndividualWorksTop name={currentService.name} picture={currentCategory.picture}>
					<div className="d-flex align-items-baseline align-items-sm-center justify-content-between flex-column flex-sm-row">
						<div className="d-flex flex-row flex-sm-column mr-0 mr-sm-5">
							<div className="IndividualWorks__top-sub-title">
								Цена:
							</div>
							<div>
								<NumberFormat
									className="IndividualWorks__top-sub-num"
									value={servicePrice}
									displayType={'text'}
									thousandSeparator={" "}/>&nbsp;₽
							</div>
						</div>

						<div className="d-flex flex-column mr0 mr-sm-5">
							<div className="d-flex align-items-center">
								<div className="IndividualWorks__top-sub-title">
									Уровень работ:
								</div>
								<div>&nbsp;{workType === 1 ? 'Стандарт' : 'Премиум'}</div>
							</div>
							<div className="d-flex align-items-center">
								<div className="IndividualWorks__top-sub-title">
									Объем работ:
								</div>
								<div>&nbsp;{quantity} {parse(currentService.unit)}</div>
							</div>
						</div>

						<div className="d-flex flex-column">
							<div className="d-flex align-items-center">
								<div className="IndividualWorks__top-sub-title">
									Дата начала:
								</div>
								<div>&nbsp;{startDate}</div>
							</div>
							<div className="d-flex align-items-center">
								<div className="IndividualWorks__top-sub-title">
									Дата выполнения:
								</div>
								<div>&nbsp;{moment(startDate, "DD.MM.YYYY")
									.add((+dueDate > 0 ? +dueDate - 1 : 1), 'd').format("DD.MM.YYYY")}</div>
							</div>
						</div>
					</div>
				</IndividualWorksTop>
			}

			<div className="IndividualWorks__conditions-desc">
				Уточните условия проведения работ для точного расчета стоимости заказа
			</div>

			<div className="d-flex justify-content-between w-100 flex-column flex-sm-row mt-3 mt-sm-0">
				<RadioTabs title="Расположение объекта" tabs={locationObjectConditions}
						   active={locationObject || locationObjectConditions[0].id}
						   onclick={tabHandler('locationObject')}/>
				<RadioTabs title="Этажность зоны работ"
						   className="mt-3 mt-sm-0"
						   tabs={floorConditions} active={floor || floorConditions[0].id}
						   onclick={tabHandler('floor')}/>
			</div>

			<div className="IndividualWorks__conditions-switchers mt-3 mt-sm-5">
				<div className="d-flex flex-column align-items-center">
					<SwitchCondition switchers={instrumentConditions}
									 active={withInstrument || instrumentConditions[0].id}
									 title="Инструменты"
									 onClick={tabHandler('withInstrument')}/>
					<SwitchCondition switchers={equipmentConditions}
									 active={withEquipment || equipmentConditions[1].id}
									 title="Оборудование"
									 className="mt-4"
									 onClick={tabHandler('withEquipment')}/>
					<SwitchCondition switchers={housingConditions}
									 active={withHousing || housingConditions[0].id}
									 title="Жилье"
									 className="mt-4"
									 onClick={tabHandler('withHousing')}/>
				</div>
				<div className="IndividualWorks__conditions-docs">
					<div className="IndividualWorks__conditions-docs-left">
						<RadioTabs title="Материал" tabs={materialPlaceConditions}
								   active={materialPlace || materialPlaceConditions[1].id}
								   onclick={tabHandler('materialPlace')} variant="small"/>
						<RadioTabs title="Исполнительная документация" tabs={docsTabs} active={docs}
								   onclick={tabHandler('docs')} variant="small" className="mt-4"/>
					</div>
					<div className="IndividualWorks__conditions-docs-right">
						{
							!!classifiersConditions.length && classifiersConditions.map((classifier) => {
								const {id, title} = classifier;
								return <div key={id}>
									<FormControlLabel
										control={<Checkbox
											checked={docs !== 2 && classifiers.map(c => c.id).includes(id)}
											onChange={(evt) => addDocsHandler(evt, id)}
											color="primary"
											disabled={docs === 2}
										/>}
										label={title}
									/>
								</div>
							})
						}
					</div>
				</div>
			</div>

		</div>
	);
};

export default IndividualWorksAdditions;