import React from 'react';
import './ad-loader.scss';

 const AdLoader = () => {
    return (
        <div className="AdLoader">
            <div className="d-flex justify-content-center align-items-center">
                <svg
                    viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g>
                        <circle cx="85.9546" cy="50" r="4" fill="#ffffff">
                            <animate attributeName="cx" repeatCount="indefinite" dur="1.0416666666666665s"
                                     values="95;35" keyTimes="0;1" begin="-0.6432000000000001s"></animate>
                            <animate attributeName="fill-opacity" repeatCount="indefinite" dur="1.0416666666666665s"
                                     values="0;1;1" keyTimes="0;0.2;1" begin="-0.6432000000000001s"></animate>
                        </circle>
                        <circle cx="44.7553" cy="50" r="4" fill="#ffffff">
                            <animate attributeName="cx" repeatCount="indefinite" dur="1.0416666666666665s"
                                     values="95;35" keyTimes="0;1" begin="-0.3168s"></animate>
                            <animate attributeName="fill-opacity" repeatCount="indefinite" dur="1.0416666666666665s"
                                     values="0;1;1" keyTimes="0;0.2;1" begin="-0.3168s"></animate>
                        </circle>
                        <circle cx="63.0029" cy="50" r="4" fill="#ffffff">
                            <animate attributeName="cx" repeatCount="indefinite" dur="1.0416666666666665s"
                                     values="95;35" keyTimes="0;1" begin="0s"></animate>
                            <animate attributeName="fill-opacity" repeatCount="indefinite" dur="1.0416666666666665s"
                                     values="0;1;1" keyTimes="0;0.2;1" begin="0s"></animate>
                        </circle>
                    </g>
                    <g transform="translate(-15 0)">
                        <path d="M50 50L20 50A30 30 0 0 0 80 50Z" fill="#ee5a30"
                              transform="rotate(90 50 50)"></path>
                        <path d="M50 50L20 50A30 30 0 0 0 80 50Z" fill="#ee5a30" transform="rotate(42.0044 50 50)">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                              dur="1.0416666666666665s" values="0 50 50;45 50 50;0 50 50"
                                              keyTimes="0;0.5;1"></animateTransform>
                        </path>
                        <path d="M50 50L20 50A30 30 0 0 1 80 50Z" fill="#ee5a30" transform="rotate(-42.0044 50 50)">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                              dur="1.0416666666666665s" values="0 50 50;-45 50 50;0 50 50"
                                              keyTimes="0;0.5;1"></animateTransform>
                        </path>
                    </g>
                </svg>

            </div>
        </div>
    );
};
export default AdLoader;
