import React from 'react';
import {Paper} from '@material-ui/core';
import AdminOrderListFinanceStatus from './AdminOrderListFinanceStatus';

const AdminOrderFinancePaysCompany = ({order}) => {
	return (
		<div className="row mx-0 pt-3">
			<div className="col-12 px-2 pb-2 d-flex flex-column justify-content-between bg-light">
				<ul className="p-0 m-0">
					<li className="AdminOrderList__finance-li mt-2">
						<Paper
							className="h-100 d-flex align-items-center py-2 px-3">

							<div className="text-secondary col-1">Компания №1</div>
							<div className="col-3">ООО "Кирпичная кладка"</div>

							<div className="text-secondary col-3 text-right">Осталось для выплаты по выполнению:</div>
							<div className="col-1">80 000 р.</div>

							<div className="text-secondary col-1 text-right">Сумма:</div>
							<div className="col-1">120 000 р.</div>

							<div className="col-2 text-right">
								<AdminOrderListFinanceStatus id="O" text="Оплачено"/>
							</div>
						</Paper>
					</li>
					<li className="AdminOrderList__finance-li mt-2">
						<Paper
							className="h-100 d-flex align-items-center py-2 px-3">

							<div className="text-secondary col-1">Компания №2</div>
							<div className="col-3">ООО "Кирпичная кладка"</div>

							<div className="text-secondary col-3 text-right">Осталось для выплаты по выполнению:</div>
							<div className="col-1">120 000 р.</div>

							<div className="text-secondary col-1 text-right">Сумма:</div>
							<div className="col-1">220 000 р.</div>

							<div className="col-2 text-right">
								<AdminOrderListFinanceStatus id="OP" text="Ожидает оплаты"/>
							</div>
						</Paper>
					</li>
					<li className="AdminOrderList__finance-li mt-2">
						<Paper
							className="h-100 d-flex align-items-center py-2 px-3">

							<div className="text-secondary col-1">Компания №3</div>
							<div className="col-3">ООО "Кирпичная кладка"</div>

							<div className="text-secondary col-3 text-right">Осталось для выплаты по выполнению:</div>
							<div className="col-1">150 000 р.</div>

							<div className="text-secondary col-1 text-right">Сумма:</div>
							<div className="col-1">360 000 р.</div>

							<div className="col-2 text-right">
								<AdminOrderListFinanceStatus id="OP" text="Ожидает оплаты"/>
							</div>
						</Paper>
					</li>
				</ul>
			</div>
			<div className="col-12 p-0 d-flex justify-content-end align-items-center p-3">
				<div className="font-weight-bold col-3 text-right">
					Итого:
				</div>
				<div className="font-weight-bold col-2">
					350 000 р.
				</div>
				<div className="font-weight-bold col-3">
					700 000 р.
				</div>
			</div>
		</div>
	);
};

export default AdminOrderFinancePaysCompany;