import React from "react";
import parse from "html-react-parser";
import EstimateOrderMaterialRow from "./EstimateOrderMaterialRow";
import { getServicePrice, getTotalMaterialsPricePerOner } from "../calculateFunctions";
import NumberFormat from "react-number-format";

export default function EstimateOrderServiceRow({
  service,
  options,
  parentIndex,
  index,
  count,
}) {
  const workPrice =
    getServicePrice(service, options.workType.value, options);
  const materialsPrice =
    getTotalMaterialsPricePerOner(service);
  const totalPrice =
    options.supplyMaterials.value === "customer"
      ? workPrice * (service.count || count)
      : (workPrice + materialsPrice)  * (service.count || count);
  return (
    <>
      <tr>
        <td className={`${service.index ? "text-center" : "text-right"}`}>
          {service.index || `${parentIndex}.${index}`}
        </td>
        <td className={`${service.index ? "" : "pl-4"} text-left`}>
          {service.name}
        </td>
        <td>{service.unit ? parse(service.unit) : "-"}</td>
        <td>{service.count || count}</td>
        {options.supplyMaterials.value === "performer" && (
          <td>
            {
              <NumberFormat
                value={materialsPrice.toFixed(2)}
                displayType={"text"}
                thousandSeparator={" "}
              />
            }
          </td>
        )}
        <td>
          {
            <NumberFormat
              value={workPrice.toFixed(2)}
              displayType={"text"}
              thousandSeparator={" "}
            />
          }
        </td>
        <td>
          {
            <NumberFormat
              value={totalPrice.toFixed(2)}
              displayType={"text"}
              thousandSeparator={" "}
            />
          }
        </td>
      </tr>
      {options.supplyMaterials.value === "performer" &&
        service.materials.map((material) => (
          <EstimateOrderMaterialRow
            material={material}
            inParent={!!typeof service.index}
          />
        ))}
      {service.items?.map((item, index) => (
        <EstimateOrderServiceRow
          service={item}
          parentIndex={service.index}
          index={index + 1}
          count={service.count}
          options={options}
        />
      ))}
    </>
  );
}
