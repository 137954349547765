import React from 'react';

const AdminOrderListFinanceStatus = ({id, text}) => {

	return (
		<div className={`AdminOrderList__status ${id}`}>
			<div className="AdminOrderList__status-indicator"/>
			<div>{text}</div>
		</div>
	);
};

export default AdminOrderListFinanceStatus;