import React from 'react'
import './OrderStatus.scss'

export default ({status, children}) => {
	const {id, name, code} = status;
	return (
		<div className={`OrderStatus_status OrderStatus_status-${code || id}`}>
			<div>{name}</div>
			{children}
		</div>
	)
}