import React, {useState} from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import * as moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {setMasterRequest} from "../../../../store/actions/workerPersonalActions";
import AdminSendLoader from "../../../Admin/AdminComponents/AdminUI/AdminSendLoader";

const Age = ({birthDay}) => {

    let years = moment().diff(moment(birthDay, 'DD.MM.YYYY'), 'year');
    let year = years.toString().split('').pop();
    let postFix = 'лет';

    switch (year) {
        case '1':
            postFix = 'год';
            break;
        case '2':
        case '3':
        case '4':
            postFix = 'года';
            break;
        default:
            postFix = 'лет';
            break;

    }

    return (
        <span className='px-2'>{years} {postFix}</span>
    )

};
const WorkerPersonalRequestMasters = () => {
        const masterList = useSelector(state => state.workerPersonal.masters);
        const loading = useSelector(state => state.workerPersonal.loading);
        const userMasters = useSelector(state => state.workerPersonal.user.requestMasters);
        const [masters, setMasters] = useState(userMasters);
        const dispatch = useDispatch();

        const checkBoxHandler = (value) => {
            let changedItemId = +value;
            let changedItemIdx = masters.findIndex(id => id === changedItemId)
            if (changedItemIdx === -1) {
                setMasters([...masters, changedItemId])
            } else {
                setMasters([
                    ...masters.slice(0, changedItemIdx),
                    ...masters.slice(changedItemIdx + 1)
                ])
            }
        };

        const submitHandler = () => {
            dispatch(setMasterRequest(masters))
        };



        return (
            <Paper className='p-3 mt-2'>
                {
                    loading && <AdminSendLoader/>
                }
                <Typography variant="button" className='p-3'>
                    Доступные бригадиры
                </Typography>

                {
                    !!masterList.length && !!userMasters &&
                    <div className="row">
                        {
                            masterList.map(master => {
                                return (
                                    <div key={master.id} className='col-12 d-flex align-items-center'>
                                        <FormControlLabel
                                            className='w-100'
                                            control={
                                                <Paper className='d-flex align-items-center w-100'>
                                                    <Checkbox
                                                        value={master.id}
                                                        checked={masters.indexOf(master.id) !== -1}
                                                        onChange={(e) => checkBoxHandler(e.target.value)}
                                                        name="checked"
                                                        color="primary"
                                                    />
                                                    <Avatar alt={master.name} src={master.picture}/>
                                                    <Typography variant="overline"
                                                                className='m-3 d-flex border-bottom border-gray'>
                                                        {master.name}
                                                        {
                                                            master.birthday &&
                                                            <Age birthDay={master.birthday}/>
                                                        }
                                                    </Typography>
                                                </Paper>
                                            }
                                            label={''}
                                        />


                                    </div>
                                )
                            })
                        }
                    </div>
                }

                <div className="w-100 mt-2 d-flex p-3 justify-content-end">

                    <Button
                        onClick={submitHandler}
                        variant="contained"
                        color='primary'
                        component='button'
                        type='submit'
                        className='col-12 col-sm-3'>
                        <div>Сохранить</div>
                    </Button>

                </div>

            </Paper>
        );
    }
;

export default WorkerPersonalRequestMasters;