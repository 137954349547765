import React from "react";
import { connect } from "react-redux";
import { renderFormControl } from "../../../../../../../form/formFramework";
import {
  nextStep,
  prevStep, setFormItemValue
} from "../../../../../../../store/actions/estimateActions";
import EstimateButton from "../../../UI/EstimateButton";
import EstimateRadioInput from "../../../UI/EstimateRadioInput";
import EstimateProcess from "../../Process/EstimateProcess";
import "./Estimate-Step2.scss";

const Step2 = (props) => {
  const { setFormItemValue, formcontrols, nextStep, prevStep } = props;
  const stepIsValid =
    formcontrols.startDate.value !== "" && formcontrols.termOfWork.value !== "";

  return (
    <>
    <EstimateProcess/>
      <div className="complex-body">
        {/* <div className="row mt-4">
          <div className="col-12 col-lg-6 d-flex flex-column">
            {renderFormControl(formcontrols.startDate, setFormItemValue)}
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column">
            {renderFormControl(formcontrols.termOfWork, setFormItemValue)}
          </div>
        </div> */}
        <div className="row mt-4">
          <div className="col-12 col-lg-6">
            <div className="estimate-firststep-bottom-item">
              <EstimateRadioInput
                element={formcontrols.workType}
                clickHandler={setFormItemValue}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="estimate-firststep-bottom-item">
              <EstimateRadioInput
                element={formcontrols.supplyMaterials}
                clickHandler={setFormItemValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="complex-footer mt-5">
        <div className="row">
          <div className="col-2 estimate-footer-item pr-0">
            <EstimateButton
              className="back"
              back={true}
              onClick={prevStep}
              text="Назад"
            />
          </div>
          <div className="col-10 estimate-footer-item pl-0">
            <EstimateButton
              onClick={nextStep}
              disabled={!stepIsValid}
              text="Следующий шаг"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  formcontrols: state.estimateOrder.formControls,
});

const actions = {
  setFormItemValue,
  nextStep,
  prevStep,
};

export default connect(mapStateToProps, actions)(Step2);
