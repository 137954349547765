import React from 'react'
import { connect } from 'react-redux'
import Grid from './Grid/Grid'
import Works from './Works/Works'
import './GanttContent.scss'

const GanttContent = ({ works, times }) => {

  return (
    <div className="ganttWrapper">
      <Grid rows={works} columns={times} />
      <Works works={works} times={times} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  works: state.gantt.works,
  times: state.gantt.times,
})
const mapDispatchToprops = {
  
}
export default connect(mapStateToProps, mapDispatchToprops)(GanttContent)