import React, {createContext, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getOrderState, getServiceSelectState} from '../../../store/selectors';
import {setOrderProperty} from '../../../store/actions/orderActions';
import {checkIndividualPrice} from '../../../containers/ServiceRoute/components/ServiceCalculate/functions';
import {getBasePrice} from './serviceFunctions';


export const ConditionsContext = createContext(null);
export const useConditionsContext = () => {
	return useContext(ConditionsContext);
}

const ConditionsContextProvider = ({children}) => {
	const dispatch = useDispatch();
	const {
		conditions
	} = useSelector(getServiceSelectState);
	const {
		docs, classifiers,
		needMaterials, locationObject,
		floor, withInstrument, withEquipment, withHousing, materialPlace,
		currentService, workType, materials, quantity,
	} = useSelector(getOrderState);
	// const [total, setTotal] = useState({
	// 	servicePrice: 0, // цена с накрутками
	// 	materialPrice: 0,
	// 	totalServicePrice: 0, // цена с накрутками * количество
	// });
	const [extraChange, setExtraChange] = useState(1);
	const [basePrice, setBasePrice] = useState(0);
	const [servicePrice, setServicePrice] = useState(0);
	const [materialPrice, setMaterialPrice] = useState(0);
	const [totalServicePrice, setTotalServicePrice] = useState(0);
	const [sum, setSum] = useState(0)

	useEffect(() => {
		if (currentService) {
			let basePrice = (checkIndividualPrice(currentService) || getBasePrice(currentService, workType)).toFixed(2);
			setBasePrice(basePrice);
		}
	}, [currentService, workType])

	useEffect(() => {
		const conditionProperties = {locationObject, floor, withInstrument, withEquipment, withHousing, materialPlace};
		const {
			locationObjectConditions,
			floorConditions,
			instrumentConditions,
			equipmentConditions,
			housingConditions,
			materialPlaceConditions,
		} = conditions;
		Object.keys(conditionProperties).forEach(key => {
			switch (key) {
				case 'locationObject':
					if (!!locationObjectConditions.length && !conditionProperties[key]) {
						dispatch(setOrderProperty(locationObjectConditions[0].id, 'locationObject'))
					}
					break;
				case 'floor':
					if (!!floorConditions.length && !conditionProperties[key]) {
						dispatch(setOrderProperty(floorConditions[0].id, 'floor'))
					}
					break;
				case 'withInstrument':
					if (!!instrumentConditions.length && !conditionProperties[key]) {
						dispatch(setOrderProperty(instrumentConditions[0].id, 'withInstrument'))
					}
					break;
				case 'withEquipment':
					if (!!equipmentConditions.length && !conditionProperties[key]) {
						dispatch(setOrderProperty(equipmentConditions[1].id, 'withEquipment'))
					}
					break;
				case 'withHousing':
					if (!!housingConditions.length && !conditionProperties[key]) {
						dispatch(setOrderProperty(housingConditions[0].id, 'withHousing'))
					}
					break;
				case 'materialPlace':
					if (!!materialPlaceConditions.length && !conditionProperties[key]) {
						dispatch(setOrderProperty(materialPlaceConditions[1].id, 'materialPlace'))
					}
					break;
			}
		})
	}, [conditions, locationObject, floor, withInstrument, withEquipment, withHousing, materialPlace])

	useEffect(() => {
		const conditionProperties = {locationObject, floor, withInstrument, withEquipment, withHousing, materialPlace};
		const {
			locationObjectConditions,
			floorConditions,
			instrumentConditions,
			equipmentConditions,
			housingConditions,
			materialPlaceConditions,
		} = conditions;
		let extraChange = 0;
		const addPercent = (currentConditions, conditionId) => {
			const condition = currentConditions.find(c => c.id === conditionId);
			if (condition) {
				extraChange += +condition.percent;
			}
		}
		Object.keys(conditionProperties).forEach(key => {
			switch (key) {
				case 'locationObject':
					if (!!locationObjectConditions.length) {
						addPercent(locationObjectConditions, conditionProperties[key]);
					}
					break;
				case 'floor':
					if (!!floorConditions.length) {
						addPercent(floorConditions, conditionProperties[key]);
					}
					break;
				case 'withInstrument':
					if (!!instrumentConditions.length) {
						addPercent(instrumentConditions, conditionProperties[key]);
					}
					break;
				case 'withHousing':
					if (!!housingConditions.length) {
						addPercent(housingConditions, conditionProperties[key]);
					}
					break;
				case 'materialPlace':
					if (!!materialPlaceConditions.length) {
						addPercent(materialPlaceConditions, conditionProperties[key]);
					}
					break;
				case 'withEquipment':
					if (!!equipmentConditions.length) {
						addPercent(equipmentConditions, conditionProperties[key]);
					}
					break;
			}
		})

		if (docs === 1) {
			if (!!classifiers.length) {
				classifiers.forEach(classifier => {
					extraChange += +classifier.percent;
				})
			}
		}
		setExtraChange(extraChange)
	}, [locationObject, floor, withInstrument, withEquipment,
		withHousing, docs, classifiers, materialPlace, conditions]);

	useEffect(() => {
		let servicePrice = +(+basePrice + (basePrice * (extraChange / 100))).toFixed(2);
		setServicePrice(servicePrice)
	}, [extraChange, basePrice]);

	useEffect(() => {
		if (currentService) {
			let materialPrice = 0;
			if (needMaterials === 2) {
				materials.forEach((material) => {
					const pack = typeof material.goods.pack !== 'undefined'
						? material.goods.pack
						: material.pack;

					let packageCoefficient = typeof material.goods.package_coefficient !== 'undefined'
						? material.goods.package_coefficient
						: material.package_coefficient
							? material.package_coefficient
							: 1;

					let quantity_in_pack = material.goods.quantity_in_pack ?? material.quantity_in_pack ?? 1
					let packQuantity = Math.ceil(material.quantity / quantity_in_pack);

					if (pack > 0) {
						const price = (((material.goods.price || material.price) / (pack > 0 ? pack : 1)) * packageCoefficient).toFixed(2);
						const packPrice = (price * quantity_in_pack).toFixed(2)
						materialPrice += packPrice * packQuantity;
					}

				});
			}
			setMaterialPrice(+materialPrice.toFixed(2))
		}
	}, [materials, currentService, needMaterials])

	useEffect(() => {
		if (currentService) {
			setTotalServicePrice(+(servicePrice * quantity).toFixed(2))
		}
	}, [currentService, quantity, servicePrice])


	useEffect(() => {
		if (currentService) {
			const totalSum = needMaterials === 2
				? materialPrice + totalServicePrice
				: totalServicePrice;
			setSum(totalSum.toFixed(2))
		}
	}, [materialPrice, totalServicePrice, needMaterials, currentService, basePrice]);


	return (
		<ConditionsContext.Provider value={{
			servicePrice, // стоимость услуги с накрутками
			materialPrice, // стоимость материалов
			totalServicePrice, // стоимость услуг с  учетом накруток и количества
			basePrice, // базовая цена
			sum, // всего
		}}>
			{children}
		</ConditionsContext.Provider>
	);
};

export default ConditionsContextProvider;