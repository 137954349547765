import axios from "../../rest/axios";
import {
	EDIT_CLASSIFIER_ADMIN,
	GET_CLASSIFIERS_ADMIN_ERROR,
	GET_CLASSIFIERS_ADMIN_START,
	GET_CLASSIFIERS_ADMIN_SUCCESS,
	GET_CLASSIFIERS_ROLES_ADMIN_SUCCESS,
} from "./actionTypes";
import qs from "qs";
import { fetchOrderConditions } from "./serviceSelectActions";

export function fetchClassifiers() {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_CLASSIFIERS_ADMIN_START });
			const { data } = await axios.get("/admin/orders/getClassifiers/");
			dispatch({ type: GET_CLASSIFIERS_ADMIN_SUCCESS, payload: { data } });
		} catch (e) {
			dispatch({ type: GET_CLASSIFIERS_ADMIN_ERROR });
		}
	};
}

export function fetchClassifierRoles() {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_CLASSIFIERS_ADMIN_START });
			const { data } = await axios.get("/admin/orders/getClassifierRoles/");
			dispatch({
				type: GET_CLASSIFIERS_ROLES_ADMIN_SUCCESS,
				payload: { data },
			});
		} catch (e) {
			dispatch({ type: GET_CLASSIFIERS_ADMIN_ERROR });
		}
	};
}

export function updateClassifier(
	name,
	description,
	role,
	id = null,
	isDelete = false,
) {
	return async (dispatch) => {
		let token = localStorage.getItem("token");
		if (token) {
			const params = {
				name,
				description,
				role,
				token,
			};
			if (isDelete) {
				params.isDelete = true;
			}
			if (id) {
				params.id = id;
			}
			try {
				dispatch({ type: GET_CLASSIFIERS_ADMIN_START });
				const { data } = await axios.post(
					"/admin/orders/updateClassifier/",
					qs.stringify(params),
				);
				dispatch(fetchClassifiers());
			} catch (e) {
				dispatch({ type: GET_CLASSIFIERS_ADMIN_ERROR });
			}
		}
	};
}

export function updateOrderSettings(condition, conditionName) {
	return async (dispatch) => {
		let token = localStorage.getItem("token");
		if (token) {
			try {
				const { data } = await axios.post(
					"/admin/orders/updateOrderSettings/",
					qs.stringify({
						condition,
						conditionName,
						token,
					}),
				);
				dispatch(fetchOrderConditions());
			} catch (e) {}
		}
	};
}

export function editClassifier(id, value = null, property = null) {
	return { type: EDIT_CLASSIFIER_ADMIN, payload: { id, value, property } };
}
