import React, { Component } from "react";
import "./PersonalOrderListItem.scss";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import OrderStatus from "../../../../../../components/UI/OrderStatus/OrderStatus";
import {
	cancelOrder,
	setOrderListProperty,
} from "../../../../../../store/actions/orderListActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

class PersonalOrderListItem extends Component {
	renderDate = () => {
		let date = "";
		switch (+this.props.status.id) {
			case 1:
			case 3:
				date = "Дата начала " + this.props.startDate;
				break;
			default:
				date = "Дата окончания " + this.props.finishDate;
		}
		return <div className='PersonalOrderItem_date'>{date}</div>;
	};

	render() {
		const {
			id,
			serviceName,
			status,
			cancelOrder,
			bCanCancel,
			bCanceled,
			totalPriceFormat,
		} = this.props;

		return (
			<li className='PersonalOrderItem mt-1 px-3'>
				<NavLink
					to={`/personal/orders/${id}`}
					className='col-8 p-3 d-flex align-items-center'>
					<div>
						<strong>Заказ № {id}</strong>
					</div>
					<div className='PersonalOrderItem_name'>{serviceName}</div>
					<div className='PersonalOrderItem_sum'>
						Сумма заказа: <strong>{totalPriceFormat} р</strong>.
					</div>

					{/*{*/}
					{/*+this.props.status.id === 1*/}
					{/*?*/}
					{/*<div className="PersonalOrderItem_payment"><span>Обработка средств банком</span></div>*/}
					{/*: null*/}
					{/*}*/}
				</NavLink>

				<div className={`col-${bCanCancel && !bCanceled ? 2 : 4}`}>
					{/*<div className={`col-4`}>*/}
					<div className='PersonalOrderItem_status'>
						<OrderStatus status={status}>{this.renderDate()}</OrderStatus>
					</div>
				</div>

				{/*<div className="col-1 PersonalOrderItem_dialog">*/}
				{/*    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
				{/*        <path*/}
				{/*            d="M14 0C6.26257 0 0 6.26151 0 14C0 16.4556 0.640442 18.8539 1.8566 20.9692L0.0527649 26.5709C-0.0730591 26.9614 0.0303345 27.3895 0.320435 27.6796C0.607758 27.9669 1.03479 28.0741 1.42914 27.9472L7.03076 26.1434C9.14606 27.3596 11.5444 28 14 28C21.7374 28 28 21.7385 28 14C28 6.26257 21.7385 0 14 0ZM14 25.8125C11.782 25.8125 9.61987 25.1936 7.74747 24.0228C7.47809 23.8544 7.14355 23.8089 6.83231 23.9091L2.78864 25.2114L4.09088 21.1677C4.18958 20.8609 4.14792 20.526 3.97702 20.2525C2.80637 18.3801 2.1875 16.218 2.1875 14C2.1875 7.48663 7.48663 2.1875 14 2.1875C20.5134 2.1875 25.8125 7.48663 25.8125 14C25.8125 20.5134 20.5134 25.8125 14 25.8125ZM15.3672 14C15.3672 14.7549 14.7552 15.3672 14 15.3672C13.2448 15.3672 12.6328 14.7549 12.6328 14C12.6328 13.2448 13.2448 12.6328 14 12.6328C14.7552 12.6328 15.3672 13.2448 15.3672 14ZM20.8359 14C20.8359 14.7549 20.2239 15.3672 19.4688 15.3672C18.7136 15.3672 18.1016 14.7549 18.1016 14C18.1016 13.2448 18.7136 12.6328 19.4688 12.6328C20.2239 12.6328 20.8359 13.2448 20.8359 14ZM9.89844 14C9.89844 14.7549 9.28641 15.3672 8.53125 15.3672C7.77631 15.3672 7.16406 14.7549 7.16406 14C7.16406 13.2448 7.77631 12.6328 8.53125 12.6328C9.28641 12.6328 9.89844 13.2448 9.89844 14Z"*/}
				{/*            fill="#8765D7"/>*/}
				{/*    </svg>*/}
				{/*</div>*/}

				{bCanCancel && !bCanceled && (
					<div
						className='col-2 PersonalOrderItem__cancel font-weight-bold'
						onClick={() => cancelOrder(id)}>
						<HighlightOffIcon className='mr-2' />
						Отменить заказ
					</div>
				)}
			</li>
		);
	}
}

const actions = {
	setOrderListProperty,
	cancelOrder,
};

export default withRouter(connect(null, actions)(PersonalOrderListItem));
