import {
	ADMIN_ORDER_SEARCH_MANAGERS_SUCCESS,
	FETCH_ADMIN_ORDER_DETAIL_START,
	FETCH_ADMIN_ORDER_DETAIL_SUCCESS, FETCH_ADMIN_ORDER_PAYS_SUCCESS,
	FETCH_ADMIN_ORDER_START,
	FETCH_ADMIN_ORDER_SUCCESS,
	FETCH_ADMIN_ORDER_USERS_START,
	FETCH_ADMIN_ORDER_USERS_SUCCESS, SET_ORDER_LIST_FORM_CONTROL_VALUE, TIMESHEETS_MASTER_REPORT_SUCCESS
} from "../actions/actionTypes";
import {
	ROLE_ADMINISTRATORS,
	ROLE_ADMINS, ROLE_ENGINEER_PTO,
	ROLE_GEODESIST,
	ROLE_INDEPENDENT_MASTER,
	ROLE_MANAGER,
	ROLE_MASTER, ROLE_SUPPLIER
} from "../../config";
import * as moment from 'moment';

const initialState = {
	loading: false,
	userLoading: false,
	orders: [],
	navigation: {},
	managers: [],
	masterList: [],
	timesheetsMasterReport: {},
	header: [
		{
			id: 1,
			title: '# [id]',
			col: 1
		},
		{
			id: 2,
			title: 'Наименование',
			col: 3
		},
		{
			id: 3,
			title: 'Количество',
			col: 1
		},
		{
			id: 4,
			title: 'Дата',
			subtitle: 'начала',
		},
		{
			id: 5,
			title: 'Дата',
			subtitle: 'завершения',
		},
		{
			id: 6,
			title: 'Общая',
			subtitle: 'сумма, ₽',
		},
		{
			id: 7,
			title: 'Статус',
			col: 2
		},
	],
	navTabs: [
		{
			id: 1,
			name: 'Общая информация',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		},
		{
			id: 2,
			name: 'Исполнители',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		},
		{
			id: 3,
			name: 'Онлайн выполнение',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS, ROLE_GEODESIST, ROLE_SUPPLIER, ROLE_ENGINEER_PTO],
		},
		{
			id: 4,
			name: 'Финансы',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		},
		{
			id: 5,
			name: 'Аналитика',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		}
	],
	navTabsFinance: [
		{
			id: 1,
			name: 'Заказчик'
		},
		{
			id: 2,
			name: 'Исполнители'
		},
		{
			id: 3,
			name: 'Оплатить исполнителям',
		},
	],
	employersTabsFinance: [
		{
			id: 1,
			name: 'Бригадиры'
		},
		// {
		// 	id: 2,
		// 	name: 'Компании'
		// }
	],
	onlineTabsFinance: [
		{
			id: 1,
			name: 'Текущее задание',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		},
		{
			id: 2,
			name: 'Прием работ',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		},
		{
			id: 3,
			name: 'Сдача работ',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS, ROLE_GEODESIST, ROLE_SUPPLIER, ROLE_ENGINEER_PTO],
		},
		{
			id: 4,
			name: 'Онлайн ТАБЛО',
			roles: [ROLE_ADMINS, ROLE_ADMINISTRATORS],
		}
	],
	order: {},
	pays: [],
	formControls: {
		quantity: {
			id: 'quantity',
			label: 'Сумма оплаты',
			type: 'text',
			value: 0,
			valid: true,
			error: '',
			validation: {
				required: true,
				number: true,
			},
			errorMessage: {
				required: 'Обязательное поле',
				number: 'Введите число',
			},
		},
		payDate: {
			id: 'payDate',
			label: 'Дата оплаты',
			type: 'date',
			value: moment().format('DD.MM.YYYY'),
			valid: true,
			validation: {
				//		minDate: 0
			},
			errorMessage: {
				//	minDate: 'Не ранее, чем через 3 дня',
			},
		},
	},
	error: null,
}

const adminOrderListReducer = (state = initialState, {type, payload}) => {

	switch (type) {
		case FETCH_ADMIN_ORDER_START:
			return {
				...state,
				loading: true
			}
		case FETCH_ADMIN_ORDER_PAYS_SUCCESS:
			return {
				...state,
				pays: payload.data.data,
				loading: false
			}
		case FETCH_ADMIN_ORDER_SUCCESS:
			let ordersWithUsers = payload.items.map(item => {
				let order = {...item};
				order.users = {
					"manager": {},
					"masters": [],
					"mastersInd": []
				}
				return order
			});
			return {
				...state,
				orders: ordersWithUsers,
				navigation: payload.navigation,
				loading: false
			};
		case ADMIN_ORDER_SEARCH_MANAGERS_SUCCESS:
			return {
				...state,
				managers: payload.managers,
				masterList: payload.masterList
			};
		case FETCH_ADMIN_ORDER_USERS_START:
			return {
				...state,
				userLoading: true
			}
		case FETCH_ADMIN_ORDER_USERS_SUCCESS:
			const orders = [...state.orders];
			let orderUsers = getUsers(payload.items, payload.groups);
			if (!!orderUsers.length) {
				let order = orders.find(order => order.id === payload.orderId);
				let manager = orderUsers.find(user => user.role.id === ROLE_MANAGER) || {};
				let masters = orderUsers.filter(user => user.role.id === ROLE_MASTER);
				let mastersInd = orderUsers.filter(user => user.role.id === ROLE_INDEPENDENT_MASTER);
				order.users = {manager, masters, mastersInd};
			}
			return {
				...state,
				orders,
				userLoading: false
			};
		case FETCH_ADMIN_ORDER_DETAIL_START:
			return {
				...state,
				orderLoading: true
			}
		case FETCH_ADMIN_ORDER_DETAIL_SUCCESS:
			return {
				...state,
				order: payload.order
			}
		case SET_ORDER_LIST_FORM_CONTROL_VALUE:
			const formControls = JSON.parse(JSON.stringify(state.formControls))
			formControls[payload.property].value = payload.value
			return {
				...state,
				formControls
			}
		case TIMESHEETS_MASTER_REPORT_SUCCESS:
			return {
				...state,
				timesheetsMasterReport: payload
			}
		default:
			return state;
	}
}

export default adminOrderListReducer;

const getUsers = (users, groups) => {


	const getUserRole = (groupIds, groups) => {
		switch (true) {
			case groupIds.indexOf(ROLE_MASTER) >= 0 :
				return groups.find(group => group.id === ROLE_MASTER);
			case groupIds.indexOf(ROLE_MANAGER) >= 0 :
				return groups.find(group => group.id === ROLE_MANAGER);
			case groupIds.indexOf(ROLE_INDEPENDENT_MASTER) >= 0 :
				return groups.find(group => group.id === ROLE_INDEPENDENT_MASTER);
			default:
				return false;
		}
	}


	return users.map(user => {
			let newUser = {...user};
			let role = getUserRole(user.groupIds, groups);
			if (role)
				newUser.role = role;
			return newUser
		}
	)
}
