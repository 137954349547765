import React from 'react'
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

export default function Developing() {
  return (
    <div className="Calculator__content-body-in-development">
      <div>Раздел в разработке</div>
      <ClimbingBoxLoader color="#7B53DA"/>
    </div>
  )
}
