import {SET_COMPLEX_FORMCONTROL} from "../actions/actionTypes";

const iniialState = {
    formControls: {
        user: {
            id: 'user',
            label: 'Контактное лицо',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Обязательное поле',
            },
        },
        phone: {
            id: 'phone',
            label: 'Введите Ваш телефон',
            type: 'tel',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: false,
                phone: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
                phone: `Введите корректный номер телефона`
            },
        },
        email: {
            id: 'email',
            label: 'Введите Ваш email',
            type: 'text',
            value: '',
            valid: true,
            validation: {
                email: true
            },
            errorMessage: {
                email: `Введите корректный email`
            },
        },
        address: {
            id: 'address',
            label: 'Адрес объекта',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        dateTime: {
            id: 'dateTime',
            label: 'Дата и время',
            type: 'date',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
                dateTime: true
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        task: {
            id: 'task',
            label: 'Опишите задачу',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
        project: {
            id: 'project',
            label: 'Опишите Ваш проект',
            type: 'text',
            value: '',
            valid: true,
            error: '',
            validation: {
                required: true,
            },
            errorMessage: {
                required: 'Это поле необходимо заполнить',
            },
        },
    },
    startValidate: false,
    loading: false,
    error: null
}

function complexReducer(state = iniialState, {type, payload}) {
    const formControls = {...state.formControls}
    switch (type) {
        case SET_COMPLEX_FORMCONTROL:
            let formControl = Object.values(formControls).find(item => item.id === payload.id)

            formControl.value = payload.value
            return {
                ...state,
                formControls
            }
        default:
            return {
                ...state
            }
    }


}

export default complexReducer