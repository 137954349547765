import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../../../components/UI/Loader/Loader";
import WorkerPersonalReportsTotal from "../../../Worker/WorkerPersonal/WorkerPersonalReports/WorkerPersonalReportsTotal";
import WorkerPersonalReportsLeftSide from "../../../Worker/WorkerPersonal/WorkerPersonalReports/WorkerPersonalReportsLeftSide";
import DraggableScroll from "../../../../components/UI/DraggableScroll/DraggableScroll";
import WorkerPersonalReportsModal from "../../../Worker/WorkerPersonal/WorkerPersonalReports/WorkerPersonalReportsModal";
import WorkerPersonalReportsPhotos from "../../../Worker/WorkerPersonal/WorkerPersonalReports/WorkerPersonalReportsPhotos";
import * as moment from "moment";
import WorkerPersonalReportStatus from "../../../Worker/WorkerPersonal/WorkerPersonalReports/UI/WorkerPersonalReportStatus";
import Typography from "@material-ui/core/Typography";
import { ROLE_MANAGER, ROLE_MASTER } from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { getWorkerPersonalState } from "../../../../store/selectors";

const AdminWorkerPersonalReportsDays = ({ masterId }) => {
	const { order, reports, user } = useSelector(getWorkerPersonalState);
	const [dayReport, setDayReport] = useState(null);
	const [report, setReport] = useState(null);
	const [masterReports, setMasterReports] = useState([]);
	const [currentDay, setCurrentDay] = useState();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const getTable = () => {
		// обработка дат
		let startDate = moment(order.startDate, "DD.MM.YYYY");
		// количество дней
		let days = moment().diff(startDate, "days");
		days = !!days ? days : 1;
		const table = [];
		// План
		const userReports = masterReports.filter((report) => {
			return report.master === masterId || report.master === user.id;
		});
		const reportedDates = userReports.map((report) => report.date);
		const today = moment().format("DD.MM.YYYY");
		for (let i = 0; i <= days; i++) {
			const day = moment(startDate, "DD.MM.YYYY")
				.add(i, "day")
				.format("DD.MM.YYYY");
			const isDayArrive = moment().diff(moment(day, "DD.MM.YYYY"), "days") >= 0;
			let isReported = reportedDates.indexOf(day) !== -1;
			const report = userReports.find((report) => report.date === day);
			const status =
				isDayArrive && !report ? 4 : !!report ? report.status : null;

			table.push(
				<Fragment key={day}>
					<div className={`wp_RightBlock__item`}>
						<div
							onClick={() => setCurrentDay(day)}
							className={`wp_RightBlock__item_head`}>
							{/* табличка дня сверху */}
							<div
								className={`${isReported ? "reported" : ""} ${
									currentDay === day ? "AdminOrderList__active-date active" : ""
								}`}>
								<strong>{day}</strong>
								<span className='text-capitalize'>
									{moment(startDate, "DD.MM.YYYY").add(i, "d").format("dd")}
								</span>
								<WorkerPersonalReportStatus status={status} />
							</div>
						</div>

						<div
							className={`px-2 border-main-4 border-bottom d-flex align-items-center justify-content-center`}>
							{/* количество в день - план */}
							<div
								className={`wp__row-top bg-white d-flex align-items-end justify-content-center w-100 py-3`}>
								<Typography variant='button' className='pt-4'>
									{Math.ceil(order.serviceValue / days)}
								</Typography>
							</div>
						</div>
						<div
							className={`px-2 border-main-2-1  border-bottom border-main-1-3 d-flex align-items-center justify-content-center`}>
							{/* план по материалу - план */}
							<div
								className={`wp__row-bottom bg-white d-flex flex-column align-items-center w-100`}>
								<Typography
									variant='button'
									className='border-main-1-3 border-bottom d-flex align-items-center justify-content-center py-3'>
									{!!report ? (
										<>
											{
												+(
													report.services[0].reportedValue
														? report.services[0].reportedValue
														: report.services[0].value
												).toFixed(2)
											}
										</>
									) : (
										0
									)}
								</Typography>
								<Typography
									variant='button'
									className='d-flex align-items-center justify-content-center py-3'>
									{!!report && report.status > 1 ? (
										<>{+report.services[0].value.toFixed(2)}</>
									) : (
										0
									)}
								</Typography>
							</div>
						</div>
					</div>
				</Fragment>,
			);
		}
		return table;
	};

	useEffect(() => {
		setMasterReports(
			masterId
				? reports.filter((report) => report.master === masterId)
				: reports,
		);
		return () => {
			setMasterReports([]);
		};
	}, [reports]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false);
		}, 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<>
			{loading ? (
				<>
					<Loader />
				</>
			) : (
				<>
					<WorkerPersonalReportsTotal
						reports={masterReports}
						order={order}
						// name={masterId ? order.workers.find(worker => worker.id === masterId).name : user.name}
					/>
					<div className='PersonalOrderItemTranches'>
						<div className='wp_LeftBlock'>
							<WorkerPersonalReportsLeftSide order={order} />
						</div>
						<DraggableScroll>
							<div className='wp_RightBlock pb-3'>{getTable()}</div>
						</DraggableScroll>
					</div>
					<WorkerPersonalReportsPhotos
						user={user}
						masterId={masterId}
						order={order}
						reports={reports}
						currentDay={currentDay}
						setCurrentDay={setCurrentDay}
					/>
				</>
			)}
		</>
	);
};

export default AdminWorkerPersonalReportsDays;
