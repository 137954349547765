import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
	renderFormControl,
	validateControl,
} from "../../../../../form/formFramework";
import {
	inspectionFormSetFieldError,
	inspectionFormSetFieldValue,
	inspectionFormSendMessage,
} from "../../../../../store/reducers/inspectionFormReducer";
import "../Complex.scss";
import EstimateButton from "../UI/EstimateButton";
import cn from "./Inspection.module.scss";

const Inspection = ({
	inspectionForm: { formControls },
	inspectionFormSetFieldValue,
	inspectionFormSetFieldError,
	inspectionFormSendMessage,
}) => {
	const [formTouched, setFormTouched] = useState(false);
	const [formValid, setFormValid] = useState(false);
	const form = useRef();
	const formValidHandler = () => {
		let formValid = true;
		Object.keys(formControls).forEach((property) => {
			const formControl = formControls[property];
			const error = validateControl(
				formControl.value,
				formControl.validation,
				property,
			);
			const valid = !error;
			if (error) inspectionFormSetFieldError(error, property);
			formValid = valid && formValid;
		});
		return formValid;
	};
	const submitFormHandler = (e) => {
		e.preventDefault();
		setFormTouched(false);
		setFormValid(formValidHandler());
		if (formValidHandler()) {
			let formData = new FormData();
			Object.keys(formControls).forEach((fieldId) => {
				if (formControls[fieldId].value !== "")
					formData.append(fieldId, formControls[fieldId].value);
			});
			inspectionFormSendMessage(formData);
		}
	};
	const setFormFieldValue = (value, id) => {
		setFormTouched(true);
		inspectionFormSetFieldError(false, id);
		inspectionFormSetFieldValue(value, id);
	};

	return (
		<>
			<div className='complex-body'>
				<form ref={form} className='row'>
					<div className='col-12'>
						<h4 className={cn.title}>
							Заполните заявку и наш специалист приедет на участок для оценки
							работ
						</h4>
					</div>
					<div className='item col-12 col-sm-6'>
						{renderFormControl(formControls.name, setFormFieldValue)}
					</div>
					<div className='item col-12 col-sm-6'>
						{renderFormControl(formControls.phone, setFormFieldValue)}
					</div>
					<div className='item col-12 col-sm-6'>
						{renderFormControl(formControls.address, setFormFieldValue)}
					</div>
					<div className='item col-12 col-sm-6'>
						{renderFormControl(formControls.date, setFormFieldValue)}
					</div>
				</form>
			</div>
			<div className='complex-footer'>
				<div className='row'>
					<div className='col-12 estimate-footer-item'>
						<EstimateButton
							onClick={submitFormHandler}
							disabled={!formValid && !formTouched}
							text='Отправить заявку'
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	inspectionForm: state.inspectionForm,
});
const mapDispatchToprops = {
	inspectionFormSetFieldValue,
	inspectionFormSetFieldError,
	inspectionFormSendMessage,
};
export default connect(mapStateToProps, mapDispatchToprops)(Inspection);
