import React, {useEffect} from 'react';
import './WorkerPersonal.scss';
import Page from "../../../hoc/Page/Page";
import WorkerPersonalNav from "./WorkerPersonalNav/WorkerPersonalNav";
import WorkerPersonalContent from "./WorkerPersonalContent/WorkerPersonalContent";
import {WorkerTopNav} from "../WorkerComponents";
import {fetchMasters, workerPersonalFetch} from "../../../store/actions/workerPersonalActions";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {getIsLogin, getUser} from "../../../store/selectors";
import {ROLE_ANCILLARY,  ROLE_SPECIALIST} from "../../../config";


const WorkerPersonal = () => {
    const user = useSelector(getUser);
    const isLogin = useSelector(getIsLogin);
    const masters = useSelector(state => state.workerPersonal.masters);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.id && !user.name && isLogin) {
        dispatch(workerPersonalFetch());
        }
    }, [user, isLogin, dispatch]);

    useEffect(() => {
        if (!masters.length) {
            if (user.role === ROLE_SPECIALIST || user.role === ROLE_ANCILLARY) {
                dispatch(fetchMasters())
            }
        }
    }, [dispatch, user, masters]);

    if (!isLogin) {
        return (
            <Redirect to={'/personal'}/>
        )
    }


    const title = `Личный кабинет`;

    return (
        <Page title={title}>
            <WorkerTopNav title={title}/>
            <WorkerPersonalNav/>
            <WorkerPersonalContent/>
        </Page>
    );
}


export default WorkerPersonal;