import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAdminOrderListTasksState } from "../selectors";
import { fetchAdminOrderTasks } from "../../../../../store/actions/adminOrderListTasksActions";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import AdminOrderListDetail from "./AdminOrderListDetail";
import AdminOrderListDetailTasksRow from "./AdminOrderListDetailTasksRow";
import AdminOrderListDetailRoom from "./AdminOrderListDetailRoom";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const AdminOrderListDetailTasks = ({ order }) => {
	const [openRoom, setOpenRoom] = useState(false);
	const [room, setRoom] = useState(false);
	const dispatch = useDispatch();
	const { workers } = useSelector(getAdminOrderListTasksState);
	useEffect(() => {
		dispatch(fetchAdminOrderTasks(order.id));
	}, []);
	const closeHandler = () => {
		setOpenRoom(false);
	};
	const openRoomHandler = (room) => {
		setOpenRoom(true);
		setRoom(room);
	};

	return (
		<>
			<div className='mt-5 px-3'>
				<TableContainer component={Paper}>
					<Table aria-label='collapsible table'>
						<Paper component={"thead"}>
							<TableRow className='AdminOrderList__table-head'>
								<TableCell className='AdminOrderList__cell px-3 py-2 border-right'>
									<Typography align='center'>№ ТЗ</Typography>
								</TableCell>
								<TableCell
									align='left'
									className='AdminOrderList__cell p-3 large border-right'>
									<Typography>Наименование ТЗ</Typography>
								</TableCell>
								<TableCell
									align='left'
									className='AdminOrderList__cell p-3 border-right'>
									<Typography>Дата оформления</Typography>
								</TableCell>
								<TableCell
									align='left'
									className='AdminOrderList__cell p-3 border-right'>
									<Typography>Срок выполнения</Typography>
								</TableCell>
								<TableCell
									align='left'
									className='AdminOrderList__cell p-3 border-right'>
									<Typography>Кол-во</Typography>
								</TableCell>
								<TableCell
									align='left'
									className='AdminOrderList__cell p-3 border-right'>
									<Typography>Ед. изм.</Typography>
								</TableCell>
								<TableCell
									align='left'
									className='AdminOrderList__cell p-3 large border-right'>
									<Typography>Бригада</Typography>
								</TableCell>
								<TableCell align='left' className='AdminOrderList__cell p-3'>
									<Typography>Статус</Typography>
								</TableCell>
								<TableCell className='AdminOrderList__cell p-3' />
							</TableRow>
						</Paper>
						<TableBody>
							{workers.map((worker) => {
								return (
									<Fragment key={worker.id}>
										<TableRow>
											<TableCell
												align='justify'
												className='AdminOrderList__row-title p-2 bg-light'
												colSpan={12}>
												<Typography>{worker.name}</Typography>
											</TableCell>
										</TableRow>
										{worker.tasks.map((task, idx) => (
											<AdminOrderListDetailTasksRow
												key={task.id || idx}
												task={task}
												idx={idx}
												worker={worker}
												order={order}
												openRoomHandler={openRoomHandler}
											/>
										))}
									</Fragment>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			{openRoom && (
				<AdminOrderListDetail
					closeHandler={closeHandler}
					title={`Зона #${room.id}`}>
					<AdminOrderListDetailRoom roomData={room} order={order} />
				</AdminOrderListDetail>
			)}
		</>
	);
};

export default AdminOrderListDetailTasks;
