import React from 'react';
import './AdminGoodsCard.scss';


import noPhoto from '../../../../../img/no-photos.jpg'
import {Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router-dom";
import parse from 'html-react-parser';


const AdminGoodsCard = ({good, history}) => {
	const {id, name, pictures, price, fullName, weight, isNew, unit, active, quantity_for_price} = good;
	const img = pictures.length ? pictures[0].src : noPhoto;

	const detailHandler = () => {
		history.push('/admin/goods/' + id);
	}

	return (
		<div className='col-12 col-sm-6 col-md-4 my-3'>
			<div className="catalog-card">
				<div className="catalog-card__header">

					<div className='catalog-card__price'>
						{
							!!quantity_for_price &&
							<span className='ml-2'> {quantity_for_price} {parse(unit.trim() || '')}</span>
						}
					</div>

					<div className='catalog-card__price'>{price || 0}
						{
							!isNew &&
							<>
								<strong className='ml-2'> р. </strong>
								{/*<strong className='ml-2'> ₽ / {parse(unit)}</strong>*/}
							</>
						}
					</div>


				</div>
				<hr/>
				<img src={img} alt={name} className='catalog-card__picture'/>
				<hr/>
				<div className='catalog-card__title'>
					{name}
					{
						!isNew && <>[ id: {id} ]</>
					}
				</div>
				<hr/>
				<div className='catalog-card__text'>
					{fullName}
				</div>
				<hr/>
				<div className='catalog-card__footer'>
					{
						active
							? <span className="text-success h6">Активен</span>
							: <span className="text-danger h6">Неактивен</span>
					}
					<Button
						onClick={detailHandler}
						className="Admin_btn text "
						variant="contained"
						color="primary">
						<Typography variant="overline" display="block">
							Подробнее
						</Typography>
					</Button>

				</div>


			</div>

		</div>
	)
		;
};

export default withRouter(AdminGoodsCard);