import React, { useEffect, useState } from "react";
import "./AdminOrderList.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchAdminOrderDetail,
	fetchAdminOrderList,
	fetchAdminOrderUsersList,
} from "../../../../store/actions/adminOrderListActions";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import Pagination from "@material-ui/lab/Pagination";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import AdminOrderListUsers from "./AdminOrderListUsers";
import AdminOrderListHeader from "./AdminOrderListHeader";
import Loader from "../../../../components/UI/Loader/Loader";
import AdminOrderListInfo from "./AdminOrderListInfo";
import AdminOrderListFinance from "./AdminOrderListFinance/AdminOrderListFinance";
import OrderStatus from "../../../../components/UI/OrderStatus/OrderStatus";
import { getAdminOrderListState } from "./selectors";
import AdminOrderListOnline from "./AdminOrderListOnline";
import { getAdminState } from "../AdminLeftNav/selectors";
// import {ROLE_ADMINISTRATORS, ROLE_ADMINS, ROLE_ENGINEER_PTO, ROLE_GEODESIST, ROLE_SUPPLIER} from '../../../../config';
import AdminOrderListDetail from "./AdminOrderListDetail/AdminOrderListDetail";
import AdminOrderListAnalytics from "./AdminOrderListAnalytics";

const AdminOrderList = () => {
	const dispatch = useDispatch();
	const { orders, navigation, loading, header, navTabs } = useSelector(
		getAdminOrderListState,
	);
	const { user } = useSelector(getAdminState);
	const [currentOrderId, setCurrentOrderId] = useState(null);
	const [currentTab, setCurrentTab] = useState(null);
	const [page, setPage] = useState(1);
	const [tabs, setTabs] = useState([]);

	const handleChange = (id) => (event, isExpanded) => {
		setCurrentOrderId(isExpanded ? id : false);
		setCurrentTab(tabs[0].id);
		if (isExpanded) {
			dispatch(fetchAdminOrderDetail(id));
		}
	};

	const setCurrentTabHandler = (event, newValue) => {
		setCurrentTab(newValue);
		if (newValue === 2) {
			let order = orders.find((order) => order.id === currentOrderId);
			if (!order.users.manager.id) {
				dispatch(fetchAdminOrderUsersList(currentOrderId));
			}
		}
	};

	const pageHandler = (evt, value) => {
		// const r = ((2))
		setPage(value);
		setCurrentTab(tabs[0].id);
		setCurrentOrderId(null);
	};

	useEffect(() => {
		dispatch(fetchAdminOrderList(page));
	}, [dispatch, page]);
	useEffect(() => {
		const tabs = navTabs.filter((tab) => tab.roles.includes(user.role));
		setCurrentTab(tabs[0].id);
		setTabs(tabs);
	}, [navTabs, user]);

	return (
		<div>
			<AdminOrderListHeader header={header} />

			{orders.map((order) => {
				const active = currentOrderId === order.id;

				return (
					<Accordion
						TransitionProps={{ unmountOnExit: true }}
						className={`w-100 ${active ? "Admin_categories" : ""}`}
						key={order.id}
						expanded={active}
						onChange={handleChange(order.id, 3)}>
						<AccordionSummary
							aria-controls='panel1bh-content'
							id='panel1bh-header'
							className='bg-admin-acc-1'>
							<div
								className={`w-100 d-flex align-items-center ${
									active ? "text-white" : ""
								} row  px-3 py-2 `}>
								<Typography variant='subtitle1' className='col-1 p-0'>
									#{order.id}
								</Typography>
								<Typography variant='subtitle1' className='col-3 p-0'>
									{order.serviceName}
								</Typography>
								<Typography variant='subtitle1' className='col-1 p-0'>
									{order.serviceValue}
								</Typography>
								<Typography variant='subtitle1' className='col p-0'>
									{order.startDate}
								</Typography>
								<Typography variant='subtitle1' className='col p-0'>
									{order.finishDate}
								</Typography>
								<Typography variant='subtitle1' className='col p-0'>
									{/*{`${order.priceFormat ? order.priceFormat : order.price + 'р.'`} */}
									{order.priceFormat}
								</Typography>
								<Typography variant='subtitle1' className='col-2 p-0'>
									<OrderStatus status={order.status} />
								</Typography>
							</div>
						</AccordionSummary>
						<AccordionDetails className='Admin_subWorks d-flex flex-column'>
							{/*<AppBar position="static" className='mt-3'>*/}
							{/*	{*/}
							{/*		!!currentTab && (*/}
							{/*			<Tabs*/}
							{/*				value={currentTab}*/}
							{/*				variant="fullWidth"*/}
							{/*				indicatorColor="secondary"*/}
							{/*				onChange={setCurrentTabHandler}*/}
							{/*				scrollButtons={'on'}*/}
							{/*			>*/}
							{/*				{*/}
							{/*					!!tabs.length && tabs.map((tab) => {*/}
							{/*						return <Tab key={tab.id} value={tab.id}*/}
							{/*									disabled={tab.disabled}*/}
							{/*									label={tab.name} {...a11yProps(tab.id)} />*/}

							{/*					})*/}
							{/*				}*/}
							{/*			</Tabs>*/}
							{/*		)*/}
							{/*	}*/}
							{/*</AppBar>*/}
							{/*<Paper className="m-2 mt-3">*/}
							{/*	{*/}
							{/*		currentTab === 1 &&*/}
							{/*		<AdminOrderListInfo/>*/}
							{/*	}*/}
							{/*	{*/}
							{/*		currentTab === 2 &&*/}
							{/*		<AdminOrderListUsers order={order} setCurrentTab={setCurrentTab}/>*/}
							{/*	}*/}
							{/*	{*/}
							{/*		currentTab === 3 &&*/}
							{/*		<AdminOrderListOnline/>*/}
							{/*	}*/}
							{/*	{*/}
							{/*		currentTab === 4 &&*/}
							{/*		<AdminOrderListFinance/>*/}
							{/*	}*/}
							{/*</Paper>*/}
							<AdminOrderListDetail
								closeHandler={handleChange(order.id, 3)}
								title={`Заказ №${order.id}`}>
								<div className='mt-5 px-3'>
									<AppBar position='static' className='mt-3'>
										{!!currentTab && (
											<Tabs
												value={currentTab}
												variant='fullWidth'
												indicatorColor='secondary'
												onChange={setCurrentTabHandler}
												scrollButtons={"on"}>
												{!!tabs.length &&
													tabs.map((tab) => {
														return (
															<Tab
																key={tab.id}
																value={tab.id}
																disabled={tab.disabled}
																label={tab.name}
																{...a11yProps(tab.id)}
															/>
														);
													})}
											</Tabs>
										)}
									</AppBar>
									<Paper className='m-2 mt-3'>
										{currentTab === 1 && <AdminOrderListInfo />}
										{currentTab === 2 && (
											<AdminOrderListUsers
												order={order}
												setCurrentTab={setCurrentTab}
											/>
										)}
										{currentTab === 3 && <AdminOrderListOnline />}
										{currentTab === 4 && <AdminOrderListFinance />}
										{currentTab === 5 && (
											<AdminOrderListAnalytics order={order} />
										)}
									</Paper>
								</div>
							</AdminOrderListDetail>
						</AccordionDetails>
					</Accordion>
				);
			})}

			{/*{*/}
			{/*    loading && <AdminSendLoader/>*/}
			{/*}*/}
			{loading && <Loader />}

			<div className='d-flex justify-content-center mt-5'>
				<Pagination
					count={navigation.pageCount}
					color='primary'
					onChange={pageHandler}
					psge={page}
				/>
			</div>
		</div>
	);
};

export default AdminOrderList;

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
