import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import {useDispatch} from 'react-redux';
import {setCustomerCoefficient} from '../../store/actions/adminServiceActions';

const AdminPricesSectionCounter = ({section}) => {
	const dispatch = useDispatch();
	const [standard, setStandard] = useState(1.9);
	const [premium, setPremium] = useState(1.9);

	const minusStandard = () => {
		setStandard(prev =>{
			section.services.forEach(service=> {
				dispatch(setCustomerCoefficient(service.id, (prev - 0.1).toFixed(2), 'standard'))
			})
			return prev - 0.1;
		} );
	}

	const plusStandard = () => {
		setStandard(prev => {
			section.services.forEach(service=> {
				dispatch(setCustomerCoefficient(service.id, (prev + 0.1).toFixed(2), 'standard'))
			})
			return prev + 0.1;
		});
	}

	const minusPremium = () => {
		setPremium(prev => {
			section.services.forEach(service=> {
				dispatch(setCustomerCoefficient(service.id, (prev - 0.1).toFixed(2), 'premium'))
			})
			return prev - 0.1;
		});
	}

	const plusPremium = () => {
		setPremium(prev => {
			section.services.forEach(service=> {
				dispatch(setCustomerCoefficient(service.id, (prev + 0.1).toFixed(2), 'premium'))
			})
			return prev + 0.1;
		});
	}

	useEffect(() => {
		const minStandard = Math.min.apply(null, section.services.map(service => service.customerCoefficient.standard))
		const minPremium = Math.min.apply(null, section.services.map(service => service.customerCoefficient.premium))
		setStandard(minStandard);
		setPremium(minPremium);

	}, [section]);

	return (
		<div className="d-flex align-items-center w-100">
			<div
				className='Admin_counter col-3 px-3 d-flex align-items-center justify-content-center align-self-center'>
				<Button
					className="Admin_btn col"
					variant="contained" color="primary"
					onClick={minusStandard}
				>
					<RemoveIcon
						className='Admin_btn-icon Admin_btn-icon-create'/>
				</Button>
				<TextField
					type="number"
					value={(+standard).toFixed(2)}
					className="Admin_cell"
					variant="outlined"
					onChange={(e) => setStandard(+e.target.value)}
				/>
				<Button
					className="Admin_btn col"
					variant="contained" color="primary"
					onClick={plusStandard}
				>
					<AddIcon
						className='Admin_btn-icon Admin_btn-icon-create'/>
				</Button>

			</div>
			<Typography variant="h6"
						className="col-3"/>

			<div
				className='Admin_counter col-3 px-3 d-flex align-items-center justify-content-center align-self-center'>
				<Button
					className="Admin_btn col"
					variant="contained" color="primary"
					onClick={minusPremium}
				>
					<RemoveIcon
						className='Admin_btn-icon Admin_btn-icon-create'/>
				</Button>
				<TextField
					type="number"
					value={premium}
					className="Admin_cell"
					variant="outlined"
					onChange={(e) => setPremium(+e.target.value)}
				/>
				<Button
					className="Admin_btn col"
					variant="contained" color="primary"
					onClick={plusPremium}
				>
					<AddIcon
						className='Admin_btn-icon Admin_btn-icon-create'/>
				</Button>

			</div>
			<Typography variant="h6"
						className="col-3 p-0 m-0 d-flex justify-content-center align-self-center"/>
		</div>
	);
};

export default AdminPricesSectionCounter;