import React, {useEffect, useState} from 'react';
import AdminWorkerPersonalNav from './AdminWorkerPersonalNav';
import AdminWorkerPersonalTeam from './AdminWorkerPersonalTeam';
import {useDispatch, useSelector} from 'react-redux';
import {
	adminWorkerPersonalFetch,
	adminWorkerPersonalOrdersFetch, updateAdminWorkerPersonalProperty
} from '../../../../store/actions/adminWorkerPersonalActions';
import AdminWorkerPersonalData from './AdminWorkerPersonalData';
import AdminWorkerPersonalCurrentOrders from './AdminWorkerPersonalCurrentOrders';
import {Route, Switch} from 'react-router-dom';
import {useHistory} from 'react-router';
import BackBtn from '../../../../components/UI/BackBtn/BackBtn';
import AdminWorkerPersonalReports from './AdminWorkerPersonalReports';
import AdminWorkerPersonalRequest from './AdminWorkerPersonalRequest';

const AdminWorkerPersonal = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {worker, orders} = useSelector(state => state.adminWorkerPersonal);
	const [selected, setSelected] = useState('');
	const [userId, setUserId] = useState(null);
	const [userGroup, setUserGroup] = useState('masters');

	const tabHandler = (tab) => () => {
		setSelected(tab)
		// let workerType = null;
		// switch (true) {
		// 	case history.location.pathname.includes('masters'):
		// 		workerType = 'masters';
		// 		break;
		// 	case history.location.pathname.includes('managers'):
		// 		workerType = 'masters';
		// 		break;
		// 	case history.location.pathname.includes('independentMaster'):
		// 		workerType = 'independentMaster';
		// 		break;
		// 	case history.location.pathname.includes('specialists'):
		// 		workerType = 'specialists';
		// 		break;
		// }
		if (userGroup) {
			history.push(props.match.url + '/' + tab)
		}
	}

	useEffect(() => {
		if (userId) {
			dispatch(adminWorkerPersonalFetch(userId));
			dispatch(adminWorkerPersonalOrdersFetch(userId));
		}
	}, [userId]);

	useEffect(() => {
		const id = props.match.params.id;
		if (!userId && id) {
			setUserId(id);
		}
	}, [props])
	const backBtnHandler = () => {
		dispatch(updateAdminWorkerPersonalProperty({}, 'worker'))
		const {history, match} = props;
		if (userId) {
			// const url = `/admin/${match.url.includes('masters') ? 'masters' : 'managers'}`;
			const url = `/admin/${userGroup}`;
			history.push(url);
		} else {
			history.goBack();
		}
	}
	useEffect(()=> {
		let workerType = '';
		switch (true) {
			case history.location.pathname.includes('masters'):
				workerType = 'masters';
				break;
			case history.location.pathname.includes('managers'):
				workerType = 'masters';
				break;
			case history.location.pathname.includes('independentMaster'):
				workerType = 'independentMaster';
				break;
			case history.location.pathname.includes('specialists'):
				workerType = 'specialists';
				break;
		}
		setUserGroup(workerType)
	}, [history])

	return (
		<div className="d-flex flex-column">
			<BackBtn title="Назад к списку" variant="dark" onClick={backBtnHandler}/>
			<div className='d-flex'>
				<AdminWorkerPersonalNav tabHandler={tabHandler} selected={selected} user={worker}/>
				<div className='w-80'>

					<Switch>
						<Route
							path={[
								'/admin/independentMaster/:id/data',
								'/admin/masters/:id/data',
								'/admin/managers/:id/data',
								'/admin/specialists/:id/data'
							]}
							exact
							component={() => <AdminWorkerPersonalData user={worker}/>}
						/>
						<Route path={['/admin/masters/:id/team', '/admin/specialists/:id/team']}
							   exact
							   component={() => <AdminWorkerPersonalTeam user={worker}/>}
						/>
						<Route path={'/admin/masters/:id/request'}
							   exact
							   component={() => <AdminWorkerPersonalRequest user={worker}/>}
						/>
						<Route
							path={[
								'/admin/masters/:id/orders-current',
								'/admin/managers/:id/orders-current',
								'/admin/independentMaster/:id/orders-current',
								// '/admin/specialists/:id/orders-current',
							]}
							exact
							component={() => <AdminWorkerPersonalCurrentOrders user={worker} orders={orders}/>}
						/>
						<Route
							path={['/admin/masters/:id/reports', '/admin/managers/:id/reports', '/admin/independentMaster/:id/reports']}
							exact
							component={() => <AdminWorkerPersonalReports user={worker} orders={orders}/>}
						/>
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default AdminWorkerPersonal;