import React, {useEffect, useState} from 'react';
import * as moment from 'moment';
import AdminOrderListDetailScoreboardTable from './AdminOrderListDetailScoreboardTable';
import {Typography} from '@material-ui/core';


const AdminOrderListDetailScoreboardOne = ({order, currentDay}) => {
	const [serviceCount, setServiceCount] = useState(0);
	useEffect(() => {
		let count = order.reports.filter((report) => report.status > 1)
			.filter((report) => {
				const day = moment(report.date, "DD.MM.YYYY")
				return moment(currentDay, "DD.MM.YYYY").diff(moment(day, "DD.MM.YYYY"), 'days') >= 0
			})
			.map((report) => report.services[0])
			.reduce((acc, report) => acc + report.value, 0);
		setServiceCount(count);
	}, [order, currentDay])
	return (
		<div className="mt-4">
			{
				!!serviceCount
					?
					<>
						<AdminOrderListDetailScoreboardTable order={order} count={serviceCount}/>
						<Typography className="mt-3">
							- Учитываются только отчеты, принятые менеджером
						</Typography>
					</>
					: <Typography className="mt-3">
						На <strong>{currentDay}</strong> в этом заказе нет отчетов, принятых менеджером.
					</Typography>
			}
		</div>
	);
};

export default AdminOrderListDetailScoreboardOne;