import React from 'react';
import {Link} from 'react-router-dom';
import {Paper} from '@material-ui/core';
import OrderStatus from '../../../../components/UI/OrderStatus/OrderStatus';

const AdminWorkerPersonalCurrentOrders = ({orders}) => {

	return (
		<div>
			<>
				<h3 className="py-3">Заказы</h3>
				{
					!!orders.length
						?
						<ul className="wp__list p-0">
							{
								orders.map((order) => {
									return (
										<li key={order.id} className="mt-2">
											<Paper className="d-flex align-items-center p-3">
												<div className="col-2">Заказ № {order.id}</div>
												<div className="col-5">{order.serviceName}</div>
												<div className="col-2">{order.location && order.location.address}</div>
												<div className="col">
													<OrderStatus status={order.status}>
														Дата окончания {order.finishDate}
													</OrderStatus>
												</div>
											</Paper>
										</li>
									)
								})
							}
						</ul>
						:
						<div>
							Список заказов пуст
						</div>
				}
			</>
		</div>
	);
};

export default AdminWorkerPersonalCurrentOrders;