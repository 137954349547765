import React, { useEffect, useState } from "react";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import is from "is_js";
import HeaderLogo from "./components/HeaderLogo/HeaderLogo";
import { fetchContacts } from "../../../store/actions/contactsActions";
import { getContacts } from "../../../store/selectors";
import HeaderNav from "./components/HeaderNav/HeaderNav";
import HeaderPhone from "./components/HeaderPhone/HeaderPhone";
import HeaderPersonal from "./components/HeaderPersonal/HeaderPersonal";
import HeaderHam from "./components/HeaderHam/HeaderHam";
import HeaderEmail from "./components/HeaderEmail/HeaderEmail";

const isMobile = is.tablet() || is.mobile();

const Header = () => {
	const contacts = useSelector(getContacts);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	const setOpenHandler = () => {
		setOpen((prevState) => {
			document.querySelector("body").style.overflowY = prevState
				? "auto"
				: "hidden";
			return !prevState;
		});
	};

	useEffect(() => {
		dispatch(fetchContacts());
	}, [dispatch]);
	return (
		<header id='header' className='Header'>
			{isMobile ? (
				<div>
					<div className='Header__logo Header__logo-mobile'>
						<HeaderLogo />
						{!!Object.keys(contacts).length && (
							<div className='d-flex align-items-center'>
								{/* <svg
									width='17'
									height='17'
									viewBox='0 0 17 17'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<g clipPath='url(#clip0)'>
										<path
											d='M15.5867 12.2476L13.3506 10.0116C12.5521 9.21299 11.1945 9.53246 10.875 10.5706C10.6355 11.2893 9.83689 11.6886 9.11817 11.5289C7.52101 11.1296 5.36485 9.05328 4.96556 7.37626C4.72599 6.65751 5.20513 5.85893 5.92386 5.61939C6.96201 5.29996 7.28144 3.94238 6.48286 3.1438L4.24684 0.907779C3.60798 0.348774 2.64968 0.348774 2.09068 0.907779L0.573381 2.42508C-0.943918 4.02223 0.733096 8.2547 4.48641 12.008C8.23973 15.7613 12.4722 17.5182 14.0694 15.9211L15.5867 14.4038C16.1457 13.7649 16.1457 12.8066 15.5867 12.2476Z'
											fill='#C9D6E1'
										/>
									</g>
									<defs>
										<clipPath id='clip0'>
											<rect
												width='16.0227'
												height='16.0227'
												fill='white'
												transform='translate(0 0.488525)'
											/>
										</clipPath>
									</defs>
								</svg>

								<a
									className='Header__phone-mobile'
									href={`tel:+7${contacts.phone.value}`}>
									8-{contacts.phone.value}
								</a> */}
							</div>
						)}
					</div>
					<div className='Header__drawer' data-open={open}>
						<div className='Header__drawer-container'>
							<HeaderNav mobile setOpen={setOpenHandler} />
							{!!Object.keys(contacts).length && (
								<div style={{ marginTop: "60px" }}>
									<div className='Header__call Header__nav-link'>
										<span>Горячая линия</span>
										<a href={`tel:+78007003877`}>8-800-700-38-77</a>
									</div>
									<HeaderPhone num={contacts.phone.value} />
									<HeaderEmail email={contacts.email.value} />
								</div>
							)}
							<HeaderPersonal />
						</div>
					</div>
					<HeaderHam open={open} onClick={setOpenHandler} />
				</div>
			) : (
				<div className='row align-items-center d-flex'>
					<div className='col-4 col-sm-2'>
						<HeaderLogo />
					</div>
					<div className='col-5 d-none d-sm-flex justify-content-start'>
						<HeaderNav />
					</div>
					<div className='col-5 d-none d-sm-flex justify-content-end'>
						<div className='Header__call'>
							<span className='Header__nav-link'>Горячая линия</span>
							<a href={`tel:+78007003877`} className='Header__nav-link'>
								8-800-700-38-77
							</a>
						</div>
						{!!Object.keys(contacts).length && (
							<HeaderPhone num={contacts.phone.value} />
						)}
						<HeaderPersonal />
					</div>
				</div>
			)}
		</header>
	);
};

export default Header;
