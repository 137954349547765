import React from 'react';

const WorkerPersonalReportStatus = ({status}) => {
	let statusClass = ''
	switch (status) {
		case 1:
			statusClass = 'reported'; // отправлено
			break;
		case 2:
			statusClass = 'accepted'; // принято
			break;
		case 3:
			statusClass = 'acceptedPart'; // принято частично
			break;
		case 4:
			statusClass = 'expired'; // принято частично
			break;
		default:
			statusClass = ''
	}
	return (
		<div className={`wp__report-status ${statusClass}`}>
			{(statusClass === 'reported' || statusClass === 'expired') ?
				<svg width="14" height="14" viewBox="0 0 14 14" fill="none">
					<g clipPath="url(#clip0)">
						<path
							d="M12.5269 3.68372C12.2369 3.82399 12.1156 4.17291 12.2558 4.46273C12.6391 5.25441 12.8333 6.10777 12.8333 7C12.8333 10.2165 10.2165 12.8333 7 12.8333C3.78355 12.8333 1.16667 10.2165 1.16667 7C1.16667 3.78355 3.78355 1.16667 7 1.16667C8.33286 1.16667 9.58498 1.60189 10.6213 2.42519C10.8727 2.62585 11.2403 2.58384 11.4409 2.33162C11.6416 2.07955 11.5996 1.7124 11.3472 1.51202C10.1203 0.536906 8.5764 0 7 0C3.1404 0 0 3.1404 0 7C0 10.8596 3.1404 14 7 14C10.8596 14 14 10.8596 14 7C14 5.93046 13.7664 4.9055 13.3059 3.95473C13.1659 3.6642 12.8158 3.54315 12.5269 3.68372Z"
							fill={`${statusClass === 'reported' ? "#B7BFAF" : "#FFF"}`}/>
						<path
							d="M7.00008 2.33301C6.67808 2.33301 6.41675 2.59434 6.41675 2.91634V6.99967C6.41675 7.32168 6.67808 7.58301 7.00008 7.58301H9.91675C10.2387 7.58301 10.5001 7.32168 10.5001 6.99967C10.5001 6.67767 10.2387 6.41634 9.91675 6.41634H7.58341V2.91634C7.58341 2.59434 7.32208 2.33301 7.00008 2.33301Z"
							fill={`${statusClass === 'reported' ? "#B7BFAF" : "#FFF"}`}/>
					</g>
					<defs>
						<clipPath id="clip0">
							<rect width="14" height="14" fill="white"/>
						</clipPath>
					</defs>
				</svg>
				:
				<svg width="12" height="9" viewBox="0 0 12 9" fill="none">
					<rect x="10.6104" width="1.89524" height="10.1079" rx="0.947618" transform="rotate(45 10.6104 0)"
						  fill="white"/>
					<rect x="0.335938" y="4.02051" width="1.89524" height="6.30516" rx="0.947618"
						  transform="rotate(-45 0.335938 4.02051)" fill="white"/>
				</svg>

			}
		</div>
	);
}
;

export default WorkerPersonalReportStatus;