import {FETCH_ONLINE_STATISTIC} from '../actions/actionTypes';

const initialState = {
	about: {
		title: [
			{item: 'Finish Work'},
			{item: 'er ', green: true},
			{item: 'это'}
		],
		video: 'tH3c7D_PFk8',
		body: `Автоматизированный сервис по ведению строительных работ. Система назначит свободных мастеров необходимой специальности и квалификации. Оповестит специалистов через мобильное приложение Finish Worker о вашем заказе. Получайте и отслеживайте ежедневные отчеты исполнителей через мобильное приложение. Вы можете выбрать удобный график оплат и следить онлайн за статусом заказа.`
	},
	inBase: {
		title: [
			{item: 'В базе Finish Work'},
			{item: 'er', green: true}
		],
		body: `Все расчеты ведутся онлайн по нормативам. Система делает расчет выработки на каждый день для выполнения вашего объема работ в заданные сроки.`,
		cards: [
			{
				id: 'services',
				heading: 'Количество услуг',
				num: 121,
				title: [
					{item: 'Количество услуг/ '},
					{item: '55', green: true}
				],
				body: `Вы можете заказать «отдельный виды работ», работу в комплексе «под ключ» вместе с другими, и «мастер по часовой» в разделе калькулятора - работы расчитаны по нормативам и имеют конкретных специалистов для ее выполнения. Заказ может быть рассчитан с материалами и без.`
			},
			{
				id: 'workers',
				title: [
					{item: 'Строителей/ '},
					{item: '234', green: true}
				],
				body: `Наша система резервирует одного или группу специалистов нужной квалификации. В личном кабинете будет полная информация о каждом исполнителе. Отчеты и фотоотчеты ежедневно.`
			},
			{
				id: 'materials',
				title: [
					{item: 'Материалов/ '},
					{item: '2385', green: true}
				],
				body: `В нашей базе материалы без наценок. В заказе будет полная информация о всех необходимых материалах нужного количества по нормативам для работы. Можно работать со своими материалами.`
			}
		]
	},
	calculator: {
		title: [
			{item: 'Калькулятор строительных работ '},
			{item: 'и материалов', green: true},
		],
		body: `Быстрый старт работ прямо сейчас! Оформите заказ на Finish Worker и Вам будет доступен личный кабинет. Состав рабочей группы. Персональный менеджер. Счета и акты КС-2 и КС-3. Оплата онлайн.
Все в режиме реального времени.`
	},
	advantage: {
		title: [
			{item: 'Преимущества сервиса Finish Work'},
			{item: 'er', green: true},
		],
		cards: [
			{
				title: [
					{item: 'Быстро'},
				],
				body: `Вам не надо искать исполнителей и запрашивать стоимость работ. Ждать и выбирать несколько дней. Рассчитать стоимость работ можно в 1 клик.`
			},
			{
				title: [
					{item: 'Все онлайн'},
				],
				body: `Ежедневные отчеты о ходе работ. Задействованные специалисты. График оплат. Стоимость материалов заказанных через сервис. Техническая поддержка.`
			},
			{
				title: [
					{item: 'Легко'},
				],
				body: `Удобная навигация в личном кабинете, информативность, наглядность и простота использования. Это новый уровень заказа строительных работ.`
			}
		]
	},
	online: {
		title: [
			{item: 'Онлайн показатели нагрузки сервиса - '},
			{item: 'мы можем больше', green: true}
		],
		body: `Уникальность нашего сервиса состоит в том, что мы автоматизировали процессы подбора строительных бригад, поставки материалов и работы с документацией.
Рассчитайте и оформите заказ, к работам можно будет приступить немедленно.`
	},
	onlineTable: {
		goodsCount: 0,
		workers: {},
		orders: {},
	}
};

const homePageAboutReducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case FETCH_ONLINE_STATISTIC:
			return {
				...state,
				onlineTable: payload.data.data
			}
		default:
			return state
	}
}
export default homePageAboutReducer;