import React, {useState} from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Rating from '@material-ui/lab/Rating';

import AdminMastersListHeader from "./AdminMastersListHeader";
import AdminMastersListLine from "./AdminMastersListLine";
import {checkModeration} from '../../../../../store/actions/adminMastersListActions';
import {useDispatch} from 'react-redux';


const AdminMastersList = ({master, idx, expanded, setExpanded}) => {

	const dispatch = useDispatch();

	const checkModerationHandler = () => {
		dispatch(checkModeration());
	};

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	};

	let totalWorkers = master.workers.length > 0 ? master.workers.length : 1;
	let freeWorkers = master.workers.filter(worker => worker.status.id === 1).length;

	let random = Math.random() * 6
	let decimal = random - Math.floor(random);
	if (decimal < 0.25) {
		decimal = 0
	} else if (0.25 <= decimal && decimal <= 0.75) {
		decimal = 0.5
	} else {
		decimal = 1
	}
	let rating = Math.floor(random) + decimal > 5 ? 5 : Math.floor(random) + decimal;

	return <Accordion TransitionProps={{unmountOnExit: true}}
					  expanded={expanded === master.id}
					  className="mt-2"
					  onChange={handleChange(master.id)}
	>
		<AccordionSummary
			expandIcon={<ExpandMoreIcon/>}
			aria-controls="panel1bh-content"
			id="panel1bh-header"
			className='Admin_categories'
		>
			<div
				className="d-flex align-items-center justify-content-between w-100">
				<div className="d-flex align-items-center text-white">
					<Typography variant="button" gutterBottom
								className="m-0">
						{idx}. {master.name} &nbsp;
					</Typography>
					<Typography variant="overline" display="block">
						{`[ рабочих: ${totalWorkers} ]`}
					</Typography>
				</div>
				<div
					className={`d-flex align-items-center justify-content-between ${master.moderation.id === 2 ? 'text-success' : 'text-warning'}`}>
					<Typography variant="overline" display="block" className='mx-3'>
						{master.moderation.name || ''}
					</Typography>
					{/*<Rating className='mx-3' name="read-only" precision={0.5}*/}
					{/*		value={rating} readOnly/>*/}
					<Typography variant="overline" display="block" className='text-warning'>
						{`Свободно: ${freeWorkers}/${totalWorkers}`}
					</Typography>
				</div>
			</div>
		</AccordionSummary>
		<AccordionDetails className="d-flex flex-column pl-2 pr-0 py-2 ">
			<AdminMastersListHeader/>
			<List className='w-100 py-0'>
				<ListItem key={master.id}
						  className='w-100 p-0'>
					<AdminMastersListLine master={master} idx={1}
										  checkModeration={checkModerationHandler}/>
				</ListItem>
				{
					master.workers.map((worker, worker_idx) => {
						if (worker.id === master.id) {
							return null
						}
						return (
							<ListItem key={worker.id}
									  className='w-100 p-0'>
								<AdminMastersListLine master={worker} idx={worker_idx + 2}
													  masterId={master.id}
													  checkModeration={checkModerationHandler}/>
							</ListItem>
						)
					})
				}
			</List>
		</AccordionDetails>
	</Accordion>
};

export default AdminMastersList;