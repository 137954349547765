import React, { useEffect, useState } from "react";
import "./PersonalOrderItemPS.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	changePaymentSystem,
	fetchPaymentList,
	fetchPaymentUrl,
	setOrderListProperty,
} from "../../../../../store/actions/orderListActions";
import Paper from "@material-ui/core/Paper";
import GreenButton from "../../../../../components/UI/GreenButton/GreenButton";
import Loader from "../../../../../components/UI/Loader/Loader";

const PersonalOrderItemPS = ({ order, trancheId, currentTrancheNumber }) => {
	const dispatch = useDispatch();
	const [tranche, setTranche] = useState({});
	const [trancheNumber, setTrancheNumber] = useState("");
	const [currentSystem, setCurrentSystem] = useState();
	const { loading, paymentSystems, paymentUrl } = useSelector(
		(state) => state.orderList,
	);

	const paymentHandler = () => {
		dispatch(
			fetchPaymentUrl({
				paymentId: trancheId,
				orderId: order.id,
				psId: currentSystem.psId,
			}),
		);
	};

	const changePaymentSystemHandler = (psId) => {
		dispatch(changePaymentSystem(order.id, trancheId, psId));
	};

	useEffect(() => {
		let system = paymentSystems.find((system) => system.isCurrent);
		setCurrentSystem(system);
	}, [paymentSystems]);

	useEffect(() => {
		if (trancheId) {
			const currentTranche = order.tranches.find(
				(tranche) => tranche.id === trancheId,
			);
			if (currentTranche.type === 1) {
				let trancheIndex = order.tranches
					.filter((tranche) => tranche.type === 1)
					.findIndex((tranche) => {
						return tranche.id === trancheId;
					});

				if (trancheIndex >= 0) {
					// const tranche = order.tranches[trancheIndex];
					// if (tranche.date && tranche.type === 1) {
					// 	if (currentTrancheNumber) {
					// 		setTrancheNumber(currentTrancheNumber)
					// 	} else {
					setTrancheNumber(trancheIndex + 1);
					// }
					// }
				}
			}
			setTranche(currentTranche);
		}
	}, [trancheId, order, currentTrancheNumber]);

	useEffect(() => {
		if (order.id) {
			dispatch(fetchPaymentList(order.id, trancheId));
		}
	}, [order]);

	useEffect(() => {
		if (paymentUrl) {
			window.open(paymentUrl);
			dispatch(setOrderListProperty("", "paymentUrl"));
		}
	}, [paymentUrl]);

	// if (loading) {
	//     return <Loader/>
	// }

	return (
		<div className='PersonalOrderItemPS'>
			<div className='PersonalOrderItemPS__header'>
				Оплата по траншу {trancheNumber}
			</div>

			<Paper className='PersonalOrderItemPS__info'>
				{tranche.date && (
					<div>
						<span>Дата оплаты:</span>
						<span className='PersonalOrderItemPS__text'>{tranche.date}</span>
					</div>
				)}
				<div>
					<span>Сумма оплаты:</span>
					<span className='PersonalOrderItemPS__text'>{tranche.sum} руб.</span>
				</div>
			</Paper>
			<div className='PersonalOrderItemPS__system-list'>
				{paymentSystems.map((system) => {
					return (
						<Paper
							className='PersonalOrderItemPS__system'
							key={system.id}
							onClick={() => changePaymentSystemHandler(system.psId)}>
							<div className='d-flex align-items-center'>
								<div
									className={`PersonalOrderItemPS__system-checkbox ${
										system.isCurrent ? "active" : ""
									}`}
								/>
								<div className='PersonalOrderItemPS__text'>{system.name}</div>
							</div>
							{system.description && (
								<div className='PersonalOrderItemPS__system-description'>
									{system.description}
								</div>
							)}
							<div className='PersonalOrderItemPS__system-picture'>
								<img src={system.image} alt='' />
							</div>
						</Paper>
					);
				})}
			</div>
			<div>
				<GreenButton onClick={paymentHandler}>
					{!!currentSystem && currentSystem.psId === 6
						? "Получить счет"
						: "Оплатить"}
				</GreenButton>
			</div>
		</div>
	);
};

export default PersonalOrderItemPS;
