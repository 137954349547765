import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getWorkerPersonalLoading,
	getWorkerPersonalOrders,
} from "../../../../store/selectors";
import { fetchWorkerPersonalOrders } from "../../../../store/actions/workerPersonalActions";
import Loader from "../../../../components/UI/Loader/Loader";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import OrderStatus from "../../../../components/UI/OrderStatus/OrderStatus";

const WorkerPersonalReports = (props) => {
	const dispatch = useDispatch();
	const orders = useSelector(getWorkerPersonalOrders);
	const loading = useSelector(getWorkerPersonalLoading);

	useEffect(() => {
		dispatch(fetchWorkerPersonalOrders());
	}, []);

	if (loading && !props.match.params.id) {
		return <Loader />;
	}

	return (
		<>
			<Typography variant='h5' className='mt-3'>
				Отчеты
			</Typography>
			<hr />
			{!!orders.length ? (
				<ul className='wp__list p-0'>
					{orders
						.filter((order) => order.reportsCount > 0)
						.map((order) => {
							return (
								<li key={order.id} className='mt-2'>
									<Link to={`/worker/personal/reports/${order.id}`}>
										<Paper className='d-flex align-items-center justify-content-between p-3'>
											<div className='col-2'>Отчет по заказу № {order.id}</div>
											<div className='col-4'>{order.serviceName}</div>
											<div className='col-3'>{order.location.address}</div>
											<div className='col-2'>
												<OrderStatus status={order.status}>
													Дата окончания {order.finishDate}
												</OrderStatus>
											</div>
											<div className='col-1 d-flex align-items-center justify-content-center'>
												<div className='wp__count'>{order.reportsCount}</div>
											</div>
										</Paper>
									</Link>
								</li>
							);
						})}
				</ul>
			) : (
				<div>Список отчетов пуст</div>
			)}
		</>
	);
};
export default WorkerPersonalReports;
