import React from 'react'
import './PersonalOrderItemAccordion.scss'
import {Accordion} from "react-bootstrap";
import PersonalOrderItemPayments from "../PersonalOrderItemPayments/PersonalOrderItemPayments";
import PersonalOrderItemPhotoReports from "../PersonalOrderItemPhotoReports/PersonalOrderItemPhotoReports";
import PersonalOrderItemAccordionWrapper from "./PersonalOrderItemAccordionWrapper/PersonalOrderItemAccordionWrapper";
import {Paper} from '@material-ui/core';


class PersonalOrderItemAccordion extends React.Component {

    state = {
        activeKey: null
    }

    setActiveKey = (activeKey) => {
        this.setState(prevState => {
            if (activeKey === prevState.activeKey) {
                return {activeKey: null}
            }
            return {activeKey}
        })
    }

    render() {
        const {order, paymentPageHandler} = this.props
        const {activeKey} = this.state
        const {setActiveKey} = this
        return (
            <Accordion
                // defaultActiveKey="1"
            >
                {/*<PersonalOrderItemAccordionWrapper*/}
                {/*    setActiveKey={setActiveKey}*/}
                {/*    activeKey={activeKey}*/}
                {/*    toggleName="Оплаты"*/}
                {/*    eventKey="0">*/}
                <Paper className="p-3 my-2" elevation={0}>
                    <PersonalOrderItemPayments
                        paymentPageHandler={paymentPageHandler}
                        order={order}/>
                </Paper>
                {/*</PersonalOrderItemAccordionWrapper>*/}

                <PersonalOrderItemAccordionWrapper
                    setActiveKey={setActiveKey}
                    activeKey={activeKey}
                    toggleName="Фотоотчет"
                    eventKey="1">
                    <PersonalOrderItemPhotoReports
                        order={order}
                    />
                </PersonalOrderItemAccordionWrapper>
            </Accordion>
        )

    }
}

export default PersonalOrderItemAccordion