import React, {Fragment} from 'react';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";

import {connect} from "react-redux";
import {setServiceProperty} from "../../../store/actions/adminServiceActions";


class AdminCommunication extends React.Component {

    inputHandler = (evt, property, itemId = null) => {
        this.props.setServiceProperty(this.props.serviceId, +evt.target.value, property, itemId)
    }

    render() {
        // const properties = ['links', 'earlyStart', 'lateStart', 'earlyFinish', 'lateFinish']
        const {services} = this.props
        const service = services.find(service => service.id === this.props.serviceId)
        const height = 60


        return (

            <Fragment>
                {
                    !!service &&
                    <List className="px-0">
                        {
                            !service.items.length
                                ?
                                <ListItem className='border-top border-bottom border-gray' style={{height}}>

                                    <TextField
                                        variant="outlined"
                                        className='Admin_cell col'
                                        type="number"
                                        onChange={(evt) => this.inputHandler(evt, 'links')}
                                        value={service.links || ''}
                                    />


                                    <TextField
                                        variant="outlined"
                                        className='Admin_cell col'
                                        type="number"
                                        onChange={(evt) => this.inputHandler(evt, 'earlyStart')}
                                        value={service.earlyStart || ''}
                                    />


                                    <TextField
                                        variant="outlined"
                                        className='Admin_cell col'
                                        type="number"
                                        onChange={(evt) => this.inputHandler(evt, 'lateStart')}
                                        value={service.lateStart || ''}
                                    />


                                    <TextField
                                        variant="outlined"
                                        className='Admin_cell col'
                                        type="number"
                                        onChange={(evt) => this.inputHandler(evt, 'earlyFinish')}
                                        value={service.earlyFinish || ''}
                                    />

                                    <TextField
                                        className='Admin_cell col'
                                        variant="outlined"
                                        type="number"
                                        onChange={(evt) => this.inputHandler(evt, 'lateFinish')}
                                        value={service.lateFinish || ''}
                                    />


                                </ListItem>
                                :
                                <Fragment>
                                    <ListItem className='border-top border-bottom border-gray' style={{height}}>

                                        <TextField
                                            variant="outlined"
                                            className='Admin_cell col'
                                            type="number"
                                            onChange={(evt) => this.inputHandler(evt, 'links')}
                                            value={service.links || ''}
                                        />


                                        <TextField
                                            variant="outlined"
                                            className='Admin_cell col'
                                            type="number"
                                            onChange={(evt) => this.inputHandler(evt, 'earlyStart')}
                                            value={service.earlyStart || ''}
                                        />


                                        <TextField
                                            variant="outlined"
                                            className='Admin_cell col'
                                            type="number"
                                            onChange={(evt) => this.inputHandler(evt, 'lateStart')}
                                            value={service.lateStart || ''}
                                        />


                                        <TextField
                                            variant="outlined"
                                            className='Admin_cell col'
                                            type="number"
                                            onChange={(evt) => this.inputHandler(evt, 'earlyFinish')}
                                            value={service.earlyFinish || ''}
                                        />

                                        <TextField
                                            className='Admin_cell col'
                                            variant="outlined"
                                            type="number"
                                            onChange={(evt) => this.inputHandler(evt, 'lateFinish')}
                                            value={service.lateFinish || ''}
                                        />


                                    </ListItem>
                                    {
                                        service.items.map((item) => {

                                            // const uid = item.uid || item.id

                                            return (
                                                <ListItem key={item.id}
                                                          className=' border-bottom border-gray'
                                                          style={{height: '50px'}}>


                                                    <TextField
                                                        variant="outlined"
                                                        className='Admin_cell col'
                                                        type="number"
                                                        onChange={(evt) => this.inputHandler(evt, 'links', item.id)}
                                                        value={item.links || ''}
                                                    />


                                                    <TextField
                                                        variant="outlined"
                                                        className='Admin_cell col'
                                                        type="number"
                                                        onChange={(evt) => this.inputHandler(evt, 'earlyStart', item.id)}
                                                        value={item.earlyStart || ''}
                                                    />


                                                    <TextField
                                                        variant="outlined"
                                                        className='Admin_cell col'
                                                        type="number"
                                                        onChange={(evt) => this.inputHandler(evt, 'lateStart', item.id)}
                                                        value={item.lateStart || ''}
                                                    />


                                                    <TextField
                                                        variant="outlined"
                                                        className='Admin_cell col'
                                                        type="number"
                                                        onChange={(evt) => this.inputHandler(evt, 'earlyFinish', item.id)}
                                                        value={item.earlyFinish || ''}
                                                    />

                                                    <TextField
                                                        className='Admin_cell col'
                                                        variant="outlined"
                                                        type="number"
                                                        onChange={(evt) => this.inputHandler(evt, 'lateFinish', item.id)}
                                                        value={item.lateFinish || ''}
                                                    />

                                                </ListItem>
                                            )
                                        })
                                    }
                                </Fragment>
                        }
                    </List>
                }
            </Fragment>

        );
    }

}

const mapState = (state) => {
    return {
        services: state.adminService.services
    }
}
const actions = {
    setServiceProperty
}

export default connect(mapState, actions)(AdminCommunication);