import {arrayUnique} from '../../../../utils/arrayFunctions';

export function getCategoriesMap(sections) {
	let categories = [];
	if(sections){
		categories = sections.map(category => ({id: category.parentId, name: category.parentName}))
		let categoriesId = arrayUnique(categories.map(category => category.id));
		categories = categoriesId.map(id => categories.find(category => category.id === id))
			.map(parent => {
				let item = {...parent};
				item.subCategories = sections.filter(category => category.parentId === parent.id)
				return item;
			});
	}
	return categories;
}