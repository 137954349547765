import React from 'react'

const HeaderEmail = ({email}) => {

  return (
    <a className="Header__phone Header__nav-link" href={`mailto:${email}`} >
            <svg width="20" height="15" viewBox="0 0 20 15" fill="#C9D6E1" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5785 9.89389C10.3896 10.012 10.1771 10.0592 9.98819 10.0592C9.79929 10.0592 9.58678 10.012 9.39787 9.89389L0 4.15601V11.7829C0 13.4122 1.32231 14.7345 2.95159 14.7345H17.0484C18.6777 14.7345 20 13.4122 20 11.7829V4.15601L10.5785 9.89389Z"/>
              <path d="M17.0482 0H2.95138C1.55823 0 0.377591 0.99174 0.0942383 2.31405L10.0116 8.35889L19.9053 2.31405C19.622 0.99174 18.4413 0 17.0482 0Z"/>
            </svg>
            <span>{email}</span>
        </a>
  )
}

export default HeaderEmail