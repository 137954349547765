import React, {Component, Fragment} from 'react';
import './ActKSTableColumn.scss'

class ActKsTableColumn extends Component {

	render() {

		const {services, column, order} = this.props
		let total = 0;
		const withMaterials = !!order.materials.length;
		const serviceCost = (services[0].price * services[0].value).toFixed(2)
		const materialsPrice = ((order.totalPrice - order.servicePrice) / order.serviceValue).toFixed(2);
		const materialsCost = (materialsPrice * services[0].value).toFixed(2)
		const totalCost = Number(serviceCost) + Number(materialsCost)

		return (
			<Fragment>
				{
					!!services.length
						?
						<>
							{
								services.map((service, idx) => {
									if (column === 1) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-left border-right border-bottom border-dark name">{idx + 1}</div>
												{!services[idx + 1] && <div className="total"/>}
											</Fragment>
										)
									}
									if (column === 2) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{idx + 1}</div>
												{!services[idx + 1] && <div className="total"/>}
											</Fragment>
										)
									}
									if (column === 3) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name justify-content-start">{service.name}</div>
												{!services[idx + 1] && <div className="total"/>}
											</Fragment>
										)
									}
									if (column === 4) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">-
												</div>
												{!services[idx + 1] && <div className="total"/>}
											</Fragment>
										)
									}
									if (column === 5) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{service.unit}</div>
												{!services[idx + 1] &&
												<div className="total border-right border-dark">Итого</div>}
											</Fragment>
										)
									}
									if (column === 6) {
										return (
											<Fragment key={service.id}>
												<div
													className="ActKSTableColumn border-right border-bottom border-dark name">{service.value}</div>
												{!services[idx + 1] && <div
													className="total border-right border-bottom border-dark"> - </div>}
											</Fragment>
										)
									}
									if (column === 7 && !withMaterials) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{service.price}</div>
												{!services[idx + 1] && <div
													className="total  border-right border-bottom border-dark">Х</div>}
											</Fragment>
										)
									}

									if (column === 7 && withMaterials) {
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name d-flex p-0 flex-column">
													<div className="d-flex h-100 w-100">
														<div
															className="border-right border-dark h-100 w-50 d-flex align-items-center justify-content-center">{materialsPrice}</div>
														<div
															className="h-100 w-50 d-flex align-items-center justify-content-center">{service.price}</div>
													</div>
												</div>
												{!services[idx + 1] && <div
													className="total  border-right border-bottom border-dark">Х</div>}
											</Fragment>
										)
									}
									if (column === 8) {
										let totalRow = service.price * service.value

										total += totalRow
										return (
											<Fragment key={service.id}>
												<div key={service.id}
													 className="ActKSTableColumn border-right border-bottom border-dark name">{withMaterials ? totalCost : totalRow.toFixed(2)}</div>
												{!services[idx + 1] && <div
													className="total  border-right border-bottom border-dark">
													<strong>{withMaterials ? totalCost : total.toFixed(2)}</strong>
												</div>}
											</Fragment>
										)
									}

									return null

								})
							}
						</>
						: null
				}
			</Fragment>
		);
	}
}

export default ActKsTableColumn;