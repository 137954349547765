import React from 'react'
import './PersonalOrderItemTabsSmall.scss'

export default ({order, tabs, currentTab, onSwitchTab}) => {

    return (
        <div className="PersonalOrderItemTabsSmall">
            {tabs.map(({id, name}) => {
                let ServiceCalcTab = "PersonalOrderItemTabsSmall_tab"
                if (id === currentTab) {
                    ServiceCalcTab += " active"
                }
                return (
                    <div
                        key={id}
                        className={ServiceCalcTab}
                        onClick={() => onSwitchTab(id)}
                        data-id={id}>
                        {name}
                    </div>
                )
            })}
        </div>
    )
}