import React, {createContext, useContext, useState} from 'react';

const ServiceContext = createContext();

export function useService() {
    return useContext(ServiceContext)
}

const ServiceRouteContext = ({children}) => {

    const [currentTab, setCurrentTab] = useState(1);

    return (
        <ServiceContext.Provider value={{
            currentTab, setCurrentTab
        }}>
            {children}
        </ServiceContext.Provider>
    );
};

export default ServiceRouteContext;