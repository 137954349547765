import React, {useEffect} from 'react';
import './AdminComponents/AdminComponents.scss'
import './Admin.scss';

import {connect, useDispatch, useSelector} from "react-redux";
import {adminLogin, setCurrentTab} from "../../store/actions/adminActions";
import AdminAuth from "./AdminAuth/AdminAuth";
import AdminTopNav from "./AdminComponents/AdminTopNav";
import Page from "../../hoc/Page/Page";
import AdminLeftNav from "./AdminComponents/AdminLeftNav/AdminLeftNav";
import AdminContent from "./AdminComponents/AdminContent/AdminContent";
import AdminContextProvider from "./context/adminContext";
import {ROLE_ADMINISTRATORS, ROLE_ADMINS, ROLE_ENGINEER_PTO, ROLE_GEODESIST, ROLE_SUPPLIER} from '../../config';

const Admin = (props) => {
	const {isLogin, user,  sendAuth} = props;
	const dispatch = useDispatch();
	const admins = [ROLE_ADMINS, ROLE_ADMINISTRATORS, ROLE_SUPPLIER, ROLE_GEODESIST, ROLE_ENGINEER_PTO];
	const {worker: {id}} = useSelector(state => state.adminWorkerPersonal);


	useEffect(() => {
		if (!isLogin) {
			dispatch(adminLogin());
		}
	}, [dispatch, isLogin]);

	const title = 'Административная панель';
	return (
		<Page title={title}>
			{
				isLogin && admins.includes(user.role) ?

					<AdminContextProvider>
						<AdminTopNav title={title}/>
						{!id && <AdminLeftNav/>}
						<AdminContent/>
					</AdminContextProvider>
					:
					<>
						{
							(sendAuth && !isLogin) &&
							<AdminAuth/>
						}
					</>
			}
		</Page>
	);
}

function mapStateToProps(state) {
	return {
		isLogin: state.admin.isLogin,
		loading: state.admin.loading,
		sendAuth: state.admin.sendAuth,
		user: state.admin.user
	}
}

const actions = {
	setCurrentTab,
};

export default connect(mapStateToProps, actions)(Admin)