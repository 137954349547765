import {
	COUNT_SLIDER_CHANGE_SERVICE,
	COUNT_SLIDER_SET_MATERIALS,
	COUNT_SLIDER_SET_TERMOFWORK,
	COUNT_SLIDER_SET_WORKLOAD,
	COUTN_SLIDER_CHANGE_CATEGORY,
	COUTN_SLIDER_SET_CATEGORIES,
	COUTN_SLIDER_SET_SERVICES,
} from "./actionTypes";

export const countSliderChangeCategory = (id) => ({
	type: COUTN_SLIDER_CHANGE_CATEGORY,
	payload: {
		id,
	},
});

export const countSliderChangeService = (service) => ({
	type: COUNT_SLIDER_CHANGE_SERVICE,
	payload: {
		service,
	},
});

export const countSliderSetServices = (services) => ({
	type: COUTN_SLIDER_SET_SERVICES,
	payload: {
		services,
	},
});

export const countSliderSetCategories = (categories) => ({
	type: COUTN_SLIDER_SET_CATEGORIES,
	payload: {
		categories,
	},
});

export const countSliderSetWorkload = (value) => ({
	type: COUNT_SLIDER_SET_WORKLOAD,
	payload: {
		value,
	},
});

export const countSliderSetTermofwork = (value) => ({
	type: COUNT_SLIDER_SET_TERMOFWORK,
	payload: {
		value,
	},
});

export const countSliderSetMaterials = (materials) => ({
	type: COUNT_SLIDER_SET_MATERIALS,
	payload: {
		materials,
	},
});
