import React from 'react'
import Estimate from "./Estimate/Estimate";
import Inspection from './Inspection/Inspection';
import ProgectCalculation from './ProgectCalculation/ProgectCalculation';

const complexTabs = {
  estimate: {
    id: 'estimate',
    component: <Estimate/>,
    title: 'Самостоятельно составить смету'
  },
  inspection: {
    id: 'inspection',
    component: <Inspection/>,
    title: 'Пригласить на осмотр'
  },
  progectCalculation: {
    id: 'progectCalculation',
    component: <ProgectCalculation/>,
    title: 'Заказать расчёт проекта'
  }
}

export default complexTabs