import React from 'react'
import './ChatInput.scss'
import ChatImageUploader from "../../ChatImageUploader/ChatImageUploader";


export default ({onSubmit}) => {

    const [focusClass, setfocusClass] = React.useState(false)

    const inputHandler = (e) => {
        setfocusClass(!!e.target.value)
    }
    return (
        <form
            className="ChatInput"
            onSubmit={(e) => onSubmit(e)}>
            <ChatImageUploader/>
            <input type="text"
                   name="msg"
                   className={focusClass ? 'focus' : null}
                   onChange={inputHandler}/>

            <div className="ChatInput-btn">
                <button type="submit">
                    <svg viewBox="0 0 15 14" fill="none">
                        <path
                            d="M14.6893 6.51055L0.761055 0.0485527C0.539275 -0.0526852 0.274638 0.00762682 0.120356 0.199333C-0.0349973 0.391039 -0.0403543 0.663519 0.107499 0.86061L2.67857 6.99951L0.107499 13.1384C-0.0403543 13.3355 -0.0349973 13.609 0.119285 13.7997C0.223211 13.93 0.378564 14 0.536061 14C0.61213 14 0.6882 13.9838 0.759984 13.9505L14.6882 7.48846C14.8789 7.40015 15 7.20952 15 6.99951C15 6.78949 14.8789 6.59886 14.6893 6.51055Z"
                            fill="white"/>
                    </svg>
                </button>
            </div>
        </form>
    )
}