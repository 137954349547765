import axios from "../../rest/axios";

const MODAL_SET_VALUE = "MODAL_SET_VALUE";
const MODAL_SET_FIELD_VALUE = "MODAL_SET_FIELD_VALUE";
const MODAL_SET_FIELD_ERROR = "MODAL_SET_FIELD_ERROR";
const MODAL_RESET = "MODAL_RESET";
const MODAL_SEND_SUCCESS = "MODAL_SEND_SUCCESS";

const initialState = {
	isOpen: false,
	title: "",
	actionURL: "",
	buttonText: "",
	success: false,
	activeFields: [],
	formControls: {
		email: {
			id: "email",
			label: "E-mail",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				email: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				email: `Введите корректный email`,
			},
		},
		name: {
			id: "name",
			label: "Имя",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				minLength: 3,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				minLength: `Минимум 3 символа`,
			},
		},
		phone: {
			id: "phone",
			label: "Телефон",
			type: "tel",
			value: "",
			valid: true,
			validation: {
				required: true,
				phone: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				phone: `Введите корректный номер телефона`,
			},
		},
		question: {
			id: "question",
			label: "Ваш вопрос",
			type: "text",
			value: "",
			valid: true,
		},
		comment: {
			id: "comment",
			label: "Комментарий",
			type: "text",
			value: "",
			valid: true,
		},
	},
	goal: "",
};

const modalReducer = (state = initialState, { type, payload }) => {
	let tempState = { ...state };
	let tempFormControls = { ...state.formControls };
	switch (type) {
		case MODAL_SET_VALUE:
			tempState[payload.field] = payload.value;
			// debugger
			return {
				...tempState,
			};
		case MODAL_SET_FIELD_VALUE:
			tempFormControls[payload.field].value = payload.value;
			return {
				...state,
				formControls: tempFormControls,
			};
		case MODAL_SET_FIELD_ERROR:
			tempFormControls[payload.field].error = payload.error;
			return {
				...state,
				formControls: tempFormControls,
			};
		case MODAL_RESET: {
			return {
				isOpen: false,
				title: "",
				actionURL: "",
				buttonText: "",
				activeFields: [],
				formControls: {
					email: {
						id: "email",
						label: "E-mail",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
							email: true,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							email: `Введите корректный email`,
						},
					},
					name: {
						id: "name",
						label: "Имя",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
							minLength: 3,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							minLength: `Минимум 3 символа`,
						},
					},
					phone: {
						id: "phone",
						label: "Телефон",
						type: "tel",
						value: "",
						valid: true,
						validation: {
							required: true,
							phone: true,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							phone: `Введите корректный номер телефона`,
						},
					},
					question: {
						id: "question",
						label: "Ваш вопрос",
						type: "text",
						value: "",
						valid: true,
					},
					comment: {
						id: "comment",
						label: "Комментарий",
						type: "text",
						value: "",
						valid: true,
					},
				},
				goal: "",
			};
		}
		case MODAL_SEND_SUCCESS:
			return {
				...state,
				success: true,
				isOpen: true,
			};
		default:
			return state;
	}
};

const _setValue = (field, value) => ({
	type: MODAL_SET_VALUE,
	payload: {
		field,
		value,
	},
});

const _reset = () => ({
	type: MODAL_RESET,
});

export const modalSuccess = () => ({
	type: MODAL_SEND_SUCCESS,
});

export const modalSetFieldValue = (value, field) => ({
	type: MODAL_SET_FIELD_VALUE,
	payload: {
		value,
		field,
	},
});

export const modalSetFieldError = (error, field) => ({
	type: MODAL_SET_FIELD_ERROR,
	payload: {
		error,
		field,
	},
});

export const modalCloseModal = () => (dispatch) => {
	dispatch(_reset());
};

export const modalOpenModal =
	({ title, buttonText, activeFields, goal }) =>
	(dispatch) => {
		dispatch(_setValue("isOpen", true));
		dispatch(_setValue("title", title));
		dispatch(_setValue("buttonText", buttonText));
		dispatch(_setValue("activeFields", activeFields));
		dispatch(_setValue("goal", goal));
	};

export const modalSendMessage = (formData) => async (dispatch) => {
	try {
		const { data } = await axios.post("feedback/modal/", formData);
		if (data) dispatch(modalSuccess());
	} catch (error) {}
};

export default modalReducer;
