import './Work.scss'
import React from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import getCellWidth from "../../../../../helpers/getCellWidth";
import { ganttChangePosition } from '../../../../../store/actions/ganttActions'

function Work({ work, times, worksCount, index }) {
    const dispatch = useDispatch()
    const cellWidth = parseInt(getCellWidth(times), 10)

    const style = {
        width: ((work.lateFinish - work.earlyStart) * cellWidth) + '%',
        left: (cellWidth * work.earlyStart) + '%',
        height: '50px',
        top: (index * 50) + 'px'
    }

    const bounds = {
        left: 0
    }

    const grid = [65, 50]

    let lastX

    const onStartHandle = (e, data) => {
        lastX = data.lastX
    }
    
    const onStopHandle = (e, data) => {
        const delta = (data.lastX - lastX) / 65
        dispatch(ganttChangePosition({ work, delta }))
        lastX = data.lastX
    }

    return(
        <Draggable
            axis="x"
            bounds={bounds}
            grid={grid}
            onStart={(e, data) => onStartHandle(e, data)}
            onStop={(e, data) => onStopHandle(e, data)}
        >
            <div className="work" style={style}>{work.name}</div>
        </Draggable>
    )
}

export default Work;
