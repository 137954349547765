import React, {useEffect, useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AdminOrderListFinanceOne from './AdminOrderListFinanceOne/AdminOrderListFinanceOne';
import {useSelector} from 'react-redux';
import AdminOrderListFinanceTwo from './AdminOrderListFinanceTwo/AdminOrderListFinanceTwo';
import {a11yProps} from '../functions';
import AdminOrderListFinanceThree from './AdminOrderListFinanceThree/AdminOrderListFinanceThree';
import {getAdminOrderListState} from '../selectors';


const AdminOrderListFinance = () => {
	const {navTabsFinance, order} = useSelector(getAdminOrderListState)
	const [currentTab, setCurrentTab] = useState(1);
	const [reports, setReports] = useState([]);

	const setCurrentTabHandler = (event, newValue) => {
		setCurrentTab(newValue)
	};

	useEffect(() => {
		const {reports} = order;
		// отбираем подтвержденные отчеты
		if (reports) {

			const acceptedReports = reports.filter((report) => report.status > 1);

			if (!!acceptedReports.length) {
				// сложанные отчеты, у которых есть все работы
				const totalAcceptedReports = [];
				// остатки от предыдущего отчета, если есть
				const leavings = {};
				// инициализируем остатки, по умолчанию 0 для каждой работы
				acceptedReports[0].services
					.map((service) => service.id)
					.forEach((id) => {
						leavings[id] = 0;
					})

				acceptedReports.forEach((report) => {

					const reportedServices = [...report.services];
					// складываем остатки от предыдущего отчета с текущим по id работы
					Object.keys(leavings).forEach((key) => {
						if (!!leavings[key]) {
							const serviceIdx = reportedServices.findIndex((service) => service.id === +key);
							if (serviceIdx >= 0) {
								reportedServices[serviceIdx].value += leavings[key];
							}
						}
					})
					// находим работы с нулевым значением
					const emptyReports = reportedServices.filter((service) => service.value === 0);
					// если все еще есть пустые значения, то кладем значения получившегося массива работ в объект остатков
					if (!!emptyReports.length) {
						reportedServices.forEach((service) => {
							if (!!service.value) {
								leavings[service.id] = service.value;
							}
						})
					} else {
						// если во всех работах есть какое-то значение, то находим его минимальное
						const minValue = reportedServices.sort((a, b) => {
							return a.value - b.value;
						})[0].value
						// записываем в массив полноценных принятых отчетов
						totalAcceptedReports.push({
							id: report.id,
							date: report.date,
							value: minValue,
							masterId: report.master
						})
						// то что осталось добавляем в остатки
						reportedServices.forEach((service) => {
							if (!!service.value && service.value > minValue) {
								leavings[service.id] += service.value - minValue;
							}
						})
					}

				})
				setReports(totalAcceptedReports);

			}
		}

	}, [order])

	return (
		<>
			<Tabs
				value={currentTab}
				variant="standard"
				indicatorColor="secondary"
				onChange={setCurrentTabHandler}
				scrollButtons={'on'}
				classes={{
					root: "AdminOrderList__finance-tabs"
				}}
			>
				{
					navTabsFinance.map((tab) => <Tab key={tab.id} value={tab.id}
													 disabled={tab.disabled}
													 label={tab.name} {...a11yProps(tab.id)} />)
				}
			</Tabs>

			{
				!!order.id &&
				<>
					{
						currentTab === 1 &&
						<AdminOrderListFinanceOne order={order} reports={reports}/>
					}
					{
						currentTab === 2 &&
						<AdminOrderListFinanceTwo order={order} reports={reports}/>
					}
					{
						currentTab === 3 &&
						<AdminOrderListFinanceThree order={order} reports={reports}/>
					}
				</>
			}
		</>
	);
};

export default AdminOrderListFinance;