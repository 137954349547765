import React from "react";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import OrderStatus from "../../../../../components/UI/OrderStatus/OrderStatus";
import { useHistory } from "react-router-dom";

const AdminSpecialistLine = ({ worker, idx }) => {
	const { id, picture, name, status, masterName, masterId, phone, email } =
		worker;

	const history = useHistory();

	const setUserPersonal = (id) => () => {
		history.push(history.location.pathname + "/" + id + "/data");
	};

	return (
		<Paper
			className='w-100 p-2 my-1 d-flex align-items-center'
			onClick={setUserPersonal(id)}
			style={{ cursor: "pointer" }}>
			<div className='col-1 p-0 d-flex justify-content-start'>
				<span>
					{idx}. / {id}
				</span>
			</div>
			<div className='col-2 p-0 d-flex justify-content-start align-items-center'>
				<Avatar alt='Cindy Baker' src={picture} />
				<span className='ml-2'>{name}</span>
			</div>
			<div className='col p-0 d-flex justify-content-start'>
				<span>{email}</span>
			</div>
			<div className='col p-0 d-flex justify-content-start'>
				<span>{phone}</span>
			</div>
			<div className='col-2 p-0 d-flex justify-content-start'>
				<OrderStatus status={status} />
			</div>
			<div className='col-2 p-0 d-flex justify-content-start'>
				{masterId && (
					<span>
						{masterName} [id: {masterId}]
					</span>
				)}
			</div>
		</Paper>
	);
};

export default AdminSpecialistLine;
