import React, { useState } from 'react'
import { connect } from 'react-redux';
import logo from "../../../../../../img/logo.png";
import cn from './EstimateOrder.module.scss'

const EstimateOrderHead = ({estimateOrder}) => {
  let workKind;
  switch (estimateOrder.workKind) {
    case 'build':
      workKind = 'строительству'
      break;
    case 'repair':
      workKind = 'ремонту'
      break;
    case 'reconstruction':
      workKind = 'реконструкции'
      break;
    default: break;
  }
  const [address, setValue] = useState('')

  

  return (
    <div className={cn.head}>
      <div className="text-left p-3">
        <img src={logo} alt="logotype" />
      </div>
      <div>
        <div className={cn.title}>Смета работ</div>
        <div className={cn.subtitle}>по {workKind} объекта</div>
      </div>
      <div/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  estimateOrder: state.estimateOrder
})
const mapDispatchToprops = {
  
}
export default connect(mapStateToProps, mapDispatchToprops)(EstimateOrderHead)