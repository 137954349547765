import {
	FETCH_DIALOG_ERROR,
	FETCH_DIALOG_START,
	FETCH_DIALOG_SUCCESS,
	FETCH_PROFILE_ERROR,
	FETCH_PROFILE_START,
	FETCH_PROFILE_SUCCESS,
	READ_MSGS_SUCCESS,
	SET_DIALOG_PROPERTY,
} from "../actions/actionTypes";

const initialState = {
	dialogs: {},
	profile: {},
	currentImage: {},
	loading: false,
	error: null,
};

export default function dialogReducer(state = initialState, action) {
	let dialogs = { ...state.dialogs };
	switch (action.type) {
		case FETCH_DIALOG_START:
			return {
				...state,
				loading: true,
			};
		case FETCH_PROFILE_START:
			return {
				...state,
				loading: true,
			};
		case FETCH_DIALOG_SUCCESS:
			dialogs[action.chat.id] = action.chat;

			return {
				...state,
				dialogs,
				loading: false,
			};
		case FETCH_PROFILE_SUCCESS:
			let profile = action.data;
			return {
				...state,
				profile,
				loading: false,
			};
		case READ_MSGS_SUCCESS:
			let currentDialog = Object.keys(dialogs).filter((key) => {
				return key === action.dialogId;
			});

			dialogs[currentDialog]["unreads"] = [];

			return {
				...state,
				dialogs,
				loading: false,
			};
		case SET_DIALOG_PROPERTY:
			return {
				...state,
				[action.property]: action.value,
				loading: false,
			};
		case FETCH_DIALOG_ERROR:
			return {
				...state,
				error: action.error,
			};
		case FETCH_PROFILE_ERROR:
			return {
				...state,
				error: action.error,
			};
		default:
			return state;
	}
}
