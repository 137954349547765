import {
    ADD_ADMIN_PROFESSIONS_LIST_PROFESSION,
    DELETE_ADMIN_PROFESSIONS_LIST_PROFESSION,
    FETCH_ADMIN_PROFESSIONS_LIST_CATEGORIES_SUCCESS,
    FETCH_ADMIN_PROFESSIONS_LIST_ERROR,
    FETCH_ADMIN_PROFESSIONS_LIST_START,
    FETCH_ADMIN_PROFESSIONS_LIST_SUCCESS,
    SAVE_ADMIN_CHANGES_PROFESSION_ERROR,
    SAVE_ADMIN_CHANGES_PROFESSION_NOTHING,
    SAVE_ADMIN_CHANGES_PROFESSION_START,
    SAVE_ADMIN_CHANGES_PROFESSION_SUCCESS,
    SAVE_ADMIN_PROFESSIONS_LIST_PROFESSION,
    SET_ADMIN_PROFESSIONS_LIST_PROPERTY
} from "./actionTypes";
import axios from "../../rest/axios";
import {store} from "../../index";
import qs from 'qs'

export function fetchAdminProfessionalListProfessions() {

    return async dispatch => {
        try {
            dispatch(fetchStart())
            let professions = await axios.post('/admin/professions/');
            dispatch(fetchSuccess(professions.data.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

export function fetchAdminProfessionalListCategories() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let categories = await axios.post('/admin/categories/', qs.stringify({token}));
            dispatch(fetchCategoriesSuccess(categories.data.data))
        }
    }
}


function fetchStart() {
    return {
        type: FETCH_ADMIN_PROFESSIONS_LIST_START
    }
}

function fetchSuccess(professions) {
    return {
        type: FETCH_ADMIN_PROFESSIONS_LIST_SUCCESS,
        payload: {
            professions
        }
    }
}

function fetchError(error) {
    return {
        type: FETCH_ADMIN_PROFESSIONS_LIST_ERROR,
        payload: {
            error
        }
    }
}

function fetchCategoriesSuccess(categories) {
    return {
        type: FETCH_ADMIN_PROFESSIONS_LIST_CATEGORIES_SUCCESS,
        payload: {
            categories
        }
    }
}

export function addAdminProfessionalListProfession(profession) {
    return {
        type: ADD_ADMIN_PROFESSIONS_LIST_PROFESSION,
        payload: {
            profession
        }
    }
}

export function saveAdminProfessionalListProfession(profession) {
    return {
        type: SAVE_ADMIN_PROFESSIONS_LIST_PROFESSION,
        payload: {
            profession
        }
    }
}

export function deleteAdminProfessionListProfession(profession) {
    return {
        type: DELETE_ADMIN_PROFESSIONS_LIST_PROFESSION,
        payload: {
            profession
        }
    }
}

export function setAdminProfessionListProperty(value, property) {
    return {
        type: SET_ADMIN_PROFESSIONS_LIST_PROPERTY,
        payload: {
            value,
            property
        }
    }
}


export function saveProfessionList() {

    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            dispatch(saveStart())
            try {
                const adminProfessionList = store.getState().adminProfessionalList
                const professions = adminProfessionList.professions.filter(profession => profession.edited)

                if (!!professions.length) {
                    let response = await axios.post('/admin/saveProfessons/', qs.stringify({professions, token}))

                    await dispatch(saveSuccess(response.data))

                    let errors = response.data.filter(data => {
                        return !data.result
                    })

                    if (!errors.length) {
                        dispatch(fetchAdminProfessionalListProfessions())
                    }

                } else {
                    dispatch(saveNothing())
                }


            } catch (e) {
                dispatch(saveError(e))
            }
        }
    }
}


function saveNothing() {
    return {
        type: SAVE_ADMIN_CHANGES_PROFESSION_NOTHING
    }
}

function saveStart() {
    return {
        type: SAVE_ADMIN_CHANGES_PROFESSION_START
    }
}

function saveSuccess(saveResult) {
    return {
        type: SAVE_ADMIN_CHANGES_PROFESSION_SUCCESS,
        payload: {
            saveResult
        }
    }
}

function saveError(error) {
    return {
        type: SAVE_ADMIN_CHANGES_PROFESSION_ERROR,
        payload: {
            error
        }
    }
}

