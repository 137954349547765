import React from 'react';
import * as moment from 'moment'

const WorkerFooter = () => {
    return (
        <div className='w__footer'>
            <div>&copy; Finish Worker - {moment().format('YYYY')}</div>
        </div>
    );
};

export default WorkerFooter;