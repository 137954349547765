import {
	COUNT_SLIDER_CHANGE_SERVICE,
	COUNT_SLIDER_SET_MATERIALS,
	COUNT_SLIDER_SET_TERMOFWORK,
	COUNT_SLIDER_SET_WORKLOAD,
	COUTN_SLIDER_CHANGE_CATEGORY,
	COUTN_SLIDER_SET_CATEGORIES,
	COUTN_SLIDER_SET_SERVICES,
} from "../actions/actionTypes";

const initialState = {
	activeCategoryId: null,
	activeService: null,
	selectedServices: [],
	services: [],
	categories: [],
	materials: [],
	workLoad: null,
	termOfWork: null,
};

const countSliderReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case COUTN_SLIDER_CHANGE_CATEGORY:
			return {
				...state,
				activeCategoryId: payload.id,
				selectedServices: state.services.filter(
					(s) => s.category.id === payload.id
				),
				activeService: state.services.filter(
					(s) => s.category.id === payload.id
				)[0],
			};
		case COUNT_SLIDER_CHANGE_SERVICE:
			return {
				...state,
				activeService: payload.service,
			};
		case COUTN_SLIDER_SET_SERVICES:
			return {
				...state,
				services: payload.services,
			};
		case COUTN_SLIDER_SET_CATEGORIES:
			return {
				...state,
				categories: payload.categories,
			};
		case COUNT_SLIDER_SET_WORKLOAD:
			return {
				...state,
				workLoad: payload.value,
			};
		case COUNT_SLIDER_SET_TERMOFWORK:
			return {
				...state,
				termOfWork: payload.value,
			};
		case COUNT_SLIDER_SET_MATERIALS:
			return {
				...state,
				materials: payload.materials,
			};
		default:
			return state;
	}
};

export default countSliderReducer;
