import React from 'react';
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import {useDispatch} from "react-redux";
import {setMasterListModeration} from '../../../../../store/actions/adminMastersListActions';
import {adminUserListIcons as icons} from '../icons';
import * as moment from 'moment';


const AdminMastersListLine = ({master, idx}) => {

	const {
		id, name, isMaster = false, status, phone,
		categories, professionMain, professionAdd, professionAdd_2,
		moderation, currentOrder,
		picture, dateInstructionFormated
	} = master;

	const dispatch = useDispatch();

	const switchModerationHandler = (userId, statusId) => {
		dispatch(setMasterListModeration(userId, statusId === 2 ? '' : 'Y'))
	};

	return (
		<Paper className='w-100 p-3 my-1 d-flex align-items-center'>
			<div className='col p-0 d-flex justify-content-between align-items-center pr-3'>{idx}. / {id}

				<Avatar alt="Cindy Baker" src={picture}/>
			</div>
			<div className='col-2 p-0'>
				{
					isMaster
						?
						<strong>{name}</strong>
						:
						<span>{name}</span>
				}
				{
					isMaster && icons['master']
				}
			</div>
			<List className='col-2 p-0'>
				{
					categories.map(category => {
						return (

							<ListItem key={category.id} className='p-0'>{category.name}</ListItem>

						)
					})
				}
			</List>
			<ul className='col-2 p-0'>
				{
					professionMain &&
					<li>{professionMain.name}</li>
				}
				{
					professionAdd &&
					<li>{professionAdd.name}</li>
				}
				{
					professionAdd_2 &&
					<li>{professionAdd_2.name}</li>
				}
			</ul>
			<div className='col-1 p-0'>{phone}</div>
			<div className='col-2 d-flex flex-column p-0'>
				<span className={`text-${status.id === 1 ? 'success' : 'danger'}`}>{status.name}</span>
				{
					status.id !== 1 &&
					<span className='text-secondary'>Заказ {currentOrder.id} от {currentOrder.date}</span>
				}
			</div>
			<div className='col-2 p-0 d-flex flex-column position-relative'>
				<FormControlLabel
					control={
						<Switch
							checked={moderation.id === 2}
							onChange={() => switchModerationHandler(id, moderation.id)}
							name="checkedB"
							color="primary"
							size="small"
							classes={{root: `Admin__switch-btn ${moderation.id === 1 ? '' : 'Admin__switch-btn_checked'}`}}
						/>
					}
					label={moderation.id === 1 ? 'На модерации' : 'Прошел модерацию'}
					classes={{
						label: `Admin__switch-label ${moderation.id === 1 ? '' : 'Admin__switch-label_checked'}`,
						root: 'Admin__switch'
					}}
				/>

				{
					!!dateInstructionFormated &&
					<time className='Admin__switch-time text-secondary'>
						{moment(dateInstructionFormated, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm')}
					</time>
				}

			</div>
		</Paper>
	);
};

export default AdminMastersListLine;