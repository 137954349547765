import React from 'react'
import GreenButton from '../../../components/UI/GreenButton/GreenButton'
import cn from './OneClickEstimate.module.css'
import image from '../../../img/oneClickEstimate.gif'
import { useDispatch } from 'react-redux'
import { setActiveNavAnchor } from '../../../store/actions/navActions'
import { CALCULATOR_ANCHOR, ONE_CLICK_ESTIMATE } from '../../../config'
import BgBlot from '../../ui/BgBlot/BgBlot'
import blot from '../../../img/oneClickEstimateBg.png'
import { useHistory } from 'react-router'

const OneClickEstimate = () => {
	const dispatch = useDispatch();
  const history = useHistory();

	const goToEstimate = (anchor) => {
		history.replace('/complex-works')
		dispatch(setActiveNavAnchor(anchor))
	};

  return (
    <>
      <BgBlot left={0} top={'50px'} image={blot} width='487px' height='514px'/>
      <section className={cn.section} id={ONE_CLICK_ESTIMATE}>
        <div className={cn.inner}>
          <h2 className={cn.title}>Коммерческая смета в 1 клик</h2>
          <p className={cn.text}>Актуальные цены и номенклатуры товаров. Стоимость трудозатрат на сегодняшний день. Все расчеты строительных работ и материалов по нормативам. Составьте смету самостоятельно, быстро и бесплатно.</p>
          <img src={image} alt="" className={cn.picture} />
          <GreenButton className={cn.button} onClick={() => goToEstimate(CALCULATOR_ANCHOR)}>смета бесплатно</GreenButton>
        </div>
      </section>
    </>
  )
}

export default OneClickEstimate