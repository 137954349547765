import {
	EDIT_CLASSIFIER_ADMIN,
	GET_CLASSIFIERS_ADMIN_START,
	GET_CLASSIFIERS_ADMIN_SUCCESS,
	GET_CLASSIFIERS_ROLES_ADMIN_SUCCESS
} from '../actions/actionTypes';

const initialState = {
	classifiers: [],
	roles: [],
	loading: false,
	error: null
}

const adminClassifiersReducer = (state = initialState, {type, payload}) => {
	const classifiers = [...state.classifiers];
	switch (type) {
		case GET_CLASSIFIERS_ADMIN_START:
			return {
				...state,
				loading: true
			}
		case GET_CLASSIFIERS_ADMIN_SUCCESS:
			return {
				...state,
				classifiers: payload.data.data,
				loading: false
			}
		case GET_CLASSIFIERS_ROLES_ADMIN_SUCCESS:
			return {
				...state,
				roles: payload.data.data,
				loading: false
			}
		case EDIT_CLASSIFIER_ADMIN:
			const classifier = classifiers.find(c => c.id === payload.id)
			if (payload.property && payload.value) {
				classifier[payload.property] = payload.value;
			}
			classifier.edit = true
			return {
				...state,
				classifiers,
			}
		default:
			return state

	}
}
export default adminClassifiersReducer;