import {
	CREATE_WORKER_ERROR,
	CREATE_WORKER_START, CREATE_WORKER_SUCCESS,
	FETCH_WORKER_CREATE_CATEGORIES_ERROR,
	FETCH_WORKER_CREATE_CATEGORIES_START, FETCH_WORKER_CREATE_CATEGORIES_SUCCESS,
	FETCH_WORKER_CREATE_PROFESSIONS_ERROR,
	FETCH_WORKER_CREATE_PROFESSIONS_START,
	FETCH_WORKER_CREATE_PROFESSIONS_SUCCESS
} from "../actions/actionTypes";

const initialState = {
	categories: [],
	professions: [],
	formControls: {
		user: {
			name: {
				id: 'name',
				label: 'Имя',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
					minLength: 3
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					minLength: `Минимум 3 символа`
				},
			},
			surname: {
				id: 'surname',
				label: 'Фамилия',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			patronymic: {
				id: 'patronymic',
				label: 'Отчество',
				type: 'text',
				value: '',
				valid: true,
				validation: {},
				errorMessage: {},
			},
			birthDay: {
				id: 'birthDay',
				label: 'Дата рождения',
				type: 'date',
				value: '',
				valid: true,
				validation: {
					required: true,
					date: true,
					birthDayMin: true,
					birthDayMax: true,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					date: 'Введите правильную дату',
					birthDayMin: 'Должно быть не менее 18 лет',
					birthDayMax: 'Должно быть не более 100 лет',
				},
			},
			phone: {
				id: 'phone',
				label: 'Телефон',
				type: 'tel',
				value: '',
				valid: true,
				validation: {
					required: true,
					phone: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					phone: `Введите корректный номер телефона`
				},
			},
			email: {
				id: 'email',
				label: 'E-mail',
				type: 'email',
				value: '',
				valid: true,
				validation: {
					required: true,
					email: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					email: `Введите корректный email`
				},
			},
			photo: {
				id: 'photo',
				label: '',
				type: 'file',
				value: '',
				valid: true,
				validation: {
					maxSize: 5000,
					image: true
				},
				errorMessage: {
					maxSize: 'Максимальный размер файла 5 МБ',
					image: `Допустимые расширения jpg, png, gif`
				},
			},
		},
		passport: {
			issuedPassport: {
				id: 'issuedPassport',
				label: 'Кем выдан',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			numberPassport: {
				id: 'numberPassport',
				label: 'Серия и номер',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			datePassport: {
				id: 'datePassport',
				label: 'Когда выдан',
				type: 'date',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			registrationPassport: {
				id: 'registrationPassport',
				label: 'Место регистрации',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
		},
		/*
		card: {
			numberCard: {
				id: 'numberCard',
				label: 'Номер карты',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
					// creditCard: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					// creditCard: 'Введите корректный номер карты'
				},
			},
			expirationDateCard: {
				id: 'expirationDateCard',
				label: 'Срок действия карты',
				type: 'date',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
					expirationDateCard: true
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					expirationDateCard: 'Введите корректный номер карты'
				},
			},
			owner: {
				id: 'owner',
				label: 'Владелец',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
		},
		*/
		experience: {
			mainExperience: {
				id: 'mainExperience',
				label: 'Основная профессия',
				type: 'select',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			dateStartMainExperience: {
				id: 'dateStartMainExperience',
				label: 'Количество лет опыта',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: true,
					number: true,
					max: 60
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					number: `Введите число`,
					max: 'Слишком много лет'
				},
			},
			addExperience: {
				id: 'addExperience',
				label: 'Дополнительная профессия',
				type: 'select',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			dateStartAddExperience: {
				id: 'dateStartAddExperience',
				label: 'Количество лет опыта',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false,
					number: true,
					max: 50
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					number: `Введите число`,
					max: 'Тебе пора на пенсию, брат'
				},
			},
			addExperience_2: {
				id: 'addExperience_2',
				label: 'Дополнительная профессия',
				type: 'select',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false,
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
				},
			},
			dateStartAddExperience_2: {
				id: 'dateStartAddExperience_2',
				label: 'Количество лет опыта',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {
					required: false,
					number: true,
					max: 50
				},
				errorMessage: {
					required: 'Это поле необходимо заполнить',
					number: `Введите число`,
					max: 'Тебе пора на пенсию, брат'
				},
			},
		},
		recommendations: {
			recommendation: {
				id: 'recommendation',
				label: 'Рекомендатель',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {},
				errorMessage: {},
			},
		},
		prices: {
			workerPrice: {
				id: 'workerPrice',
				// label: 'Цена за час',
				type: 'text',
				value: '',
				valid: true,
				error: '',
				validation: {},
				errorMessage: {},
			},
		}
	},
	createResult: {},
	loading: false,
	error: false
}

const workerCreateReducer = (state = initialState, {type, payload}) => {
	switch (type) {

		case FETCH_WORKER_CREATE_PROFESSIONS_START:
			return {
				...state,
				loading: true
			}
		case FETCH_WORKER_CREATE_PROFESSIONS_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}
		case FETCH_WORKER_CREATE_PROFESSIONS_SUCCESS:
			return {
				...state,
				loading: false,
				professions: payload.data
			}

		case FETCH_WORKER_CREATE_CATEGORIES_START:
			return {
				...state,
				loading: true
			}
		case FETCH_WORKER_CREATE_CATEGORIES_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}
		case FETCH_WORKER_CREATE_CATEGORIES_SUCCESS:
			return {
				...state,
				loading: false,
				categories: payload.data
			}

		case CREATE_WORKER_START:
			return {
				...state,
				loading: true
			}
		case CREATE_WORKER_SUCCESS:
			return {
				...state,
				loading: false,
				createResult: payload.data
			}
		case CREATE_WORKER_ERROR:
			return {
				...state,
				loading: false,
				error: payload.error
			}


		default:
			return {
				...state
			}
	}
}

export default workerCreateReducer;