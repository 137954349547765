export function getAdminMasterListState(state) {
	return state.adminMastersList
}

export function getAdminMasterListUsers(state) {
	return getAdminMasterListState(state).users
}

export function getAdminMasterListNav(state) {
	return getAdminMasterListState(state).navigation
}

export function getAdminMasterListTotalCount(state) {
	return getAdminMasterListState(state).usersTotalCount
}

export function getAdminMasterListLoading(state) {
	return getAdminMasterListState(state).loading
}

export function getAdminMasterListFilter(state) {
	return getAdminMasterListState(state).filter
}