import React, { Component } from "react";
import "./PersonalOrderItemTranches.scss";
import DraggableScroll from "../../../../../components/UI/DraggableScroll/DraggableScroll";
import PersonalOrderItemTranchesLeftSide from "./components/PersonalOrderItemTranchesLeftSide";
import PersonalOrderItemTranchesDays from "./components/PersonalOrderItemTranchesDays";
import PersonalOrderItemTranchesTotal from "./components/PersonalOrderItemTranchesTotal";

const PersonalOrderItemTranches = ({ order, paymentPageHandler }) => {
	return (
		<>
			<div className='PersonalOrderItemTranches_title px-3'>
				<h6>{order.serviceName}</h6>
			</div>
			<div className='PersonalOrderItemTranches'>
				<div className='PersonalOrderItemTranches_LeftBlock border-right border-main-1-3'>
					<PersonalOrderItemTranchesLeftSide order={order} />
				</div>
				<DraggableScroll>
					<div className='PersonalOrderItemTranches_RightBlock pb-3'>
						<PersonalOrderItemTranchesTotal order={order} />
						<PersonalOrderItemTranchesDays
							order={order}
							paymentPageHandler={paymentPageHandler}
						/>
					</div>
				</DraggableScroll>
			</div>
		</>
	);
};

export default PersonalOrderItemTranches;
