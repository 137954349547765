import React, {useCallback, useEffect, useState} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import AdLoader from "../ad-loader/ad-loader";
import {connect} from "react-redux";
import * as moment from 'moment'
import {fetchEndPoints, fetchResponse} from "../../../store/actions/apiDocsActions";
import EndPointContent from "./EndPointContent";
import logo from '../../../img/logo_blue.png'

const EndPoints = (props) => {
    const {fetchEndPoints, groups, loading, fetchResponse, response} = props;
    const [actionId, setActionId] = useState(null);
    const [action, setAction] = useState({})

    const getAction = useCallback(() => {
        if (actionId) {
            let actions = []
            groups.forEach(group => {
                actions = [...actions, ...group.actions]
            });
            let action = actions.find(action => action.id === +actionId);
            setAction(action)
            fetchResponse(action.endpoint)
        }
    }, [actionId, fetchResponse, groups]);

    useEffect(() => {
        getAction()
    }, [getAction]);


    useEffect(() => {
        fetchEndPoints();
    }, [fetchEndPoints]);

    const getStatusClass = (statusId) => {

        switch (statusId) {
            case 1:
                return 'badge-success';
            case 2:
                return 'badge-primary';
            case 3:
                return 'badge-danger';
            case 4:
                return 'badge-warning';
            case 5:
                return 'badge-light';
            default :
                return 'badge-dark';
        }

    }

    return (
        <>
            {
                !!groups.length
                    ?
                    <div className="row d-flex  justify-content-center">
                        <div className="ApiDocs_left col-12 col-sm-4">
                            <Accordion defaultActiveKey={0}>
                                {
                                    groups.map((group) => {

                                        const {id, actions, code, name} = group
                                        // let statusClass = getStatusClass(item.id)
                                        return (
                                            <Card key={id}>
                                                <Accordion.Toggle as={Card.Header} eventKey={id}>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <span className=''>{code}</span>
                                                            <span
                                                                className="text-warning ml-2">( {name} )</span>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={id}>
                                                    {
                                                        !!actions.length
                                                            ?
                                                            <>

                                                                {
                                                                    actions.map((action) => {
                                                                        return (
                                                                            <Card.Body key={action.id}>

                                                                                <div className="ApiDocs_link"
                                                                                     onClick={() => setActionId(action.id)}>
                                                                               <div>
                                                                                    <span
                                                                                        className=''>{action.code}</span><span
                                                                                   className='text-warning'>( {action.name} )</span>
                                                                               </div>

                                                                                    <div
                                                                                        className="d-flex justify-content-between">
                                                                                        <div>
                                                                                            {
                                                                                                action.status &&
                                                                                                <span
                                                                                                    className={`mr-2 badge ${getStatusClass(action.status.id)}`}>{action.status.name}</span>
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                        <span
                                                                                            className="badge badge-dark">{moment.unix(action.date).format('DD.MM.YYYY')}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </Card.Body>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <Card.Body>
                                                                <div className='pl-3'>
                                                                 ...
                                                                </div>
                                                            </Card.Body>

                                                    }
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    })
                                }

                            </Accordion>
                        </div>
                        <div className="col-12 col-sm-8 h-100 ApiDocs_content">
                            <div className="ApiDocs_right w-100">
                                {
                                    actionId ?
                                        <>
                                            {
                                                loading ?
                                                    <AdLoader/>
                                                    :
                                                    <>
                                                        {
                                                            !!action && !!response &&
                                                            <EndPointContent action={action} response={response}/>
                                                        }
                                                    </>

                                            }
                                        </>
                                        :
                                        <img className='ApiDocs_bg-logo' src={logo} alt='Finish Worker Logo'/>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <AdLoader/>
            }

        </>
    );
};

const mapState = state => {
    return {
        groups: state.apiDocs.groups,
        response: state.apiDocs.response,
        loading: state.apiDocs.loading
    }
};

const actions = {
    fetchEndPoints,
    fetchResponse
};

export default connect(mapState, actions)(EndPoints);



