import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useCalculatorContext } from "../CalculatorContext";
import { useSelector } from "react-redux";
import {
	getOrderState,
	getServiceSelectState,
} from "../../../../store/selectors";
import ScaleLoader from "react-spinners/ScaleLoader";

const IndividualWorksSubCategories = ({ back }) => {
	const { activeSubCategories, currentServiceId, setServiceHandler } =
		useCalculatorContext();
	const { currentCategory, currentService } = useSelector(getOrderState);
	const { services } = useSelector(getServiceSelectState);

	const [currentSubCategory, setCurrentSubCategory] = useState(null);
	const [currentServices, setCurrentServices] = useState([]);

	const handleChange = (id) => (event, isExpanded) => {
		setCurrentSubCategory(isExpanded ? id : null);
		if (!isExpanded) {
			setCurrentServices([]);
		}
	};

	const checkActiveSubcategory = (subCategoryId) => {
		let isActive = false;
		if (currentSubCategory === subCategoryId) {
			return true;
		}
		if (
			currentService &&
			currentService.category.subCategory.id === subCategoryId
		) {
			return true;
		}

		return isActive;
	};

	useEffect(() => {
		if (currentSubCategory) {
			const currentSubCategoryServices = services.filter((service) => {
				const {
					category: { subCategory },
					active,
					norms,
					items,
				} = service;
				if (subCategory) {
					if (subCategory.id === currentSubCategory && active) {
						if (!!items && items.length) {
							let norms = true;
							items.forEach((item) => (norms = norms && !!item.norms.length));
							return norms;
						} else {
							return !!norms.length;
						}
					}
				}
				return false;
			});
			setCurrentServices(currentSubCategoryServices);
		}
	}, [currentSubCategory, services]);

	if (!activeSubCategories.length) {
		return (
			<div className='d-flex align-items-center justify-content-center m-5 p-5'>
				<ScaleLoader
					color='#7B53DA'
					height={50}
					width={12}
					radius={3}
					margin={3}
				/>
			</div>
		);
	}

	return (
		<div className='IndividualWorks__sub-categories'>
			<div onClick={back} className='IndividualWorks__back'>
				<svg width='24' height='21' viewBox='0 0 24 21' fill='none'>
					<path
						d='M10.5 20.0024L1 10.5024M1 10.5024L10.5 1.00244M1 10.5024L23.5 10.5024'
						stroke='#5D20C1'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
				{currentCategory && (
					<div className='d-flex align-items-center'>
						<img
							src={currentCategory.picture}
							className='IndividualWorks__back-img'
						/>
						<div className='IndividualWorks__back-name'>
							{currentCategory.name}
						</div>
						<div className='IndividualWorks__back-count'>
							({activeSubCategories.length})
						</div>
					</div>
				)}
			</div>

			{activeSubCategories.map((subCategory) => {
				return (
					<Accordion
						key={subCategory.id}
						TransitionProps={{ unmountOnExit: true }}
						className={`IndividualWorks__sub-categories-accordion w-100`}
						expanded={currentSubCategory === subCategory.id}
						onChange={handleChange(subCategory.id)}>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon className='IndividualWorks__sub-categories-icon' />
							}>
							<div
								className={`IndividualWorks__sub-categories-name ${
									checkActiveSubcategory(subCategory.id) ? "active" : ""
								}`}>
								{subCategory.name}
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<div className='IndividualWorks__sub-categories-list'>
								{currentServices.map((service) => {
									const { id, name } = service;
									return (
										<div
											key={id}
											onClick={setServiceHandler(id, "service")}
											className={`IndividualWorks__sub-categories-service ${
												currentServiceId === id ? "active" : ""
											}`}>
											<div className='IndividualWorks__sub-categories-service-name w-100'>
												{name}
											</div>
											{/*<div*/}
											{/*	className="IndividualWorks__sub-categories-service-name IndividualWorks__sub-categories-service-price w-25">*/}
											{/*	Базовая цена:&nbsp;*/}
											{/*	{getBasePrice(service, workType).toFixed(2)}&nbsp;*/}
											{/*	₽/{parse(service.unit)}*/}
											{/*</div>*/}
										</div>
									);
								})}
							</div>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
};

export default IndividualWorksSubCategories;
