import React from "react";
import { connect } from "react-redux";
import EstimateRadioInput2 from "../../../UI/EstimateRadioInput2";
import EstimateRadioInput from "../../../UI/EstimateRadioInput";
import {
  setFormItemValue,
  nextStep,
  changeCheckboxSelect,
  prevStep,
} from "../../../../../../../store/actions/estimateActions";
import EstimateSwitch from "../../../UI/EstimateSwitch";
import EstimateCheckbox from "../../../UI/EstimateCheckbox";
import EstimateButton from "../../../UI/EstimateButton";
import "./Estimate-Step3.scss";
import EstimateProcess from "../../Process/EstimateProcess";

const Step3 = (props) => {
  const {
    setFormItemValue,
    formcontrols,
    nextStep,
    changeCheckboxSelect,
    prevStep,
  } = props;

  const changeSupplyDocumentationHandler = (value, id) => {
    setFormItemValue("documentation", []);
    setFormItemValue(value, id);
  };

  return (
    <>
      <EstimateProcess />
      <div className="complex-body">
        <div className="row mb-5">
          <div className="col-12 d-flex justify-content-center">
            <span>
              Уточните условия проведения работ для точного расчета стоимости
              заказа
            </span>
          </div>
          <div className="col-12 d-flex justify-content-between"></div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-6 mb-4">
            <div className="estimate-firststep-bottom-item">
              <EstimateRadioInput
                element={formcontrols.workType}
                clickHandler={setFormItemValue}
              />
            </div>
          </div>
          <div className="col-12 col-xl-6 mb-4">
            <div className="estimate-firststep-bottom-item">
              <EstimateRadioInput
                element={formcontrols.supplyMaterials}
                clickHandler={setFormItemValue}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between flex-wrap">
            <EstimateRadioInput2
              element={formcontrols.locationObjectConditions}
              clickHandler={setFormItemValue}
            />
            <EstimateRadioInput2
              element={formcontrols.floorConditions}
              clickHandler={setFormItemValue}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-lg-4 estimate-supply-block">
            <EstimateSwitch
              element={formcontrols.instrumentConditions}
              clickHandler={setFormItemValue}
            />
            <EstimateSwitch
              element={formcontrols.equipmentConditions}
              clickHandler={setFormItemValue}
            />
            <EstimateSwitch
              element={formcontrols.housingConditions}
              clickHandler={setFormItemValue}
            />
          </div>
          <div className="col-12 col-lg-8">
            <div className="d-flex justify-content-end estimate-docs">
              <div className="estimate-docs-left">
                <EstimateRadioInput2
                  element={formcontrols.materialPlaceConditions}
                  clickHandler={setFormItemValue}
                  titleClassNames="small"
                />
                <EstimateRadioInput2
                  element={formcontrols.supplyDocument}
                  clickHandler={setFormItemValue}
                  titleClassNames="small"
                />
              </div>
              <div className="estimate-docs-right d-flex flex-column justify-content-between">
                <EstimateCheckbox
                  element={formcontrols.classifiersConditions}
                  action={changeCheckboxSelect}
                  disabled={formcontrols.supplyDocument.value === "customer"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="complex-footer mt-4">
        <div className="row">
          <div className="col-4 col-lg-2 estimate-footer-item pr-0">
            <EstimateButton
              className="back"
              back={true}
              onClick={prevStep}
              text="Назад"
            />
          </div>
          <div className="col-8 col-lg-10 estimate-footer-item pl-0">
            <EstimateButton onClick={nextStep} text="Следующий шаг" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  formcontrols: state.estimateOrder.formControls,
});

const actions = {
  setFormItemValue,
  nextStep,
  changeCheckboxSelect,
  prevStep,
};

export default connect(mapStateToProps, actions)(Step3);
