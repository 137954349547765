import React from 'react';
import DraggableScroll from '../../../../components/UI/DraggableScroll/DraggableScroll';
import WorkerListHeaderRequest from '../../../Worker/WorkerPersonal/WorkerPersonalList/WorkerListHeaderRequest';
import WorkerListHeaderRequestLine from './WorkerListHeaderRequestLine';

const AdminWorkerPersonalRequest = ({user:{requestWorkers}}) => {
	return (
		<DraggableScroll>
			<WorkerListHeaderRequest/>
			{
				!!requestWorkers && !!requestWorkers.length &&
				<>
					{
						requestWorkers.map((worker, idx) => <WorkerListHeaderRequestLine key={worker.id} idx={idx + 1}
																			master={worker}/>)
					}
				</>
			}
		</DraggableScroll>
	);
};

export default AdminWorkerPersonalRequest;