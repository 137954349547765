import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	getAdminMasterListFilter,
	getAdminMasterListLoading,
	getAdminMasterListNav, getAdminMasterListTotalCount,
	getAdminMasterListUsers
} from './selectors';
import {adminMastersFetch} from '../../../../store/actions/adminMastersListActions';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import Pagination from '@material-ui/lab/Pagination';
import {getUserRoleName} from '../../../../utils/roleFunctions';
import AdminUserLine from './AdminUserLine';
import AdminSendLoader from '../AdminUI/AdminSendLoader';
import {ROLE_MASTER} from '../../../../config';
import AdminMastersList from './AdminMastersList/AdminMastersList';
import {getRowNum} from './functions';
import AdminOrderListDetail from '../AdminOrderList/AdminOrderListDetail/AdminOrderListDetail';
import AdminWorkerPersonal from '../AdminWorkerPersonal/AdminWorkerPersonal';
import Typography from '@material-ui/core/Typography';

const AdminUserList = ({role}) => {
	const dispatch = useDispatch();
	const stateUsers = useSelector(getAdminMasterListUsers);
	const stateNav = useSelector(getAdminMasterListNav);
	const stateUsersTotalCount = useSelector(getAdminMasterListTotalCount);
	const loading = useSelector(getAdminMasterListLoading);
	const stateFilter = useSelector(getAdminMasterListFilter);
	// const {worker} = useSelector(state => state.adminWorkerPersonal)

	const [users, setUsers] = useState([]);
	const [filter, setFilter] = useState('all');
	const [count, setCount] = useState(1);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	// const [openPersonal, setOpenPersonal] = useState(false);
	// const [pageUsers, setPageUsers] = useState([]);
	// const [dates, setDates] = useState([]);
	// const [currentDate, setCurrentDate] = useState('ALL');
	const [expanded, setExpanded] = useState(1);
	const [masterFull, setMasterFull] = useState(false);
	// const countItemsOnPage = 10;

	// useEffect(() => {
	// 	dispatch(adminMastersFetch(role))
	// }, []);

	useEffect(() => {
		let start = true;
		const fetchData = async () => {
			const roleName = await getUserRoleName(role);
			if (stateNav[roleName] && (stateNav[roleName].currentPage !== page || filter !== stateFilter)) {
				dispatch(adminMastersFetch(role, page, 10, filter))
			}
		};
		if (start) {
			fetchData();
		}
		return () => {
			start = false
		}

	}, [filter, page]);


	useEffect(() => {
		const roleName = getUserRoleName(role);
		if (stateNav[roleName]) {
			setUsers(stateUsers[roleName]);
			setCount(stateNav[roleName].pageCount);
			setTotalCount(stateUsersTotalCount[roleName])
		}
	}, [stateUsers]);

	const setFilterHandler = (filter) => {
		setFilter(filter);
	};

	const pageHandler = (evt, val) => {
		setPage(val)
	};
	return (
		<>
			{loading && <AdminSendLoader/>}
			<div className='d-flex justify-content-between align-items-center mb-3'>
				<div className='d-flex justify-content-between align-items-center'>
					<ButtonGroup color="primary" aria-label="outlined primary button group">
						<Button onClick={() => setFilterHandler('all')}
								variant={`${filter === 'all' ? 'contained' : 'outlined'}`}>
							Все
						</Button>
						<Button onClick={() => setFilterHandler('N')}
								variant={`${filter === 'N' ? 'contained' : 'outlined'}`}>
							На модерации
						</Button>
						<Button onClick={() => setFilterHandler('Y')}
								variant={`${filter === 'Y' ? 'contained' : 'outlined'}`}>
							Прошли модерацию
						</Button>
						{
							role === ROLE_MASTER && <Button
								onClick={() => setMasterFull(state => !state)}
								variant={`${masterFull ? 'contained' : 'outlined'}`}
							>
								{masterFull ? 'Только бригадиры' : 'С бригадами'}
							</Button>
						}
					</ButtonGroup>
				</div>
				{
					count > 1 &&
					<Pagination count={count}
								page={page}
								color="primary"
								onChange={pageHandler}/>
				}
			</div>

			<Typography variant="button" className="my-3">Всего специалистов: {totalCount}</Typography>

			{
				masterFull
					? users.map((master, idx) => <AdminMastersList key={master.id}
																   master={master}
																   expanded={expanded}
																   setExpanded={setExpanded}
																   idx={getRowNum(idx, page)}/>)
					: users.map((user, idx) => <AdminUserLine key={user.id}
															  role={role}
															  user={user}
															  // setOpenPersonal={setOpenPersonal}
															  idx={getRowNum(idx, page)}/>)
			}
			{/*{*/}
			{/*	openPersonal && <AdminOrderListDetail*/}
			{/*		children=''*/}
			{/*		closeHandler={() => setOpenPersonal(false)}*/}
			{/*		title={'Личный кабинет - ' + worker.name}*/}
			{/*	>*/}
			{/*		<AdminWorkerPersonal userId={openPersonal}/>*/}
			{/*	</AdminOrderListDetail>*/}
			{/*}*/}

		</>
	);
};

export default AdminUserList;