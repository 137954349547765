import React, {Component, Fragment} from 'react';
import ActKSTableHeading from "./ActKSTableHeading/ActKSTableHeading";
import ActKSTableBody from "./ActKSTableBody/ActKSTableBody";
import ActKS3TableBody from "./ActKS3TableBody/ActKS3TableBody";


class ActKSTable extends Component {

    render() {
        const {order, period, ks} = this.props
        return (
            <Fragment>
                <ActKSTableHeading order={order} ks={ks}/>
                {
                    ks === 'ks2' &&
                    <ActKSTableBody order={order} period={period}/>
                }
                {
                    ks === 'ks3' &&
                    <ActKS3TableBody order={order} period={period}/>
                }
            </Fragment>
        );
    }
}

export default ActKSTable;