import React from 'react'
import cn from '../Advantages.module.scss'

import notebook from '../../../../img/notebookMainpage.png'

const WorkControl = (props) => {

  const listDataFirst = [
    'видите, на сколько фактически сделаны работы независимо от того выполнен ли объем работ в целостном виде или только его часть, и в результате можете принять решение о следующих шагах; ',
    'знаете, в каком количестве и кто именно из исполнителей выполняет работу на вашем проекте и получаете ежедневно фотоотчет с места выполнения;',
    'по факту выполнения целостного объема, представляющего для вас ценность, вы видите и можете распечатать выполнение за любой период, в форме Акта выполненных работ - КС-2. КС-2 ОНЛАЙН! '
  ]

  return (
    <section className={cn.main}>
			<div className={`${cn.mainWrapper} row`}>
      	<div className={`col-12 col-lg-7 order-2 order-lg-0`}>
					<div className={`${cn.controlWrapper}`}>
							<img src={notebook}/>
					</div>
				</div>
				<div className={`col-12 col-lg-5`}>
					<h3 className={cn.mainTitle}>
						<span className={cn.mainTitleNumber}>02 </span>
						<span className={cn.mainTitleText}>Контроль работ</span>
					</h3>
					<div className={cn.mainTextBlock}>
						<p className={cn.mainParagraph}>
              В Личном кабинете в режиме реального времени вы осуществляете контроль строительных работ: 
						</p>
						<ul className={cn.mainList}>
							{listDataFirst.map((item, index) => (
								<li key={index} className={cn.mainListItem}>
									{item}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
      <div className='test'>
        
      </div>
		</section>
  )
}

export default WorkControl