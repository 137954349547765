import axios from '../../rest/axios'
import {store} from '../../index'
import {
    FETCH_SERVICE_ERROR,
    FETCH_SERVICE_START,
    FETCH_SERVICE_SUCCESS, SWITCH_TAB_CALC, UPDATE_SERVICE_CALC_PROPERTY,
    WORKERS_COUNT
} from "./actionTypes";

export function setWorkerCount() {
    let quantity = store.getState().order.quantity
    let norm = store.getState().serviceCalc.service.norm
    let dueDate = store.getState().order.dueDate


    dueDate = dueDate | 1
    let workerCount = quantity / (norm * dueDate * 8 )
    return {
        type: WORKERS_COUNT,
        payload: Math.ceil(workerCount)
    }
}


export function fetchService() {

    return async dispatch => {
        dispatch(fetchStart())
        try {
            let service = await axios.get('/web/services/group/',
            // let service = await axios.get('/get/services.php',
                {
                params: {
                    id: store.getState().order.service
                }
            })
            dispatch(fetchSuccess(service.data[0]))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}

function fetchStart() {
    return {
        type: FETCH_SERVICE_START
    }
}

function fetchSuccess(service) {
    return {
        type: FETCH_SERVICE_SUCCESS,
        service
    }
}

function fetchError(error) {
    return {
        type: FETCH_SERVICE_ERROR,
        error
    }
}


export function switchTab(id) {
    return {
        type: SWITCH_TAB_CALC,
        payload: id
    }
}

export function updateServiceCalcProperty (value, property) {
    return {
        type: UPDATE_SERVICE_CALC_PROPERTY,
        payload: {
            value,
            property,
        }
    }
}




