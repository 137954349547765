import * as moment from "moment";
import {
	CLEAR_ORDER,
	ORDER_SET_MATERIAL_QUANTITY,
	ORDER_SET_MATERIALS,
	SET_DEFAULT_MATERIAL_QUANTITY,
	SET_ORDER_FORM_CONTROL_VALUE,
	SET_ORDER_PROPERTY,
	SET_ORDER_TRANCHE,
	VALIDATE_FORM_CONTROLS_ORDER,
	WORKERS_COUNT,
} from "../actions/actionTypes";
import { CLEAR_ORDER_FIELDS } from "../actions/confirmOrderActions";

const getLocalStorageValue = (property) => {
	return +localStorage.getItem(property) || "";
};
const service = getLocalStorageValue("service");
const subCategory = getLocalStorageValue("subCategory");
const category = getLocalStorageValue("category");
const quantity = getLocalStorageValue("quantity");
const dueDate = getLocalStorageValue("dueDate");

const initialState = {
	id: "",
	service,
	subCategory,
	category,
	quantity,
	//createDate: moment().format('YYYY-MM-DD'),
	startDate: moment().format("DD.MM.YYYY"),
	dueDate,
	needMaterials: 2,
	workType: 1,
	workerCount: "",
	materials: [],
	currentCategory: null,
	currentService: null,
	formControls: {
		quantity: {
			id: "quantity",
			label: "Объем выполнения",
			type: "text",
			value: getLocalStorageValue("quantity"),
			valid: true,
			error: "",
			validation: {
				required: true,
				number: true,
				// max: 10000
			},
			errorMessage: {
				required: "Обязательное поле",
				number: "Введите число",
				max: "Максимум 10000",
			},
		},
		dueDate: {
			id: "dueDate",
			label: "Срок выполнения (дней)",
			type: "text",
			value: getLocalStorageValue("dueDate"),
			valid: true,
			error: "",
			validation: {
				required: true,
				number: true,
				// max: 365
			},
			errorMessage: {
				required: "Обязательное поле",
				number: "Введите число",
				max: "Максимум 365",
			},
		},
		startDate: {
			id: "startDate",
			label: "Дата начала работ",
			type: "date",
			// value: moment().add(3, 'days').format('DD.MM.YYYY'),
			value: moment().format("DD.MM.YYYY"),
			// value: '',
			valid: true,
			validation: {
				minDate: 0,
			},
			errorMessage: {
				minDate: "Не ранее, чем сегодня",
			},
		},
	},
	tranches: [],
	locationObject: null,
	floor: null,
	withInstrument: null,
	withEquipment: null,
	withHousing: null,
	materialPlace: null,
	docs: 2,
	classifiers: [],
	addDocs: [],
	clear: false,
	startValidate: false,
	loading: false,
	error: null,
};

export default function orderReducer(state = initialState, { type, payload }) {
	let formControls = { ...state.formControls };
	let materials = [...state.materials];
	let material;
	switch (type) {
		case CLEAR_ORDER:
			return {
				...state,
				startDate: moment().format("DD.MM.YYYY"),
				dueDate: 0,
				needMaterials: 2,
				workType: 1,
				service: null,
				subCategory: null,
				category: null,
				quantity: 0,
				serviceSelectStep: 0,
				formControls: {
					quantity: {
						id: "quantity",
						label: "Объем выполнения",
						type: "text",
						value: getLocalStorageValue("quantity"),
						valid: true,
						error: "",
						validation: {
							required: true,
							number: true,
							max: 10000,
						},
						errorMessage: {
							required: "Обязательное поле",
							number: "Введите число",
							max: "Максимум 10000",
						},
					},
					dueDate: {
						id: "dueDate",
						label: "Срок выполнения (дней)",
						type: "text",
						value: getLocalStorageValue("dueDate"),
						valid: true,
						error: "",
						validation: {
							required: true,
							number: true,
							max: 365,
						},
						errorMessage: {
							required: "Обязательное поле",
							number: "Введите число",
							max: "Максимум 365",
						},
					},
					startDate: {
						id: "startDate",
						label: "Дата начала работ",
						type: "date",
						// value: moment().add(3, 'days').format('DD.MM.YYYY'),
						value: moment().format("DD.MM.YYYY"),
						// value: '',
						valid: true,
						validation: {
							minDate: 0,
						},
						errorMessage: {
							minDate: "Не ранее, чем сегодня",
						},
					},
				},
			};
		case SET_ORDER_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value,
			};
		case SET_ORDER_FORM_CONTROL_VALUE:
			let formControl = formControls[payload.property] || null;

			if (formControl) {
				formControl.value = payload.value;
				formControl.error = payload.error;
			}
			return {
				...state,
				formControls,
			};

		case ORDER_SET_MATERIAL_QUANTITY:
			material = materials.find(
				(material) => material.id === payload.materialId,
			);
			let pack = material.goods.pack || material.pack;
			material["quantity"] = payload.quantity * pack;
			return {
				...state,
				materials,
				loading: false,
			};

		case WORKERS_COUNT:
			return {
				...state,
				workerCount: payload.payload,
			};
		case SET_DEFAULT_MATERIAL_QUANTITY:
			materials.forEach((material) => {
				material["quantity"] =
					material.norms * material.overNorms * state.quantity || 0;
			});
			return {
				...state,
				materials,
			};

		case ORDER_SET_MATERIALS:
			return {
				...state,
				materials: payload.materials,
			};

		case VALIDATE_FORM_CONTROLS_ORDER:
			formControls[payload.formControlId].error = payload.error;
			formControls[payload.formControlId].valid = payload.valid;

			return {
				...state,
				formControls,
			};
		case SET_ORDER_TRANCHE:
			const tranches = [...state.tranches].filter(
				(tranche) => +tranche.id !== +payload.id,
			);
			const tranche = {
				id: payload.id,
				count: payload.count,
			};
			tranches.push(tranche);
			return {
				...state,
				tranches,
			};

		case CLEAR_ORDER_FIELDS:
			return {
				...state,
				service: "",
				subCategory: "",
				category: "",
				quantity: "",
				startDate: moment().add(3, "days").format("DD.MM.YYYY"),
				dueDate: "",
				needMaterials: 2,
				workType: 1,
				workerCount: "",
				materials: [],
				clear: true,
				formControls: {
					quantity: {
						id: "quantity",
						label: "Объем выполнения",
						type: "text",
						value: getLocalStorageValue("quantity"),
						valid: true,
						error: "",
						validation: {
							required: true,
							number: true,
							// max: 10000
						},
						errorMessage: {
							required: "Обязательное поле",
							number: "Введите число",
							//  max: 'Максимум 10000'
						},
					},
					dueDate: {
						id: "dueDate",
						label: "Срок выполнения (дней)",
						type: "text",
						value: getLocalStorageValue("dueDate"),
						valid: true,
						error: "",
						validation: {
							required: true,
							number: true,
							//  max: 365
						},
						errorMessage: {
							required: "Обязательное поле",
							number: "Введите число",
							//   max: 'Максимум 365'
						},
					},
					startDate: {
						id: "startDate",
						label: "Дата начала работ",
						type: "date",
						value: moment().add(3, "days").format("DD.MM.YYYY"),
						// value: '',
						valid: true,
						validation: {
							minDate: 3,
						},
						errorMessage: {
							minDate: "Не ранее, чем через 3 дня",
						},
					},
				},
				loading: false,
			};
		default:
			return state;
	}
}
