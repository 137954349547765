import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {renderFormControl} from "../../../../form/formFramework";


const WorkerPassport = ({controls, formControlHandler}) => {

    return (
        <Paper className='p-3 mt-2'>
            <Typography variant="button" className='py-3'>
                Паспортные данные
            </Typography>
            <div className='row'>
                {
                    Object.keys(controls).map(property => {
                        const control = controls[property]
                        return (
                            <div key={control.id} className="col-12 col-sm-6 mt-3">
                                {renderFormControl(control, formControlHandler)}
                            </div>
                        )
                    })
                }
            </div>
        </Paper>
    );
};

export default WorkerPassport;


