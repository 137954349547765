import axios from '../../rest/axios';
import qs from 'qs';
import {
	ADMIN_WORKER_PERSONAL_FETCH_SUCCESS,
	ADMIN_WORKER_PERSONAL_ORDERS_FETCH_SUCCESS, ADMIN_WORKER_PERSONAL_UPDATE_PROPERTY,
} from './actionTypes';

export function updateAdminWorkerPersonalProperty (value, property) {
	return {type: ADMIN_WORKER_PERSONAL_UPDATE_PROPERTY, payload:{value, property}}
}

export function adminWorkerPersonalFetch(userId, token = false) {
	return async dispatch => {
		try {
			if (!token) {
				token = localStorage.getItem('token');
			}
			if (token) {
				// dispatch(workerPersonalFetchStart())
				const {data} = await axios.post('/worker/about/', qs.stringify({
					token,
					userId,
				}));
				dispatch({type: ADMIN_WORKER_PERSONAL_FETCH_SUCCESS, payload: {data: data.data}})
			}

		} catch (e) {
			// dispatch(workerPersonalFetchError(e))
		}
	}
}

export function adminWorkerPersonalOrdersFetch(userId) {
	return async dispatch => {
		let token = localStorage.getItem('token')
		if (token) {
			try {
				// dispatch(workerPersonalFetchStart());
				const {data} = await axios.get('/mobile/orders/getMy/',
					{
						params: {
							token,
							userId,
						}
					}
				);
				dispatch({
					type: ADMIN_WORKER_PERSONAL_ORDERS_FETCH_SUCCESS,
					payload: {
						orders: data.data,
					}
				})
			} catch (e) {
				// dispatch(workerPersonalFetchError(e))
			}
		}
	}
}