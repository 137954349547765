import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {fetchApiStory, fetchErrorList} from "../../../store/actions/apiDocsActions";
import AdLoader from "../ad-loader/ad-loader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";

const ErrorList = () => {
    const dispatch = useDispatch();
    const errorList = useSelector((state) => state.apiDocs.errorList);
    const {loading} = useSelector((state) => state.apiDocs.loading);
    const [page, setPage] = useState(errorList.navigation.currentPage);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        dispatch(fetchErrorList(page));
    }, [dispatch, page]);

    const pagHandler = (evt, value) => {
        setPage(value);
    };

    if (loading) {
        return <AdLoader/>;
    }

    return (
        <div className="py-5">
            {
                !!errorList.items.length && (
                    <>
                        <List>
                            {
                                errorList.items.map((item) => {
                                    return (
                                        <ListItem key={item.id} className="border-bottom d-flex flex-column">
                                            <Typography variant="subtitle1" className="w-100 text-success">
                                                {item.dateCreateFormat}
                                            </Typography>
                                            <Typography variant="subtitle1" className="w-100 text-warning">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body2" className="w-100">
                                                {item.description}
                                            </Typography>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                        <div className="d-flex justify-content-center my-3">
                            <Pagination
                                count={errorList.navigation.pageCount}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={pagHandler}
                                page={errorList.navigation.currentPage}
                                classes={{
                                    root: 'ApiDocs__pagination',
                                }}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default connect()(ErrorList);