import React, {useState} from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Accordion, AccordionDetails} from '@material-ui/core';
import NumberFormat from 'react-number-format';

const AdminOrderListFinanceAccordion = ({children, name, sum}) => {
	const [expanded, setExpanded] = useState(false);
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Accordion
			disabled={!sum}
			expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon/>}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				className="AdminOrderList__finance-accordion-head"
			>
				<div className="w-100 d-flex align-items-center justify-content-between">
					<strong>{name}</strong>
					<strong>
						<NumberFormat
							value={sum}
							displayType={'text'}
							thousandSeparator={" "}/>&nbsp;
						р. </strong>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{children}
			</AccordionDetails>
		</Accordion>
	);
};

export default AdminOrderListFinanceAccordion;