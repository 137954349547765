import axios from "../../rest/axios";
// import qs from 'qs'
import {
    REMOVE_SELECTED_MASTER,
    SET_SELECTED_MASTER, WORKER_SELECT_CLEAR_WORKERS,
    WORKER_SELECT_DETAIL_SUCCESS,
    WORKER_SELECT_SEARCH_SUCCESS
} from "./actionTypes";
import {store} from "../../index";


export function workerSelectSearch(q, roles = 'MASTER,INDEPENDENT_MASTER') {
    const categoryId = store.getState().order.subCategory;

        return async dispatch => {
        if (q.length >= 3) {
            try {
                let response = await axios.get('/web/users/search/', {
                    params: {
                        q: decodeURIComponent(q), roles, categoryId
                    }
                })

                dispatch({type: WORKER_SELECT_SEARCH_SUCCESS, payload: {masters: response.data.data.items}})

            } catch (e) {

            }
        }

    }
}

export function workerSelectDetail(id) {
    return async dispatch => {
        if (id) {
            try {
                let response = await axios.get('/web/users/getDetail/', {
                    params: {
                        id
                    }
                });
                dispatch({type: WORKER_SELECT_DETAIL_SUCCESS, payload: {master: response.data.data}})

            } catch (e) {
                console.warn(e.errorMessage)
            }
        }

    }
}

export function setSelectedMaster() {
    return {
        type: SET_SELECTED_MASTER
    }
}

export function removeSelectedMaster(masterId) {
    return {
        type: REMOVE_SELECTED_MASTER,
        payload: {
            masterId
        }
    }

}

export function clearSelectedWorkers() {
        return{
            type: WORKER_SELECT_CLEAR_WORKERS
        }
}



