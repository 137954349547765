import React, { useEffect, useState } from "react";

import TableCell from "@material-ui/core/TableCell";
import { Typography, Paper, Switch } from "@material-ui/core";
import { setOrderClassifier } from "../../../../../store/actions/adminOrderListActions";
import { useDispatch } from "react-redux";
import { ROLE_ADMINISTRATORS, ROLE_ADMINS } from "../../../../../config";

const AdminOrderListDetailWorkSubmitRow = ({ task, idx, order, role }) => {
	const [items, setItems] = useState([]);
	const dispatch = useDispatch();
	const isAdmin = [ROLE_ADMINISTRATORS, ROLE_ADMINS].includes(role);

	useEffect(() => {
		if (task.items) {
			setItems(task.items);
		}
	}, [task]);

	const getClassifier = (zoneId, type) => {
		return order.classifiers.find(
			(classifier) =>
				classifier.zoneId === zoneId && classifier.type === type.id,
		);
	};

	const setClassifierHandler = (event, type, itemId, id) => {
		dispatch(
			setOrderClassifier(
				order.id,
				type,
				event.target.checked ? 1 : 0,
				[itemId],
				id,
			),
		);
	};

	return (
		<Paper component='tr' colSpan={12} className=''>
			<TableCell
				align='justify'
				className='AdminOrderList__row-left w-40 p-0'
				colSpan={2}>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='w-20'>
						<Typography align='center'>{idx + 1}</Typography>
					</div>
					<div className='w-80'>
						<Typography className='pl-3'>{task.name}</Typography>
					</div>
				</div>
			</TableCell>
			<TableCell align='justify' className='w-60 p-0' colSpan={3}>
				<div className='AdminOrderList__row_list'>
					{items.map((item, idx) => {
						return (
							<div className='d-flex flex-column' key={item.id}>
								<div className='d-flex justify-content-between align-items-center border-left AdminOrderList__row'>
									<div className='AdminOrderList__table-head_50'>
										<Typography className='pl-3'>{idx + 1}</Typography>
									</div>
									<div className='AdminOrderList__table-head_200'>
										<Typography className='pl-3'>{item.place}</Typography>
									</div>
									<div className='AdminOrderList__table-head_75'>
										<Typography className='pl-3'>
											{item.acceptedValue}
										</Typography>
									</div>
									<div className='AdminOrderList__table-head_75'>
										<Typography className='pl-3'>{order.unit}</Typography>
									</div>
									<div className='flex-grow-1 h-100 d-flex align-items-center justify-content-center'>
										{/*{*/}
										{/*	order.classifiers.map(classifier => (*/}
										{/*		<div*/}
										{/*			key={classifier.id}*/}
										{/*			className={`d-flex align-items-center justify-content-center flex-grow-1 px-2`}>*/}
										{/*			<Switch*/}
										{/*				color="primary"*/}
										{/*				checked={!!getClassifierValue(item.id, classifier)}*/}
										{/*				onChange={(e) => setClassifierHandler(classifier.type.id, e.target.value, classifier.id, item.id)}*/}
										{/*			/>*/}
										{/*		</div>*/}
										{/*	))*/}
										{/*}*/}

										{order.classifierTypes.map((type) => {
											const classifier = getClassifier(item.id, type);
											const id = classifier ? classifier.id : null;
											const checked = !!classifier ? !!classifier.value : false;

											return (
												<div
													key={type.id}
													className={`d-flex align-items-center justify-content-center flex-grow-1 px-2`}>
													{(isAdmin || type.role === role) && (
														<Switch
															disabled={checked && !isAdmin}
															color='primary'
															checked={checked}
															onChange={(e) =>
																setClassifierHandler(e, type.id, item.id, id)
															}
														/>
													)}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</TableCell>
		</Paper>
	);
};

export default AdminOrderListDetailWorkSubmitRow;
