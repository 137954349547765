import React from 'react'
import Developing from '../Developing/Developing'

export default function MasterHourly() {
  return (

    <Developing/>

  )
}
