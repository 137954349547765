import React from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import { getSpecialization } from "../../../../utils/roleFunctions";
import { getCategoriesMap } from "./functions";

const WorkerPersonalData = ({ user }) => {
	const {
		name,
		picture,
		role,
		moderation,
		dateInstruction,
		categories,
		birthday,
		description,
		email,
		phone,
		professionMain,
		experienceAdd,
		professionAdd,
		experienceMain,
		professionAdd_2,
		experienceAdd_2,
	} = user;
	const userRole = getSpecialization(role);
	const userCategories = getCategoriesMap(categories);

	return (
		<div className='wp__data row'>
			<div className='col-12 col-lg-4 px-2 pl-lg-0'>
				<Paper className='wp__data-block'>
					<Avatar alt='Avatar' src={picture} className={"wp__avatar_large"} />
					{!!name && (
						<Typography color='textPrimary' variant='h5'>
							{name}
						</Typography>
					)}
				</Paper>
				{(role === 5 || role === 6 || role === 11) && (
					<>
						<Paper className='wp__data-block'>
							<List className='w-100'>
								<ListItem className='d-flex align-items-center border-bottom border-gray p-0'>
									<Typography variant='button' className='mr-3'>
										Модерация:
									</Typography>
									{moderation && (
										<Typography
											variant='overline'
											className={`${
												moderation.id === 1 ? "text-warning" : "text-success"
											}`}>
											{moderation.id === 1
												? "На модерации"
												: "Прошел модерацию"}
										</Typography>
									)}
								</ListItem>
								{moderation.id === 1 && (
									<ListItem className='d-flex align-items-center border-bottom border-gray'>
										{dateInstruction ? (
											<>
												<Typography variant='button' className='mr-3'>
													Дата инструктажа:
												</Typography>
												<Typography variant='overline'>
													{dateInstruction}
												</Typography>
											</>
										) : (
											<Typography color='secondary' variant='overline'>
												<Link
													to={"/worker/personal/dates"}
													className={"text-danger"}>
													Вам необходимо выбрать дату инструктажа.
												</Link>
											</Typography>
										)}
									</ListItem>
								)}
							</List>
						</Paper>
						{role === 5 && (
							<Paper>
								<List className='w-100'>
									<ListItem className='d-flex align-items-center border-bottom border-gray'>
										<Typography variant='button'>Категории</Typography>
									</ListItem>
									{userCategories.map((category, category_idx) => {
										return (
											<ListItem
												key={category.id}
												className='border-bottom border-gray d-flex flex-column align-items-start'>
												<Typography
													variant='button'
													className='w-100 text-left'>
													{category_idx + 1}. {category.name}
												</Typography>
												<List>
													{category.subCategories.map(
														(subCategory, subCategory_idx) => {
															return (
																<ListItem
																	key={subCategory.id}
																	className='px-2 py-1'>
																	<Typography variant='overline'>
																		{subCategory_idx + 1}. {subCategory.name}
																	</Typography>
																</ListItem>
															);
														},
													)}
												</List>
											</ListItem>
										);
									})}
								</List>
							</Paper>
						)}
					</>
				)}
			</div>
			<div className='col-12 col-lg-8 pl-2 pr-0'>
				<Paper className='wp__data-block'>
					<List className='w-100'>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								ФИО:
							</Typography>
							{name && <Typography variant='overline'>{name}</Typography>}
						</ListItem>

						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Дата рождения:
							</Typography>
							<Typography variant='overline'>{birthday}</Typography>
						</ListItem>

						{userRole && (
							<ListItem className='d-flex align-items-center border-bottom border-gray'>
								<Typography variant='button' className='mr-3'>
									Специализация:
								</Typography>
								<Typography variant='overline'>{userRole.name}</Typography>
							</ListItem>
						)}

						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								e-mail:
							</Typography>
							<Typography variant='overline'>{email}</Typography>
						</ListItem>

						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Телефон:
							</Typography>
							<Typography variant='overline'>{phone}</Typography>
						</ListItem>
						{professionMain && (
							<ListItem className='d-flex align-items-center border-bottom border-gray'>
								<Typography variant='button' className='mr-3'>
									Основная профессия:
								</Typography>
								<Typography variant='overline'>
									{professionMain.name}
								</Typography>
								{experienceMain && (
									<Typography variant='overline'>
										&nbsp; - {experienceMain} лет
									</Typography>
								)}
							</ListItem>
						)}

						{user.professionAdd && (
							<ListItem className='d-flex align-items-center border-bottom border-gray'>
								<Typography variant='button' className='mr-3'>
									Дополнительная профессия:
								</Typography>
								<Typography variant='overline'>{professionAdd.name}</Typography>
								{experienceAdd && (
									<Typography variant='overline'>
										&nbsp; - {experienceAdd} лет
									</Typography>
								)}
							</ListItem>
						)}
						{user.professionAdd_2 && (
							<ListItem className='d-flex align-items-center border-bottom border-gray'>
								<Typography variant='button' className='mr-3'>
									Дополнительная профессия:
								</Typography>
								<Typography variant='overline'>
									{professionAdd_2.name}
								</Typography>
								{experienceAdd_2 && (
									<Typography variant='overline'>
										&nbsp; - {experienceAdd_2} лет
									</Typography>
								)}
							</ListItem>
						)}

						{user.workerPrice && (
							<ListItem className='d-flex align-items-center border-bottom border-gray'>
								<Typography variant='button' className='mr-3'>
									Цена за час, руб.:
								</Typography>
								<Typography variant='overline'>{user.workerPrice}</Typography>
							</ListItem>
						)}
					</List>
				</Paper>

				{!!categories.length && role !== 7 && !!description && (
					<Paper className='wp__data-block'>
						<List className='w-100'>
							<ListItem className='d-flex align-items-center border-bottom border-gray flex-column'>
								<Typography variant='button' className='w-100 d-flex'>
									О себе:
								</Typography>
								<Typography variant='overline'>{description}</Typography>
							</ListItem>
						</List>
					</Paper>
				)}
			</div>
		</div>
	);
};

const mapState = (state) => {
	return {
		user: state.workerPersonal.user,
	};
};

export default connect(mapState)(WorkerPersonalData);
