import React, {Fragment} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import parse from 'html-react-parser'
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import cuid from 'cuid';
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {
	addMaterialNorm, deleteMaterialNorm,
	editMaterialNorm,
	setMaterialNorm
} from "../../../../store/actions/adminServiceActions";
import {Autocomplete} from '@material-ui/lab';


const AdminMaterialsNorms = (props) => {
	const {
		setMaterialNorm,
		editMaterialNorm,
		deleteMaterialNorm,
		serviceId,
		materials,
		addMaterialNorm,
		services
	} = props;

	const setMaterialHandler = (materialId, value, property, itemId = null) => {
		setMaterialNorm(serviceId, materialId, +value, property, itemId)
	}

	const minusHandler = (materialId, value, itemId = null) => {
		setMaterialNorm(serviceId, materialId, +value - 0.01 < 0 ? 0 : (+value - 0.01).toFixed(2), 'overNorms', itemId)
	}

	const plusHandler = (materialId, value, itemId = null) => {
		setMaterialNorm(serviceId, materialId, (+value + 0.01).toFixed(2), 'overNorms', itemId)
	}

	const addMaterialHandler = (itemId = null) => {

		const service = services.find(service => service.id === serviceId)
		if (itemId) {
			// выбираем профессии, которые не учавствуют
			const subService = service.items.find(item => item.id === itemId)

			const activeMaterials = subService.materials.map(material => material.id)
			const freeMaterials = materials.filter(material => {

				return activeMaterials.indexOf(material.id) < 0
			})

			if (!!freeMaterials.length) {
				const material = {...freeMaterials[0], norms: 0, overNorms: 1, edit: true}
				addMaterialNorm(service.id, material, itemId)
			}
		} else {
			// выбираем материалы, которые не учавствуют
			const activeMaterials = service.materials.map(material => material.id)
			const freeMaterials = materials.filter(material => {
				return activeMaterials.indexOf(material.id) < 0
			})

			if (!!freeMaterials.length) {
				const material = {...freeMaterials[0], norms: 0, overNorms: 1, edit: true}
				addMaterialNorm(service.id, material, itemId)
			}
		}

	}

	const editMaterialHandler = (materialId, id, itemId = null) => {
		if (!!materials.length) {
			const selectMaterial = materials.find(material => {
				return material.id === id
			})
			const material = {...selectMaterial, norms: 0, overNorms: 1, goods: [], edit: true}
			editMaterialNorm(serviceId, materialId, material, itemId)
		}

	}

	const deleteMaterialHandler = async (materialId, itemId = null) => {
		await deleteMaterialNorm(serviceId, materialId, itemId)
	}


	const service = services.find(service => service.id === serviceId)
	const height = '70px'

	return (
		<List className="px-0">
			{
				!!service.items.length ?
					<ListItem className='p-0 m-0 align-items-baseline'>
						<List className="p-0 m-0 w-100">
							<ListItem className='py-0 overflow-hidden align-items-center border-top border-gray'
									  style={{height}}/>
							{
								service.items.map((item) => {
									return (
										<ListItem key={item.id} className='p-0 m-0 align-items-baseline '>
											{
												item.materials.length > 0 ?

													<List className='p-0 m-0 w-100'
													>

														{
															item.materials.map((materialItem, materialItem_idx) => {
																let freeMaterials = []
																if (materialItem.edit) {
																	const activeMaterials = item.materials.map(norm => norm.id)
																	freeMaterials = materials.filter(material => {
																		return activeMaterials.indexOf(material.id) < 0 || material.id === materialItem.id
																	})
																}

																return (
																	<ListItem key={materialItem.id}
																			  className='overflow-hidden align-items-center py-0 border-bottom bottom-gray'
																			  style={{height}}
																	>


																		{
																			materialItem.edit ?
																				<FormControl variant="outlined"
																							 className='Admin_select col-6'>
																					{/*<Select*/}
																					{/*	className="Admin_select-list"*/}
																					{/*	size={5}*/}
																					{/*	value={materialItem.id}*/}
																					{/*	onChange={evt => this.editMaterialHandler(materialItem.id, evt.target.value, item.id)}*/}
																					{/*>*/}
																					{/*	{freeMaterials.map(material => {*/}
																					{/*		return (*/}

																					{/*			<MenuItem*/}
																					{/*				key={material.id}*/}
																					{/*				value={material.id}>*/}
																					{/*				{material.name}*/}
																					{/*			</MenuItem>*/}
																					{/*		)*/}
																					{/*	})}*/}

																					{/*</Select>*/}
																					<Autocomplete
																						id="item-material"
																						options={freeMaterials}
																						noOptionsText="Ничего не найдено"
																						getOptionLabel={(material) => material.name}
																						onChange={(evt, value) => editMaterialHandler(materialItem.id, value.id, item.id)}
																						renderInput={(params) =>
																							<TextField {...params}
																									   label={materialItem.name || "Выберите материал"}
																									   variant="outlined"/>
																						}
																					/>
																				</FormControl>
																				:
																				<Typography
																					variant="subtitle1"
																					className="col-6 p-0 pl-2 m-0"><span
																					className="mr-1"> {materialItem_idx + 1}</span> {materialItem.name}
																				</Typography>
																		}

																		<Typography variant="subtitle1"
																					className="col-1 p-0 pl-2 m-0">{parse(materialItem.unit)}</Typography>

																		<div
																			className="h-100 col-4 p-0 pl-2 pr-3 d-flex align-items-center justify-content-center">
																			<TextField
																				type="number"
																				value={materialItem.norms}
																				className="Admin_cell col-4"
																				variant="outlined"
																				onChange={(e) => setMaterialHandler(materialItem.id, e.target.value, 'norms', item.id)}
																			/>
																			<div
																				className='Admin_counter col-8 p-0 px-2 d-flex align-items-center justify-content-center'>
																				<Button
																					className="Admin_btn col"
																					variant="contained"
																					color="primary"
																					onClick={() => minusHandler(materialItem.id, materialItem.overNorms, item.id)}
																				>
																					<RemoveIcon
																						className='Admin_btn-icon Admin_btn-icon-create'/>
																				</Button>
																				<TextField
																					type="number"
																					value={materialItem.overNorms}
																					className="Admin_cell"
																					variant="outlined"
																					onChange={(e) => setMaterialHandler(materialItem.id, e.target.value, 'overNorms', item.id)}
																				/>
																				<Button
																					className="Admin_btn col"
																					variant="contained"
																					color="primary"
																					onClick={() => plusHandler(materialItem.id, materialItem.overNorms, item.id)}
																				>
																					<AddIcon
																						className='Admin_btn-icon Admin_btn-icon-create'/>
																				</Button>

																			</div>
																		</div>


																		<div
																			className='col-1 d-flex px-0 pl-2 justify-content-end align-items-center'>
																			{
																				materialItem.edit ?
																					<Button
																						className="Admin_btn ml-2"
																						variant="contained"
																						color="primary"
																						onClick={() => setMaterialHandler(materialItem.id, false, 'edit', item.id)}
																					>
																						<SaveIcon
																							className='Admin_btn-icon Admin_btn-icon-create'/>
																					</Button>
																					:
																					<Button
																						className="Admin_btn ml-2"
																						variant="contained"
																						color="primary"
																						onClick={() => setMaterialHandler(materialItem.id, true, 'edit', item.id)}
																					>
																						<EditIcon
																							className='Admin_btn-icon Admin_btn-icon-create'/>
																					</Button>
																			}
																			<Button
																				className="Admin_btn col ml-2"
																				variant="contained"
																				color="secondary"
																				onClick={() => deleteMaterialHandler(materialItem.id, item.id)}
																			>
																				<DeleteIcon
																					className='Admin_btn-icon Admin_btn-icon-create'/>
																			</Button>
																		</div>

																	</ListItem>
																)
															})
														}

														<ListItem
															className='w-100 d-flex align-self-center align-items-center justify-content-end  border-bottom border-gray'
															style={{height}}>
															<Button
																className="Admin_btn"
																variant="contained" color="primary"
																onClick={() => addMaterialHandler(item.id)}
															>
																<AddCircleOutlineIcon
																	className='Admin_btn-icon Admin_btn-icon-create'/>
															</Button>
														</ListItem>


													</List>
													:
													<List className='w-100 p-0 m-0 d-flex border-bottom border-gray'
														  style={{height}}>
														<ListItem
															className='w-100 d-flex align-self-center align-items-center justify-content-end'
														>
															<Button
																className="Admin_btn"
																variant="contained" color="primary"
																onClick={() => addMaterialHandler(item.id)}
															>
																<AddCircleOutlineIcon
																	className='Admin_btn-icon Admin_btn-icon-create'/>
															</Button>
														</ListItem>
													</List>
											}

										</ListItem>
									)
								})
							}
						</List>
					</ListItem>
					:
					<ListItem className='p-0 m-0 align-items-baseline '>
						<List className='p-0 m-0 w-100'>

							{
								service.materials.map((materialItem, material_idx) => {
									let freeMaterials = []
									if (materialItem.edit) {
										const activeMaterials = service.materials.map(material => material.id)
										freeMaterials = materials.filter(material => {
											return activeMaterials.indexOf(material.id) < 0 || material.id === materialItem.id
										})
									}
									return (
										<ListItem key={materialItem.id}
												  className='py-0 overflow-hidden align-items-center border-top border-gray'
												  style={{height}}>

											<Fragment>
												{
													materialItem.edit
														?
														<FormControl variant="outlined"
																	 className='Admin_select col-6'>
															{/*<Select*/}
															{/*	className="Admin_select-list"*/}
															{/*	size={5}*/}
															{/*	value={materialItem.id}*/}
															{/*	onChange={evt => this.editMaterialHandler(materialItem.id, evt.target.value)}*/}
															{/*>*/}
															{/*	{freeMaterials.map(material => {*/}
															{/*		return (*/}
															{/*			<MenuItem*/}
															{/*				key={material.id}*/}
															{/*				value={material.id}>*/}
															{/*				{material.name}*/}
															{/*			</MenuItem>*/}
															{/*		)*/}
															{/*	})}*/}

															{/*</Select>*/}

															<Autocomplete
																id="service-material"
																options={freeMaterials}
																noOptionsText="Ничего не найдено"
																getOptionLabel={(material) => material.name}
																onChange={(evt, value) => editMaterialHandler(materialItem.id, value.id)}
																renderInput={(params) => <TextField {...params}
																									label={materialItem.name || "Выберите материал"}
																									variant="outlined"/>}
															/>

														</FormControl>
														:
														<Typography variant="subtitle1"
																	className="col-6 p-0 pl-2 m-0"><span
															className="mr-1"> {material_idx + 1}</span> {materialItem.name}
														</Typography>
												}


												<Typography variant="subtitle1"
															className="col-1 p-0 pl-2 m-0">{parse(materialItem.unit)}</Typography>

												<div
													className="h-100 col-4 p-0 pr-3 d-flex align-items-center justify-content-center">
													<TextField
														type="number"
														value={materialItem.norms}
														className="Admin_cell col-4"
														variant="outlined"
														onChange={(e) => setMaterialHandler(materialItem.id, e.target.value, 'norms')}
													/>
													<div
														className='Admin_counter col-8 p-0 px-2 d-flex align-items-center justify-content-center'>
														<Button
															className="Admin_btn col"
															variant="contained"
															color="primary"
															onClick={() => minusHandler(materialItem.id, materialItem.overNorms)}
														>
															<RemoveIcon
																className='Admin_btn-icon Admin_btn-icon-create'/>
														</Button>
														<TextField
															type="number"
															value={materialItem.overNorms}
															className="Admin_cell"
															variant="outlined"
															onChange={(e) => setMaterialHandler(materialItem.id, e.target.value, 'overNorms')}
														/>
														<Button
															className="Admin_btn col"
															variant="contained"
															color="primary"
															onClick={() => plusHandler(materialItem.id, materialItem.overNorms)}
														>
															<AddIcon
																className='Admin_btn-icon Admin_btn-icon-create'/>
														</Button>

													</div>
												</div>

											</Fragment>


											<div
												className='col-1 d-flex px-0 pl-2 justify-content-end align-items-center align-self-center'>
												{
													materialItem.edit ?
														<Button
															className="Admin_btn ml-2"
															variant="contained" color="primary"
															onClick={() => setMaterialHandler(materialItem.id, false, 'edit')}
														>
															<SaveIcon
																className='Admin_btn-icon Admin_btn-icon-create'/>
														</Button>
														:
														<Button
															className="Admin_btn ml-2"
															variant="contained" color="primary"
															onClick={() => setMaterialHandler(materialItem.id, true, 'edit')}
														>
															<EditIcon
																className='Admin_btn-icon Admin_btn-icon-create'/>
														</Button>
												}
												<Button
													className="Admin_btn col ml-2"
													variant="contained" color="secondary"
													onClick={() => deleteMaterialHandler(materialItem.id)}
												>
													<DeleteIcon
														className='Admin_btn-icon Admin_btn-icon-create'/>
												</Button>
											</div>

										</ListItem>
									)
								})
							}

							<ListItem
								className='w-100 d-flex align-items-center justify-content-end border-top border-bottom border-gray'
								style={{height}}>
								<Button
									className="Admin_btn align-self-center"
									variant="contained" color="primary"
									onClick={() => addMaterialHandler()}
								>
									<AddCircleOutlineIcon
										className='Admin_btn-icon Admin_btn-icon-create'/>
								</Button>
							</ListItem>

						</List>
					</ListItem>
			}
		</List>
	);


}

const mapState = (state) => {
	return {
		services: state.adminService.services,
		materials: state.adminService.materials
	}
}
const actions = {
	setMaterialNorm,
	addMaterialNorm,
	editMaterialNorm,
	deleteMaterialNorm
}
export default connect(mapState, actions)(AdminMaterialsNorms);
