import React from 'react'
import cn from './BgBlot.module.scss'

const BgBlot = (props) => {

  return (
    <div className={cn.wrapper}>
      <img src={props.image} className={cn.image} style={{...props}}/>
    </div>
  )
}

export default BgBlot