import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkerPersonalState } from "../../../../store/selectors";
import {
	fetchWorkerPersonalOrderDetail,
	fetchWorkerPersonalOrderReports,
	setWorkerPersonalProperty,
} from "../../../../store/actions/workerPersonalActions";
import WorkerPersonalReportsDays from "./WorkerPersonalReportsDays";
import BackBtn from "../../../../components/UI/BackBtn/BackBtn";
import { ROLE_MANAGER, ROLE_MASTER } from "../../../../config";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const WorkerPersonalReportsDetail = (props) => {
	const { order, reports, user } = useSelector(getWorkerPersonalState);
	const [orderId, setOrderId] = useState();
	const [masterId, setMasterId] = useState(null);
	const dispatch = useDispatch();
	const isManager = user.role === ROLE_MANAGER;
	const isMaster = user.role === ROLE_MASTER;

	const getReportsCount = (worker) => {
		return reports.filter((report) => report.master === worker.id).length;
	};

	const clearReports = () => {
		dispatch(setWorkerPersonalProperty([], "reports"));
		props.history.push("/worker/personal/reports");
	};

	useEffect(() => {
		if (orderId) {
			dispatch(fetchWorkerPersonalOrderDetail(orderId));
			dispatch(fetchWorkerPersonalOrderReports(orderId));
		}
	}, [orderId]);

	useEffect(() => {
		setOrderId(props.match.params.id);
	}, [props]);

	return (
		<>
			{!!order.id && (
				<>
					{
						// кнопка назад
						masterId ? (
							<BackBtn onClick={() => setMasterId(null)} variant='dark' />
						) : (
							<BackBtn onClick={clearReports} variant='dark' />
						)
					}
					{isManager && (
						<>
							{!masterId && ( // не выбран конкретный мастер
								<>
									<div className='d-flex flex-column'>
										<Typography variant='h6'>{order.serviceName}</Typography>
										<br />
										<div className='d-flex flex-column mr-5'>
											<Typography variant='h6'>
												Итого объем: {order.reportData.totalValue}
											</Typography>
											<Typography variant='h6'>
												Итого сумма: {order.reportData.totalSumm}
											</Typography>
										</div>
									</div>
									<hr />
									{order.workers.map((worker, idx) => (
										<Paper
											key={worker.id}
											className='wp__line-user wp__line p-3 my-1'
											onClick={() => setMasterId(worker.id)}>
											<div className='w-100 d-flex justify-content-between align-items-center'>
												<div className='col-6 d-flex justify-content-between align-items-center'>
													<div className='w-20 p-0 d-flex justify-content-between align-items-center'>
														<span className='mr-3'>
															{" "}
															{idx + 1}. / {worker.id}
														</span>
														<Avatar alt='Cindy Baker' src={worker.picture} />
													</div>
													<div className={`w-50 p-0 m-0 d-flex flex-column`}>
														<div className='d-flex align-items-center'>
															<span>{worker.name}</span>
														</div>
													</div>
													<div className='w-30 col-1 p-0 m-0'>
														{worker.phone}
													</div>
												</div>
												<div className='d-flex align-items-center justify-content-center'>
													<div className='wp__count'>
														{getReportsCount(worker)}
													</div>
												</div>
											</div>
										</Paper>
									))}
								</>
							)}
						</>
					)}
					{(masterId || isMaster) && (
						<WorkerPersonalReportsDays masterId={masterId} />
					)}
				</>
			)}
		</>
	);
};
export default WorkerPersonalReportsDetail;
