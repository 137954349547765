import React, {useEffect} from 'react'
import './ServiceCalculateBottom.scss'

import Table from 'react-bootstrap/Table'
import parse from 'html-react-parser'
import CountUp from "react-countup";
import * as moment from 'moment'
import {useSelector} from "react-redux";
import Th from "../../../../../../components/UI/Th/Th";
import Td from "../../../../../../components/UI/Td/Td";
import TooltipBtn from "../../../../../../components/UI/TooltipBtn/TooltipBtn";
import DraggableScroll from "../../../../../../components/UI/DraggableScroll/DraggableScroll";
import {useHistory} from 'react-router';
import {getOrderState} from '../../../../../../store/selectors';
import {useConditionsContext} from '../../../../../../app/containers/Calculator/ConditionsContext';


const ServiceCalculateBottom = () => {
	const history = useHistory();
	const {
		currentService,
		quantity, startDate, dueDate,
	} = useSelector(getOrderState);
	const {servicePrice, totalServicePrice} = useConditionsContext();

	function DateRow() {
		let row = [];

		for (let i = 0; i < +dueDate; i++) {
			row.push(<Th key={i}>
				<div className="ServiceCalcBottom_rightBlock-heads">
					<strong
						className='text-capitalize'>{moment(startDate, "DD.MM.YYYY").add(i, 'd')
						.format("DD.MM.YYYY")}</strong>
					{/*<span className='text-capitalize'>{moment(props.order.startDate).add(i, 'd').format("dddd")}</span>*/}
					<span className=''>{i + 1} день</span>
				</div>
			</Th>)
		}

		return row
	}

	function AmountRow() {
		let row = [];
		// let sum = props.getSum(table.id)
		for (let i = 0; i < +dueDate; i++) {
			let amount = (quantity / dueDate).toFixed(2)
			row.push(<Td key={i} width={120}>
				{
					<CountUp
						end={+amount}
						duration={.3}
						decimals={2}
						formattingFn={(number) => {
							return new Intl.NumberFormat('ru-RU').format(number)
						}}
					/>
				}
			</Td>)
		}
		return row
	}

	function FinanceRow() {
		let row = [];
		for (let i = 0; i < +dueDate; i++) {
			let financialValue = (totalServicePrice / dueDate).toFixed(2)

			if (financialValue && !isNaN(+financialValue)) {

				row.push(<Td key={i} width={120}>
					{
						<CountUp
							end={+financialValue}
							duration={.3}
							decimals={2}
							formattingFn={(number) => {
								return new Intl.NumberFormat('ru-RU').format(number)
							}}
						/>
					}
				</Td>)
			}
		}
		return row
	}

	useEffect(() => {
		if (!currentService) {
			history.push('/app')
		}
	}, [currentService]);

	return (
		<>
			{
				currentService && servicePrice
					&&  (
					<div className="ServiceCalcBottom p-4 rounded mt-1">
						<div className="ServiceCalcBottom_leftBlock">
							<Table borderless>
								<thead>
								<tr className="ServiceCalcBottom_headRow">
									<Th colSpan={1}/>
									<Th>
										Ед. изм.
									</Th>
								</tr>
								</thead>
								<tbody>
								<tr className="ServiceCalcBottom_bodyRow">
									<Td colSpan={1}>
										{/*<TooltipBlueWhite right={"10%"} top={5}*/}
										{/*content={'Равномерно распределены по дням '}/>*/}
										<div className="WidthToolTip">Объемы
											<TooltipBtn content={'Равномерно распределены по дням '}/>
										</div>

									</Td>
									<Td>{currentService.unit && parse(currentService.unit)}</Td>
								</tr>
								<tr className="ServiceCalcBottom_bodyRow">
									<Td colSpan={1}>Фин. выполнение</Td>
									<Td>руб.</Td>
								</tr>

								</tbody>

							</Table>
						</div>
						<DraggableScroll>
							<div className="ServiceCalcBottom_rightBlock">
								<Table borderless>
									<thead>
									<tr className="ServiceCalcBottom_headRow">
										<DateRow/>
									</tr>
									</thead>
									<tbody>
									<tr className="ServiceCalcBottom_bodyRow">
										<AmountRow/>
									</tr>
									<tr className="ServiceCalcBottom_bodyRow">
										<FinanceRow/>
									</tr>
									</tbody>
								</Table>
							</div>
						</DraggableScroll>
					</div>
				)
			}
		</>
	)
}

export default ServiceCalculateBottom;