import axios from 'axios';
import {
    API_DOCS_FETCH_API_STORY_SUCCESS,
    API_DOCS_FETCH_ERROR, API_DOCS_FETCH_ERROR_LIST_SUCCESS,
    API_DOCS_FETCH_START,
    API_DOCS_FETCH_SUCCESS, API_DOCS_RESPONSE_FETCH_SUCCESS,
    API_DOCS_SET_PROP,
    API_USER_STORY_FETCH_SUCCESS,
} from './actionTypes';

const baseUrl = 'https://master.finish-worker.ru';

function fetchStart() {
    return {
        type: API_DOCS_FETCH_START,
    };
}

function fetchSuccess(groups) {
    return {
        type: API_DOCS_FETCH_SUCCESS,
        payload: {
            groups,
        },
    };
}

function fetchError(error) {
    return {
        type: API_DOCS_FETCH_ERROR,
        payload: {
            error,
        },
    };
}

function fetchResponseSuccess(response) {
    return {
        type: API_DOCS_RESPONSE_FETCH_SUCCESS,
        payload: {
            response,
        },
    };
}

function fetchUserStorySuccess(userStory) {
    return {
        type: API_USER_STORY_FETCH_SUCCESS,
        payload: {
            userStory,
        },
    };
}

export function fetchEndPoints() {
    return async (dispatch) => {
        dispatch(fetchStart());
        try {
            const groups = await axios.get(`${baseUrl}/api/documentation/endPoints/`);
            if (groups.data) {
                dispatch(fetchSuccess(groups.data.data));
            }
        } catch (e) {
            dispatch(fetchError(e));
        }
    };
}

export function setApiProperty(value, property) {
    return {
        type: API_DOCS_SET_PROP,
        value,
        property,
    };
}

export function fetchApiList() {
    return async (dispatch) => {
        dispatch(fetchStart());
        try {
            const list = await axios.get(`${baseUrl}documentation/`);
            dispatch(fetchSuccess(list.data));
        } catch (e) {
            dispatch(fetchError(e));
        }
    };
}

export function fetchResponse(endPoint) {
    return async (dispatch) => {
        dispatch(fetchStart());
        try {
            const url = `${endPoint}doc.php`;
            const response = await axios.get(baseUrl + url);
            dispatch(fetchResponseSuccess(response.data));
        } catch (e) {
            dispatch(fetchError(e));
        }
    };
}

export function fetchUserStory() {
    return async (dispatch) => {
        dispatch(fetchStart());
        try {
            const userStory = await axios.get(`${baseUrl}/api/documentation/userStory/`);
            dispatch(fetchUserStorySuccess(userStory.data.data));
        } catch (e) {
            dispatch(fetchError(e));
        }
    };
}

export function fetchApiStory(page = 1, size = 10) {
    return async (dispatch) => {
        dispatch(fetchStart());
        try {
            const apiStory = await axios.get(`${baseUrl}/api/documentation/apiStory/getList/`, {
                params: {
                    nav: `page-${page}-size-${size}`,
                },
            });
            dispatch({
                type: API_DOCS_FETCH_API_STORY_SUCCESS,
                payload: {apiStory: apiStory.data.data},
            });
        } catch (e) {
            dispatch(fetchError(e));
        }
    };
}

export function fetchErrorList(page = 1, size = 10) {
    return async (dispatch) => {
        dispatch(fetchStart());
        try {
            const errorList = await axios.get(`${baseUrl}/api/documentation/errorTexts/getList/`, {
                params: {
                    nav: `page-${page}-size-${size}`,
                },
            });
            dispatch({
                type: API_DOCS_FETCH_ERROR_LIST_SUCCESS,
                payload: {errorList: errorList.data.data},
            });
        } catch (e) {
            dispatch(fetchError(e));
        }
    };
}