import {
    FETCH_WORKERS_ERROR,
    FETCH_WORKERS_START,
    FETCH_WORKERS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
    workers: [],
    loading: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_WORKERS_START:
            return {
                ...state,
                loading: true
            }
        case FETCH_WORKERS_SUCCESS:
            return {
                ...state,
                workers: action.workers,
                loading: false
            }
        case FETCH_WORKERS_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}