import React, {useEffect, useRef, useState} from 'react';
import './ServiceWorkerSelect.scss';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import cuid from 'cuid'


import ph from '../../../../img/nophoto.jpg'
import Avatar from "@material-ui/core/Avatar";
import GreenButton from "../../../../components/UI/GreenButton/GreenButton";
import {
	setSelectedMaster,
	workerSelectDetail,
	workerSelectSearch
} from "../../../../store/actions/workerSelectActions";
import {useDispatch, useSelector} from "react-redux";
import ServiceWorkerSelectItem from "./ServiceWorkerSelectItem";
import Button from "@material-ui/core/Button";

const ServiceWorkerSelect = () => {
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState('');
	const [options, setOptions] = useState([]);
	const [clearKey, setClearKey] = useState('');

	const dispatch = useDispatch();
	const {masters, loading, selectedMasters, activeMaster} = useSelector(state => state.workerSelect);

	const inputRef = useRef();


	useEffect(() => {
		if (query) {
			const delay = setTimeout(
				() => {
					dispatch(workerSelectSearch(query))
				}, 300
			);
			return () => {
				clearTimeout(delay);
			};
		}
	}, [query, dispatch]);

	useEffect(() => {
		setOptions(masters);
	}, [masters]);

	useEffect(()=>{
	    setQuery('');
    }, [selectedMasters]);

	const getDetail = (e, value) => {
		if (value && value.id) {
			dispatch(workerSelectDetail(value.id))
		}
	};

	const setSelected = () => {
		dispatch(setSelectedMaster())
		setClearKey(cuid())
	};


	return (
		<div className='mt-5'>
			{/*<ServiceSelectWrapper>*/}
				<div
					className='ServiceWorkerSelect rounded p-3 d-flex justify-content-center flex-column align-items-center'>
					<Typography variant='button'>
						Вы можете выбрать исполнителей для этого задания
					</Typography>

					<div className="row w-100 justify-content-center d-flex">
						<div className='col-12 mt-3 d-flex align-items-center'>
							<Autocomplete
								key={clearKey}
								id='worker-select'
								ref={inputRef}
								classes={
									{
										listbox: 'worker_select__list'
									}
								}
								className='w-100'
								open={open}
								onOpen={() => {
									setOpen(true);
								}}
								onClose={() => {
									setOpen(false);
								}}
                                inputValue={query}
								getOptionSelected={(option, value) => option.name === value.name}
								getOptionLabel={(option) => option.name}
								renderOption={(option) => (
									<div
										className='d-flex w-100 justify-content-between border-bottom border-gray py-2 align-items-center'>
										<span>{option.name}</span>
										<span
											className={`text-${option.isBusy ? 'danger' : 'success'}`}>{option.isBusy ? 'Занят' : 'Свободен'}</span>
									</div>
								)}
								options={options}
								loading={loading}
								clearOnBlur
								clearOnEscape
								clearText="Очистить"
								noOptionsText='Ничего не найдено'
								onChange={getDetail}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Введите имя мастера"
										variant="outlined"
										onChange={(e) => setQuery(e.target.value)}
                                        value={query}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? <CircularProgress color="inherit" size={20}/> : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</div>
						{
							activeMaster.id &&
							<div
								className='col-12 d-flex align-items-center'>

								<div className='col-12 col-sm-2 p-3 d-flex'>
									<Avatar src={activeMaster.picture || ph} className='ServiceWorkerSelect__avatar'/>
								</div>


								<div className="col-12 col-sm-3">
									<Typography variant='subtitle1'>
										{
											activeMaster.name
										}
									</Typography>
								</div>


								<div className="col-12 col-sm-2">
									<Typography variant='subtitle1'>
										{
											activeMaster.professionMain
										}
									</Typography>
								</div>


								<div className="col-12 col-sm-3">
									<Typography variant='subtitle1'
												className={`${activeMaster.isBusy ? 'text-danger' : 'text-success'}`}>
										{
											activeMaster.isBusy ? 'Бригадир занят' : 'Бригадир свободен'
										}
									</Typography>
								</div>

								<div className="col-12 col-sm-3 d-flex justify-content-center">
									<Button size="small" variant='outlined' onClick={setSelected}
											disabled={activeMaster.isBusy}>
										<Typography variant='subtitle1'
													className='px-3'><span>Выбрать</span></Typography>
									</Button>
								</div>

							</div>
						}
					</div>

					{
						selectedMasters.map(master => {
							return (
								<ServiceWorkerSelectItem key={master.id} master={master}/>
							)
						})
					}

				</div>

				{
					!!selectedMasters.length &&
					<div className='d-flex flex-column flex-sm-row justify-content-end align-items-center mt-4'>
						<GreenButton
							className='mt-2 mt-sm-0'
							to={'/confirm'}
						>
							Продолжить
						</GreenButton>
					</div>
				}

			{/*</ServiceSelectWrapper>*/}
		</div>
	);
};

export default ServiceWorkerSelect;