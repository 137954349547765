import React from "react";
import "./EstimateUI.scss";

export default function EstimateRadioInput({ element, clickHandler }) {
  return (
    <div className="estimate-radio-input-wrapper first-type">
      {element.label && (
        <span className={`radio-input-title ${element.id}-title`}>
          {element.label}
        </span>
      )}
      {element.options.map((option) => (
        <label
          className="label-pointer"
          onClick={() => clickHandler(option.id, element.id)}
        >
          <input
            checked={element.value === option.id}
            type="radio"
            name={element.id}
            value={option.id}
            className="estimate-hidden-input"
          />
          <span className="fake-input"></span>
          <div className="estimate-radio-input-text">
            <span className="estimate-radio-input-label">{option.label}</span>
            {option.sublabel && (
              <div className="estimate-radio-input-sublabel">{option.sublabel}</div>
            )}
          </div>
        </label>
      ))}
    </div>
  );
}
