import React, { useRef, useState } from "react";
import "./AdminAuth.scss";
import { connect } from "react-redux";
import { validateControl } from "../../../form/formFramework";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import WorkerHomeLink from "../../Worker/WorkerComponents/WorkerHomeLink";
import WorkerAuthFooter from "../../Worker/WorkerComponents/WorkerAuthFooter";
import { adminLogin } from "../../../store/actions/adminActions";

const AdminAuth = (props) => {
	const { error, formControls, adminLogin } = props;
	const [controls, setControls] = useState(formControls || {});
	// const [formValid, setFormValid] = useState(true);
	const [formTouched, setFormTouched] = useState(false);

	const formRef = useRef();

	const formSubmitHandler = (evt) => {
		evt.preventDefault();
		setFormTouched(true);
		if (checkFormHandler()) {
			sendFormDataHandler();
		}
	};

	const sendFormDataHandler = () => {
		const formData = {};
		const formControls = { ...controls };
		Object.keys(formControls).forEach((property) => {
			formData[property] = formControls[property].value;
		});
		adminLogin(formData);
	};

	const inputHandler = (evt) => {
		if (formTouched) {
			checkFormHandler();
		} else {
			const formControls = { ...controls };
			const control = formControls[evt.target.id];
			control.value = evt.target.value;
			setControls(formControls);
		}
	};

	const checkFormHandler = () => {
		const formControls = { ...controls };
		const form = formRef.current;
		let isFormValid = true;

		Object.keys(formControls).forEach((property) => {
			const control = formControls[property];
			const value = form[control.id].value;
			const error = validateControl(value, control.validation);
			isFormValid = isFormValid && !error;
			control.error = error;
			control.value = value;
		});
		setControls(formControls);
		// setFormValid(isFormValid);
		return isFormValid;
	};
	return (
		<div className='AdminAuth'>
			<WorkerHomeLink />
			<div className='WorkerAuthForm_container'>
				<form onSubmit={formSubmitHandler} autoComplete={"on"} ref={formRef}>
					<div className='WorkerAuthForm_name mb-2'>Авторизация</div>
					{error && (
						<Typography
							className='d-flex justify-content-center '
							variant='button'
							color='secondary'>
							{error}
						</Typography>
					)}
					<TextField
						id={"email"}
						error={!!controls.email.error}
						label='EMAIL'
						className='mb-4'
						value={controls.email.value}
						onChange={inputHandler}
						helperText={`${
							!!controls.email.error
								? controls.email.errorMessage[controls.email.error]
								: ""
						}`}
						variant='outlined'
					/>
					<TextField
						id={"password"}
						error={!!controls.password.error}
						label='Пароль'
						className='mb-4'
						type='password'
						value={controls.password.value}
						onChange={inputHandler}
						helperText={`${
							!!controls.password.error
								? controls.password.errorMessage[controls.password.error]
								: ""
						}`}
						variant='outlined'
					/>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						className='mt-2'>
						Войти
					</Button>
				</form>
			</div>
			<WorkerAuthFooter />
		</div>
	);
};
const mapState = (state) => {
	return {
		formControls: state.admin.formControls,
		error: state.admin.error,
		// token: state.workerPersonal.token,
		// error: state.workerPersonal.error,
		// authType: state.workerPersonal.authType,
	};
};
const actions = {
	adminLogin,
	// workerPersonalReg,
	// workerPersonalLogin,
	// setWorkerPersonalProperty
};

export default connect(mapState, actions)(AdminAuth);
