import React, { useState } from "react";
import ComplexHead from "./ComplexHead";
import complexTabs from "./complexTabs";
import "./Complex.scss";

export default function Complex() {
  const [activeTab, setActiveTab] = useState("estimate");

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="complex-wrapper">
      <ComplexHead activeTab={activeTab} clickHandler={changeTab} />
      {complexTabs[activeTab].component}
    </div>
  );
}
