import axios from "../../rest/axios";
import {
    CREATE_WORKER_ERROR,
    CREATE_WORKER_START, CREATE_WORKER_SUCCESS,
    FETCH_WORKER_CREATE_CATEGORIES_ERROR,
    FETCH_WORKER_CREATE_CATEGORIES_START,
    FETCH_WORKER_CREATE_CATEGORIES_SUCCESS,
    FETCH_WORKER_CREATE_PROFESSIONS_ERROR,
    FETCH_WORKER_CREATE_PROFESSIONS_START,
    FETCH_WORKER_CREATE_PROFESSIONS_SUCCESS
} from "./actionTypes";
import qs from 'qs'

export function fetchProfessions() {

    return async dispatch => {
        dispatch(fetchProfessionsStart())
        try {
            let response = await axios.get('/worker/professions/')
            dispatch(fetchProfessionsSuccess(response.data.data))

        } catch (e) {
            dispatch(fetchProfessionsError(e))
        }
    }
}

export function fetchCategories() {

    return async dispatch => {
        dispatch(fetchCategoriesStart())
        try {
            let response = await axios.get('/worker/categories/')
            dispatch(fetchCategoriesSuccess(response.data.data))

        } catch (e) {
            dispatch(fetchCategoriesError(e))
        }
    }
}



function fetchProfessionsStart() {
    return {
        type: FETCH_WORKER_CREATE_PROFESSIONS_START
    }
}

function fetchProfessionsSuccess(data) {
    return {
        type: FETCH_WORKER_CREATE_PROFESSIONS_SUCCESS,
        payload: {
            data
        }
    }
}

function fetchProfessionsError(error) {
    return {
        type: FETCH_WORKER_CREATE_PROFESSIONS_ERROR,
        error
    }
}

function fetchCategoriesStart() {
    return {
        type: FETCH_WORKER_CREATE_CATEGORIES_START
    }
}

function fetchCategoriesSuccess(data) {
    return {
        type: FETCH_WORKER_CREATE_CATEGORIES_SUCCESS,
        payload: {
            data
        }
    }
}

function fetchCategoriesError(error) {
    return {
        type: FETCH_WORKER_CREATE_CATEGORIES_ERROR,
        error
    }
}



export function createWorker(formData) {
    return async dispatch => {
        try {
            dispatch(createWorkerStart())
            let response = await axios.post('/worker/create/', qs.stringify(formData))
            if (response.data) {
                dispatch(createWorkerSuccess(response.data.data))
            }

        } catch (e) {
            dispatch(createWorkerError(e))
        }
    }
}

function createWorkerStart() {
    return {
        type: CREATE_WORKER_START
    }
}

function createWorkerSuccess(data) {
    return {
        type: CREATE_WORKER_SUCCESS,
        payload: {
            data
        }
    }
}

function createWorkerError(error) {
    return {
        type: CREATE_WORKER_ERROR,
        payload: {
            error
        }
    }
}