import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetMasterReport } from "../../../../store/actions/adminOrderListActions";

const AdminOrderListAnalytics = ({ order }) => {
	const dispatch = useDispatch();
	const {
		timesheetsMasterReport: {
			doneCount,
			timesheets,
			donePrice,
			masterPrice,
			pAndL,
			needDonePrice,
			standartPrice,
			overPrice,
			employerPrice,
		},
	} = useSelector((state) => state.adminOrderList);

	useEffect(() => {
		dispatch(getTimesheetMasterReport(order.id));
	}, []);

	return (
		<div className='p-3'>
			<h4>По отчету бригадира:</h4>
			<div>
				Выолнено: <strong>{doneCount || ""} м2</strong>
			</div>
			<div>
				Трудозатраты составили: <strong>{timesheets || ""} часов</strong>
			</div>
			<div>
				Сумма трудозатрат составила: <strong>{donePrice || ""} рублей</strong>
			</div>
			<hr />
			<h4>Сервис рассчитал:</h4>
			<div>
				Сумма выполнения к заказчику:{" "}
				<strong>{employerPrice || ""} рублей</strong>
			</div>
			<div>
				Сумма рассчитанная сервисом для бригадира:{" "}
				<strong>{masterPrice || ""} рублей</strong>
			</div>
			<div>
				Должно было быть сделано: <strong>{needDonePrice || ""} рублей</strong>
			</div>
			<div>
				Могло быть сделано по нормам:{" "}
				<strong>{standartPrice || ""} рублей</strong>
			</div>
			<hr />
			<h4>Результат аналитики:</h4>
			<div>
				Маржинальная Прибыль/Убыток:{" "}
				<strong>{pAndL ? +pAndL.toFixed(3) : ""}</strong>
			</div>
			<div>
				Переплата трудозатрат по параметрам сервиса:{" "}
				<strong>{overPrice || ""} рублей</strong>
			</div>
		</div>
	);
};

export default AdminOrderListAnalytics;
