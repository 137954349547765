import axios from "../../rest/axios";
import {
    FETCH_NAV_ERROR,
    FETCH_NAV_START,
    FETCH_NAV_SUCCESS, SET_ACTIVE_NAV_ANCHOR,
    SET_CURRENT_NAV_LINK
} from "./actionTypes";

export function setCurrentNavLink(id) {
    return {
        type: SET_CURRENT_NAV_LINK,
        id
    }
}

export function fetchNav(navType) {

    return async dispatch => {
        dispatch(fetchStart())
        try {
            let nav = await axios.get('/get/nav.php', {
                params: {
                    nav: navType
                }
            })
            dispatch(fetchSuccess(nav.data))
        } catch (e) {
            dispatch(fetchError(e))
        }
    }
}


function fetchStart() {
    return {
        type: FETCH_NAV_START
    }
}

function fetchSuccess(nav) {

    return {
        type: FETCH_NAV_SUCCESS,
        nav
    }
}

function fetchError(error) {
    return {
        type: FETCH_NAV_ERROR,
        error
    }
}

export function setActiveNavAnchor(anchor) {
    return {
        type: SET_ACTIVE_NAV_ANCHOR,
        payload:{
            anchor
        }
    }
}