import React from 'react'
import classes from './PersonalTabs.module.scss'
import {NavLink} from 'react-router-dom'

const PersonalTabs = ({ tabs }) => {


    const renderTab = ({id, name, to}) => {

        return (
            <li key={id}>
                <NavLink to={`/personal/${to}`} activeClassName={classes.ActiveTab}>
                  {name}
                </NavLink>
            </li>
        )
    }

    return (
        <ul className={classes.PersonalTabs}>
            {tabs.map(tab => {
                return (renderTab(tab))
            })}
        </ul>
    )
}
export default PersonalTabs