import React from 'react';
import {Route, Switch} from 'react-router-dom'
import ServiceCalculate from "./components/ServiceCalculate/ServiceCalculate";
import ServiceConfirm from "./components/ServiceConfirm/ServiceConfirm";
import Home from '../../app/pages/Home/Home';


const ServiceRoutes = () => {

	return (
			<Switch>
				<Route path={'/calculate'} component={ServiceCalculate}/>
				<Route path={'/confirm'} component={ServiceConfirm}/>
				<Route path={['/', '/complex-works', '/master-hourly']} exact component={Home}/>
			</Switch>
	);
};

export default ServiceRoutes;