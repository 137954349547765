import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import {Paper} from '@material-ui/core';
import OrderStatus from '../../../../components/UI/OrderStatus/OrderStatus';
import AdminOrderListDetail from '../AdminOrderList/AdminOrderListDetail/AdminOrderListDetail';
import AdminWorkerPersonalReportsDetail from './AdminWorkerPersonalReportsDetail';

const AdminWorkerPersonalReports = ({orders, user}) => {
	const [detail, setDetail] = useState(false)
	const [order, setOrder] = useState(null)
	const closeDetailHandler = () => {
		setDetail(false);
		setOrder(null)
	}

	const openDetailHandler = (order) => () => {
		setDetail(true);
		setOrder(order)
	}

	useEffect(() => {

	}, []);

	return (
		<div>
			<Typography variant="h5" className="mt-3">Отчеты</Typography>
			<hr/>
			{
				!!orders.length
					?
					<ul className="wp__list p-0">
						{
							orders.filter(order => order.reportsCount > 0).map((order) => {
								return (
									<li key={order.id} className="mt-2">
										<Paper className="d-flex align-items-center justify-content-between p-3"
											   onClick={openDetailHandler(order)} style={{cursor: 'pointer'}}>

											<div className="col-2">Отчет по заказу № {order.id}</div>
											<div className="col-4">{order.serviceName}</div>
											<div className="col-3">{order.location.address}</div>
											<div className="col-2">
												<OrderStatus status={order.status}>
													Дата окончания {order.finishDate}
												</OrderStatus>
											</div>
											<div className="col-1 d-flex align-items-center justify-content-center">
												<div className="wp__count">{order.reportsCount}</div>
											</div>
										</Paper>

									</li>
								)
							})
						}
					</ul>
					:
					<div>
						Список отчетов пуст
					</div>
			}
			{
				detail && order && <AdminOrderListDetail closeHandler={closeDetailHandler}>
					<AdminWorkerPersonalReportsDetail orderId={order.id} user={user}/>
				</AdminOrderListDetail>
			}

		</div>
	);
};

export default AdminWorkerPersonalReports;