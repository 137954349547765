import React, {Fragment} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";

class AdminWorkerPrices extends React.Component {

    state = {
        currentTab: 0,
        standardPriceTotal: 0,
        premiumPriceTotal: 0,

    }

    setTotalValues = () => {

        let standardPriceTotal = 0
        let premiumPriceTotal = 0

        const service = this.props.services.find(service => service.id === this.props.serviceId)

        if (!!service.items.length) {
            service.items.forEach((item) => {

                item.norms.forEach(norm => {

                    standardPriceTotal += norm.norm * norm.cc * norm.costHourStandard
                    premiumPriceTotal += norm.norm * norm.cc * norm.costHourPremium

                })

            })


        } else {
            service.norms.forEach(norm => {
                standardPriceTotal += norm.norm * norm.cc * norm.costHourStandard
                premiumPriceTotal += norm.norm * norm.cc * norm.costHourPremium

            })
        }


        this.setState({
            standardPriceTotal,
            premiumPriceTotal,
        })
    }


    componentDidMount() {
        this.setTotalValues(this.state.currentTab)
    }


    render() {

        const service = this.props.services.find(service => service.id === this.props.serviceId)
        const {
            standardPriceTotal,
            premiumPriceTotal,
        } = this.state
        const height = '50px'

        return (

            <List className="w-100 px-0">
                {
                    !!service.items.length ?
                        <Fragment>
                            <ListItem
                                className="Admin_row d-flex p-0  align-items-center justify-content-end border-top border-bottom border-gray"
                                style={{height}}>

                                <Typography variant="h6"
                                            className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {standardPriceTotal.toFixed(2)}
                                </Typography>
                                <Typography variant="h6"
                                            className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {premiumPriceTotal.toFixed(2)}
                                </Typography>
                            </ListItem>
                            {
                                service.items.map((item, item_idx) => {
                                    return (
                                        <Fragment key={item.id}>
                                            {
                                                !!item.norms.length ?
                                                    <Fragment>
                                                        {
                                                            item.norms.map((normItem, normItem_idx) => {
                                                                return (
                                                                    <ListItem
                                                                        key={normItem.id}
                                                                        className={`Admin_row d-flex p-0 align-items-center border-gray ${item.norms[normItem_idx - 1] ? 'border-top' : ''} ${!item.norms[normItem_idx + 1] ? 'border-bottom' : ''}`}
                                                                        style={{height}}>

                                                                        <Typography variant="subtitle1"
                                                                                    className="col-4 p-0 m-0 d-flex justify-content-center align-self-center">
                                                                            {normItem.name}
                                                                        </Typography>

                                                                        <Typography variant="subtitle1"
                                                                                    className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                                            {normItem.costHourStandard}
                                                                        </Typography>
                                                                        <Typography variant="subtitle1"
                                                                                    className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                                            {normItem.costHourPremium}
                                                                        </Typography>

                                                                        <Typography variant="subtitle1"
                                                                                    className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                                            {(normItem.cc * normItem.norm * normItem.costHourStandard).toFixed()}
                                                                        </Typography>


                                                                        <Typography variant="subtitle1"
                                                                                    className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                                            {(normItem.cc * normItem.norm * normItem.costHourPremium).toFixed()}
                                                                        </Typography>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }
                                                    </Fragment>
                                                    :
                                                    <ListItem
                                                        className={`Admin_row d-flex p-0 align-items-center border-gray ${service.items[item_idx - 1] ? 'border-top' : ''} ${!service.items[item_idx + 1] ? 'border-bottom' : ''}`}
                                                        style={{height}}
                                                    />

                                            }

                                        </Fragment>
                                    )
                                })
                            }
                        </Fragment>
                        :
                        <Fragment>
                            <ListItem
                                className="Admin_row d-flex p-0  align-items-center justify-content-end border-top border-bottom border-gray"
                                style={{height}}>


                                <Typography variant="h6"
                                            className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {standardPriceTotal.toFixed(2)}
                                </Typography>
                                <Typography variant="h6"
                                            className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                    {premiumPriceTotal.toFixed(2)}
                                </Typography>

                            </ListItem>

                            {
                                service.norms.map(normItem => {


                                    return (


                                        <ListItem
                                            key={normItem.id}
                                            className="Admin_row d-flex p-0 align-items-center border-bottom border-gray"
                                            style={{height}}>


                                            <Typography variant="subtitle1"
                                                        className="col-4 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {normItem.name}
                                            </Typography>

                                            <Typography variant="subtitle1"
                                                        className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {normItem.costHourStandard}
                                            </Typography>
                                            <Typography variant="subtitle1"
                                                        className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {normItem.costHourPremium}
                                            </Typography>

                                            <Typography variant="subtitle1"
                                                        className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {(normItem.cc * normItem.norm * normItem.costHourStandard).toFixed()}
                                            </Typography>


                                            <Typography variant="subtitle1"
                                                        className="col-2 p-0 m-0 d-flex justify-content-center align-self-center">
                                                {(normItem.cc * normItem.norm * normItem.costHourPremium).toFixed()}
                                            </Typography>


                                        </ListItem>


                                    )

                                })
                            }
                        </Fragment>
                }
            </List>

        );
    }
}

const mapState = (state) => {
    return {
        services: state.adminService.services,
    }
}
export default connect(mapState)(AdminWorkerPrices);
