import {ROLE_MANAGER, ROLE_MASTER} from '../../../../config';
import * as moment from 'moment';

export const getUserReports = (user, masterId = null, reports) => {
	if (user.role === ROLE_MANAGER && masterId) {
		return reports.filter((report) => report.master === masterId)
	} else if (user.role === ROLE_MASTER) {
		return reports.filter((report) => report.master === user.id)
	} else return [];
}

export const getReportsValue = (reports) => {
	let value = 0;
	if (!!reports.length) {
		reports.filter((report) => report.status > 1)
			.forEach((report) => {
				value += report.services[0].value
			})
	}
	return value
}

export const getCurrentDate = (reports, order) => {
	return !!reports[reports.length - 1] ? reports[reports.length - 1].date : moment(order.startDate, 'DD.MM.YYYY')
		.format('DD.MM.YYYY')
}