import React, {useEffect, useMemo, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import {
	fetchAdminOrderUsersList,
	managerSelectSearch,
	setOrderManager, setOrderMaster
} from '../../../../store/actions/adminOrderListActions';
import {useDispatch, useSelector} from 'react-redux';
import ListItem from '@material-ui/core/ListItem';


const AdminOrderListUsersManagers = ({order, roles = 'MANAGER'}) => {
	const dispatch = useDispatch();
	const {loading, managers, masterList} = useSelector(state => state.adminOrderList);
	// const [clearKey, setClearKey] = useState('');
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState('');
	const [currentManager, setCurrentManager] = useState(null);

	const getDetail = (e, value) => {
		if (value && value.id) {
			setCurrentManager(value.id)
		}
	};

	const setManagerHandler = async () => {
		if (roles === 'MANAGER') {
			await dispatch(setOrderManager(order.id, currentManager));
		}
		if (roles === 'MASTER') {
			await dispatch(setOrderMaster(order.id, currentManager));
		}
		dispatch(fetchAdminOrderUsersList(order.id))
	};

	const options = useMemo(() => {
		return roles === 'MANAGER' ? managers : masterList;
	}, [managers, masterList])

	useEffect(() => {
		if (query) {
			const delay = setTimeout(
				() => {
					dispatch(managerSelectSearch(query, roles))
				}, 300
			);
			return () => {
				clearTimeout(delay)
			};
		}
	}, [query, dispatch, roles]);

	const {manager, masters} = order.users;

	return (
		<>
			{
				roles === 'MANAGER' &&
				<>
					{
						manager.id
							? (
								<div
									className='w-100 d-flex align-items-center justify-content-between border-bottom py-3'>
									<div className='d-flex align-items-center'>
										<Typography variant='body2'
													className='mr-3'>Менеджер:</Typography>
										<Typography variant='subtitle2'>{manager.name}</Typography>
									</div>
								</div>
							)
							: (
								<div
									className='w-100 d-flex align-items-center justify-content-between  py-3'>
									<div className='w-75 p-3'>
										<Autocomplete
											// key={clearKey}
											classes={
												{
													listbox: 'worker_select__list'
												}
											}
											open={open}
											onOpen={() => {
												setOpen(true);
											}}
											onClose={() => {
												setOpen(false);
											}}
											getOptionSelected={(option, value) => option.name === value.name}
											getOptionLabel={(option) => option.name}
											renderOption={(option) => (
												<div
													className='d-flex w-100 justify-content-between border-bottom border-gray align-items-center'>
													<span>{option.name}</span>
													<span
														className={`text-${option.isBusy ? 'danger' : 'success'}`}>{option.isBusy ? 'Занят' : 'Свободен'}</span>
												</div>
											)
											}
											options={options}
											loading={loading}
											clearOnBlur
											clearOnEscape
											clearText="Очистить"
											noOptionsText='Ничего не найдено'
											onChange={(e, value) => getDetail(e, value)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Введите имя менеджера"
													variant="outlined"
													className='AdminOrderList__input'
													onChange={(e) => setQuery(e.target.value)}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{loading ? <CircularProgress color="inherit"
																							 size={20}/> : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														),
													}}
												/>
											)}
										/>

									</div>
									<div className='w-25 p-3'>
										<Button variant='contained' color='primary'
												onClick={setManagerHandler}>
											Назначить менеджера
										</Button>
									</div>
								</div>
							)
					}
				</>
			}

			{
				roles === 'MASTER' && !!masters &&
				<div className='w-100 d-flex flex-column'>

					<div
						className='w-100 d-flex align-items-center justify-content-between  py-3'>
						<div className='w-75 p-3'>
							<Autocomplete
								// key={clearKey}
								classes={
									{
										listbox: 'worker_select__list'
									}
								}
								open={open}
								onOpen={() => {
									setOpen(true);
								}}
								onClose={() => {
									setOpen(false);
								}}
								getOptionSelected={(option, value) => option.name === value.name}
								getOptionLabel={(option) => option.name}
								renderOption={(option) => (
									<div
										className='d-flex w-100 justify-content-between border-bottom border-gray align-items-center'>
										<span>{option.name}</span>
										<span
											className={`text-${option.isBusy ? 'danger' : 'success'}`}>{option.isBusy ? 'Занят' : 'Свободен'}</span>
									</div>
								)
								}
								options={options}
								loading={loading}
								clearOnBlur
								clearOnEscape
								clearText="Очистить"
								noOptionsText='Ничего не найдено'
								onChange={(e, value) => getDetail(e, value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Введите имя бригадира"
										variant="outlined"
										className='AdminOrderList__input'
										onChange={(e) => setQuery(e.target.value)}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? <CircularProgress color="inherit"
																				 size={20}/> : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>

						</div>
						<div className='w-25 p-3'>
							<Button variant='contained' color='primary'
									onClick={setManagerHandler}>
								Назначить Бригадира
							</Button>
						</div>
					</div>

					{
						masters.map(master => {
							return (
								<ListItem
									key={master.id}
									className='w-100 d-flex align-items-center justify-content-between border-bottom py-3'>
									<div className='d-flex align-items-center justify-content-between w-100'>
										<div className="d-flex align-items-center">
											<Typography variant='body2'
														className='mr-3'>Бригадир:</Typography>
											<Typography variant='subtitle2'>{master.name}</Typography>
										</div>
										<div className="d-flex align-items-center">
											<Typography variant='body2'
														className='mr-3'>Телефон:</Typography>
											<Typography variant='subtitle2'>{master.phone}</Typography>
										</div>
										<div className="d-flex align-items-center">
											<Typography variant='body2'
														className='mr-3'>Количество мастеров в
												заказе:</Typography>
											<Typography
												variant='subtitle2'>{master.workersCountInOrder}</Typography>
										</div>
									</div>
								</ListItem>
							)
						})
					}
				</div>
			}

		</>
	);
};

export default AdminOrderListUsersManagers;