import React from "react";
import cn from "./CategoryList.module.scss";

const CategoryListTextItem = (props) => {
	return (
		<div className={cn.textBlockItem}>
			<h4 className={cn.textBlockTitle}>{props.title}</h4>
			<p className={cn.textBlockText}>{props.children}</p>
		</div>
	);
};

export default CategoryListTextItem;
