import React from "react";
import {
  getTotalCategoryMaterialsPrice,
  getTotalCategoryServicesPrice,
} from "../calculateFunctions";
import EstimateOrderServiceRow from "./EstimateOrderServiceRow";
import NumberFormat from "react-number-format";

export default function EstimateOrderSubcategoryRow({
  subCategory,
  services,
  options,
}) {
  const totalWorkPrice = getTotalCategoryServicesPrice(
    services,
    options.workType.value,
    options
  );
  const totalMaterialsPrice = getTotalCategoryMaterialsPrice(services);
  const totalPrice =
    options.supplyMaterials.value === "customer"
      ? totalWorkPrice
      : totalWorkPrice + totalMaterialsPrice;
  return (
    <>
      <tr>
        <th className="text-left" colspan="7" scope="row">
          <h6 class="m-0 pl-3">
            <b>{subCategory.name}</b>
          </h6>
        </th>
      </tr>
      {services.map((service) => (
        <EstimateOrderServiceRow service={service} options={options} />
      ))}
      <tr>
        <td></td>
        <td className="text-left" colSpan="3">
          <b style={{ fontSize: "1.1rem" }}>{`ИТОГО за ${subCategory.name}`}</b>
        </td>
        {options.supplyMaterials.value === "performer" && (
          <td>
            <b>
              {
                <NumberFormat
                  value={totalMaterialsPrice.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={" "}
                />
              }
            </b>
          </td>
        )}
        <td>
          <b>
            {
              <NumberFormat
                value={totalWorkPrice.toFixed(2)}
                displayType={"text"}
                thousandSeparator={" "}
              />
            }
          </b>
        </td>
        <td>
          <b>
            {
              <NumberFormat
                value={totalPrice.toFixed(2)}
                displayType={"text"}
                thousandSeparator={" "}
              />
            }
          </b>
        </td>
      </tr>
    </>
  );
}
