import React, {useEffect, useMemo, useState} from 'react';
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {managerSelectSearch, setOrderManager} from "../../../../store/actions/adminOrderListActions";
import LittleLoader from "../../../../components/UI/LittleLoader/LittleLoader";
import AdminOrderListUsersManagers from './AdminOrderListUsersManagers';

const AdminOrderListUsers = ({order, setCurrentTab}) => {
	// const dispatch = useDispatch();
	const {loading, userLoading} = useSelector(state => state.adminOrderList);
	// const [open, setOpen] = useState(false);
	// const [query, setQuery] = useState('');
	// const [options, setOptions] = useState([]);
	// const [clearKey, setClearKey] = useState('');
	// const [currentManager, setCurrentManager] = useState(null);

	// const setManagerHandler = () => {
	// 	dispatch(setOrderManager(order.id, currentManager));
	// 	setCurrentTab(1)
	// };

	// const getDetail = (e, value) => {
	// 	if (value && value.id) {
	// 		setCurrentManager(value.id)
	// 	}
	// };

	// const options = useMemo(() => {
	// 	return managers;
	// }, [managers])

	// useEffect(() => {
	// 	setOptions(managers);
	// }, [managers]);

	return (
		<div className='col-12 my-3 d-flex align-items-center'>
			{
				!userLoading
					? <List className='w-100'>
						<ListItem
							className='w-100 d-flex align-items-center justify-content-between border-bottom py-3'>
							<div className='d-flex align-items-center justify-content-between w-100'>
								<Typography variant='subtitle1'
											className='mr-3'>Исполнители:</Typography>
								<div className='d-flex align-items-center'>
									<div className='d-flex align-items-center mr-5'>
										<Typography variant='subtitle1' className='mr-2'>Расчетное количество
											мастеров:</Typography>
										<Typography variant='subtitle1'>{order.workerCount}</Typography>
									</div>
									<div className='d-flex align-items-center'>
										<Typography variant='subtitle1' className='mr-2'>Суммарное фактическое
											количество
											мастеров:</Typography>
										<Typography variant='subtitle1'>{order.hiredCount}</Typography>
									</div>
								</div>
							</div>
						</ListItem>

						<ListItem>
							<AdminOrderListUsersManagers
								order={order}
							/>
						</ListItem>

						<ListItem>
							<AdminOrderListUsersManagers
								roles="MASTER"
								order={order}
							/>
						</ListItem>

					</List>
					: <LittleLoader/>
			}
		</div>
	);
};

export default AdminOrderListUsers;