import React, {Component} from 'react';
import {connect} from "react-redux";

import List from "@material-ui/core/List";
import {
    saveMaterialList,
} from "../../../../store/actions/adminMaterialListActions";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

import cuid from 'cuid'
import LoaderAdmin from "../../../../components/UI/LoaderAdmin/Loader";
import AdminPopup from "../AdminUI/AdminPopup";
import AdminSendLoader from "../AdminUI/AdminSendLoader";
import AdminProfessionListHeader from "./AdminProfessionListHeader";
import AdminProfessionListItem from "./AdminProfessionListItem";
import {
    addAdminProfessionalListProfession,
    deleteAdminProfessionListProfession,
    fetchAdminProfessionalListCategories,
    fetchAdminProfessionalListProfessions,
    saveAdminProfessionalListProfession,
    saveProfessionList,
    setAdminProfessionListProperty
} from "../../../../store/actions/adminProfessionalListActions";


class AdminProfessionList extends Component {
    componentDidMount = async () => {
        if (!this.props.professions.length || !this.props.categories.length) {
            await this.props.fetchAdminProfessionalListProfessions()
            this.props.fetchAdminProfessionalListCategories()
        }
    }

    addProfessionHandler = () => {
        const profession = {
            id: cuid(),
            name: "",
            active: false,
            costHourStandard: 0,
            costHourPremium: 0,
            categories: [],
            edit: true,
            edited: true,
            isNew: true
        }
        this.props.addAdminProfessionalListProfession(profession)
    }

    clearResultHandler = () => {
        this.props.setAdminProfessionListProperty({}, 'saveResult')
    }


    render() {
        const {
            professions,
            header,
            saveAdminProfessionalListProfession,
            deleteAdminProfessionListProfession,
            categories,
            loading,
            saveResult,
            saveInProcess
        } = this.props

        const subCategories = []
        categories.forEach(category => {
            if (category.subCategory) {
                category.subCategory.forEach(subCategory => {
                    subCategories.push(subCategory)
                })
            }
        })

        return (
            <>
                {
                    loading ?
                        <LoaderAdmin/>
                        :
                        <>
                            <AdminProfessionListHeader header={header}/>
                            <List className="Admin_material-list">
                                {
                                    professions.map((profession, idx) => {
                                        // if (!profession.deleted) {
                                        return (
                                            <AdminProfessionListItem
                                                key={profession.id}
                                                num={idx + 1}
                                                saveHandler={saveAdminProfessionalListProfession}
                                                deleteHandler={deleteAdminProfessionListProfession}
                                                categories={categories}
                                                subCategories={subCategories}
                                                profession={profession}/>
                                        )
                                        // }
                                    })
                                }
                            </List>
                            <Paper className="mt-2">
                                <div className="d-flex p-3 justify-content-end">
                                    <Button
                                        className="Admin_btn text mr-2"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.addProfessionHandler}
                                    >
                                        <AddIcon
                                            className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                        <Typography variant="overline" display="block">
                                            Добавить специальность
                                        </Typography>
                                    </Button>
                                    <Button
                                        className="Admin_btn text "
                                        variant="contained"
                                        color="primary"
                                        onClick={this.props.saveProfessionList}
                                    >
                                        <SaveIcon
                                            className='Admin_btn-icon Admin_btn-icon-create mr-1'/>
                                        <Typography variant="overline" display="block">
                                            Сохранить изменения
                                        </Typography>
                                    </Button>
                                </div>

                            </Paper>
                            {
                                saveResult.title &&
                                <AdminPopup saveResult={saveResult} clearResultHandler={this.clearResultHandler}/>
                            }

                            {
                                saveInProcess &&
                                <AdminSendLoader/>
                            }
                        </>
                }

            </>
        );
    }
}

const mapState = (state) => {
    return {
        header: state.admin.professionalTabs,
        professions: state.adminProfessionalList.professions,
        categories: state.adminProfessionalList.categories,
        loading: state.adminProfessionalList.loading,
        saveResult: state.adminProfessionalList.saveResult,
        saveInProcess: state.adminProfessionalList.saveInProcess,
    }
}
const actions = {
    fetchAdminProfessionalListProfessions,
    fetchAdminProfessionalListCategories,
    addAdminProfessionalListProfession,
    saveAdminProfessionalListProfession,
    deleteAdminProfessionListProfession,
    saveProfessionList,
    saveMaterialList,
    setAdminProfessionListProperty
}

export default connect(mapState, actions)(AdminProfessionList);