import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ModalImage from "react-modal-image";
import { getFormatDate } from "../functions";
import is from "is_js";

const AdminOrderListDetailRoom = ({ roomData = null, order }) => {
	const [room, setRoom] = useState();

	useEffect(() => {
		if (roomData) {
			setRoom(roomData);
		}
	}, [roomData]);

	return (
		<div>
			<List className='w-100'>
				{!!room && (
					<>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Название зоны:
							</Typography>
							<Typography variant='overline'>{room.place}</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Дата оформления:
							</Typography>
							<Typography variant='overline'>
								{getFormatDate(room.activeFrom)}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Дата завершения:
							</Typography>
							<Typography variant='overline'>
								{getFormatDate(room.termDatetime)}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Количество:
							</Typography>
							<Typography variant='overline'>
								{is.number(+room.volume) ? room.volume : room.measureText}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Ед. изм.:
							</Typography>
							<Typography variant='overline'>{order.unit}</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Опись задания:
							</Typography>
							<Typography variant='overline'>{room.taskInventory}</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Опись предупреждения:
							</Typography>
							<Typography variant='overline'>
								{room.warningInventory}
							</Typography>
						</ListItem>
						<ListItem className='d-flex align-items-center border-bottom border-gray'>
							<Typography variant='button' className='mr-3'>
								Статус:
							</Typography>
							<Typography variant='overline'>{room.status.value}</Typography>
						</ListItem>
					</>
				)}

				{
					// !!additional &&
					// <>
					// 	<ListItem className='d-flex align-items-center border-bottom border-gray'>
					// 		<Typography variant="button" className="mr-3">
					// 			Поданный объем:
					// 		</Typography>
					// 		<Typography variant="overline">
					// 			{additional.volume}
					// 		</Typography>
					// 	</ListItem>
					// 	<ListItem className='d-flex align-items-center border-bottom border-gray'>
					// 		<Typography variant="button" className="mr-3">
					// 			Подтвержденный объем:
					// 		</Typography>
					// 		<Typography variant="overline">
					// 			{additional.volumeAccepted}
					// 		</Typography>
					// 	</ListItem> <ListItem className='d-flex align-items-center border-bottom border-gray'>
					// 	<Typography variant="button" className="mr-3">
					// 		Проверенный объем:
					// 	</Typography>
					// 	<Typography variant="overline">
					// 		{additional.inspectionsCount}
					// 	</Typography>
					// </ListItem>
					// </>
				}
				{!!room && !!room.projects.length && (
					<ListItem className='d-flex align-items-baseline border-gray flex-column'>
						<Typography variant='button' className='mr-3'>
							Проект:
						</Typography>
						<div className='AdminOrderList__gallery d-flex flex-wrap align-items-center'>
							{room.projects.map((src, idx) => {
								return <ModalImage key={idx} small={src} large={src} />;
							})}
						</div>
					</ListItem>
				)}
			</List>
		</div>
	);
};
export default AdminOrderListDetailRoom;
