import React, {Component} from 'react';
import './ActKSInfo.scss'
import moment from "moment";

class ActKSInfo extends Component {
    render() {

        const {order, period: {startPeriod, endPeriod}} = this.props

        return (
            <div className='ActKSTopInfo'>
                <div className="ActKSTopInfo_item left">
                    <div className="d-flex flex-column w-50 border-right border-dark">
                        <div
                            className="d-flex align-items-center justify-content-center border-bottom border-dark">Номер
                            документа
                        </div>
                        <div className="text-center border border-dark border-right-0">
                            {order.id}
                        </div>
                    </div>
                    <div className="d-flex flex-column w-50">
                        <div className="d-flex align-items-center justify-content-center border-bottom border-dark">Дата
                            составления
                        </div>
                        <div className="text-center border border-dark border-left-0">
                            {moment().format('DD.MM.YYYY')}
                        </div>
                    </div>
                </div>
                <div className="ActKSTopInfo_item right">
                    <div
                        className="d-flex align-items-center justify-content-center border-bottom border-dark">Отчетный
                        период
                    </div>
                    <div className="d-flex w-100">
                        <div className="d-flex flex-column w-50 border-right border-dark">
                            <div className="text-center border-bottom border-dark">с</div>
                            <div className="text-center border border-dark border-right-0">{moment(startPeriod).format('DD.MM.YYYY')}</div>
                        </div>
                        <div className="d-flex flex-column w-50">
                            <div className="text-center border-bottom border-dark">по</div>
                            <div className="text-center border border-dark border-left-0">{moment(endPeriod).format('DD.MM.YYYY')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActKSInfo;