import React from "react";
import "./EstimateUI.scss";

export default function EstimateSwitch({ element, clickHandler }) {
  const firstOption = element.options[0];
  const secondOption = element.options[1];

  const switchValue = () => {
    if (element.value === firstOption.id)
      return clickHandler(secondOption.id, element.id);
    return clickHandler(firstOption.id, element.id);
  };

  return (
    <div className="estimate-switch-wrapper">
      {element.label && (
        <span className={`estimate-switch-title ${element.id}-title`}>
          {element.label}
        </span>
      )}
      <div className="estimate-switch-content">
        <label
          className={`label-pointer ${
            element.value === firstOption.id ? "active" : ""
          }`}
          onClick={() => clickHandler(firstOption.id, element.id)}
        >
          <input
            checked={element.value === firstOption.id}
            type="radio"
            name={element.id}
            value={firstOption.id}
            className="estimate-hidden-input"
          />
          {firstOption.label}
        </label>
        <div className="estimate-switch-indicator" onClick={switchValue}>
          <div
            className={`estimate-switch-indicator-slider ${
              element.value === secondOption.id ? "right" : ""
            }`}
          ></div>
        </div>
        <label
          className={`label-pointer ${
            element.value === secondOption.id ? "active" : ""
          }`}
          onClick={() => clickHandler(secondOption.id, element.id)}
        >
          <input
            checked={element.value === secondOption.id}
            type="radio"
            name={element.id}
            value={secondOption.id}
            className="estimate-hidden-input"
          />
          {secondOption.label}
        </label>
      </div>
    </div>
  );
}
