import React, {Fragment, useEffect, useState} from 'react';
import * as moment from 'moment';
import DraggableScroll from '../../../../../components/UI/DraggableScroll/DraggableScroll';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {a11yProps} from '../functions';
import {AppBar} from '@material-ui/core';
import AdminOrderListDetailScoreboardOne from './AdminOrderListDetailScoreboardOne';
import {fetchAdminOrderTasks} from '../../../../../store/actions/adminOrderListTasksActions';
import {useDispatch} from 'react-redux';
import AdminOrderListDetailScoreboardTwo from './AdminOrderListDetailScoreboardTwo';
import AdminOrderListDetailScoreboardThree from './AdminOrderListDetailScoreboardThree';

const AdminOrderListDetailScoreboard = ({order}) => {
	const [currentDay, setCurrentDay] = useState(moment().format("DD.MM.YYYY"));
	const [currentTab, setCurrentTab] = useState("1");
	const dispatch = useDispatch();

	const setCurrentTabHandler = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const getTable = () => {
		// обработка дат
		let startDate = moment(order.startDate, 'DD.MM.YYYY')
		// количество дней
		let days = moment().diff(startDate, 'days')
		const table = []
		// План
		const reportedDates = order.reports.map((report) => report.date)
		const today = moment().format("DD.MM.YYYY");
		for (let i = 0; i <= days; i++) {
			const day = moment(startDate, "DD.MM.YYYY").add(i, 'day').format("DD.MM.YYYY")
			const isDayArrive = moment().diff(moment(day, "DD.MM.YYYY"), 'days') >= 0
			let isReported = reportedDates.indexOf(day) !== -1

			const report = order.reports.find(report => report.date === day)
			const status = isDayArrive && !report ? 4 : !!report ? report.status : null

			table.push(
				<Fragment key={day}>
					<div className={`wp_RightBlock__item`}>
						<div
							onClick={() => setCurrentDay(day)}
							className={`wp_RightBlock__item_head`}>
							<div className={`${isReported ? 'reported' : ''} ${currentDay === day ? 'AdminOrderList__active-date active' : ''}`}>
								<strong>{day}</strong>
								<span
									className="text-capitalize">{moment(startDate, "DD.MM.YYYY").add(i, 'd')
									.format("dd")}</span>
								{/*<WorkerPersonalReportStatus status={status}/>*/}
							</div>
						</div>
					</div>
				</Fragment>
			)
		}
		return table;
	}
	useEffect(() => {
		dispatch(fetchAdminOrderTasks(order.id))
	}, [])

	useEffect(() => {
		const reportedDates = order.reports.map((report) => moment(report.date, 'DD.MM.YYYY'))
			.sort((a, b) => b.unix() - a.unix());
		if (!!reportedDates.length) {
			setCurrentDay(reportedDates[0].format("DD.MM.YYYY"));
		}
	}, [order])
	return (
		<div className="p-4">
			<DraggableScroll>
				<div className="d-flex py-5">
					{
						getTable()
					}
				</div>
			</DraggableScroll>
			<AppBar position="static" className="mt-4">
				<Tabs
					value={currentTab}
					variant="standard"
					indicatorColor="secondary"
					onChange={setCurrentTabHandler}
					scrollButtons={'on'}
					// classes={{
					// 	root: "AdminOrderList__finance-links"
					// }}
				>
					<Tab
						value="1"
						label="Онлайн ОТЧЕТ (выполнено)"
						{...a11yProps("1")}
						classes={{root: 'AdminOrderList__finance-link'}}
					/>
					<Tab
						value={"2"}
						label="Онлайн ВЫПОЛНЕНИЕ (принято)"
						{...a11yProps("2")}
						classes={{root: 'AdminOrderList__finance-link'}}
					/>
					<Tab
						value={"3"}
						label="КС-2 ОНЛАЙН"
						{...a11yProps("3")}
						classes={{root: 'AdminOrderList__finance-link'}}
					/>
				</Tabs>
			</AppBar>
			{currentTab === "1" && <AdminOrderListDetailScoreboardOne currentDay={currentDay} order={order}/>}
			{currentTab === "2" && <AdminOrderListDetailScoreboardTwo currentDay={currentDay} order={order}/>}
			{currentTab === "3" && <AdminOrderListDetailScoreboardThree currentDay={currentDay} order={order}/>}
		</div>
	);
};

export default AdminOrderListDetailScoreboard;