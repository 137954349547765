export const getConfirmedValue = (values) => {
	if (values.length > 0) {
		return values.reduce((min, current) => {
			return Math.min(min, current)
		})
	}
	return 0
}

export const withMaterials = (order) => {
	if (!order.materials) {
		order.materials = []
	}
	return order && !!order.materials.length
}