import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CategoryItem from "./CategoryItem";
import EstimateButton from "../../../UI/EstimateButton";
import {
  nextStep,
  prevStep,
  setValue,
} from "../../../../../../../store/actions/estimateActions";
import "./Estimate-Step4.scss";
import EstimateProcess from "../../Process/EstimateProcess";

const Step4 = (props) => {
  const [animate, setAnimate] = useState(false);
  if (props.animateServicesItems.length > 0) {
    setTimeout(() => {
      setAnimate(true);
    }, 3);
  }
  useEffect(() => {
    return () => {
      props.setValue('animateServicesItems', []);
    }
  }, [])
  const nextStepWithReset = () => {
    props.nextStep();
  }
  return (
    <>
      <EstimateProcess />
      <div className="complex-body">
        <div className="row">
          {props.activeCategories.map((category) => (
            <CategoryItem
              category={category}
              setSelectedCategory={props.setValue}
              selectedCategoryId={props.selectedCategoryId}
            />
          ))}
          {props.animateServicesItems.length > 0 && (

            <div className="Estimate-animate-service-item-wrapper">
              {props.animateServicesItems.map((i) => (
                <div className={`Estimate-animate-service-item ${animate ? 'animate' : ''}`}>{i.name}</div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="complex-footer mt-5">
        <div className="row">
          <div className="col-4 col-lg-2 estimate-footer-item pr-0">
            <EstimateButton
              className="back"
              back={true}
              onClick={props.prevStep}
              text="Назад"
            />
          </div>
          <div className="col-8 col-lg-10 estimate-footer-item pl-0">
            <EstimateButton
              disabled={props.selectedCategoryId === null}
              onClick={nextStepWithReset}
              text={
                props.selectedCategoryId === null
                  ? "Выберите вид работ"
                  : "Выбрать услуги"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCategoryId: state.estimate.selectedCategoryId,
  activeCategories: state.estimate.activeCategories,
  animateServicesItems: state.estimate.animateServicesItems,
});

const actions = {
  nextStep,
  prevStep,
  setValue,
};

export default connect(mapStateToProps, actions)(Step4);
