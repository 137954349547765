import React from 'react';
import AdminOrderListFinanceAccordion from './AdminOrderListFinanceAccordion';

const AdminOrderListFinanceCompanyTotalDone = () => {
	const pays = [
		{
			id: 1,
			name: "по Документу №1",
			date: "23.08.2020",
			sum: "100 000 р."
		},
		{
			id: 2,
			name: "по Документу №2",
			date: "25.08.2020",
			sum: "105 000 р."
		},
	];
	return (
		<div className="mt-2">
			<AdminOrderListFinanceAccordion pays={pays}
											name="Всего выполнено"
											sum="100 000 р."/>
		</div>
	);
};

export default AdminOrderListFinanceCompanyTotalDone;