import React from 'react';
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";


const AdminHeader = ({header, currentTab}) => {
    return (
        <Paper className='d-flex mt-2 Admin_head'>

            {
                currentTab !== 6
                && ( <div className='col-4 p-0 d-flex'>
                    <Typography variant="overline"
                                className="Admin_head-item col-1 d-flex justify-content-center align-items-center p-0 m-0">#</Typography>
                    <Typography variant="overline" className="Admin_head-item col-9 d-flex align-items-center p-0 m-0">Наименование
                        работ</Typography>
                    <Typography variant="overline"
                                className="Admin_head-item col-2 d-flex justify-content-center flex-column align-items-center p-0 m-0">
                        <span>Ед</span>
                        <span>изм</span>
                    </Typography>
                </div>)
            }

            <div className={`${currentTab === 6 ? 'col-12' : 'col-8'} p-0 d-flex`}>
                {
                    header.map(item => {
                        return (
                            <Typography variant="overline"
                                        key={item.id}
                                        className={`Admin_head-item d-flex justify-content-center align-items-center flex-column p-0 col ${item.col ? 'col-' + item.col : 'col'}`}>
                                <span className='text-center'> {item.title}</span>
                                {
                                    !!item.subtitle &&
                                    <span className='text-center'>{item.subtitle}</span>
                                }
                            </Typography>
                        )
                    })
                }
            </div>

        </Paper>
    );
};

const mapState = (state, {tabId}) => {

    let header = []

    if (state.admin.tables.length) {
        header = state.admin.tables.find(table => table.id === tabId).head
    }

    return {
        header
    }
}

export default connect(mapState)(AdminHeader);