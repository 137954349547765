import React from 'react';
import NumberFormat from 'react-number-format';
import {useConditionsContext} from '../ConditionsContext';
import {useSelector} from 'react-redux';
import {getOrderState} from '../../../../store/selectors';

const IndividualWorksBottomTotal = () => {
	const {sum, totalServicePrice, materialPrice} = useConditionsContext();
	const {
		needMaterials, workerCount,
	} = useSelector(getOrderState);

	return (
		<div className="IndividualWorks__bottom-total">
			<div className="IndividualWorks__bottom-total-box pl-0 pl-sm-4">
				<div className="IndividualWorks__title-small">
					Общая сумма заказа:
				</div>
				<div className="IndividualWorks__title-sum">
					<NumberFormat
						value={sum}
						displayType={'text'}
						thousandSeparator={" "}/>&nbsp;₽
				</div>
			</div>
			<div className="IndividualWorks__bottom-total-box">
				<div className="IndividualWorks__bottom-total-box-group">
					<div className="IndividualWorks__title-small">
						Работы:&nbsp;
					</div>
					<NumberFormat
						value={totalServicePrice}
						displayType={'text'}
						thousandSeparator={" "}/>&nbsp;₽
				</div>
				{
					needMaterials === 2
					&& (
						<div className="IndividualWorks__bottom-total-box-group">
							<div className="IndividualWorks__title-small">
								Материалы:&nbsp;
							</div>
							<NumberFormat
								value={materialPrice}
								displayType={'text'}
								thousandSeparator={" "}/>&nbsp;₽
						</div>
					)
				}
			</div>
			<div className="IndividualWorks__bottom-total-box">
				<div className="IndividualWorks__bottom-total-box-count">
					<div className="IndividualWorks__title-small">
						Расчетное кол-во мастеров:&nbsp;
					</div>
					<div className="d-flex align-items-center">
						{
							(workerCount).toString().split('').map((num, idx) => {
								return <div key={idx}
											className="IndividualWorks__title-num">{num}</div>
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default IndividualWorksBottomTotal;