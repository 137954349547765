import {
	ADMIN_WORKER_PERSONAL_FETCH_SUCCESS,
	ADMIN_WORKER_PERSONAL_ORDERS_FETCH_SUCCESS,
	ADMIN_WORKER_PERSONAL_UPDATE_PROPERTY,
} from "../actions/actionTypes";

const initialState = {
	worker: {},
	orders: [],
};
const adminWorkerPersonalReducer = (
	state = initialState,
	{ type, payload },
) => {
	switch (type) {
		case ADMIN_WORKER_PERSONAL_ORDERS_FETCH_SUCCESS:
			return {
				...state,
				orders: payload.orders,
			};
		case ADMIN_WORKER_PERSONAL_FETCH_SUCCESS:
			return {
				...state,
				worker: payload.data,
			};
		case ADMIN_WORKER_PERSONAL_UPDATE_PROPERTY:
			return {
				...state,
				[payload.property]: payload.value,
			};
		default:
			return state;
	}
};
export default adminWorkerPersonalReducer;
