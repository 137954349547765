import React from 'react'
import classes from './ServiceCalculateTabs.module.scss'

export default ({tabs, currentTab, setCurrentTab, withMaterials}) => {

    return (
        <div className={classes.tabs}>
            {tabs.map(tab => {
                let ServiceCalcTab = [];
                if (tab.id === currentTab) {
                    ServiceCalcTab.push(classes.active)
                }
                if (!withMaterials && tab.id === 2) {
                    return null
                }
                if(!withMaterials){
                    ServiceCalcTab.push(classes.single)
                }
                return (
                    <div
                        key={tab.id}
                        className={ServiceCalcTab.join(" ")}
                        onClick={() => setCurrentTab(tab.id)}
                        data-id={tab.id}>
                        {tab.name}
                    </div>
                )
            })}
        </div>
    )
}