import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useDispatch, useSelector} from 'react-redux';
import {getAdminOrderListState} from '../../selectors';
import {fetchAdminOrderUsersList} from '../../../../../../store/actions/adminOrderListActions';
import AdminOrderListFinanceMastersTotalPayed from './AdminOrderListFinanceMastersTotalPayed';
import AdminOrderListFinanceMastersTotalDone from './AdminOrderListFinanceMastersTotalDone';
import AdminOrderListFinanceMastersDebt from './AdminOrderListFinanceMastersDebt';
import _ from 'lodash';

const AdminOrderListFinanceMasters = ({order, reports}) => {
	const {orders} = useSelector(getAdminOrderListState)
	const [masters, setMasters] = useState([]);
	const [expanded, setExpanded] = useState(null);
	const [workerCountFact, setWorkerCountFact] = useState(0);
	const [workerCountsFact, setWorkerCountsFact] = useState({});
	const dispatch = useDispatch();
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	useEffect(() => {
		const currentOrder = orders.find((o) => o.id === order.id);
		if (currentOrder.users && currentOrder.users.masters) {
			setMasters(currentOrder.users.masters);
		}
	}, [orders])
	useEffect(() => {
		let counts = {};
		if (!!order.workers.length) {
			order.workers.forEach(worker => {
				counts[worker.id] = 0;
				// if (worker.orders.indexOf(order.id) !== -1) {
				// 	counts[worker.id] += 1;
				// }
				counts[worker.id] += worker.subWorkers.length
			})
		}
		setWorkerCountFact(_.sum(Object.values(counts)));
		setWorkerCountsFact(counts)
	}, [order])
	useEffect(() => {
		dispatch(fetchAdminOrderUsersList(order.id))
	}, [order, dispatch])
	return (
		<div className="d-flex flex-column">
			<div
				className="d-flex align-items-center justify-content-end p-3 px-4 border-bottom border-top mb-1 bg-white">
				<div>Расчетное количество мастеров: {order.workersCount}</div>
				<div className="ml-3">Суммарное фактическое количество мастеров: {workerCountFact}</div>
			</div>
			{
				!!masters.length
				&&
				<>
					{
						masters.map((master, master_idx) => {
							return <Accordion key={master.id} expanded={expanded === master.id}
											  onChange={handleChange(master.id)} className="mt-2">
								<AccordionSummary
									expandIcon={<ExpandMoreIcon/>}
									aria-controls="panel1bh-content"
									id="panel1bh-header"
									className="AdminOrderList__finance-accordion-head"
								>
									<div className="w-100 d-flex align-items-center justify-content-between">
										<strong>Бригадир № {master_idx + 1}</strong>
										<strong>{master.name}</strong>
										<strong>{master.phone}</strong>
										<strong>Фактическое количество мастеров: {workerCountsFact[master.id]}</strong>
									</div>
								</AccordionSummary>
								<AccordionDetails className="px-1 py-2 bg-light">
									<div className="row w-100 p-0 m-0">
										<div className="col-6 px-1 d-flex flex-column">
											<AdminOrderListFinanceMastersTotalDone order={order} masterId={master.id} reports={reports}/>
											{/*<ul className="px-0 m-0">*/}
											{/*	<li className="AdminOrderList__finance-li mt-2">*/}
											{/*		<Paper*/}
											{/*			className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">*/}
											{/*			<div>Остаток оплаты</div>*/}
											{/*			<div>10 000 р.</div>*/}
											{/*		</Paper>*/}
											{/*	</li>*/}
											{/*</ul>*/}
										</div>
										<div className="col-6 px-1 d-flex flex-column justify-content-between">
											<AdminOrderListFinanceMastersTotalPayed order={order} masterId={master.id}/>
											<AdminOrderListFinanceMastersDebt order={order} masterId={master.id}/>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
						})
					}
				</>
			}

		</div>
	);
};

export default AdminOrderListFinanceMasters;