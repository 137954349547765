import React from 'react'
import './ServiceConfirmTypeTabs.scss'
import {useSelector} from "react-redux";

const ServiceConfirmTypeTabs =  ({currentTab, setCurrentTab}) => {

    let tabs = useSelector(state=>state.confirmOrder.tabsSmall);

    return (
        <div className="ServiceConfirmTypeTabs">
            {tabs.map(({id, name}) => {
                let ServiceCalcTab = "ServiceConfirmTypeTabs_tab"
                if (id === currentTab) {
                    ServiceCalcTab += " active"
                }
                return (
                    <div
                        key={id}
                        className={ServiceCalcTab}
                        onClick={() => setCurrentTab(id)}
                        data-id={id}>
                        {name}
                    </div>
                )
            })}
        </div>
    )
}
export default ServiceConfirmTypeTabs;