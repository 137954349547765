import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './index.scss';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {createStore, compose, applyMiddleware} from 'redux';
import reduxThunk from 'redux-thunk';

import App from './App.tsx';
import * as serviceWorker from './serviceWorker';
import rootReducer from './store/reducers/rootReducer';

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(reduxThunk),
    ),
);

const rootElement = document.getElementById('root');
const application = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(application, rootElement);
serviceWorker.unregister();
