import {
	ADD_SPECIALIST,
	AUTH_SPECIALIST_START,
	AUTH_SPECIALIST_SUCCESS,
	FETCH_CATEGORIES_SUCCESS,
	FETCH_SPECIALIST_START,
	FETCH_SPECIALIST_SUCCESS,
	GET_PROFESSIONS_SUCCESS,
	GET_SPECIALIST_SPECIALIZATIONS,
	REMOVE_SPECIALIST,
	SEND_DATE_INSTRUCTION,
	SET_CATEGORY,
	SET_CURRENT_TAB_SPECIALIST,
	SET_SPECIALIST,
	SET_SPECIALIST_CARD_TYPE,
	SET_SPECIALIST_FORM_CONTROL,
	SET_SPECIALIST_METHOD_WORK,
	SET_SPECIALIST_PROPERTY,
	SET_SPECIALIST_TYPE_WORK,
} from "../actions/actionTypes";

const initialState = {
	loading: false,
	currentTab: 1,
	status: {
		id: 9,
		name: "",
	},
	dateInstruction: null,
	dateInstructionConfirm: false,
	instructionInfo: "",
	professions: [],
	categories: [],
	categoriesSelected: [],
	tabs: [
		{
			id: 1,
			name: "Личные данные",
		},
		{
			id: 2,
			name: "Правила работы",
		},
		{
			id: 3,
			name: "Заказы",
		},
		{
			id: 4,
			name: "Диалог с заказчиком",
		},
	],
	user: {
		name: null,
		surname: null,
		patronymic: null,
		birthDay: null,
		phone: null,
		email: null,
		photo: {},
	},
	passport: {
		issuedPassport: null, // кем выдан
		numberPassport: null, // серия и номер
		datePassport: null, // дата выдачи
		registrationPassport: null, // зарегистрирован
	},
	card: {
		typeCard: null, // тип карты
		numberCard: null, // номер карты
		expirationDateCard: null, // срок действия карты
		ownerCard: null, // владелец
	},
	experience: {
		mainExperience: null, // основной опыт
		dateStartMainExperience: null, // дата начала основного опыта
		// дополнительная профессия, может быть несколько
		additionalExperience: null,
		dateStartAddExperience: null,
	},
	methodOfWork: [1], // метод работ сдельно или по часам
	typeOfWork: [1], // тип работ стандарт премиум
	recommendationsExist: false, // есть ли рекомендации
	specializationType: 1, // тип специализации
	specializations: [],
	lastSpecialistId: 1,
	specialists: [
		{
			id: 1,
			formControls: {
				name: {
					id: "name",
					label: "Имя",
					type: "text",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: true,
						minLength: 3,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						minLength: `Минимум 3 символа`,
					},
				},
				surname: {
					id: "surname",
					label: "Фамилия",
					type: "text",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: true,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
					},
				},
				patronymic: {
					id: "patronymic",
					label: "Отчество",
					type: "text",
					value: "",
					valid: true,
					error: "",
					validation: {},
					errorMessage: {},
				},
				birthDay: {
					id: "birthDay",
					label: "Дата рождения",
					type: "date",
					value: "",
					valid: true,
					error: "",
					validation: {
						birthDay: true,
					},
					errorMessage: {
						birthDay: "Должно быть не менее 18 лет",
					},
				},
				phone: {
					id: "phone",
					label: "Телефон",
					type: "tel",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: false,
						phone: true,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						phone: `Введите корректный номер телефона`,
					},
				},
				email: {
					id: "email",
					label: "email",
					type: "email",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: true,
						email: true,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						email: `Введите корректный email`,
					},
				},
				mainExperience: {
					id: "mainExperience",
					label: "Основная профессия",
					type: "select",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: true,
						minLength: 3,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						minLength: `Минимум 3 символа`,
					},
				},
				dateStartMainExperience: {
					id: "dateStartMainExperience",
					label: "Количество лет опыта",
					type: "text",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: true,
						number: true,
						max: 60,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						number: `Введите число`,
						max: "Введите корректное количество",
					},
				},
				addExperience: {
					id: "addExperience",
					label: "Дополнительная профессия",
					type: "select",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: false,
						minLength: 3,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						minLength: `Минимум 3 символа`,
					},
				},
				dateStartAddExperience: {
					id: "dateStartAddExperience",
					label: "Количество лет опыта",
					type: "text",
					value: "",
					valid: true,
					error: "",
					validation: {
						required: false,
						number: true,
						max: 60,
					},
					errorMessage: {
						required: "Это поле необходимо заполнить",
						number: `Введите число`,
						max: "Введите корректное количество лет",
					},
				},
				photo: {
					id: "photo",
					label: "",
					type: "file",
					value: {},
					valid: true,
					error: "",
					validation: {
						maxSize: 5000,
						image: true,
					},
					errorMessage: {
						maxSize: "Максимальный размер файла 5 МБ",
						image: `Допустимые расширения jpg, png, gif`,
					},
				},
			},
		},
	],
	formControls: {
		user: {
			name: {
				id: "user.name",
				label: "Имя",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
					minLength: 3,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					minLength: `Минимум 3 символа`,
				},
			},
			surname: {
				id: "user.surname",
				label: "Фамилия",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
				},
			},
			patronymic: {
				id: "user.patronymic",
				label: "Отчество",
				type: "text",
				value: "",
				valid: true,
				validation: {},
				errorMessage: {},
			},
			birthDay: {
				id: "user.birthDay",
				label: "Дата рождения",
				type: "date",
				value: "",
				valid: true,
				validation: {
					required: true,
					birthDay: true,
				},
				errorMessage: {
					birthDay: "Должно быть не менее 18 лет",
					required: "Это поле необходимо заполнить",
				},
			},
			phone: {
				id: "user.phone",
				label: "Телефон",
				type: "tel",
				value: "",
				valid: true,
				validation: {
					required: true,
					phone: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					phone: `Введите корректный номер телефона`,
				},
			},
			email: {
				id: "user.email",
				label: "email",
				type: "email",
				value: "",
				valid: true,
				validation: {
					required: true,
					email: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					email: `Введите корректный email`,
				},
			},
			photo: {
				id: "user.photo",
				label: "",
				type: "file",
				value: "",
				valid: true,
				validation: {
					maxSize: 5000,
					image: true,
				},
				errorMessage: {
					maxSize: "Максимальный размер файла 5 МБ",
					image: `Допустимые расширения jpg, png, gif`,
				},
			},
		},
		passport: {
			issuedPassport: {
				id: "passport.issuedPassport",
				label: "Кем выдан",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
				},
			},
			numberPassport: {
				id: "passport.numberPassport",
				label: "Серия и номер",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
				},
			},
			datePassport: {
				id: "passport.datePassport",
				label: "Когда выдан",
				type: "date",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
				},
			},
			registrationPassport: {
				id: "passport.registrationPassport",
				label: "Место регистрации",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
				},
			},
		},
		card: {
			numberCard: {
				id: "card.numberCard",
				label: "Номер карты",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
					creditCard: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					creditCard: "Введите корректный номер карты",
				},
			},
			expirationDateCard: {
				id: "card.expirationDateCard",
				label: "Срок действия карты",
				type: "date",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
					expirationDateCard: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					expirationDateCard: "Введите корректный номер карты",
				},
			},
			owner: {
				id: "card.owner",
				label: "Владелец",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
				},
			},
		},
		experience: {
			mainExperience: {
				id: "experience.mainExperience",
				label: "Основная профессия",
				type: "select",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
					minLength: 3,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					minLength: `Минимум 3 символа`,
				},
			},
			dateStartMainExperience: {
				id: "experience.dateStartMainExperience",
				label: "Количество лет опыта",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: true,
					number: true,
					max: 50,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					number: `Введите число`,
					max: "Введите корректное количество лет",
				},
			},
			addExperience: {
				id: "experience.addExperience",
				label: "Дополнительная профессия",
				type: "select",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: false,
					minLength: 3,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					minLength: `Минимум 3 символа`,
				},
			},
			dateStartAddExperience: {
				id: "experience.dateStartAddExperience",
				label: "Количество лет опыта",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {
					required: false,
					number: true,
					max: 50,
				},
				errorMessage: {
					required: "Это поле необходимо заполнить",
					number: `Введите число`,
					max: "Введите корректное количество лет",
				},
			},
		},
		recommendations: {
			recommendation: {
				id: "recommendations.recommendation",
				label: "Рекомендатель",
				type: "text",
				value: "",
				valid: true,
				error: "",
				validation: {},
				errorMessage: {},
			},
		},
	},
};
export default function SpecialistReducer(state = initialState, action) {
	let specialists = [...state.specialists];
	switch (action.type) {
		case GET_SPECIALIST_SPECIALIZATIONS:
			return {
				...state,
				specializations: action.payload.data,
			};
		case SET_CURRENT_TAB_SPECIALIST:
			return {
				...state,
				currentTab: action.id,
			};
		case SET_SPECIALIST_FORM_CONTROL:
			const formControls = { ...state.formControls };

			if (action.property !== "photo") {
				let property = action.property.split(".");

				if (property.length === 2) {
					formControls[property[0]][property[1]].value = action.value;
					formControls[property[0]][property[1]].error = action.error;
					formControls[property[0]][property[1]].valid = !action.error;
				} else {
					formControls[property[0]].value = action.value;
					formControls[property[0]].error = action.value;
					formControls[property[0]].valid = !action.value;
				}
			} else {
				// user.photo = action.value
				formControls.user["photo"].value = action.value;
			}

			return {
				...state,
				formControls,
			};
		case SET_SPECIALIST_PROPERTY:
			return {
				...state,
				[action.property]: action.value,
			};
		case SET_SPECIALIST_CARD_TYPE:
			let card = state.card;
			card.typeCard = action.value;

			return {
				...state,
				card,
			};
		case SET_SPECIALIST_METHOD_WORK:
			let methodOfWork = [...state.methodOfWork];
			let indexMethodOfWork = methodOfWork.findIndex(
				(el) => el === action.value,
			);

			if (indexMethodOfWork !== -1) {
				methodOfWork.splice(indexMethodOfWork, 1);
			} else {
				methodOfWork = [action.value, ...methodOfWork];
			}
			return {
				...state,
				methodOfWork,
			};
		case SET_SPECIALIST_TYPE_WORK:
			let typeOfWork = [...state.typeOfWork];
			let indexTypeOfWork = typeOfWork.findIndex((el) => el === action.value);

			if (indexTypeOfWork !== -1) {
				typeOfWork.splice(indexTypeOfWork, 1);
			} else {
				typeOfWork = [action.value, ...typeOfWork];
			}
			return {
				...state,
				typeOfWork,
			};
		case ADD_SPECIALIST:
			let specialistEmpty = {
				id: state.lastSpecialistId + 1,
				photo: {},
				formControls: {
					name: {
						id: "name",
						label: "Имя",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
							minLength: 3,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							minLength: `Минимум 3 символа`,
						},
					},
					surname: {
						id: "surname",
						label: "Фамилия",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
						},
					},
					patronymic: {
						id: "patronymic",
						label: "Отчество",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {},
						errorMessage: {},
					},
					birthDay: {
						id: "birthDay",
						label: "Дата рождения",
						type: "date",
						value: "",
						valid: true,
						error: "",
						validation: {
							birthDay: true,
						},
						errorMessage: {
							birthDay: "Должно быть не менее 18 лет",
						},
					},
					phone: {
						id: "phone",
						label: "Телефон",
						type: "tel",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: false,
							phone: true,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							phone: `Введите корректный номер телефона`,
						},
					},
					email: {
						id: "email",
						label: "email",
						type: "email",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
							email: true,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							email: `Введите корректный email`,
						},
					},
					mainExperience: {
						id: "mainExperience",
						label: "Основная профессия",
						type: "select",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
							minLength: 3,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							minLength: `Минимум 3 символа`,
						},
					},
					dateStartMainExperience: {
						id: "dateStartMainExperience",
						label: "Количество лет опыта",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: true,
							number: true,
							max: 50,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							number: `Введите число`,
							max: "Введите корректное количество лет",
						},
					},
					addExperience: {
						id: "addExperience",
						label: "Дополнительная профессия",
						type: "select",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: false,
							minLength: 3,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							minLength: `Минимум 3 символа`,
						},
					},
					dateStartAddExperience: {
						id: "dateStartAddExperience",
						label: "Количество лет опыта",
						type: "text",
						value: "",
						valid: true,
						error: "",
						validation: {
							required: false,
							number: true,
							max: 50,
						},
						errorMessage: {
							required: "Это поле необходимо заполнить",
							number: `Введите число`,
							max: "Введите корректное количество лет",
						},
					},
					photo: {
						id: "photo",
						label: "",
						type: "file",
						value: "",
						valid: true,
						error: "",
						validation: {
							maxSize: 5000,
							image: true,
						},
						errorMessage: {
							maxSize: "Максимальный размер файла 5 МБ",
							image: `Допустимые расширения jpg, png, gif`,
						},
					},
				},
				error: "",
			};

			specialists.push(specialistEmpty);
			return {
				...state,
				specialists,
				lastSpecialistId: state.lastSpecialistId + 1,
			};
		case SET_SPECIALIST:
			let specialistId = specialists.findIndex((specialist) => {
				return specialist.id === action.id;
			});

			let specialistFormControls = {
				...specialists[specialistId]["formControls"],
			};
			specialistFormControls[action.property]["value"] = action.value;
			specialistFormControls[action.property]["error"] = action.error;
			specialistFormControls[action.property]["valid"] = !action.error;
			specialists[specialistId]["formControls"] = specialistFormControls;

			return {
				...state,
				specialists,
			};
		case REMOVE_SPECIALIST:
			let removeSpecialistId = [...state.specialists].findIndex(
				(specialist) => {
					return specialist.id === action.id;
				},
			);
			specialists = [
				...specialists.slice(0, removeSpecialistId),
				...specialists.slice(removeSpecialistId + 1),
			];
			return {
				...state,
				specialists,
			};
		case FETCH_SPECIALIST_START:
			return {
				...state,
				loading: true,
			};
		case FETCH_SPECIALIST_SUCCESS:
			return {
				...state,
				userId: action.userId,
				loading: false,
			};
		case AUTH_SPECIALIST_START:
			return {
				...state,
				loading: true,
			};
		case AUTH_SPECIALIST_SUCCESS:
			return {
				...state,
				status: action.data.status,
				loading: false,
			};
		case SEND_DATE_INSTRUCTION:
			return {
				...state,
				instructionInfo: action.payload,
			};
		case GET_PROFESSIONS_SUCCESS:
			return {
				...state,
				professions: action.professions,
				loading: false,
			};
		case FETCH_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: action.payload.categories,
				loading: false,
			};
		case SET_CATEGORY:
			let categoriesSelected = [...state.categoriesSelected];
			let changedItemId = parseInt(action.payload.id);
			let changedItemIdx = categoriesSelected.findIndex(
				(id) => id === changedItemId,
			);
			// если в массиве нет такого элемента, добавляем
			if (changedItemIdx === -1) {
				categoriesSelected = [...categoriesSelected, changedItemId];
			} else {
				categoriesSelected = [
					...categoriesSelected.slice(0, changedItemIdx),
					...categoriesSelected.slice(changedItemIdx + 1),
				];
			}

			return {
				...state,
				categoriesSelected,
			};
		default:
			return state;
	}
}
