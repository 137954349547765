import React, {useEffect, useState} from 'react'
import './PersonalOrderItemPayments.scss'
import {useDispatch, useSelector} from "react-redux";
import {setOrderListProperty} from "../../../../../store/actions/orderListActions";
import Loader from "../../../../../components/UI/Loader/Loader";
import Typography from '@material-ui/core/Typography';

const PersonalOrderItemPayments = ({order, paymentPageHandler}) => {

	const dispatch = useDispatch();
	const [tranches, setTranches] = useState([]);
	const {loading} = useSelector(state => state.orderList);

	useEffect(() => {
		let tranchesArray = Object.keys(order.tranches).map(key => {
			return order.tranches[key]
		});

		let tranches = [...tranchesArray].filter(item => {
			return item.type === 1
		});

		setTranches(tranches);

	}, []);

	const RenderMaterialRow = () => {

		let tranchesArray = Object.keys(order.tranches).map(key => {
			return order.tranches[key]
		})
		let tranche = tranchesArray.filter(item => {
			return item.type === 2
		})[0]

		return (
			<>
				{
					!!tranche &&
					<>
						{/*<div className="PersonalOrderItemPayments_head materials">*/}
						{/*	<div className="w-70">Оплата Материала</div>*/}
						{/*	<div className="w-10">Сумма(руб.)</div>*/}
						{/*	<div className="w-10">Дата оплаты</div>*/}
						{/*	<div className="w-10">Статус</div>*/}
						{/*</div>*/}
						<div className="PersonalOrderItemPayments_body materials">
							<div className="w-70"><Typography
								align='left'>Материалы</Typography></div>
							<div className="w-10"><Typography
								align='left'>{tranche.sum}</Typography></div>
							<div className="w-10"><Typography
								align='left'>{tranche.date}</Typography></div>
							{
								tranche.isPaid ?
									<div className={`PersonalOrderItemPayments_pay-btn paid w-10`}>
										<Typography
											className="px-2"
											align='left'>
											Оплачено
										</Typography> className"w-10
									</div>
									:
									<div onClick={() => paymentPageHandler(tranche.id)}
										 className={`PersonalOrderItemPayments_pay-btn w-10`}>
										<Typography
											className="px-2"
											align='left'>
											Оплатить
										</Typography>
									</div>
							}
						</div>
					</>
				}
			</>
		)
	}

	const RenderServiceRow = () => {
		let rows = tranches.map((tranche, idx) => {
			const trancheClassName = ['PersonalOrderItemPayments_pay-btn w-10'];
			let disabled = false;
			if (tranches[idx - 1] && !tranches[idx - 1].isPaid) {
				trancheClassName.push('disabled')
				disabled = true;
			}
			if (tranche.isPaid) {
				trancheClassName.push('paid')
				disabled = true;
			}
			return (
				<div key={tranche.id} className="PersonalOrderItemPayments_body services">
					{/*<div>{order.serviceName}</div>*/}
					<div className="w-50"><Typography
						align='left'>Транш {idx + 1}</Typography></div>
					<div className="w-10"><Typography
						align='left'>{order.unit}
					</Typography>
					</div>
					<div className="w-10"><Typography
						align='left'>{(order.serviceValue / tranches.length).toFixed(2)}
					</Typography>
					</div>
					<div className="w-10"><Typography
						align='left'>{(+tranche.sum).toFixed(2)}</Typography></div>
					<div className="w-10"><Typography
						align='left'>{tranche.date}</Typography></div>
					<div onClick={!disabled ? () => paymentPageHandler(tranche.id, idx + 1) : null}
						 className={trancheClassName.join(' ')}>
						<Typography
							align='left' className="px-2">
							{tranche.isPaid ? 'Оплачено' : 'Оплатить'}
						</Typography>
					</div>
				</div>
			)
		})
		return (
			<>
				{rows}
			</>
		)
	}

	return (
		<div className="PersonalOrderItemPayments">
			<div className='PersonalOrderItemPayments__paid'>
				Оплачено траншей: {tranches.filter(tranche => tranche.isPaid).length}
			</div>
			<div className="PersonalOrderItemPayments_head services">
				<div className="w-50">График оплаты</div>
				<div className="w-10">Ед. Изм</div>
				<div className="w-10">Общий объем <br/> выполненных работ</div>
				<div className="w-10">Сумма(руб.)</div>
				<div className="w-10">Дата оплаты</div>
				<div className="w-10">Статус</div>
			</div>
			<RenderMaterialRow/>
			<RenderServiceRow/>
		</div>
	)

}
export default PersonalOrderItemPayments;