import React from 'react';
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";

const WorkerHomeLink = () => {
    return (
        <div className='w__home-link'>
            <Link to={'/'}>
                <HomeIcon/>
                <span className='ml-3'>На главную</span>
            </Link>
        </div>
    );
};

export default WorkerHomeLink;