import React from "react";
import { connect } from "react-redux";
import stepsList from "./stepsList";

export const Steps = ({ step }) => {
  return stepsList[step];
};

const mapStateToProps = (state) => ({
  step: state.estimate.step,
});

const actions = {};

export default connect(mapStateToProps, actions)(Steps);
