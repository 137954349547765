import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Worker from "./containers/Worker/Worker";
import Admin from "./containers/Admin/Admin";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Personal from "./containers/Personal/Personal";
import ApiDocs from "./rest/ApiDocs/ApiDocs";
import WorkerPersonal from "./containers/Worker/WorkerPersonal/WorkerPersonal";
import WorkerCreate from "./containers/Worker/WorkerCreate/WorkerCreate";
import Test from "./test/others/Test";
import GanttPage from './app/pages/GanttPage/GanttPage'
import ServiceContainer from "./containers/ServiceRoute/ServiceContainer";

const Routes = () => {
  return (
    <Switch>
      <Route path={"/personal"} component={Personal} />
      <Route path={"/test"} component={Test} />
      <Route
        path={["/worker/personal/:tab", "/worker/personal"]}
        component={WorkerPersonal}
      />
      <Route path={"/worker/create"} component={WorkerCreate} exact />
      <Route path={"/worker"} exact component={Worker} />
      <Route path={["/admin/:tab", "/admin"]} component={Admin} />
      <Route path={"/privacy-policy"} component={PrivacyPolicy} />
      <Route path={"/api/docs/"} exact component={ApiDocs} />
      <Route
        path={[
          "/",
          "/calculate",
          "/confirm",
          "/complex-works",
          "/master-hourly",
        ]}
        component={ServiceContainer}
        exact
      />
      <Route
        path={'/gantt'}
        component={GanttPage}
       />
      <Redirect to={"/"} />
    </Switch>
  );
};

export default Routes;
