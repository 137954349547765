import React from "react";
import Main from "../../hoc/Main/Main";
import ServiceRoutes from "./ServiceRoutes";
import ServiceRouteContext from "./context/ServiceRouteContext";
import ConditionsContextProvider from '../../app/containers/Calculator/ConditionsContext';

const ServiceRoute = ({isLoaded}) => {
	return (
		<ConditionsContextProvider>
			<ServiceRouteContext>
				<Main title="Заказ услуги | Finish Worker">
					<ServiceRoutes isLoaded={isLoaded}/>
				</Main>
			</ServiceRouteContext>
		</ConditionsContextProvider>
	);
};
export default ServiceRoute;
