import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AdminActive from '../AdminUI/AdminActive';
import AdminBtn from '../AdminUI/AdminBtn';
import {
	deleteServiceListCategory,
	setServiceListCategoryProperty,
} from '../../../../store/actions/adminServiceListActions';

const maxSize = 5000;

const AdminServiceListCategories = ({category, idx, addSubCategoryHandler}) => {
	const fileUploadInput = useRef();
	const [errorImage, setErrorImage] = useState(null);
	const [picture, setPicture] = useState(category.picture)
	const dispatch = useDispatch();
	const setPropertyHandler = (categoryId, property, value = undefined) => {
		return (evt) => {
			dispatch(setServiceListCategoryProperty(categoryId, typeof value !== 'undefined' ? value : evt.target.value, property))
		}
	};

	const saveInputHandler = ({id, name}) => {
		if (name) {
			dispatch(setServiceListCategoryProperty(id, false, 'edit'));
		}
	};

	const deleteCategory = (id) => {
		dispatch(deleteServiceListCategory(id))
	};

	const isCategoryActive = (active) => {
		return active === 'Y' || active === true;
	}

	const openFileSelectorHandler = () => {
		fileUploadInput.current.click()
	}

	const   fileLoader = () => {
		let file = fileUploadInput.current.files[0]

		if (!file) {
			return
		}
		if (!file.type.startsWith('image/')) {
			fileUploadInput.current.value = ""
		setErrorImage('Допустимые расширения jpg, png, gif');
			setTimeout(() => {
				setErrorImage(null);
			}, 5000)

			return

		} else if (file.size / 1024 > maxSize) {
			fileUploadInput.current.value = ""
			setErrorImage(`Максимальный размер файла ${(maxSize / 1000).toFixed(2)} МБ`)
			setTimeout(() => {
				setErrorImage(null);
			}, 5000)

			return
		}

		setErrorImage(null);

		let reader = new FileReader()

		reader.onload = () => {

			let src = URL.createObjectURL(file)
			setPicture(src);

			if (reader.result) {
				let value = {
					name: file.name,
					src: reader.result,
					encoding: "base64"
				}
				dispatch(setServiceListCategoryProperty(category.id,  value, 'pictureValue'))
				dispatch(setServiceListCategoryProperty(category.id,  true, 'pictureChange'))
			}

		}
		reader.readAsDataURL(file)

	}

	return (
		<div
			className="d-flex align-items-center justify-content-between w-100">

			{
				category.edit ?
					<div className='d-flex align-items-center w-75'>
						<div className="d-flex align-items-center">
						<div className="edit_btn__js">
							<img src={picture} className="Admin_category-list-img Admin_category-list-img-edit"
								 onClick={openFileSelectorHandler}/>
							<input onChange={fileLoader} ref={fileUploadInput} type="file" className="d-none"/>
						</div>
							<TextField
								variant="outlined"
								className='Admin_category-list col-8 edit_btn__js'
								onChange={setPropertyHandler(category.id, 'name')}
								value={category.name || ''}
							/>
						</div>
						<TextField
							variant="outlined"
							className='Admin_category-list col-4 edit_btn__js'
							onChange={setPropertyHandler(category.id, 'sort')}
							value={category.sort}
						/>
					</div>
					:
					<div className="d-flex align-items-center justify-content-between text-white a__service-list__item">
						<div className='d-flex align-items-center'>
							<Typography variant="button" gutterBottom
										className="Admin_category-list-num m-0 mr-2">
								{idx}. &nbsp;

							</Typography>
							<img src={picture} className="Admin_category-list-img"/>
							<Typography variant="button" gutterBottom
										className="m-0 ml-2">
								{category.name} &nbsp;

							</Typography>
							<Typography variant="overline" display="block">
								{`[ разделов: ${category.subCategory ? category.subCategory.length : 0} ]`}
							</Typography>
						</div>
						<div>Сортировка: {category.sort}</div>
					</div>
			}


			<div className="d-flex align-items-center justify-content-end">

				<AdminActive active={isCategoryActive(category.active)} edited={category.edited}/>

				{
					!category.isNew &&
					<AdminBtn tip="Добавить раздел" type="add"
							  onClick={() => addSubCategoryHandler(category.id)}/>
				}

				{
					category.edit
						?
						<AdminBtn
							tip="Сохранить категорию"
							type="save"
							onClick={() => saveInputHandler(category)}/>
						:
						<AdminBtn
							tip="Редактировать категорию"
							type="edit"
							onClick={setPropertyHandler(category.id, 'edit', true)}/>
				}

				{
					category.active ?
						<AdminBtn tip="Деактивировать категорию" type="clear"
								  onClick={setPropertyHandler(category.id, 'active', 'N')}/>
						:
						<AdminBtn tip="Активировать категорию" type="check"
								  onClick={setPropertyHandler(category.id, 'active', 'Y')}/>
				}
				<AdminBtn tip="Удалить категорию"
						  type={`${category.deleted ? 'restore' : 'delete'}`}
						  onClick={() => deleteCategory(category.id)}/>
			</div>
		</div>
	);
};

export default AdminServiceListCategories;