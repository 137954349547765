import React, { useEffect, useState } from "react";
import DraggableScroll from "../../../../components/UI/DraggableScroll/DraggableScroll";
import AdminWorkerPersonalTeamLine from "./AdminWorkerPersonalTeamLine";
import AdminWorkerPersonalTeamHead from "./AdminWorkerPersonalTeamHead";
import { ROLE_SPECIALIST } from "../../../../config";
import axios from "../../../../rest/axios";
import qs from "qs";

const AdminWorkerPersonalTeam = ({ user }) => {
	const [team, setTeam] = useState([]);

	useEffect(() => {
		const workers = user.workers;
		if (workers) {
			const master = workers.find((worker) => worker.isMaster);
			if (master) {
				setTeam([master, ...user.workers.filter((worker) => !worker.isMaster)]);
			} else {
				setTeam([...user.workers.filter((worker) => !worker.isMaster)]);
			}
		}
	}, [user]);

	useEffect(() => {
		const fetchMaster = async (userId) => {
			try {
				const { data } = await axios.post(
					"/worker/about/",
					qs.stringify({
						token: localStorage.getItem("token"),
						userId,
					}),
				);
				if (data) {
					const master = data.data;
					setTeam([
						master,
						...master.workers.filter((worker) => !worker.isMaster),
					]);
				}
			} catch (e) {}
		};
		if (user.role === ROLE_SPECIALIST) {
			fetchMaster(user.master);
		}
	}, [user]);

	return (
		<>
			{!!team.length && (
				<DraggableScroll>
					<AdminWorkerPersonalTeamHead user={user} />
					{team.map((worker, idx) => (
						<AdminWorkerPersonalTeamLine
							key={worker.id}
							idx={idx + 1}
							master={worker}
							user={user}
						/>
					))}
				</DraggableScroll>
			)}
		</>
	);
};

export default AdminWorkerPersonalTeam;
