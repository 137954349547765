import React, {useEffect, useState} from 'react';
import {Paper} from '@material-ui/core';
import AdminOrderListFinancePayed from './AdminOrderListFinancePayed';
import AdminOrderListFinanceActs from './AdminOrderListFinanceActs';
import NumberFormat from 'react-number-format';

const AdminOrderListFinanceOne = ({order, reports}) => {
	const [acceptValueFinance, setValueFinance] = useState(0);
	const [totalPaid, setTotalPaid] = useState(0)
	useEffect(() => {
		let value = 0;
		let total = 0;
		if (!!reports.length) {
			reports.forEach((report) => {
				value += report.value
			})
			if (value) {
				setValueFinance((value * order.unitPrice).toFixed())
			}
		}
		if (!!order.tranches.length) {
			order.tranches.forEach((tranche) => {
				if (tranche.isPaid) {
					total += tranche.sum
				}
			})
			if (total) {
				setTotalPaid(total)
			}
		}
	}, [order, reports])

	return (
		<div className="row mx-0 pt-3 pb-2 px-1">
			<div className="col-6 p-0 d-flex flex-column justify-content-between">
				<ul className="px-1 m-0">
					<li className="AdminOrderList__finance-li">
						<Paper className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
							<div>Сумма заказа</div>
							<div><NumberFormat
								value={order.totalPrice.toFixed()}
								displayType={'text'}
								thousandSeparator={" "}/> р.
							</div>
						</Paper>
					</li>
					<li className="AdminOrderList__finance-li mt-2">
						<Paper className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
							<div>Фактическое выполнение</div>
							<div>
								<NumberFormat
									value={acceptValueFinance}
									displayType={'text'}
									thousandSeparator={" "}/> р.
							</div>
						</Paper>
					</li>
				</ul>
				<AdminOrderListFinanceActs order={order} reports={reports} sum={acceptValueFinance}/>
				<ul className="px-1 m-0">
					<li className="AdminOrderList__finance-li mt-2">
						<Paper className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
							<div>Неоформленное выполнение</div>
							<div>{acceptValueFinance - acceptValueFinance} р.</div>
						</Paper>
					</li>
					<li className="AdminOrderList__finance-li mt-2">
						<Paper
							className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
							<div>Осталось выполнить</div>
							<div><NumberFormat
								value={(order.totalPrice - acceptValueFinance).toFixed()}
								displayType={'text'}
								thousandSeparator={" "}/> р.
							</div>
						</Paper>
					</li>
				</ul>
			</div>
			<div className="col-6 p-0 d-flex flex-column justify-content-between">
				<AdminOrderListFinancePayed order={order} sum={totalPaid}/>
				<ul className="px-1 m-0">
					{
						acceptValueFinance - totalPaid > 0
						&& (
							<li className="AdminOrderList__finance-li mt-2">
								<Paper
									className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
									<div>Неоплаченное выполнение</div>
									<div><NumberFormat
										value={acceptValueFinance - totalPaid}
										displayType={'text'}
										thousandSeparator={" "}/> р.
									</div>
								</Paper>
							</li>
						)
					}

					<li className="AdminOrderList__finance-li mt-2">
						<Paper className="h-100 d-flex align-items-center justify-content-between py-2 pl-3 pr-5">
							<div>Остаток по оплате</div>
							<div><NumberFormat
								value={(order.totalPrice - totalPaid).toFixed()}
								displayType={'text'}
								thousandSeparator={" "}/> р.
							</div>
						</Paper>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default AdminOrderListFinanceOne;