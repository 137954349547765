import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {TITLE_CATEGORY} from '../../../../../config';


const AdminMastersListHeader = () => {
    return (
        <Paper className='d-flex my-2 w-100 p-3'>

            <Typography variant="overline"
                        className="col p-0 m-0"># / ID</Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0">ФИО</Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0">
                {TITLE_CATEGORY}
            </Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0">
                Профессия
            </Typography>

            <Typography variant="overline"
                        className="col-1 p-0 m-0">
                Телефон
            </Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0">
                Статус
            </Typography>

            <Typography variant="overline"
                        className="col-2 p-0 m-0">
                Модерация
            </Typography>

        </Paper>
    );
};

export default AdminMastersListHeader;