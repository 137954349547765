import React from "react";
import NumberFormat from "react-number-format";
import {
  getTotalCategoryMaterialsPrice,
  getTotalCategoryServicesPrice,
} from "./calculateFunctions";
import EstimateOrderHead from "./EstimateOrderHead";
import EstimateOrderCategoryRow from "./Rows/EstimateOrderCategoryRow";

export default class EstimateOrderToPrint extends React.Component {
  render() {
    const { estimateOrder, categories, options } = this.props;
    const totalMaterialsPrice = getTotalCategoryMaterialsPrice(
      estimateOrder.services
    );
    const totalWorkPice = getTotalCategoryServicesPrice(
      estimateOrder.services,
      options.workType.value,
      options
    );
    const totalPrice =
      options.supplyMaterials.value === "customer"
        ? totalWorkPice
        : totalWorkPice + totalMaterialsPrice;
    return (
      <div className="table-wrapper text-center overflow-auto">
        <EstimateOrderHead/>
        <table className="table table-bordered table-hover">
          <thead>
            <tr className="align-middle">
              <th className="align-middle" rowSpan="2">
                №
              </th>
              <th style={{ width: "40%" }} className="align-middle" rowSpan="2">
                Наименование работ
              </th>
              <th style={{ width: "3%" }} className="align-middle" rowSpan="2">
                Ед.изм.
              </th>
              <th style={{ width: "6%" }} className="align-middle" rowSpan="2">
                Кол-во
              </th>
              <th
                className="align-middle"
                colSpan={
                  options.supplyMaterials.value === "performer" ? "2" : "1"
                }
              >
                Цена
              </th>
              <th style={{ width: "22%" }} className="align-middle" rowSpan="2">
                Сумма
              </th>
            </tr>
            <tr>
              {options.supplyMaterials.value === "performer" && (
                <th>Материалы</th>
              )}
              <th>Работа</th>
            </tr>
          </thead>
          <tbody>
            {estimateOrder.categories.map((orderCategory) => {
              const category = categories.find(
                (cat) => cat.id === orderCategory.id
              );
              const subCategories = category.subCategory.filter((subC) =>
                estimateOrder.subCategories.find((e) => e.id === subC.id)
              );
              const categoryServices = estimateOrder.services.filter(
                (service) => service.category.id === orderCategory.id
              );
              return (
                <>
                  <EstimateOrderCategoryRow
                    category={category}
                    subCategories={subCategories}
                    categoryServices={categoryServices}
                    options={options}
                  />
                </>
              );
            })}
            <tr>
              <td />
              <td colSpan="3" className="text-left">
                <h5>
                  <b style={{ fontSize: "1.3rem" }}>ВСЕГО: </b>
                </h5>
              </td>
              {options.supplyMaterials.value === "performer" && (
                <td>
                  <b>
                    <NumberFormat
                      value={totalMaterialsPrice.toFixed(2)}
                      displayType="text"
                      thousandSeparator={" "}
                    />
                  </b>
                </td>
              )}
              <td>
                <b>
                  <NumberFormat
                    value={totalWorkPice.toFixed(2)}
                    displayType="text"
                    thousandSeparator={" "}
                  />
                </b>
              </td>
              <td>
                <b>
                  <NumberFormat
                    value={totalPrice.toFixed(2)}
                    displayType="text"
                    thousandSeparator={" "}
                  />
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
