import axios from "../../rest/axios";
import {
	FETCH_CONTACTS_ERROR,
	FETCH_CONTACTS_START,
	FETCH_CONTACTS_SUCCESS,
	FETCH_ONLINE_STATISTIC
} from "./actionTypes";

export function fetchContacts() {
	return async dispatch => {
		dispatch(fetchStart())
		try {
			const contacts = await axios.get('/get/contacts.php')
			dispatch(fetchSuccess(contacts.data))
		} catch (e) {
			dispatch(fetchError(e))
		}
	}
}

export function fetchOnlineStatistic() {
	return async dispatch => {
		dispatch(fetchStart())
		try {
			const {data} = await axios.get('/mobile/support/onlineStatistic/')
			dispatch({type: FETCH_ONLINE_STATISTIC, payload: {data}})
		} catch (e) {
			dispatch(fetchError(e))
		}
	}
}

function fetchStart() {
	return {
		type: FETCH_CONTACTS_START
	}
}

function fetchSuccess(contacts) {
	return {
		type: FETCH_CONTACTS_SUCCESS,
		contacts
	}
}

function fetchError(error) {
	return {
		type: FETCH_CONTACTS_ERROR,
		error
	}
}