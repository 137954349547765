import {
    ADD_MATERIAL_NORMS,
    ADD_SERVICE_NORMS,
    DELETE_MATERIAL_NORMS,
    DELETE_SERVICE_NORMS,
    EDIT_MATERIAL_NORMS,
    EDIT_SERVICE_NORMS,
    FETCH_ADMIN_CATEGORIES_SUCCESS,
    FETCH_ADMIN_GOODS_SUCCESS,
    FETCH_ADMIN_MATERIALS_SUCCESS,
    FETCH_ADMIN_PROFESSIONS_SUCCESS,
    FETCH_ADMIN_SERVICES_ERROR,
    FETCH_ADMIN_SERVICES_START,
    FETCH_ADMIN_SERVICES_SUCCESS,
    SAVE_ADMIN_CHANGES_SERVICE_NORM_ERROR,
    SAVE_ADMIN_CHANGES_SERVICE_NORM_NOTHING,
    SAVE_ADMIN_CHANGES_SERVICE_NORM_START,
    SAVE_ADMIN_CHANGES_SERVICE_NORM_SUCCESS,
    SET_ADMIN_SERVICE_NORM_PROPERTY,
    SET_CUSTOMER_COEFFICIENT,
    SET_MATERIAL_NORMS,
    SET_SERVICE_NORMS,
    SET_SERVICE_PROPERTY
} from "./actionTypes";
import {store} from "../../index";
import axios from "../../rest/axios";
import qs from 'qs';


export function setServiceProperty(serviceId, value, property, itemId = null) {
    return {
        type: SET_SERVICE_PROPERTY,
        payload: {
            serviceId,
            value,
            property,
            itemId
        }
    }
}

export function setServiceNorm(serviceId, normId, value, property, itemId = null) {

    return {
        type: SET_SERVICE_NORMS,
        payload: {
            serviceId,
            normId,
            value,
            property,
            itemId
        }
    }
}

export function addServiceNorm(serviceId, norm, itemId = null) {

    return {
        type: ADD_SERVICE_NORMS,
        payload: {
            serviceId,
            norm,
            itemId
        }
    }
}

export function deleteServiceNorm(serviceId, normId, itemId = null) {

    return {
        type: DELETE_SERVICE_NORMS,
        payload: {
            serviceId,
            normId,
            itemId
        }
    }
}

export function editServiceNorm(serviceId, normId, norm, itemId = null) {

    return {
        type: EDIT_SERVICE_NORMS,
        payload: {
            serviceId,
            normId,
            norm,
            itemId
        }
    }
}

export function setCustomerCoefficient(serviceId, value, type) {
    return {
        type: SET_CUSTOMER_COEFFICIENT,
        payload: {
            serviceId, value, type
        }
    }
}


export function setMaterialNorm(serviceId, materialId, value, property, itemId = null) {

    return {
        type: SET_MATERIAL_NORMS,
        payload: {
            serviceId,
            materialId,
            value,
            property,
            itemId
        }
    }
}

export function addMaterialNorm(serviceId, material, itemId = null) {

    return {
        type: ADD_MATERIAL_NORMS,
        payload: {
            serviceId,
            material,
            itemId
        }
    }
}

export function editMaterialNorm(serviceId, materialId, material, itemId = null) {

    return {
        type: EDIT_MATERIAL_NORMS,
        payload: {
            serviceId,
            materialId,
            material,
            itemId
        }
    }
}

export function deleteMaterialNorm(serviceId, materialId, itemId = null) {

    return {
        type: DELETE_MATERIAL_NORMS,
        payload: {
            serviceId,
            materialId,
            itemId
        }
    }
}


export function fetchServices() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            dispatch(fetchStart())
            try {
                let services = await axios.post('/admin/services/', qs.stringify({token}))

                dispatch(fetchSuccess(services.data.data))
            } catch (e) {
                dispatch(fetchError(e))
            }
        }
    }
}

export function fetchMaterials() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let materials = await axios.post('/admin/materials/', qs.stringify({token}))
            dispatch(fetchAllMaterialsSuccess(materials.data.data))
        }
    }
}

export function fetchProfessions() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let professions = await axios.post('/admin/professions/', qs.stringify({token}))
            dispatch(fetchProfessionsSuccess(professions.data.data))
        }
    }
}

export function fetchCategories() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let categories = await axios.post('/admin/categories/', qs.stringify({token}))
            dispatch(fetchCategoriesSuccess(categories.data.data))
        }
    }
}

export function fetchGoods() {
    return async dispatch => {
        let token = localStorage.getItem('token')
        if (token) {
            let goods = await axios.post('/admin/goods/', qs.stringify({token}))
            dispatch(fetchGoodsSuccess(goods.data.data))
        }
    }
}


function fetchStart() {
    return {
        type: FETCH_ADMIN_SERVICES_START
    }
}

function fetchSuccess(services) {
    return {
        type: FETCH_ADMIN_SERVICES_SUCCESS,
        payload: {
            services
        }
    }
}

function fetchError(error) {
    return {
        type: FETCH_ADMIN_SERVICES_ERROR,
        payload: {
            error
        }
    }
}

function fetchAllMaterialsSuccess(materials) {
    return {
        type: FETCH_ADMIN_MATERIALS_SUCCESS,
        payload: {
            materials
        }
    }
}

function fetchProfessionsSuccess(professions) {
    return {
        type: FETCH_ADMIN_PROFESSIONS_SUCCESS,
        payload: {
            professions
        }
    }
}

function fetchCategoriesSuccess(categories) {
    return {
        type: FETCH_ADMIN_CATEGORIES_SUCCESS,
        payload: {
            categories
        }
    }
}

function fetchGoodsSuccess(goods) {
    return {
        type: FETCH_ADMIN_GOODS_SUCCESS,
        payload: {
            goods
        }
    }
}

// SAVE
export function saveAdminChanges() {

    return async dispatch => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(saveStart())
            try {
                const adminService = store.getState().adminService
                // выбираем услуги, которые были изменены
                const services = adminService.services.filter(service => service.edited)

                if (!!services.length) {
                    // let response = await axios.post('/admin/save/', qs.stringify({services}))
                    let response = await axios.post('/admin/saveNorms/', qs.stringify({services, token}));
                    let errors = response.data.filter(data => {
                        return !data.result
                    })

                    if (!errors.length) {
                        await dispatch(saveSuccess(response.data))
                        dispatch(fetchServices())
                    }
                } else {
                    dispatch(saveNothing())
                }

            } catch (e) {
                dispatch(saveError(e))
            }
        }
    }
}

function saveNothing() {
    return {
        type: SAVE_ADMIN_CHANGES_SERVICE_NORM_NOTHING
    }
}

function saveStart() {
    return {
        type: SAVE_ADMIN_CHANGES_SERVICE_NORM_START
    }
}

function saveSuccess(saveResult) {
    return {
        type: SAVE_ADMIN_CHANGES_SERVICE_NORM_SUCCESS,
        payload: {
            saveResult
        }
    }
}

function saveError(error) {
    return {
        type: SAVE_ADMIN_CHANGES_SERVICE_NORM_ERROR,
        payload: {
            error
        }
    }
}

export function setAdminServiceNormProperty(value, property) {
    return {
        type: SET_ADMIN_SERVICE_NORM_PROPERTY,
        payload: {
            value,
            property
        }
    }
}