import {
	AUTH_LOGOUT,
	FETCH_AUTH_ERROR,
	FETCH_AUTH_START,
	FETCH_AUTH_SUCCESS,
	FETCH_LOGIN_ERROR,
	FETCH_LOGIN_START,
	FETCH_LOGIN_SUCCESS,
	FETCH_USER_ABOUT,
	SET_AUTH_PROPERTY,
} from "../actions/actionTypes";

const initialState = {
	currentTab: 2,
	email: "",
	password: "",
	token: "",
	passwordConfirm: "",
	checkWord: "",
	isLogin: false,
	wrongPass: false,
	about: {},
	formControls: {
		email: {
			id: "email",
			label: "E-mail",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				email: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				email: `Введите корректный email`,
			},
		},
		name: {
			id: "name",
			label: "Имя",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				minLength: 3,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				minLength: `Минимум 3 символа`,
			},
		},
		phone: {
			id: "phone",
			label: "Телефон",
			type: "tel",
			value: "",
			valid: true,
			validation: {
				required: true,
				phone: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				phone: `Введите корректный номер телефона`,
			},
		},
		password: {
			id: "password",
			label: "Пароль",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				minLength: 8,
				password: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				minLength: `Минимум 8 символов`,
				password: "Недопустимые символы для пароля",
			},
		},
		passwordConfirm: {
			id: "passwordConfirm",
			label: "Подтвердите пароль",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
				confirmPass: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
				confirmPass: `Пароли должны совпадать`,
			},
		},
		passwordAuth: {
			id: "password",
			label: "Пароль",
			type: "text",
			value: "",
			valid: true,
			error: "",
			validation: {
				required: true,
			},
			errorMessage: {
				required: "Это поле необходимо заполнить",
			},
		},
	},
	user: {},
	error: false,
};

export default function AuthReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AUTH_PROPERTY:
			return {
				...state,
				[action.propertyName]: action.value,
			};

		// case VALIDATE_AUTH_FORM_CONTROLS:
		//     let formControls = {...state.formControls}
		//     formControls[action.formControlId].error = action.error
		//     formControls[action.formControlId].valid = action.valid
		//     return {
		//         ...state,
		//         formControls
		//     }
		case FETCH_AUTH_START:
		case FETCH_LOGIN_START:
			return {
				...state,
				loading: true,
			};
		case FETCH_AUTH_SUCCESS:
			return {
				...state,
				user: action.user,
				loading: false,
			};
		case FETCH_LOGIN_SUCCESS:
			return {
				...state,
				token: action.token,
				loading: false,
			};
		case FETCH_AUTH_ERROR:
		case FETCH_LOGIN_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			};

		case AUTH_LOGOUT:
			localStorage.removeItem("token");
			localStorage.removeItem("userId");
			localStorage.removeItem("expirationDate");
			return {
				...state,
				token: null,
			};

		case FETCH_USER_ABOUT:
			return {
				...state,
				about: action.data,
			};

		default:
			return state;
	}
}
