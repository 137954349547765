import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
	fetchWorkerPersonalDates,
	setWorkerPersonalDate,
} from "../../../../store/actions/workerPersonalActions";
// import AdminSendLoader from "../../../Admin/AdminComponents/AdminUI/AdminSendLoader";
import * as moment from "moment";

const WorkerPersonalDates = () => {
	const [currentDate, setCurrentDate] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);
	const { dates } = useSelector((state) => state.workerPersonal);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!!dates.length && !currentDate) {
			setCurrentDate(dates[0]);
		}
	}, [dates, currentDate]);

	useEffect(() => {
		if (!!currentDate) {
			setCurrentTime(currentDate.times[0]);
		}
	}, [currentDate]);

	useEffect(() => {
		dispatch(fetchWorkerPersonalDates());
	}, [dispatch]);

	const dateHandler = (evt) => {
		let date = dates.find((date) => date.id === evt.target.value);

		setCurrentDate(date);
	};

	const timeHandler = (evt) => {
		setCurrentTime(evt.target.value);
	};

	const submitHandler = () => {
		dispatch(
			setWorkerPersonalDate(
				moment.unix(currentDate.date).format("DD.MM.YYYY") + " " + currentTime,
			),
		);
	};

	return (
		<>
			{!!dates.length ? (
				<Paper className='p-3 d-flex align-items-center justify-content-between'>
					{!!currentDate && (
						<div className='pr-3 w-25'>
							<FormControl className='form-control my-3'>
								<InputLabel id='date'>Дата</InputLabel>
								<Select
									labelId='date'
									value={currentDate.id}
									onChange={dateHandler}>
									{dates.map((date) => {
										return (
											<MenuItem key={date.id} value={date.id}>
												<span>
													{moment.unix(date.date).format("DD.MM.YYYY")}
												</span>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					)}
					{!!currentDate && currentDate.times.indexOf(currentTime) !== -1 && (
						<div className='pr-3 w-25'>
							<FormControl className='form-control my-3'>
								<InputLabel id='date'>Время</InputLabel>
								<Select
									labelId='date'
									value={currentTime}
									onChange={timeHandler}>
									{currentDate.times.map((time, idx) => {
										return (
											<MenuItem key={idx} value={time}>
												<span>{time}</span>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					)}
					{!!currentDate && currentDate.address.value && (
						<div className='mr-3'>
							<strong>Адрес инструктажа:&nbsp;</strong>
							{currentDate.address.value}
						</div>
					)}
					<div className='mt-2 d-flex justify-content-end'>
						<Button
							onClick={submitHandler}
							variant='contained'
							color='primary'
							component='button'
							type='submit'>
							<div>Сохранить</div>
						</Button>
					</div>
				</Paper>
			) : (
				<div className='wp__date-empty'>
					Нет доступных дат для прохождения инструктажа
				</div>
			)}
		</>
	);
};

export default WorkerPersonalDates;
